import { useRef, useEffect } from 'react';

import './index.less';

interface LiElementProps {
  children: React.ReactNode;
}

const LiElement: React.FC<LiElementProps> = (props) => {
  const { children } = props;

  const liRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const liElement = entries?.[0]?.target as HTMLLIElement;
      const math = liElement?.querySelectorAll<HTMLSpanElement>('.math')?.[0];

      if (math) {
        const top = math?.offsetTop === 0 ? math?.clientHeight / 2 - 10 : 0;

        liElement?.style?.setProperty('--before-top', `${top}px`);
      }
    });

    if (liRef?.current) {
      observer.observe(liRef?.current as HTMLLIElement);
    }

    return () => observer.disconnect();
  }, [liRef?.current]);

  return <li ref={liRef}>{children}</li>;
};

export default LiElement;
