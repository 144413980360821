import { useCallback, useEffect, useMemo, useState } from 'react';
import { App } from 'antd';
import useConnectorStore, { ConnectorType, Connector } from '@/store/connector';
import useDrivePicker from 'react-google-drive-picker';
import { CallbackDoc } from 'react-google-drive-picker/dist/typeDefs';
import { NonOAuthError, useGoogleLogin } from '@react-oauth/google';
import { getGoogleDriveToken, chatCreateUsingPOST } from '@/services';
import {
  useChatStore,
  useUploadStore,
  useCommonStore,
  useUserStore,
  useSendUploadStore,
} from '@/store';
import { useGlobalModal } from '@/layout/BasicLayout';
import { useTranslation } from 'react-i18next';
import useGASendEvent from '@/hooks/useGASendEvent';
import type { ResponseType, DocEntries } from '@/type';
import type { RcFile } from 'antd/es/upload';

import '@/components/UploadGroup/GdriveUploadPicker.less';
import useDA from './useDataAnalytics';
import { DOC_FILE_MIME_WHITE_LIST, isModelPro } from '@/common/config';
import { UPLOAD_STATUS } from '@/store/sendUpload';

export enum EventConnectFrom {
  upload,
  setting,
}

export const useGdriveEvent = ({
  sendEvent,
}: {
  sendEvent: (name: string, params?: any) => Promise<void>;
}) => {
  return {
    connect: (from: EventConnectFrom) => {
      sendEvent('Click_ConnectGoogleDrive', { from });
    },
    connectFinish: () => {
      sendEvent('ConnectedGoogleDrive');
    },
    uploadStart: () => {
      sendEvent('Click_AddFromGoogleDrive');
    },
    disconnect: () => {
      sendEvent('Click_DisconnectGoogleDrive');
    },
  };
};

interface UseGdriveUploadProps {
  onAddFilesPopoverClose?: () => void;
  /**
   *  是否来自Sendcontroller
   */
  fromSendController?: boolean;
}

const useGdriveUpload = (props?: UseGdriveUploadProps) => {
  const { fromSendController, onAddFilesPopoverClose } = props || {};
  const uploadStore = useUploadStore();
  const { connectorList, updateConnector, updateGdrive } = useConnectorStore();
  const { model } = useCommonStore();
  const [isConnecting, setIsConnecting] = useState(false);
  const [openPicker] = useDrivePicker();
  const { ocrLanguage, setChannel, setSendOptions } = useChatStore();
  const { allUploadFiles, setFileStatusMap, setCurrentUploadFiles, setAllUploadFiles } =
    useSendUploadStore();

  const { createComplete, checkLoginStatus, checkPayStatus } = useGlobalModal();
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { sendEvent } = useGASendEvent();
  const { sendDAEvent, preparePropertyFor } = useDA();
  const sendGdriveEvent = useGdriveEvent({ sendEvent });
  const { userInfo } = useUserStore.getState();
  const { usageInfo } = useCommonStore.getState();

  const gDrive = useMemo(
    () => connectorList.find((c) => c.type === ConnectorType.Gdrive),
    [connectorList],
  );
  const gDriveToken = gDrive?.access_token;

  useEffect(() => {
    if (userInfo.isLogin && (!gDriveToken || gDrive.expires_at <= Date.now())) {
      updateConnector();
    }
  }, []);

  useEffect(() => {
    const isNewLogin = userInfo.isLogin && !gDriveToken;
    const isLogout = !userInfo.isLogin && gDriveToken;
    if (isNewLogin || isLogout) {
      updateConnector();
    }
  }, [userInfo, gDriveToken]);

  const createChatByGdrive = async (gDriveParam: {
    id: string;
    mimeType: string;
    name: string;
    url: string;
  }) => {
    uploadStore.reset();
    uploadStore.setUploading(true);
    // 首页上传进度需要文件类型 拼接在 name 中 用来判断
    uploadStore.setFile({
      name: `${gDriveParam.name}ext_google.${gDriveParam.mimeType}`,
    } as RcFile);
    uploadStore.increaseProgressTo99();

    const PARSE_FILE_MODEL = 'Standard';
    try {
      const res = await chatCreateUsingPOST<
        ResponseType<{
          md5: string;
          channel_id: string;
          channel_name: string;
          docEntries: DocEntries;
        }>
      >({
        model: model || PARSE_FILE_MODEL,
        gdrive: gDriveParam,
        languages: ocrLanguage.ocrValue,
        channelType: 'ONE_CHAT',
        // searchSwitch: isUpgrade, // 暂时注释，默认不联网，等模型效果达到预期再打开
      });
      const { channel_id: channelId, channel_name: channelName, ...restParams } = res?.data || {};
      const fileType =
        ['doc', 'docx', 'pdf'].find((k) => gDriveParam.mimeType?.includes(k)) ||
        gDriveParam.mimeType;
      setChannel({
        channelName,
        channelId,
        fileTypeEnum: fileType,
        gdriveFileTypeEnum: fileType,
        ...restParams,
      });
      setSendOptions({
        channelType: 'ONE_CHAT',
        roleEnum: 'SUMMARYPROMPT',
        docs: [
          {
            md5: gDriveParam.id,
            file_name: gDriveParam.name,
            file_type: gDriveParam.mimeType,

            file_page_count: 1,
          },
        ],
        defaultOpenGUI: ['application/pdf', 'pplication/vnd.google-apps.document'].includes(
          gDriveParam.mimeType,
        ),
      });
      createComplete(channelId);
    } catch (e: any) {
      const reqMsg = e?.message;
      const serverMsg = e?.response?.data?.message;
      const dataMsg = e?.data?.message;
      const msg = dataMsg || serverMsg || reqMsg || null;
      if (msg) {
        message.error(msg);
      }
    } finally {
      uploadStore.reset();
    }
  };

  const handleOpenGdrivePicker = useCallback(
    ({ token }: { token: string }) => {
      setIsConnecting(true);
      const startUploadTime = Date.now();
      openPicker({
        appId: import.meta.env.VITE_APP_GDRIVE_APPID,
        clientId: import.meta.env.VITE_APP_GOOGLE_CLIENT_ID,
        developerKey: '',
        viewId: 'DOCS',
        token,
        showUploadView: false,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: false,
        locale: navigator.language || 'en',
        // customViews: customViewsArray, // custom view
        callbackFunction: (data) => {
          if (['cancel', 'picked'].includes(data.action)) {
            setIsConnecting(false);
          }
          const { action, docs } = data;
          if (action === 'picked' && docs[0]) {
            const { id, mimeType, name, url } = docs[0];

            if (fromSendController) {
              if (onAddFilesPopoverClose) {
                if (!DOC_FILE_MIME_WHITE_LIST.includes(mimeType)) {
                  message.error(t('components.upload.uploadDocTypeErr'));
                  return;
                }
                onAddFilesPopoverClose?.();
              }
              const fileInfo = {
                uid: id,
                name,
                type: mimeType,
                url,
                mimeType,
              };
              setFileStatusMap(fileInfo.uid, {
                status: UPLOAD_STATUS.Success,
                progress: 100,
              });
              setCurrentUploadFiles([fileInfo]);
              setAllUploadFiles([...allUploadFiles, fileInfo]);
            } else {
              sendGdriveEvent.uploadStart();
              const uploadTime = Date.now() - startUploadTime;
              sendDAEvent('UploadDocumentResult', {
                is_success: true,
                file_type: mimeType,
                filename: name,
                md5: id,
                duration: uploadTime / 1000,
                failurereason: '',
              });
              preparePropertyFor(
                'Chat_EnterChat',
                'docentries',
                docs.map((doc: CallbackDoc) => ({
                  md5: doc.serviceId,
                  filename: doc.name,
                  file_type: doc.mimeType,
                })),
              );
              createChatByGdrive({ id, mimeType, name, url });
            }
          }
        },
      });
    },
    [location, t, openPicker, createChatByGdrive, onAddFilesPopoverClose, fromSendController],
  );

  const handleConnectGdrive = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/drive.file',
    overrideScope: true, // 默认权限包含 email 等，是不需要的 https://github.com/MomenSherif/react-oauth/issues/124
    onSuccess: async (codeResponse) => {
      if (codeResponse?.code) {
        const res = await getGoogleDriveToken<ResponseType<Connector>>({
          code: codeResponse?.code as string,
          uri: location.origin,
        });
        if (res.data) {
          sendGdriveEvent.connectFinish();
          message.success(t('components.GdriveUpload.connectSuccess'));
          handleOpenGdrivePicker({
            token: res.data.access_token,
          });
          updateGdrive(res.data);
        }
      }
    },
    onNonOAuthError: (nonOAuthError: NonOAuthError) => {
      if (nonOAuthError.type === 'popup_failed_to_open') {
        message.warning(t('layout.settingModal.connectPopupBlockWarning'));
      }
    },
  });

  const validatePay = (model?: string) => {
    if (!model) return true;
    if (usageInfo.docUploadLimitHint) {
      checkPayStatus?.({
        shortMessage: t('common.filesPerDay', { count: 2 }),
        source: 'FREEDAYMAXFILE',
      });
      return false;
    }
    if (!userInfo.isPro && !userInfo.isTrial && isModelPro(model)) {
      checkPayStatus({
        shortMessage: t('common.responseWith', { name: model }),
        source: 'GPT4Turbo',
      });
      return false;
    }
    return true;
  };

  const handleGdrive = async (model?: string) => {
    if (!checkLoginStatus?.({ type: 'openurl', model: 'fake' })) return;
    // 校验付费
    if (!validatePay(model)) {
      return;
    }
    let token = gDriveToken;
    const isTokenExpires = token && gDrive?.expires_at && Date.now() >= gDrive?.expires_at;
    // 过期刷新 & 没存刷新，没存可能是刚 login
    if (isTokenExpires || !gDriveToken) {
      const connectorList = await updateConnector();
      token = connectorList.find((c) => c.type === ConnectorType.Gdrive)?.access_token;
    }
    if (token) {
      handleOpenGdrivePicker({
        token,
      });
    } else {
      sendGdriveEvent.connect(EventConnectFrom.upload);
      handleConnectGdrive();
    }
  };

  return {
    gDrive,
    connectorList,
    isConnecting,
    handleConnectGdrive,
    openPicker,
    handleOpenGdrivePicker,
    createChatByGdrive,
    updateConnector,
    handleGdrive,
  };
};

export default useGdriveUpload;
