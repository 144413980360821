import { Extension } from '@tiptap/core';
import { TextSelection } from '@tiptap/pm/state';
import { Callout } from '../Callout';
import { CodeBlock } from '../CodeBlock';

export const CustomSelectAll = Extension.create({
  name: 'customSelectAll',

  addKeyboardShortcuts() {
    return {
      'Mod-a': () => {
        const { tr, doc, selection } = this.editor.state;
        const { $from } = selection;
        let depth = $from.depth;
        let cell = $from.node(depth);

        while (
          depth > 0 &&
          !['tableCell', 'tableHeader', 'column', Callout.name, CodeBlock.name].includes(
            cell.type.name,
          )
        ) {
          depth--;
          cell = $from.node(depth);
        }

        if (['tableCell', 'tableHeader', 'column'].includes(cell.type.name)) {
          const cellPos = $from.start(depth);
          // cell empty
          if (cell.content.size === 2) {
            this.editor.commands.selectAll();
          } else {
            const cellSelection = TextSelection.create(doc, cellPos, cellPos + cell.nodeSize - 2);
            this.editor.view.dispatch(tr.setSelection(cellSelection));
          }
        } else if (cell.type.name === Callout.name) {
          const calloutPos = $from.start(depth);
          const calloutContentSize = cell.content.size;
          // 选择callout的文本，不选择emoji
          const calloutSelection = TextSelection.create(
            doc,
            calloutPos + 1,
            calloutPos + calloutContentSize - 1,
          );
          this.editor.view.dispatch(tr.setSelection(calloutSelection));
        } else {
          this.editor.commands.selectAll();
        }

        return true;
      },
    };
  },
});
