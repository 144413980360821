import { useState, useRef, useEffect, KeyboardEvent } from 'react';
import { Button, Modal, Input, Divider } from 'antd';
import CustomIcon from '@/components/CustomIcon';
import { useDA, useGASendEvent, useSundry } from '@/hooks';
import { useGoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
import {
  googleCodeLoginTokenUsingGET,
  toLoginByEmailValidationCodeUsingPOST,
  loginByEmailValidationCodeUsingPOST,
} from '@/services';
import classNames from 'classnames';
import qs from 'qs';
import { isString } from 'lodash-es';
import { useLocation } from 'react-router-dom';
import { GRAY_HEADER_KEY, GRAY_HEADER_VALUES, AB_EXPERIMENTS } from '@/common/config';
import { getGrecaptchaToken } from '@/common/grecaptcha';

import type { ResponseType } from '@/type';
import type { InputRef } from 'antd';
import { useTranslation } from 'react-i18next';
import { RawAxiosRequestHeaders } from 'axios';
import { useDebounceFn } from 'ahooks';

import './index.less';
import { useUserStore } from '@/store';

interface ILoginProps {
  open: boolean;
  onCancel: () => void;
  onLoginSuccess: (token: string, loginType?: string) => void;
}

const { VITE_APP_HOST, VITE_APP_APPLE_CLIENT_ID } = import.meta.env;

const Login: React.FC<ILoginProps> = (props) => {
  const { open, onCancel, onLoginSuccess } = props;
  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();
  const [loginStep, setLoginStep] = useState(1);
  const [email, setEmail] = useState('');
  const [emailFormat, setEmailFormat] = useState(false); // 邮箱格式校验
  const [verificationCodeFormat, setVerificationCodeFormat] = useState(false); // 验证校验
  const [verificationCode, setVerificationCode] = useState(Array(6).fill(''));
  const [countdown, setCountdown] = useState(59);
  const [isCounting, setIsCounting] = useState(false);
  const { pathname } = useLocation();
  const isHomePage = pathname === '/home';
  const { experiment } = useSundry();
  const freeModelFeature = experiment(AB_EXPERIMENTS.STANDARD_FREE);
  const { guestToken } = useUserStore();

  const inputEmailRef = useRef<InputRef>(null);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(true);

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      if (codeResponse?.code) {
        const res = await googleCodeLoginTokenUsingGET<ResponseType<string>>(
          {
            code: codeResponse?.code as string,
            uri: location.origin,
          },
          isHomePage ? { [GRAY_HEADER_KEY]: GRAY_HEADER_VALUES.input } : undefined,
        );
        sendEvent('googlelogin_success_status');
        onLoginSuccess(res.data, 'google-default');
      }
    },
    onError: (errorResponse) => {
      sendEvent('LoginFail', {
        loginType: 'google-default',
        error: errorResponse?.error,
        error_description: errorResponse?.error_description,
        error_uri: errorResponse?.error_uri,
      });
    },
  });

  const onClickGoogleLogin = () => {
    sendEvent('Clickgooglelogin');
    sendDAEvent('RegistLoginModalButtonClick', {
      button_name: 'continue_with_google',
    });
    handleGoogleLogin();
  };

  const handleAppleLogin = (props: { onClick: (e?: any) => void; disabled?: boolean }) => {
    sendEvent('applelogin_click');
    sendDAEvent('RegistLoginModalButtonClick', {
      button_name: 'continue_with_apple',
    });
    props.onClick();
  };

  const handleCancel = (loginStep: number) => {
    sendEvent('loginwindow_close_click');
    sendDAEvent(
      loginStep === 1 ? 'RegistLoginModalButtonClick' : 'EmailVerificationPageButtonClick',
      {
        button_name: 'close',
      },
    );
    onCancel();
    setShowMore(true);
    setLoginStep(1);
    setEmail('');
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailFormat(false);
  };

  const handleNext = async () => {
    sendDAEvent('RegistLoginModalButtonClick', {
      button_name: 'next',
    });
    const regexPattern = /[A-Za-z0-9.\-+_]+@[a-z0-9.\-+_]+\.[a-z]+/;
    if (!email) return;
    sendEvent('emaillogin_click');

    if (regexPattern.test(email)) {
      sendEvent('emaillogin_check_success_status');
      setLoginStep((prev) => prev + 1);
      setIsCounting(true);
      setCountdown(59);
      setVerificationCode(Array(6).fill(''));
      const token = await getGrecaptchaToken();
      const extraHeaders: RawAxiosRequestHeaders = {
        gtoken: token,
        ...(isHomePage ? { [GRAY_HEADER_KEY]: GRAY_HEADER_VALUES.input } : {}),
      };
      toLoginByEmailValidationCodeUsingPOST({ email, token }, extraHeaders);
    } else {
      setEmailFormat(true);
    }
  };

  const { run: handleNextDebounce } = useDebounceFn(handleNext, {
    wait: 2000,
    leading: true,
    trailing: false,
  });

  const handleInput = (index: number, value: string) => {
    if (!/^\d$/.test(value) && value !== '') return;
    // 更新验证码数组中的对应位置
    const updatedCode = [...verificationCode];
    updatedCode[index] = value;
    setVerificationCode(updatedCode);
    if (value) {
      // 移动焦点到下一个输入框
      if (index < verificationCode.length - 1) {
        inputRefs.current[index + 1]?.focus();
        if (verificationCode[index + 1]) {
          setTimeout(() => {
            inputRefs.current[index + 1]?.select();
          });
        }
      }
    }
    // else {
    // if (index > 0) {
    //   inputRefs.current[index - 1]?.focus();
    // }
    // }
    verificationCodeFormat && setVerificationCodeFormat(false);
  };

  // 处理按键按下事件
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && index > 0 && verificationCode[index] === '') {
      e.preventDefault();
      inputRefs.current[index - 1]?.focus(); // 当按下退格键且当前格子为空时，向前移动选中框
    } else if (e.key === 'ArrowRight') {
      if (index < verificationCode.length - 1) {
        inputRefs.current[index + 1]?.focus();
        setTimeout(() => {
          inputRefs.current[index + 1]?.select();
        });
      }
    } else if (e.key === 'ArrowLeft') {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
        inputRefs.current[index - 1]?.select();
        setTimeout(() => {
          inputRefs.current[index - 1]?.select();
        });
      }
    } else if (e.key >= '0' && e.key <= '9') {
      if (verificationCode[index] == e.key) {
        e.preventDefault();
        inputRefs.current[index + 1]?.focus();
        setTimeout(() => {
          inputRefs.current[index + 1]?.select();
        });
      }
    }
  };

  const handleFocus = (index: number) => {
    inputRefs.current[index]?.select();
  };

  const handleResendCode = async () => {
    sendDAEvent('EmailVerificationPageButtonClick', {
      button_name: 'resend_code',
    });
    // 发邮件
    setIsCounting(true);
    setCountdown(59);
    const token = await getGrecaptchaToken();
    const extraHeaders: RawAxiosRequestHeaders = {
      gtoken: token,
      ...(isHomePage ? { [GRAY_HEADER_KEY]: GRAY_HEADER_VALUES.input } : {}),
    };
    toLoginByEmailValidationCodeUsingPOST({ email, token }, extraHeaders);
  };

  const handleVerifyCode = async () => {
    sendEvent('emaillogin_verifycode_click');
    sendDAEvent('EmailVerificationPageButtonClick', {
      button_name: 'verify',
    });
    const code = verificationCode.join('');
    try {
      const res = await loginByEmailValidationCodeUsingPOST<ResponseType<string>>({ email, code });
      if (res?.data) {
        sendEvent('emaillogin_success_status');
        sendDAEvent('EmailVerificationResult', {
          issuccess: true,
        });
        props?.onLoginSuccess(res?.data, 'Email');
      } else {
        setVerificationCodeFormat(true);
        sendDAEvent('EmailVerificationResult', {
          issuccess: false,
        });
      }
    } catch (e) {
      setVerificationCodeFormat(true);
      sendDAEvent('EmailVerificationResult', {
        issuccess: false,
      });
    }
  };

  const { run: handleVerifyCodeDebounce } = useDebounceFn(handleVerifyCode, {
    wait: 2000,
    leading: true,
    trailing: false,
  });

  const handleToggleEmail = () => {
    setLoginStep((prev) => prev - 1);
    setVerificationCodeFormat(false);
    sendEvent('Clikedifferentlogin');
    sendDAEvent('EmailVerificationPageButtonClick', {
      button_name: 'use_a_different_email',
    });
  };

  const { run: handleToggleEmailDebounce } = useDebounceFn(handleToggleEmail, {
    wait: 2000,
    leading: true,
    trailing: false,
  });

  const handlePasteCode = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData('text');
    // 如果粘贴的数据是六位数字，将其设置到输入框中
    if (/^\d{6}$/.test(pastedData)) {
      const code = (pastedData + '').split('');
      setVerificationCode(code);
    } else {
      // 阻止默认粘贴行为
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (open) {
      sendEvent('loginwindow_click');
      setTimeout(() => {
        inputEmailRef?.current?.focus();
      });
    }
  }, [open]);

  useEffect(() => {
    if (loginStep === 2) {
      inputRefs.current && inputRefs.current[0]?.focus();
      sendEvent('emaillogin_verifypage_show');
    }
  }, [loginStep]);

  useEffect(() => {
    let timer: any;

    if (isCounting && countdown > 0) {
      // 启动倒计时
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsCounting(false);
    }

    // 清理倒计时定时器
    return () => {
      clearInterval(timer);
    };
  }, [isCounting, countdown]);

  useEffect(() => {
    const originalWindowOpen = window.open;

    window.open = function (
      url?: string | URL | undefined,
      target?: string | undefined,
      features?: string | undefined,
    ) {
      if (
        isString(url) &&
        url?.toString()?.includes('https://accounts.google.com/o/oauth2/v2/auth')
      ) {
        const [baseUrl, queryString] = (url as string).split('?');
        const params = qs.parse(queryString);
        params.prompt = 'select_account';
        const newQueryString = qs.stringify(params);
        const newUrl = `${baseUrl}?${newQueryString}`;
        return originalWindowOpen(newUrl, target, features);
      }
      return originalWindowOpen(url, target, features);
    };

    return () => {
      window.open = originalWindowOpen;
    };
  }, []);

  const renderStepOne = () => (
    <>
      <div
        className={classNames({
          'email-login-wrapper': true,
          'free-model': freeModelFeature,
        })}
      >
        <Input
          className={classNames({
            'email-login-input': true,
            'email-format-error': emailFormat,
          })}
          value={email}
          onChange={handleEmailChange}
          placeholder={t('common.emailAddress')}
          ref={inputEmailRef}
        />

        <div
          className={classNames({
            'email-error-tip': true,
            'email-error-tip-show': !emailFormat,
          })}
        >
          {t('common.againEmail')}
        </div>

        <Button
          className={classNames({
            'step-next': true,
            'step-next-disabled': !email,
          })}
          onClick={handleNextDebounce}
        >
          {t('common.Next')}
        </Button>

        <Divider plain>{t('common.or')}</Divider>
      </div>

      <div className="login-btn-wrapper">
        <Button className="login-btn" onClick={() => onClickGoogleLogin()}>
          <CustomIcon className="login-icon" type="google" />
          <span className="login-text">{t('common.continueGoogle')}</span>
        </Button>
        {showMore && (
          <span className="show-more-btn" onClick={() => setShowMore(false)}>
            {t('common.more')} <CustomIcon className="arrow-icon" type="arrowBottomSquare" />
          </span>
        )}
        <AppleLogin
          clientId={VITE_APP_APPLE_CLIENT_ID}
          redirectURI={VITE_APP_HOST}
          responseType={'code'}
          responseMode={'query'}
          render={(props) => {
            return (
              <Button
                className={classNames({
                  'login-btn': true,
                  'show-more': showMore,
                })}
                onClick={() => handleAppleLogin(props)}
              >
                <CustomIcon className="login-icon" type="apple" />
                <span className="login-text">{t('common.continueApple')}</span>
              </Button>
            );
          }}
        />
      </div>

      <div className="desc-text">
        {t('hoc.requireAuth.descText')}{' '}
        <a className="highlight-text" target="_blank" href="/terms/user">
          {t('common.descService')}
        </a>
        {' & '}
        <a className="highlight-text" target="_blank" href="/terms/privacy">
          {t('common.descPrivacy')}
        </a>
        .
      </div>
    </>
  );

  const renderStepTwo = () => (
    <div
      className={classNames({
        'email-login-step-two': true,
        'free-model': freeModelFeature,
      })}
    >
      <div className="verify-email">{t('hoc.requireAuth.verifyEmail')}</div>
      <div className="sent-email">
        {t('hoc.requireAuth.sentTo')} {email}.{t('hoc.requireAuth.checkEmail')}
      </div>
      <div className="code-input-wra">
        {verificationCode.map((value, index) => (
          <input
            className={classNames({
              'code-input': true,
              'code-input-error': verificationCodeFormat,
            })}
            key={index}
            type="text"
            maxLength={1}
            value={value}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onFocus={() => handleFocus(index)}
            onChange={(e) => handleInput(index, e.target.value)}
            ref={(input) => (inputRefs.current[index] = input as HTMLInputElement)}
            onPaste={handlePasteCode}
          />
        ))}
      </div>
      <div
        className={classNames({
          'wrong-code': true,
          'wrong-code-show': verificationCodeFormat,
        })}
      >
        {t('hoc.requireAuth.wrongCode')}
      </div>
      {countdown ? (
        <div className="count-down">{countdown}s</div>
      ) : (
        <div className="resend-code">
          <span onClick={handleResendCode}>{t('hoc.requireAuth.resendCode')}</span>
        </div>
      )}
      <div className="verify-btn" onClick={handleVerifyCodeDebounce}>
        {t('hoc.requireAuth.Verify')}
      </div>
      <div className="use-different" onClick={handleToggleEmailDebounce}>
        {t('hoc.requireAuth.useEmail')}
      </div>
    </div>
  );

  const renderContent = () => {
    if (loginStep === 1) {
      return renderStepOne();
    }
    if (loginStep === 2) {
      return renderStepTwo();
    }
  };

  return (
    <Modal
      wrapClassName="login-modal"
      centered
      width={440}
      zIndex={1100}
      open={open}
      footer={null}
      styles={{
        mask: {
          backgroundColor: 'rgba(0, 0, 0, 0.88)',
        },
      }}
      onCancel={() => handleCancel(loginStep)}
      closeIcon={<CustomIcon className="close-icon" type="modalClose" />}
    >
      <div className="login-container">
        <div className="login-header">
          <div className="login-logo">
            <CustomIcon className="brand-icon" type="logoText" />
          </div>
          <div className="login-title">
            <div className="login-title-text"> {import.meta.env.VITE_APP_TITLE}</div>
            <div className="login-slogan">{t('common.Workspace')}</div>
          </div>
        </div>
        {freeModelFeature && !guestToken && (
          <div className="free-model-desc">
            {t('common.freeModelBefore')}
            <span className="emp">{t('common.freeModel')}</span>
            {t('common.freeModelAfter')}
          </div>
        )}
        {guestToken && <div className="free-model-desc">{t('common.exemptFromLogin')}</div>}
        {renderContent()}
      </div>
    </Modal>
  );
};

export default Login;
