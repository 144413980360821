/*
 * @Author: wubo
 * @Date: 2023-06-13 20:22:37
 * @LastEditTime: 2024-01-07 10:36:45
 * @LastEditors: zuoyuxing001
 * @Description:
 */
import { useEffect, useState } from 'react';
import a from 'axios';
import {
  CUSTOM_RESPONSE_HEADER,
  RESPONSE_DATA_CODE,
  STORE_KEY,
  defaultUserInfo,
  defaultUserProperties,
} from './config';
import { useCommonStore, useUserStore } from '@/store';
import { App } from 'antd';
import ToastContent from '@/components/ToastContent';
import { getRiskControlToken } from '@/common/utils';
import i18next from 'i18next';

const { VITE_APP_APP_NAME, VITE_APP_BASE_URL } = import.meta.env;

const axios = a.create({
  baseURL: VITE_APP_BASE_URL,
  withCredentials: true,
});

declare module 'axios' {
  export interface AxiosRequestConfig {
    noAuthHeader?: boolean;
    noAlertError?: boolean; // 请求错误是否全局弹窗
    noPayModal?: boolean; // 是否显示付费弹窗
    useRiskControl?: boolean; // 请求是否接入风控
  }
}

const AxiosInterceptor = ({ children }: { children: any }) => {
  const { message } = App.useApp();
  const { userInfo, setUserInfo, authStatus, setAuthStatus, setUserProperties } = useUserStore();
  const { requirePayInfo, setRequirePayInfo } = useCommonStore();

  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    setIsSet(true);

    const interceptorRequest: number = axios.interceptors.request.use(async (config) => {
      // const { guestToken } = useUserStore.getState();
      const token = localStorage.getItem(STORE_KEY.Authorization);
      config.headers.Accept = 'application/json';
      config.headers.language = config.headers.language || i18next.language;
      // 请求头携带当前页面地址，@张致玮 需求
      config.headers['Pop-Url'] = location.href;
      config.headers['App-Name'] = VITE_APP_APP_NAME;
      config.headers['Device-Info'] = JSON.stringify({
        web_id: window?.td?.getDistinctId(),
        baidu_id: localStorage.getItem('__bid_n'),
        language: navigator.language,
      });
      if (token && !config?.noAuthHeader) {
        config.headers.Authorization = token;
      }

      if (config?.useRiskControl) {
        const jt = await getRiskControlToken();
        config.headers['x-popai-riskControlToken'] = jt;
      }

      return config;
    });

    const interceptorResponse: number = axios.interceptors.response.use(
      async (res) => {
        if (res.config.noAuthHeader && res.status >= 200 && res.status < 300) {
          return res;
        }
        if (res?.data instanceof Blob) {
          return res;
        }
        const { data } = res;
        if (+data.code === RESPONSE_DATA_CODE.Success) {
          return res;
        }
        throw res;
      },
      (err) => {
        const noAlertError = err?.config?.noAlertError;
        const noPayModal = err?.config?.noPayModal;
        const errCode = err?.response?.data?.code;
        if (errCode === RESPONSE_DATA_CODE.InterfaceForbidden) {
          window.location.href = '/403.html';
          return;
        }
        if (err?.response?.status === 401) {
          localStorage.removeItem(STORE_KEY.Authorization);
          setUserInfo(defaultUserInfo);
          setUserProperties(defaultUserProperties);
          if (!(noAlertError || authStatus.loginOpened || authStatus.showLogin)) {
            setAuthStatus({ showLogin: true });
          }
        }
        if (
          (errCode === RESPONSE_DATA_CODE.UsageExceedsLimit ||
            err?.response?.data instanceof Blob) &&
          !noPayModal &&
          !userInfo?.parsedRoles?.includes('Trail')
        ) {
          const data =
            err?.response?.data instanceof Blob
              ? JSON.parse(err?.response?.headers?.get(CUSTOM_RESPONSE_HEADER) || '{}')
              : err?.response?.data;

          setRequirePayInfo({
            requirePay: requirePayInfo.requirePay + 1,
            errorMsg: data?.message,
            source: data?.source,
            shortMessage: data?.shortMessage,
          });
        }
        if (
          !noAlertError &&
          err?.code !== 'ERR_CANCELED' &&
          errCode !== RESPONSE_DATA_CODE.Turnstile
        ) {
          const errMsg = err?.response?.data?.message || err.message;
          message.open({
            content: <ToastContent icon="error" content={errMsg} />,
          });
        }

        throw err;
      },
    );

    return () => {
      axios.interceptors.request.eject(interceptorRequest);
      axios.interceptors.response.eject(interceptorResponse);
    };
  }, []);
  return isSet && children;
};

export default axios;
export { AxiosInterceptor };
