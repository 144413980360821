import { Node } from '@tiptap/pm/model';
import { Editor, NodeViewWrapper } from '@tiptap/react';
import { useSize } from 'ahooks';
import { useCallback, useRef } from 'react';
import { Rnd } from 'react-rnd';
import { NodeSelection } from '@tiptap/pm/state';

import './ImageBlockView.less';

interface ImageBlockViewProps {
  editor: Editor;
  getPos: () => number;
  node: Node & {
    attrs: {
      src: string;
    };
  };
  updateAttributes: (attrs: Record<string, string>) => void;
}

export const ImageBlockView = (props: ImageBlockViewProps) => {
  const { editor, getPos, node } = props;

  const isRndChangeSize = useRef(false);

  const shouldShow = useCallback(() => {
    const selection = editor.state.selection;
    if (selection instanceof NodeSelection) {
      return selection.from === getPos();
    }
    return false;
  }, [editor, getPos]);

  const imageRef = useRef<HTMLImageElement>(null);
  const { src, align, width, height } = node.attrs;

  const imgSize = useSize(imageRef);

  const wrapperClassName = `align-${align || 'center'}`;

  const onClick = useCallback(() => {
    editor.commands.setNodeSelection(getPos());
  }, [getPos, editor.commands]);

  const onSizeChange = useCallback(
    ({ width, height }: { width: number; height: number }) => {
      isRndChangeSize.current = true;
      editor
        .chain()
        .focus(undefined, { scrollIntoView: false })
        .setImageBlockSize({ width, height })
        .run();
    },
    [editor],
  );

  return (
    <NodeViewWrapper>
      <div className={wrapperClassName} style={{ width, maxWidth: '100%' }}>
        <div contentEditable={false}>
          <Rnd
            className="editor-image-resize"
            size={{ width, height }}
            minWidth={32}
            onResize={(_e, _direction, ref) => {
              onSizeChange({
                width: parseFloat(ref.style.width),
                height: parseFloat(ref.style.height),
              });
            }}
            disableDragging
            enableResizing={shouldShow()}
          >
            <img ref={imageRef} className="block" src={src} alt="" onClick={onClick} />
            {shouldShow() && imgSize?.width && imgSize?.height && (
              <div className="corner-wrap">
                <div
                  className="corner-item"
                  style={{ transform: 'translate(0px, 0px)  translate(-50%, -50%)' }}
                ></div>
                <div
                  className="corner-item"
                  style={{
                    transform: `translate(${imgSize.width}px, 0px)  translate(-50%, -50%)`,
                  }}
                ></div>
                <div
                  className="corner-item"
                  style={{
                    transform: `translate(0px, ${imgSize.height}px)  translate(-50%, -50%)`,
                  }}
                ></div>
                <div
                  className="corner-item"
                  style={{
                    transform: `translate(${imgSize.width}px, ${imgSize.height}px)  translate(-50%, -50%)`,
                  }}
                ></div>
              </div>
            )}
          </Rnd>
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default ImageBlockView;
