/*
 * @Author: wubo
 * @Date: 2023-06-27 14:46:03
 * @LastEditTime: 2023-10-07 13:21:03
 * @LastEditors: wubo
 * @Description:
 */
import axios, { RawAxiosRequestHeaders } from 'axios';
import { UAParser } from 'ua-parser-js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import i18next from 'i18next';
import 'dayjs/locale/ar';
import { CreateChatParams } from '@/type';
import { Editor, JSONContent, isTextSelection } from '@tiptap/react';
import {
  Figcaption,
  HorizontalRule,
  Link,
  CodeBlock,
  // Image,
  ImageBlock,
  ImageUpload,
  Callout,
  Columns,
} from '@/pages/Chat/components/Write/extensions';
import { DOC_FILE_MIME_WHITE_LIST, PPT_FILE_MIME_WHITE_LIST } from './config';
import { Heading } from '@/store';
import type { Channel } from '@/type';
import { lngCodes } from '@/i18n/config';
import { getGrecaptchaToken } from './grecaptcha';
dayjs.extend(relativeTime);
dayjs.extend(isToday);

const { VITE_APP_AID } = import.meta.env;

const parser = new UAParser(window?.navigator?.userAgent);

export async function urlToBase64(url: string) {
  try {
    if (!url) {
      return null;
    }
    const response = await axios.get(url, {
      timeout: 5000,
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], { type: 'image/png' });
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    // console.error('Error:', error);
    return null;
  }
}

export const isFullUrl = (str: string) => {
  const urlRegex = /(https?):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/;
  return urlRegex.test(str);
};

export const isUrl = (str: string) => {
  const urlRegex = /^(https?:\/\/)([\w-]+\.)*[\w-]+(:\d+)?(\/[\w-./?%&=]*)?$/;
  return urlRegex.test(str);
};

export const isUrlEndWithPdf = (str: string) => {
  // const urlRegex =
  //   /^(https?:\/\/)?([\w-]+\.)+[\w-]+(:\d+)?(\/[\w-./?%&=]*)?\.pdf$|^(https?:\/\/)?(\d{1,3}\.){3}\d{1,3}(:\d+)?(\/[\w-./?%&=]*)?\.pdf$/;
  const urlRegex =
    /^(https?:\/\/)?([\w-]+\.)+[\w-]+(:\d+)?(\/[\w-./?%&=]*)?\.pdf$|^(https?:\/\/)?(\d{1,3}\.){3}\d{1,3}(:\d+)?(\/[\w-./?%&=]*)?\.pdf$|^(https?:\/\/)arxiv\.org\/pdf/;
  return urlRegex.test(str);
};

export const getFileExtension = (filename: string) => {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex !== -1) {
    const extension = filename.substring(dotIndex + 1);
    return extension;
  }
  return '';
};

export const getFileExtensionByParams = (params: CreateChatParams) => {
  if (params?.fileContentType === 'application/pdf' || params?.url) {
    return 'pdf';
  }
  if (
    [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ].includes(params?.fileContentType as string)
  ) {
    return 'doc';
  }
  return null;
};

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.body.removeChild(textArea);
  return Promise.resolve();
}
export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  return navigator.clipboard.writeText(text).then(
    function () {
      // console.log('Async: Copying to clipboard was successful!')
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    },
  );
}

// 拷贝将 markdown 语法（渲染格式）还原为（原始格式）
export const transformCopyContent = (content: string) => {
  return content
    ?.replace(/:sup\[:a\[(\d+)\]{href="[^"]+"}\]/g, (_match, num) => `[${num}]`)
    ?.replace(/section{.section title="(.*?)" type="[^"]+"}/g, (_match, title) => `### ${title}`)
    ?.replace(/blockquote{.blockquote(.*?)}/g, '---')
    ?.replace(/:{3,5}/g, '')
    ?.replace(/\n{3,5}/g, '\n\n')
    ?.replace(/<sup>.*?<\/sup>/g, '');
};

export const getBaseUrl = () => {
  return import.meta.env.VITE_APP_BASE_URL === '/' ? '' : import.meta.env.VITE_APP_BASE_URL;
};

export const getAppHost = () => {
  return import.meta.env.VITE_APP_HOST === '/' ? '' : import.meta.env.VITE_APP_HOST;
};

export const blobToString = async (blob: Blob) => {
  try {
    if (blob instanceof Blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const res = (reader.result as string)
            .split('')
            .map((v) => v.charCodeAt(0))
            .map((v) => v.toString(16).toUpperCase())
            .map((v) => v.padStart(2, '0'))
            .join(' ');
          resolve(res);
        };
        reader.readAsBinaryString(blob);
      });
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

export const blobToUint8Array = async (blob: Blob) => {
  try {
    if (blob instanceof Blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const res = new Uint8Array(reader.result as ArrayBuffer);
          resolve(res);
        };
        reader.readAsArrayBuffer(blob);
      });
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

export const isMobile = () => {
  return !['desktop', 'tablet'].includes(getDevice() as string);
};

export const isDesktop = () => {
  return getDevice() === 'desktop';
};

export const isTablet = () => {
  return getDevice() === 'tablet';
};

export const getDevice = () => {
  return parser.getDevice()?.type || 'desktop';
};

export const getOS = () => {
  return parser.getOS()?.name;
};

export const getBrowser = () => {
  return parser.getBrowser()?.name;
};

const CHUNK_SIZE = 1 * 1024 * 1024;

// 拆分文件
export const splitFile = (file: File, size = CHUNK_SIZE) => {
  const fileChunkList = [];
  let curChunkIndex = 0;
  while (curChunkIndex <= file.size) {
    const chunk = file.slice(curChunkIndex, curChunkIndex + size);
    fileChunkList.push({ chunk: chunk });
    curChunkIndex += size;
  }
  return fileChunkList;
};

export const calculateHash = (
  chunkList: {
    chunk: Blob;
  }[],
): Promise<string> => {
  return new Promise((resolve) => {
    const worker: Worker = new Worker('/hashWorker.js');
    worker.postMessage({ chunkList: chunkList });
    worker.onmessage = (e) => {
      const { hash } = e.data;
      if (hash) {
        resolve(hash);
      }
    };
  });
};

/**
 * 下载文件
 *
 * @param url - 文件URL
 * @param fileName - 文件名（可选）
 * @param transToBlob - 是否转换成Blob对象（可选）
 * @returns Promise<void>
 */
export async function downloadFile(url: string, fileName?: string, transToBlob?: boolean) {
  const link = document.createElement('a');

  if (transToBlob) {
    const response = await fetch(url, { mode: 'cors' });
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    link.href = blobUrl;
    if (fileName) link.download = fileName;
    link.click();
    URL.revokeObjectURL(blobUrl);
  } else {
    link.href = url;
    if (fileName) link.download = fileName;
    link.click();
  }
}

export async function downloadFileWithImgElement(url: string, fileName?: string) {
  return new Promise((resolve, reject) => {
    const link = document.createElement('a');
    const img = document.createElement('img');
    // 禁用缓存，防止下载失败
    img.src = url + '?t=' + new Date().getTime();
    img.crossOrigin = 'anonymous';
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob(function (blob) {
        if (blob) {
          const blobUrl = URL.createObjectURL(blob);
          link.href = blobUrl;
          if (fileName) link.download = fileName;
          link.click();
          URL.revokeObjectURL(blobUrl);
          resolve(null);
        } else {
          reject(null);
        }
      });
    };
    img.onerror = function () {
      reject(null);
    };
  });
}

/**
 * 将图片复制到剪贴板
 *
 * @param imageUrl 图片的URL地址
 * @returns 无返回值
 */
export async function copyImageToClipboard(imageUrl: string) {
  const response = await fetch(imageUrl, { mode: 'cors' });
  const blob = await response.blob();
  const data = [new ClipboardItem({ 'image/png': blob })];

  navigator.clipboard.write(data).then(
    function () {},
    function (error) {
      console.error('Error: ', error);
    },
  );
}

/**
 * 将时间转换成字符串，如：an hour ago
 *
 * @param time 时间戳
 * @returns 字符串类型
 */
export const timeTransform = (time: number) => {
  if (i18next?.language === 'ar') {
    dayjs.locale('ar');
  }
  return dayjs(time).fromNow();
};

export const writeDocTimeTransform = (date: number) => {
  const day = dayjs(date);
  const now = dayjs();
  if (day.isToday()) {
    return day.format('HH:mm');
  } else if (day.year() === now.year()) {
    return day.format('MMM DD');
  } else {
    return day.format('MMM DD, YYYY');
  }
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');
  // @ts-expect-error ignore TODO
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const blob = new Blob([u8arr], { type: mime });
  const file = new File([blob], filename, { type: mime });
  return file;
};

export const sleep = (duration: number) => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

interface riskControlTokenProps {
  code: number;
  msg: string;
  jt: string;
}
/**
 * 增加风控
 * 通过大数据JS SDK 异步获取token，并将token放置到请求头header中
 */
export const getRiskControlToken = () => {
  return new Promise((resolve, reject) => {
    const data = {
      aid: VITE_APP_AID,
      complete: function (data: riskControlTokenProps) {
        if (data.code === 0) {
          resolve(data?.jt);
        } else {
          reject(data?.jt);
        }
      },
    };
    window.xaf.report(data);
  });
};
/**
 * 判断文案是否是阿拉伯语
 * @param text 目标文案
 */
export const isArabic = (text: string) => {
  let arabicWordCount = 0;
  let totalWordCount = 0;

  // 使用正则表达式匹配阿拉伯文单词
  const wordRegex = /[\u0600-\u06FF]+/gu;

  // 使用exec方法迭代匹配的单词
  while (wordRegex.exec(text) !== null) {
    totalWordCount++;
    arabicWordCount++;
  }

  // 判断阿拉伯文单词占比是否超过一半
  return arabicWordCount / totalWordCount > 0.5;
};

export const isTableGripSelected = (node: HTMLElement) => {
  let container = node;

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container.parentElement!;
  }

  const gripColumn =
    container && container.querySelector && container.querySelector('a.grip-column.selected');
  const gripRow =
    container && container.querySelector && container.querySelector('a.grip-row.selected');

  if (gripColumn || gripRow) {
    return true;
  }

  return false;
};

export const isCustomNodeSelected = (editor: Editor, node: HTMLElement) => {
  const customNodes = [
    HorizontalRule.name,
    // Image.name,
    ImageBlock.name,
    ImageUpload.name,
    CodeBlock.name,
    Link.name,
    Figcaption.name,
  ];

  return customNodes.some((type) => editor.isActive(type)) || isTableGripSelected(node);
};

export const isTextSelected = ({ editor }: { editor: Editor }) => {
  const {
    state: {
      doc,
      selection,
      selection: { empty, from, to },
    },
  } = editor;

  // Sometime check for `empty` is not enough.
  // Doubleclick an empty paragraph returns a node size of 2.
  // So we check also for an empty text size.
  const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(selection);

  if (empty || isEmptyTextBlock || !editor.isEditable) {
    return false;
  }

  editor.chain().setTextSelection({ from, to }).run();

  return true;
};

export const getModKey = () => {
  const os = getOS();
  return os === 'Mac OS' ? '⌘' : 'Ctrl';
};

export const getRenderContainer = (editor: Editor, nodeType: string) => {
  const {
    view,
    state: {
      selection: { from },
    },
  } = editor;

  const elements = document.querySelectorAll('.has-focus');
  const elementCount = elements.length;
  const innermostNode = elements[elementCount - 1];
  const element = innermostNode;

  if (
    (element &&
      element.getAttribute('data-type') &&
      element.getAttribute('data-type') === nodeType) ||
    (element && element.classList && element.classList.contains(nodeType))
  ) {
    return element;
  }

  const node = view.domAtPos(from).node as HTMLElement;
  let container = node;

  if (!container.tagName) {
    container = node.parentElement as HTMLElement;
  }

  while (
    container &&
    !(container.getAttribute('data-type') && container.getAttribute('data-type') === nodeType) &&
    !container.classList.contains(nodeType)
  ) {
    container = container.parentElement as HTMLElement;
  }

  return container;
};

export const replaceEmptyMarkdownCells = (content: string) => {
  return content
    .split('\n')
    .map((line) => {
      // 仅对表格的内容行进行处理，跳过表头分隔线
      if (!line.startsWith('|---')) {
        // 替换行中的空白单元格为带有"-"的单元格，但不影响行末的分隔符
        return line.replace(/\|(\s*)\|/g, '|$1-|');
      }
      return line;
    })
    .join('\n');
};

export const getFileType = (fileType: string) => {
  if (DOC_FILE_MIME_WHITE_LIST.includes(fileType)) {
    return fileType === 'application/pdf' ? 'pdf' : 'doc';
  }
  if (PPT_FILE_MIME_WHITE_LIST.includes(fileType)) return 'ppt';
  return fileType;
};

export const extractHeadings = (content: JSONContent[]): Heading[] => {
  const extractTextContent = (node: JSONContent) => {
    return node?.content
      ?.filter((innerNode) => innerNode.type === 'text')
      ?.map((innerNode) => innerNode.text)
      ?.join('');
  };

  const mapHeadingNode = (node: JSONContent) => ({
    id: node?.attrs?.id,
    level: node?.attrs?.level,
    text: extractTextContent(node),
  });

  return content
    .flatMap((node) => {
      if (node.type === 'heading') {
        return [mapHeadingNode(node)];
      } else if (node.type === Columns.name) {
        return node?.content?.flatMap((column) =>
          column?.content?.flatMap((subNode) => {
            if (subNode.type === 'heading') {
              return [mapHeadingNode(subNode)];
            } else if (subNode.type === 'blockquote') {
              return extractHeadings([subNode]);
            }
            return [];
          }),
        );
      } else if (node.type === 'blockquote' || node.type === Callout.name) {
        return node?.content?.flatMap((subNode) => {
          if (subNode.type === 'heading') {
            return [mapHeadingNode(subNode)];
          } else if (subNode.type === 'blockquote') {
            return extractHeadings([subNode]);
          }
          return extractHeadings([subNode]);
        });
      }
      return [];
    })
    .filter((heading): heading is Heading => !!heading);
};

export const checkConvertStatus = (channel: Channel | undefined, status: 'OK' | 'ERR') => {
  if (!channel || (!channel.fileTypeEnum && !channel.docEntries?.multiDoc)) {
    return false;
  }
  const isMulti = channel.docEntries?.multiDoc;
  if (isMulti) {
    return channel.docEntries?.docs?.every((doc) => {
      if (doc.fileTypeEnum !== 'doc') {
        return true;
      }
      return doc.pdfConvertStatus === status || !doc.pdfConvertStatus;
    });
  }
  if (channel.fileTypeEnum !== 'doc') {
    return true;
  }
  return channel.pdfConvertStatus === status || !channel.pdfConvertStatus;
};

export const extractPathName = () => {
  const defaultPage = 'home';
  const parts = location.pathname.split('/').filter(Boolean);

  let page_name = parts[0];
  if (!page_name) {
    return defaultPage;
  }
  if (lngCodes.includes(page_name)) {
    page_name = parts[1];
  }
  return page_name || defaultPage;
};

export const getExtraHeaders = async (): Promise<RawAxiosRequestHeaders> => {
  const token = await getGrecaptchaToken();
  return {
    gtoken: token,
  };
};
