import { useTranslation } from 'react-i18next';
import PauseUpgradeUrl from '@/assets/pause-upgrade.png';

import './pauseUpgrade.less';

interface PauseUpgradeProps {}

const PauseUpgrade: React.FC<PauseUpgradeProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="pause-upgrade">
      <img className="pause-upgrade-icon" width="116" src={PauseUpgradeUrl} />
      <div className="pause-upgrade-title">
        {t('layout.studentDiscount.continueService')}
        <br />
        {t('layout.studentDiscount.placeUpgrade')}
        <br />
        {t('layout.studentDiscount.studentFreeSummer')}
      </div>
      <div className="pause-upgrade-tip">
        {t('layout.studentDiscount.toEmail')}{' '}
        <a href="mailto:customerservice@popai.pro">customerservice@popai.pro</a>.
      </div>
    </div>
  );
};

export default PauseUpgrade;
