/**
 * 生成 reCAPTCHA token
 */
export function getGrecaptchaToken(): Promise<string> {
  return new Promise((resolve) => {
    // 如果 grecaptcha 未加载完成，直接返回空字符串
    if (typeof window.grecaptcha?.enterprise?.ready !== 'function') {
      resolve('');
    } else {
      // 调用 grecaptcha 生成 token
      window.grecaptcha.enterprise.ready(async () => {
        try {
          const token = await window.grecaptcha.enterprise.execute(
            '6LfP64kpAAAAAP_Jl8kdL0-09UKzowM87iddJqXA',
            { action: 'LOGIN' },
          );
          resolve(token);
        } catch (error) {
          resolve('');
        }
      });
    }
  });
}
