import cls from 'classnames';
import { useUserStore } from '@/store';
import CustomIcon from '@/components/CustomIcon';
import { AB_EXPERIMENTS, MEMBERSHIP_ID } from '@/common/config';
import ProSvg from '@/assets/pay/pro.svg';

import './index.less';
import { useSundry } from '@/hooks';

const iconMap = {
  free: { icon: '', label: 'Free', iconClassName: 'Free' },
  freeTrial: (isShort?: boolean) => ({
    icon: 'userBadgeTrail',
    label: isShort ? 'Trail' : 'Free Trail',
    iconClassName: isShort ? 'Trail' : 'Free-Trail',
  }),
  pro: { icon: 'userBadgePro', label: 'PRO', iconClassName: 'PRO' },
  unlimited: { icon: 'userBadgeUnlimited', label: 'Unlimited', iconClassName: 'Unlimited' },
};

interface UsageBadgeProps {
  siderCollapsed?: boolean;
  isShort?: boolean;
  renderType: 'fixedIcon' | 'usageHint' | 'featureHint' | 'sider';
  className?: string;
}

const UserBadge: React.FC<UsageBadgeProps> = (props) => {
  const { isShort, renderType, className = '' } = props;
  const { userInfo } = useUserStore();
  const { experiment } = useSundry();

  const guestFeature = experiment(AB_EXPERIMENTS.GUEST);
  const isFreeTrialUser = userInfo?.parsedRoles?.includes('Trail');

  const { isUnlimited, isProPlus, isPro, membershipId } = userInfo;

  const getIconData = () => {
    switch (renderType) {
      case 'fixedIcon':
        // 展示用户权益对应的图标
        if ([membershipId].includes(MEMBERSHIP_ID.Free)) return iconMap.free;
        if (isUnlimited) return iconMap.unlimited;
        if (isFreeTrialUser) return iconMap.freeTrial(isShort);
        if (isProPlus || isPro || guestFeature) return iconMap.pro;
        return null;

      case 'usageHint':
        // 展示用量提示场景下的icon逻辑（仅在用量耗尽时展示）展示下一级的icon(用于升级)
        // [免登录、free]===> pro,    [freeTrail、pro]===> unlimited,   unlimited 仅展示icon
        if (isUnlimited) return { ...iconMap.unlimited, label: '' };
        if ([membershipId].includes(MEMBERSHIP_ID.Free)) return iconMap.pro;
        if (isFreeTrialUser || isPro || isProPlus) return iconMap.unlimited;
        if (guestFeature) return iconMap.pro;
        if (!userInfo?.isLogin && !guestFeature) return iconMap.pro;
        return null;

      case 'featureHint':
      case 'sider':
        // 展示功能提示场景下的icon逻辑
        // [免登录、ft、free、pro] ===> pro,   unlimited只展示标
        if (isUnlimited) return { ...iconMap.unlimited, label: '' };
        return iconMap.pro;

      default:
        return null;
    }
  };

  const selectedIcon = getIconData();

  if (!selectedIcon) return null;

  const { icon, label, iconClassName } = selectedIcon;
  return (
    <div
      className={cls('user-type-badge', iconClassName, {
        [className]: !!className,
      })}
    >
      {icon === 'userBadgePro' && <img src={ProSvg} />}
      {icon && icon !== 'userBadgePro' && <CustomIcon type={icon} className="user-type-icon" />}

      {isUnlimited && isShort ? (
        <CustomIcon type="popai-a-unlimitedicon" className="user-badge-unlimited" />
      ) : (
        label && <p className="badge-type">{label}</p>
      )}
    </div>
  );
};

export default UserBadge;
