import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { clone } from 'lodash-es';
import { checkConnector } from '@/services/user'
import { STORE_KEY } from '@/common/config';

export enum ConnectorType {
    Gdrive = 'GOOGLE_DRIVE',
    Onenote = 'MICROSOFT_ONENOTE',
    Onedrive = 'MICROSOFT_ONEDRIVE',
    Evernote = 'EVERNOTE',
    Notion = 'NOTION',
    Obsidian = 'OBSIDIAN',
    Zotero = 'ZOTERO',
}
export enum ConnectorStatus {
    disable,
    enable,
    waiting,
}
export type Connector = {
    type: ConnectorType;
    status: ConnectorStatus;
    access_token: string;
    expires_at: number;
}
interface connectorState {
    connectorList: Connector[];
    resetConnector: () => void;
    updateConnector: () => Promise<Connector[]>;
    updateGdrive: (payload: Connector) => void;
}
const useConnectorStore = create<
    connectorState,
    [['zustand/devtools', never], ['zustand/persist', Partial<connectorState>]]
>(
    devtools(
        persist(
            (set) => ({
                connectorList: [],
                resetConnector: () => {
                    set(() => ({
                        connectorList: []
                    }))
                },
                updateConnector: async () => {
                    try {
                        const res = await checkConnector()
                        const connectorList = res?.data?.connectors || []
                        set(() => ({
                            connectorList,
                        }))
                        return connectorList
                    } catch (e: any) {
                        const code = e?.response?.data?.code || e?.response?.status
                        if (code === 401) {
                            set(() => ({
                                connectorList: []
                            }))
                        }
                        return []
                    }
                },
                updateGdrive: (payload) => {
                    set((state) => {
                        const curConnectors = clone(state.connectorList)
                        const gDriveIndex = curConnectors.findIndex(c => c.type === ConnectorType.Gdrive)
                        if (gDriveIndex > -1) {
                            curConnectors[gDriveIndex] = payload
                        } else {
                            curConnectors.push(payload)
                        }
                        return ({
                            connectorList: curConnectors
                        })
                    })
                },
            }),
            { 
                name: STORE_KEY.Connector,
                storage: createJSONStorage(() => localStorage),
            }
        )
    )
)

export default useConnectorStore