import { useState, useEffect } from 'react';
import CustomIcon from '@/components/CustomIcon';
import './index.less';
import { getAnnouncementGET } from '@/services';
import type { ResponseType } from '@/type';
interface BulletinBannerProps {}

const BulletinBanner: React.FC<BulletinBannerProps> = () => {
  const [bulletinBannerOpen, setBulletinBannerOpen] = useState(false); // 网站公告banner
  const [announcement, setAnnouncement] = useState(''); // 网站公告文案
  // 获取网站公告
  const getAnnouncement = async () => {
    try {
      const res = await getAnnouncementGET<ResponseType<string>>();
      if (res && res.data) {
        setAnnouncement(res.data);
        setBulletinBannerOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAnnouncement(); // 获取网站公告
  }, []);

  return bulletinBannerOpen ? (
    <div className="bulletin-banner">
      <CustomIcon type="ExclamationMark" />
      <span>{announcement}</span>
    </div>
  ) : null;
};

export default BulletinBanner;
