import axios from '@/common/request';
import { CreateChatParams, GetChannelParams } from '@/type';

import type { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

export async function getLandingPageInfo<T>() {
  const result = await axios.get<T>('/api/v1/landing');
  return result.data;
}

export async function getConversationList<T>() {
  const result = await axios.get<T>('/api/v1/chat/myChannelList');
  return result.data;
}

export async function editChat<T>(params: { channelId: string; channelName: string }) {
  const result = await axios.post<T>('/api/v1/channel', params);
  return result.data;
}

export async function deleteChat<T>(params: { channelId: string }) {
  const result = await axios.post<T>('/api/v1/channel/delete', params);
  return result.data;
}

export async function createNewChat<T>(params: GetChannelParams, config?: AxiosRequestConfig) {
  const result = await axios.post<T>('/api/v1/chat/getChannel', params, config);
  return result.data;
}

export async function getStripeProductsUsingGET<T>() {
  const result = await axios.get<T>(`/api/v1/stripe/products`);
  return result.data;
}

export async function getLanguages<T>() {
  const result = await axios.get<T>('/api/v1/landing/locales');
  return result.data;
}

export async function getPresignedUsingGET<T>(params: {
  md5: string;
  bucket?: string;
  prefix?: string;
}) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/py/api/v1/chat/getPresignedPost?${paramArr.join('&')}`, {
    noAlertError: true,
  });
  return result.data;
}

export async function chatCreateUsingPOST<T>(params: CreateChatParams) {
  const result = await axios.post<T>(`/py/api/v1/chat/create`, params, {
    noAlertError: true,
  });
  return result.data;
}

export async function getLangList<T>() {
  const result = await axios.get<T>('/api/v1/front/I18n/locales');
  return result.data;
}
export async function langConf<T>({ locale }: { locale: string }) {
  const result = await axios.get<T>(`/api/v1/front/getI18n`, {
    headers: {
      language: locale,
    },
  });
  return result.data;
}

export async function getImages<T>(params: {
  channelId: string;
  beforeImageId?: number;
  limit?: number;
  md5?: string;
  noPayModal?: boolean;
}) {
  const result = await axios.post<T>(
    `/api/v1/chat/getImages`,
    {
      channelId: params.channelId,
      beforeImageId: params?.beforeImageId,
      limit: params?.limit,
      md5: params?.md5,
    },
    { noPayModal: params.noPayModal },
  );
  return result.data;
}

export async function extractImage(params: {
  channelId: string;
  md5?: string;
  startPage: number;
  isMultiDoc?: boolean;
}) {
  const result = await axios.post(`/py/api/v1/chat/extract_image`, params);
  return result.data;
}

export async function getTplPreviewDetail<T>(
  params: { shareKey: string },
  options?: AxiosRequestConfig,
) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/channel/share/preview?${paramArr.join('&')}`, options);
  return result.data;
}

export async function searchTemplateByKeyword<T>(
  params: {
    name: string;
    page?: string;
    pageSize?: string;
  },
  options?: AxiosRequestConfig,
) {
  const result = await axios.post<T>(`/api/v1/prompt/recentSearch`, params, options);
  return result.data;
}

export async function fastSpringOrderUsingPOST<T>(params: any) {
  const result = await axios.post<T>(`/api/v1/fastspring/order`, params);
  return result.data;
}

export async function fastSpringPriceUsingGET<T>(params: { subscriptionId: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/fastspring/price?${paramArr.join('&')}`);
  return result.data;
}

export async function isFastSpringUsingGET<T>() {
  const result = await axios.get<T>(`/api/v1/membership/isFastspring`);
  return result.data;
}

export async function getFastSpringProductsUsingGET<T>() {
  const result = await axios.get<T>(`/api/v1/fastspring/products`);
  return result.data;
}

// 获取IP和country @晓东
export async function getIpAndCountryUsingGET<T>() {
  const result = await axios.get<T>(`/api/v1/tool/ip`);
  return result.data;
}

export async function marketingActivePOST<T>(params = {}) {
  const result = await axios.post<T>(`/api/v1/marketing/active`, params, {
    noAlertError: true,
  });
  return result.data;
}

export async function isMembershipUnlimited<T>() {
  const result = await axios.get<T>('/api/v1/membership/isUnlimited', {
    noAlertError: true,
  });
  return result.data;
}

// 获取用户注册时间+24小时 与 当前日期的时分秒差
export async function userCountDown<T>() {
  const result = await axios.get<T>('/api/v1/user/countDown', {
    noAlertError: true,
  });
  return result.data;
}

// 获取当前要展示的更新提示文案
export async function getPromptNotes<T>() {
  const result = await axios.get<T>('/api/v1/release/notes');
  return result.data;
}

export async function billingDetailGET<T>() {
  const result = await axios.get<T>('/api/v1/billing/detail');
  return result.data;
}

export async function cancelSubscriptionPOST<T>(data: {
  feedback: string;
  subscriptionId: string;
}) {
  const result = await axios.post<T>('/api/v1/billing/subscription/cancel', data);
  return result.data;
}

export async function allowCancelDiscountGet<T>() {
  const result = await axios.get<T>('/api/v1/billing/allowCancelDiscount');
  return result.data;
}

export async function cancelDiscountGet<T>(params: { subscriptionId: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }

  const result = await axios.get<T>(`/api/v1/billing/cancelDiscount?${paramArr.join(' & ')}`);
  return result.data;
}

export async function addDiscountGet<T>(params: { coupon: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }

  const result = await axios.get<T>(`/api/v1/billing/addDiscount?${paramArr.join(' & ')}`);
  return result.data;
}

export async function detailPreviewGet<T>(params: { fastSpring: string; stripe: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/billing/detailPreview?${paramArr.join('&')}`);
  return result.data;
}

export async function getPayment<T>() {
  const result = await axios.get<T>('/api/v1/membership/payment', {
    noAlertError: true,
  });
  return result.data;
}

export async function getAllProductsUsingGET<T>(extraHeaders?: RawAxiosRequestHeaders) {
  const result = await axios.get<T>(`/api/v1/membership/allProducts`, {
    useRiskControl: true,
    ...(extraHeaders ? { headers: extraHeaders } : {}),
  });
  return result.data;
}

// 创建partnerShare账号
export async function createPartnerShareAccount<T>() {
  const result = await axios.get<T>('/api/v1/partner/create', {
    useRiskControl: true,
  });
  return result.data;
}

// login 返回跳转链接
export async function getPartnerShareLink<T>() {
  const result = await axios.get<T>('/api/v1/partner/login', {
    useRiskControl: true,
  });
  return result.data;
}

export async function getIsPartner<T>() {
  const result = await axios.get<T>('/api/v1/user/isPartner');
  return result.data;
}

// 判断是否是free-trial流量
export async function getfreeTrialGET<T>() {
  const result = await axios.get<T>(`/api/v1/membership/freeTrial`, {
    useRiskControl: true,
  });
  return result.data;
}

// 获取网站公告
export async function getAnnouncementGET<T>() {
  const result = await axios.get<T>(`/api/v1/release/announcement`);
  return result.data;
}

// 是否在学生假期内
export async function allowStudentDiscountGET<T>() {
  const result = await axios.get<T>('/api/v1/billing/allowStudentDiscount');
  return result.data;
}

// 验证邮箱以及验证码
export async function sendStudentEmailValidationCode<T>(data: {
  email: string;
  code: string;
  subscriptionId: string;
}) {
  const result = await axios.post<T>('/api/v1/auth/studentEmailValidationCode', data);
  return result.data;
}

// 发送学生邮箱验证码;
export async function toStudentEmailValidationCode<T>(data: { email: string }) {
  const result = await axios.post<T>('/api/v1/auth/toStudentEmailValidationCode', data);
  return result.data;
}

export async function fastSpringOrderCheck<T>(
  params: { productId: string },
  extraHeaders?: RawAxiosRequestHeaders,
) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/fastspring/check?${paramArr.join('&')}`, {
    useRiskControl: true,
    ...(extraHeaders ? { headers: extraHeaders } : {}),
  });
  return result.data;
}

// 退出登录-登出令牌失效
export async function signoutGET<T>(data: any) {
  const result = await axios.post<T>('/api/v1/auth/signout', data);
  return result.data;
}

// 强制命中某个实验
export async function forcePost<T>(data: any) {
  const result = await axios.post<T>('/api/v1/user/force', data);
  return result.data;
}

export async function memberProvider() {
  const result = await axios.get('/api/v1/membership/provider');
  return result.data;
}
