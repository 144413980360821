import { useDA, useGASendEvent } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { useCommonStore, useUserStore } from '@/store';
import CustomIcon from '@/components/CustomIcon';
import classNames from 'classnames';
import './index.less';

interface QuickEntranceProps {
  handleDownloadAppShow: (event: React.MouseEvent) => void;
}

const QuickEntrance: React.FC<QuickEntranceProps> = (props) => {
  const pageLanguage = useCommonStore((state) => state.pageLanguage);
  const { t } = useTranslation();
  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();
  const { userInfo } = useUserStore();
  const handleDiscord = (e: React.MouseEvent) => {
    e?.stopPropagation();
    sendEvent(userInfo.isLogin ? 'home_login_discord_click' : 'home_discord_click');
    sendDAEvent('SidebarButtonClick', { button_name: 'discord', sidebar_status: 'open' });
    window.open('https://discord.gg/qBhS3vTYhv', '_blank');
  };

  const handleTwitter = (e: React.MouseEvent) => {
    e?.stopPropagation();
    sendEvent(userInfo.isLogin ? 'home_login_twitter_click' : 'home_twitter_click');
    sendDAEvent('SidebarButtonClick', { button_name: 'twitter', sidebar_status: 'open' });
    window.open('https://twitter.com/popaiinone', '_blank');
  };

  const handleDownloadAppShow = (e: React.MouseEvent, eventName: string) => {
    sendEvent(eventName);
    sendDAEvent('SidebarButtonClick', { button_name: 'download_app', sidebar_status: 'open' });
    props.handleDownloadAppShow(e);
  };

  return (
    <div
      className={classNames({
        'quick-entrance': true,
        'space-between': !userInfo.isLogin,
      })}
    >
      <div className="discord-wrapper">
        <CustomIcon
          className="discord-wrapper-icon-discord"
          type="discord"
          onClick={handleDiscord}
        />
        <CustomIcon
          className="discord-wrapper-icon-twitter"
          type="twitter"
          onClick={handleTwitter}
        />
      </div>
      <div className="user-info-survey" onClick={(e) => e?.stopPropagation()}>
        <CustomIcon
          className="user-info-icon left"
          type="chrome"
          onClick={(e) =>
            handleDownloadAppShow(
              e,
              userInfo.isLogin ? 'home_login_chromeextension_click' : 'home_chromeextension_click',
            )
          }
        />

        <CustomIcon
          className="user-info-icon center"
          type="appStore"
          onClick={(e) =>
            handleDownloadAppShow(e, userInfo.isLogin ? 'home_login_iOS_click' : 'home_iOS_click')
          }
        />
        <CustomIcon
          className="user-info-icon right"
          type="googlePlay"
          onClick={(e) =>
            handleDownloadAppShow(
              e,
              userInfo.isLogin ? 'home_login_android_click' : 'home_android_click',
            )
          }
        />
        {pageLanguage === 'ar' ? (
          <div className="new-tag">{t('common.new')}</div>
        ) : (
          <CustomIcon className="new-tag" type="new" />
        )}
      </div>
    </div>
  );
};

export default QuickEntrance;
