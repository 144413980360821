import { useGASendEvent } from '@/hooks';

interface LinkElementProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  messageId?: string;
}

/**
 * 超链接组件：增加点击事件统计
 */
const LinkElement: React.FC<LinkElementProps> = ({ href, children, messageId }) => {
  const { sendEvent } = useGASendEvent();

  const linkClick = () => {
    sendEvent('click_message_link', {
      message_id: messageId,
      link_url: href,
    });
  };

  return (
    <a href={href} target="_blank" onClick={linkClick}>
      {children}
    </a>
  );
};

export default LinkElement;
