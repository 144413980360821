import { ProductItem } from '.';

export const teamProduct: ProductItem = {
  productType: 'team',
  productInfoSummary: 'layoutPayTeamProductSummary',
  productInfoItemsDetail: [
    {
      title: 'layoutPayTeamProductDetailTitle',
      desc: [
        {
          label: 'layoutPayTeamProductDetailDesc1Title',
          value: [
            {
              text: 'layoutPayTeamProductDetailDesc1Value1',
            },
            {
              text: 'layoutPayTeamProductDetailDesc1Value2',
            },
          ],
        },
        {
          label: 'layoutPayTeamProductDetailDesc2Title',
          value: [
            {
              text: 'layoutPayTeamProductDetailDesc2Value1',
            },
            {
              text: 'layoutPayTeamProductDetailDesc2Value2',
            },
          ],
        },
        {
          label: 'layoutPayTeamProductDetailDesc3Title',
          value: [
            {
              text: 'layoutPayTeamProductDetailDesc3Value1',
            },
            {
              text: 'layoutPayTeamProductDetailDesc3Value2',
            },
            {
              text: 'layoutPayTeamProductDetailDesc3Value3',
            },
          ],
        },
      ],
    },
  ],
  membershipId: 0,
  strikePrice: 0,
  productIds: {},
  productPriceNum: 0,
  productPriceText: '',
};
