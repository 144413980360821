import { Modal } from 'antd';
import React, { useRef, useEffect } from 'react';

interface TurnstileContainerProps {
  sitekey?: string;
  callback: (token: string) => void;
}

// Turnstile弹窗内部的组件
const TurnstileContainer: React.FC<TurnstileContainerProps> = ({
  sitekey = '0x4AAAAAAAbOeDhO05qWd9Aj',
  callback,
}) => {
  // 人机验证容器
  const container = useRef<HTMLDivElement>(null);
  const isReady = useRef(false);

  useEffect(() => {
    const renderTurnstile = () => {
      window.turnstile.render(container.current, {
        sitekey,
        callback: (token: any) => {
          callback(token);
          isReady.current = false;
        },
      });
    };

    if (container.current) {
      try {
        window.turnstile?.ready(() => {
          if (isReady.current) return;
          isReady.current = true;
          renderTurnstile();
        });
      } catch {
        // https://community.cloudflare.com/t/turnstile-fails-to-load-with-explicit-render/660912/7
        try {
          renderTurnstile();
        } catch (err) {
          console.error(err);
        }
      }
    }
  }, [callback, sitekey]);

  return (
    <div
      ref={container}
      style={{
        paddingInlineStart: 34,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    ></div>
  );
};

// 弹出cloudflare的人机验证弹窗
export async function requestTurnstileToken() {
  return new Promise((resolve) => {
    Modal.info({
      title: null,
      centered: true,
      footer: null,
      keyboard: false,
      icon: null,
      width: 400,
      content: (
        <TurnstileContainer
          callback={(token) => {
            resolve(token);
            Modal.destroyAll();
          }}
        />
      ),
    });
  });
}
