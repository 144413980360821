import classNames from 'classnames';
import { getDevice } from '@/common/utils';
import CustomIcon from '../CustomIcon';

import './index.less';

interface SectionElementProps {
  className?: string;
  type?: string;
  title?: string;
  children: React.ReactNode;
}

const SectionElement: React.FC<SectionElementProps> = (props) => {
  const { className = '', type, title, children } = props;

  return (
    <section className={className}>
      {type ? (
        <>
          <h6>
            <CustomIcon className="section-icon" type={type} />
            {title}
          </h6>
          <div
            className={classNames({
              [`${type}-content`]: true,
              [`${type}-${getDevice()}-content`]: true,
            })}
          >
            {children}
          </div>
        </>
      ) : children}
    </section>
  );
}

export default SectionElement;
