import { QuoteInfoItem } from '@/type';
import { message } from 'antd';
import ToastContent from '../ToastContent';
import classNames from 'classnames';
import { useDA } from '@/hooks';
import { useChatStore } from '@/store';
import { getChatType } from '@/common/helpers/chatHelper';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';

interface SupElementProps {
  /**
   * 角标
   * QuoteInfoItem[]: 互联网模式下的引用信息，点击打开新页面
   */
  quoteInfoList?: QuoteInfoItem[];
  children: React.ReactNode;
  node?: any;
  messageId?: string;
}

const SupElement: React.FC<SupElementProps> = (props) => {
  const { sendDAEvent } = useDA();
  const { channel } = useChatStore();
  const { t } = useTranslation();

  const { quoteInfoList, children, messageId } = props;

  const childrenValue = Array.isArray(children) ? children[0] : null;
  const childrenStringValue = typeof childrenValue === 'string' ? childrenValue : '';
  const isNumeric = /^\d+$/.test(childrenStringValue);

  const handleSupClick = () => {
    // 处理 quoteInfoList 中引用链接的跳转，当 quoteInfoList 为空的时候，不处理点击
    // 如果有 a 标签，则会默认行为跳转
    if (isEmpty(quoteInfoList)) return;

    if (isNumeric) {
      const url = quoteInfoList?.[childrenValue - 1]?.url;
      sendDAEvent('OnlineSearchResultCitation_Click', {
        channelid: channel?.channelId,
        chattype: getChatType(channel),
        messageid: messageId,
        url,
        no: childrenStringValue,
      });
      if (url) {
        window.open(url);
      }
    } else {
      sendDAEvent('OnlineSearchResultCitation_Click', {
        channelid: channel?.channelId,
        chattype: getChatType(channel),
        messageid: messageId,
        url: '',
        no: childrenStringValue,
      });
      message.open({
        content: <ToastContent icon="error" content={t('pages.chat.urlNotFound')} />,
      });
    }
  };

  return (
    <span
      className={classNames({
        sup: true,
        'is-number': isNumeric,
      })}
      onClick={handleSupClick}
    >
      {children}
    </span>
  );
};

export default SupElement;
