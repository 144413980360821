import { Channel } from '@/type';

export enum CHAT_TYPE {
  /**
   * ALL IN ONE
   */
  ONE_CHAT = 'ONE_CHAT',
  /**
   * 文档聊天，包含但但文档或者多文档
   */
  DOC_CHAT = 'DocChat',
  /**
   * AI PPT 创作
   */
  AI_CREATION_PPT = 'AiCreateionPpt',
  /**
   * AI创作，PPT除外
   */
  AI_CREATION = 'AiCreation',
  /**
   * 普通对话
   */
  CHAT = 'CHAT',
}
/**
 * 获取chattype
 * @param channel
 * @returns
 */
export const getChatType = (channel: Channel): CHAT_TYPE => {
  // one chat
  if (channel?.ext?.channelTypeEnum) return CHAT_TYPE.ONE_CHAT;
  // 单文档或多文档
  if (channel?.upload) return CHAT_TYPE.DOC_CHAT;
  // ppt
  if ((channel?.fileTypeEnum as string) === 'ppt') {
    return CHAT_TYPE.AI_CREATION_PPT;
  } else if (channel?.promptTemplateId) {
    return CHAT_TYPE.AI_CREATION;
  }
  // 普通对话
  return CHAT_TYPE.CHAT;
};
