import { useMemo, useState } from 'react';
import { Button, Skeleton } from 'antd';
import {
  NEW_PRODUCT_INFO_ITEMS_DETAIL,
  NEW_PRODUCT_INFO_SUMMARY,
  PRODUCT_TYPE,
  PRODUCT_PERIOD_TYPE,
  MEMBERSHIP_ID,
  PRICE_STATUS,
  TRIAL_STATUS,
  AB_EXPERIMENTS,
} from '@/common/config';
import { useDA, useGASendEvent, useSundry } from '@/hooks';
import { useUserStore } from '@/store';
import classNames from 'classnames';
import CustomIcon from '@/components/CustomIcon';
import { isNumber } from 'lodash-es';
import { useDebounceFn } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { shouldUserUseNewModelName } from '@/common/gray';

import type { Desc, DiscountCouponInfo, IProduct, ProductInfoItem } from '@/type';
import { ProductItem } from './index';
import DiscountBanner from './DiscountBanner';
import SaveTag from './SaveTag';
import { hasCouponActivity } from '@/components/UpgradeButton';
import { teamProduct } from './teamProduct';

import './newPay.old.less';

interface IProductProps {
  products: ProductItem[];
  priceStatus: PRICE_STATUS | undefined;
  trialStatus: TRIAL_STATUS;
  isLoading: boolean;
  proType: PRODUCT_PERIOD_TYPE;
  discountInfo: DiscountCouponInfo | null;
  onBeforePay?: (params: { product: ProductItem; subType: string }) => void;
}

interface IPayProps {
  products: IProduct[];
  priceStatus: PRICE_STATUS | undefined;
  trialStatus: TRIAL_STATUS;
  isLoading: boolean;
  shortMessage?: string;
  onBeforePay?: (params: { product: ProductItem; subType: string }) => void;
}

const Product: React.FC<IProductProps> = (props) => {
  const { sendDAEvent } = useDA();
  const { experiment } = useSundry();
  const teamFeature = experiment(AB_EXPERIMENTS.TEAMS);
  const [showFree, setShowFree] = useState<boolean>(false);
  const { products, priceStatus, trialStatus, isLoading, proType, discountInfo, onBeforePay } =
    props;
  const isHaveProPlus = useMemo(() => {
    return products.some((item) => {
      return [MEMBERSHIP_ID.YearProPlus, MEMBERSHIP_ID.MonthProPlus].includes(item.membershipId);
    });
  }, [products]);

  const { t } = useTranslation();
  const formatProducts = useMemo(() => {
    const productTypes = isHaveProPlus
      ? [PRODUCT_TYPE.Free, PRODUCT_TYPE.Pro, PRODUCT_TYPE.ProPlus, PRODUCT_TYPE.Unlimited]
      : [PRODUCT_TYPE.Free, PRODUCT_TYPE.Pro, PRODUCT_TYPE.Unlimited];
    if (isLoading) {
      return productTypes.map((item) => {
        return {
          productType: item,
          productPriceNum: 0,
          productPriceText: '',
          productInfoSummary: '',
          productInfoItemsSummary: [],
          productInfoItemsDetail: [],
          productIds: {},
          membershipId: MEMBERSHIP_ID.Free,
          strikePrice: '',
        };
      });
    } else {
      return products;
    }
  }, [isLoading, products, isHaveProPlus]);

  const handlePay = async (product: ProductItem) => {
    sendDAEvent('ProductPurchaseModalButtonClick', {
      couponid: discountInfo?.desc || '',
      currency: product.productPriceText,
      desc: `${
        proType === PRODUCT_PERIOD_TYPE.Year ? 'annually' : 'monthly'
      }_${product.productType.toLocaleLowerCase()}`,
      discount_rate:
        (proType === PRODUCT_PERIOD_TYPE.Year
          ? discountInfo?.yearlyDiscount
          : discountInfo?.monthlyDiscount) || 0,
      price: Number(product.price),
      button_name:
        proType === PRODUCT_PERIOD_TYPE.Year
          ? product.productType === PRODUCT_TYPE.Unlimited
            ? 'subscribe_annually_unlimited'
            : 'subscribe_annually_pro'
          : product.productType === PRODUCT_TYPE.Unlimited
          ? 'subscribe_monthly_unlimited'
          : 'subscribe_monthly_pro',
    });
    onBeforePay?.({
      product,
      subType: proType,
    });
  };

  const { run: handlePayDebounce } = useDebounceFn(handlePay, {
    wait: 2000,
    leading: true,
    trailing: false,
  });

  const renderCancelTip = (product: ProductItem) => {
    if (proType === PRODUCT_PERIOD_TYPE.Year && product.productType !== 'FREE') {
      if (trialStatus === TRIAL_STATUS.Pro && product.productType === PRODUCT_TYPE.Pro) {
        return t('layout.pay.freeTrialCancelTip', {
          price: `${product.productPriceText}${product.productPriceNum}`,
        });
      }

      if (
        trialStatus === TRIAL_STATUS.ProAndUnlimit &&
        [PRODUCT_TYPE.Pro, PRODUCT_TYPE.Unlimited].includes(product.productType as PRODUCT_TYPE)
      ) {
        return t('layout.pay.freeTrialCancelTip', {
          price: `${product.productPriceText}${product.productPriceNum}`,
        });
      }

      if ([PRICE_STATUS.TESTGROUPB].includes(priceStatus as PRICE_STATUS))
        return t('layout.pay.cancelTip');
    }
    return '';
  };

  const renderSubscribeBtnLabel = (product: ProductItem) => {
    if (proType === PRODUCT_PERIOD_TYPE.Year && product.productType !== 'FREE') {
      if (trialStatus === TRIAL_STATUS.Pro && product.productType === PRODUCT_TYPE.Pro) {
        return t('common.freeSevenDayTrial');
      }

      if (
        trialStatus === TRIAL_STATUS.ProAndUnlimit &&
        [PRODUCT_TYPE.Pro, PRODUCT_TYPE.Unlimited].includes(product.productType as PRODUCT_TYPE)
      ) {
        return t('common.freeSevenDayTrial');
      }
    }
    return t('common.subscribe');
  };

  const formatInfoItems = (product: ProductItem) => {
    const isUnlimitedProduct = product?.productType === PRODUCT_TYPE.Unlimited;
    const isProPlusProduct = product?.productType === PRODUCT_TYPE.ProPlus;
    return (
      <div className="info-detail">
        {product?.productInfoItemsDetail?.map((item, index) => {
          const productInfoItem = item as ProductInfoItem;
          return (
            <div className="info-item" key={`info-item${index}`}>
              {productInfoItem.title && (
                <div className="info-item-title">{t(`ENUM.${productInfoItem.title}`)}</div>
              )}
              {productInfoItem.desc && (
                <ul
                  className={classNames({
                    'info-item-desc': true,
                    'plus-summary': isUnlimitedProduct,
                  })}
                >
                  {productInfoItem.desc.map((descItem, index) => {
                    const item = descItem as Desc;
                    return (
                      <li key={`info-item-desc${index}`}>
                        <div
                          className={classNames({
                            'info-item-label': true,
                            'info-item-label-pro-plus': isProPlusProduct,
                            'info-item-label-plus': isUnlimitedProduct,
                          })}
                        >
                          {/* {typeof(item.label) === 'string' ? item.label : item.label.param || '' + t(`ENUM.${item.label.text}`)} */}
                          {typeof item.label === 'string'
                            ? t(`ENUM.${item.label}`)
                            : item.label.param || '' + t(`ENUM.${item.label.text}`)}
                        </div>
                        {item.value && (
                          <ul className="info-item-value">
                            {item.value?.map((value, index) => {
                              return (
                                <li key={`info-item-value${index}`}>
                                  <CustomIcon className="tick-icon" type="tick"></CustomIcon>
                                  {isNumber(value.param)
                                    ? t(`ENUM.${value.text}`, { count: value.param })
                                    : t(`ENUM.${value.text}`)}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <>
      {formatProducts.map((product, index) => {
        const isFreeProduct = product?.productType === PRODUCT_TYPE.Free;
        const isProPruoduct = product?.productType === PRODUCT_TYPE.Pro;
        const isProPlusProduct = product?.productType === PRODUCT_TYPE.ProPlus;
        const isUnlimitedProduct = product?.productType === PRODUCT_TYPE.Unlimited;
        const hasPrice = isNumber(product?.productPriceNum) && product?.productPriceNum > 0;
        const cancelTip = renderCancelTip(product);

        // 月是否显示折扣
        const shouldShowMonthSaveTag =
          (isProPruoduct || isProPlusProduct || isUnlimitedProduct) &&
          proType === PRODUCT_PERIOD_TYPE.Month &&
          discountInfo?.monthlyDiscount &&
          discountInfo?.monthlyDiscount > 0;
        // 年是否显示折扣
        const shouldShowYearSaveTag =
          (isProPruoduct || isProPlusProduct || isUnlimitedProduct) &&
          proType === PRODUCT_PERIOD_TYPE.Year &&
          discountInfo?.yearlyDiscount &&
          discountInfo?.yearlyDiscount > 0;

        // 是否显示独家优惠: 无限产品且（没有月折扣或者没有年折扣）,有折扣显示折扣，没折扣显示这个
        const shouldShowExclusiveOffer =
          isUnlimitedProduct &&
          ((proType === PRODUCT_PERIOD_TYPE.Month && !discountInfo?.monthlyDiscount) ||
            (proType === PRODUCT_PERIOD_TYPE.Year && !discountInfo?.yearlyDiscount));

        return index === 0 && !showFree && isHaveProPlus ? (
          <div
            className="free-plan-close"
            onClick={() => setShowFree(true)}
            key={`product-detail${index}`}
          >
            <CustomIcon type="leftArrow" />
            Free Plan
          </div>
        ) : (
          <div
            className={classNames({
              'product-detail': true,
              'product-detail-free': isFreeProduct && isHaveProPlus,
            })}
            key={`product-detail${index}`}
          >
            <Skeleton loading={isLoading} active paragraph={{ rows: 8 }}>
              <div className="pro-border">
                {isProPruoduct && <CustomIcon type="payProBorder" />}
                {product?.productType === PRODUCT_TYPE.ProPlus && (
                  <CustomIcon type="payProBorder" />
                )}
                {isUnlimitedProduct && <CustomIcon type="payProPlusBorder" />}
              </div>
              {shouldShowMonthSaveTag && (
                <SaveTag
                  save={discountInfo?.monthlyDiscount as number}
                  isUnlimited={isUnlimitedProduct}
                />
              )}
              {shouldShowYearSaveTag && (
                <SaveTag
                  save={discountInfo?.yearlyDiscount as number}
                  isUnlimited={isUnlimitedProduct}
                />
              )}
              {shouldShowExclusiveOffer && (
                <div className="product-tag">
                  <CustomIcon className="triangle-icon" type="triangle" />
                  <div className="product-tag-text">{t('layout.pay.exclusiveOffer')}</div>
                </div>
              )}
              <div className="product-summary">
                <div
                  className={classNames({
                    'product-type': true,
                    'product-type-pay': hasPrice,
                    'product-type-pro-plus': isProPlusProduct,
                    'product-type-pay-plus': isUnlimitedProduct,
                  })}
                >
                  {product?.productType}
                </div>
                <div className="info-summary">
                  {product?.productInfoSummary && t(`ENUM.${product?.productInfoSummary}`)}
                </div>
              </div>
              <div>
                <div className="product-price">
                  <span className="price-currency">{product.productPriceText}</span>
                  <span className="price-num">{product.productPriceNum}</span>
                  <span className="price-period">{t('layout.pay.mo')}</span>
                  {/* 年有折扣显示年划线价，月有折扣显示月划线价 */}
                  {!!product?.strikePrice &&
                    ((discountInfo?.monthlyDiscount && proType === PRODUCT_PERIOD_TYPE.Month) ||
                      (discountInfo?.yearlyDiscount && proType === PRODUCT_PERIOD_TYPE.Year)) &&
                    [PRICE_STATUS.TESTGROUPA, PRICE_STATUS.TESTGROUPB].includes(
                      priceStatus as PRICE_STATUS,
                    ) && (
                      <span className="strike-price">
                        {product.productPriceText}
                        {product?.strikePrice}
                      </span>
                    )}
                </div>
                {hasPrice && proType === PRODUCT_PERIOD_TYPE.Year && (
                  <div className="billed-tag-wra">
                    <span className="billed-tag">{t('layout.pay.billedYearly')}</span>
                  </div>
                )}
                {cancelTip && <div className="cancel-tip">{cancelTip}</div>}
              </div>
              {hasPrice && (
                <div className="btn-wrapper">
                  <Button
                    className={classNames({
                      'pro-plus-btn': isProPlusProduct,
                      'plus-btn': isUnlimitedProduct,
                    })}
                    onClick={() => handlePayDebounce(product)}
                  >
                    <span>{renderSubscribeBtnLabel(product)}</span>
                    <CustomIcon type="rightArrowOutlined"></CustomIcon>
                  </Button>
                </div>
              )}
              {formatInfoItems(product)}
            </Skeleton>
          </div>
        );
      })}
      {teamFeature && (
        <div className="product-detail" key={`product-detail-teams`}>
          <div className="product-summary">
            <div className="product-type">{t('layout.pay.teams')}</div>
            <div className="info-summary">{t('layout.pay.teamsSummary')}</div>
          </div>
          <div className="team-contact">
            <CustomIcon type="payEmail" />
            <a href="mailto:customerservice@01.ai">customerservice@01.ai</a>
          </div>
          {formatInfoItems(teamProduct)}
        </div>
      )}
    </>
  );
};

const NewPay: React.FC<IPayProps> = (props) => {
  const { products, priceStatus, trialStatus, isLoading, shortMessage, onBeforePay } = props;
  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();
  const { t } = useTranslation();

  const { discountInfo, userInfo } = useUserStore();
  const { experiment } = useSundry();
  const freeModelFeature = experiment(AB_EXPERIMENTS.STANDARD_FREE);

  const [proType, setProType] = useState<PRODUCT_PERIOD_TYPE>(PRODUCT_PERIOD_TYPE.Year);
  const yearlyOrder = [
    MEMBERSHIP_ID.Free,
    MEMBERSHIP_ID.YearPro,
    MEMBERSHIP_ID.YearProPlus,
    MEMBERSHIP_ID.YearUnlimited,
  ];
  const monthlyOrder = [
    MEMBERSHIP_ID.Free,
    MEMBERSHIP_ID.MonthPro,
    MEMBERSHIP_ID.MonthProPlus,
    MEMBERSHIP_ID.MonthUnlimited,
  ];

  const isHaveProPlus = useMemo(() => {
    return products.some((item) => {
      return [MEMBERSHIP_ID.YearProPlus, MEMBERSHIP_ID.MonthProPlus].includes(
        item.membership.membershipId,
      );
    });
  }, [products]);

  const currentProducts = useMemo(() => {
    if (proType === PRODUCT_PERIOD_TYPE.Month) {
      return products
        .filter((item) =>
          [
            MEMBERSHIP_ID.Free,
            MEMBERSHIP_ID.MonthPro,
            MEMBERSHIP_ID.MonthProPlus,
            MEMBERSHIP_ID.MonthUnlimited,
          ].includes(item.membership.membershipId),
        )
        .sort((a, b) => {
          return (
            monthlyOrder.indexOf(a.membership.membershipId) -
            monthlyOrder.indexOf(b.membership.membershipId)
          );
        });
    }
    return products
      .filter((item) =>
        [
          MEMBERSHIP_ID.Free,
          MEMBERSHIP_ID.YearPro,
          MEMBERSHIP_ID.YearProPlus,
          MEMBERSHIP_ID.YearUnlimited,
        ].includes(item.membership.membershipId),
      )
      .sort((a, b) => {
        return (
          yearlyOrder.indexOf(a.membership.membershipId) -
          yearlyOrder.indexOf(b.membership.membershipId)
        );
      });
  }, [proType, products]);

  const formatPrice = (item: IProduct) => {
    const productPriceNum = Number(item.price) || 0;
    let formatPrice;
    if (
      [MEMBERSHIP_ID.YearPro, MEMBERSHIP_ID.YearProPlus, MEMBERSHIP_ID.YearUnlimited].includes(
        item?.membership?.membershipId,
      )
    ) {
      formatPrice = (productPriceNum / 12 / 100).toFixed(1);
    } else {
      formatPrice = productPriceNum / 100;
    }
    return Number(formatPrice);
  };

  const formatstrikePrice = (price: string | number | undefined) => {
    const productPriceNum = Number(price) || 0;
    return Number(productPriceNum / 100);
  };

  const formatProducts = useMemo(() => {
    const result: ProductItem[] = [];
    const showProductType = isHaveProPlus
      ? [PRODUCT_TYPE.Free, PRODUCT_TYPE.Pro, PRODUCT_TYPE.ProPlus, PRODUCT_TYPE.Unlimited]
      : [PRODUCT_TYPE.Free, PRODUCT_TYPE.Pro, PRODUCT_TYPE.Unlimited];

    currentProducts.map((item: IProduct, index: number) => {
      const productType = showProductType[index];
      const price = Number(item.price) || 0;
      result.push({
        productType,
        productIds: item.productIds,
        productPriceNum: formatPrice(item),
        price: (price / 100).toFixed(1),
        productPriceText: item.currency,
        productInfoSummary: NEW_PRODUCT_INFO_SUMMARY[productType],
        productInfoItemsDetail: NEW_PRODUCT_INFO_ITEMS_DETAIL(
          {
            type: productType,
            ...item.membership,
          },
          shouldUserUseNewModelName(userInfo),
          freeModelFeature || false,
        ),
        membershipId: item.membership.membershipId,
        strikePrice: formatstrikePrice(item.strikePrice),
      });
    });
    return result;
  }, [currentProducts, isHaveProPlus]);

  const handleProTypeChange = (proType: PRODUCT_PERIOD_TYPE) => {
    setProType(proType);
    sendDAEvent('ProductPurchaseModalButtonClick', {
      button_name: proType === PRODUCT_PERIOD_TYPE.Month ? 'monthly' : 'annually',
    });
    sendEvent('ClickPayTab', {
      tabType: proType,
    });
  };

  // 是否有活动折扣
  const hasActiveDiscount = useMemo(() => {
    return hasCouponActivity(discountInfo);
  }, [discountInfo]);

  // 折扣，如果有活动折扣，显示活动折扣，否则显示默认折扣
  const discount = useMemo(() => {
    return discountInfo?.yearlyDiscount && discountInfo?.yearlyDiscount > 0
      ? discountInfo?.yearlyDiscount * 100
      : 16;
  }, [discountInfo?.yearlyDiscount]);

  // 是否显示折扣标签，如果有活动折扣，且选择了月，在年上显示活动折扣标签；如果没有活动折扣，显示默认折扣标签
  const showDiscountLabel = useMemo(() => {
    if (hasActiveDiscount) {
      return proType === PRODUCT_PERIOD_TYPE.Month;
    }
    return true;
  }, [hasActiveDiscount, proType]);

  return (
    <div className="product-wrapper">
      {shortMessage && (
        <div className="pay-desc">
          <span className="pay-desc-reason">{shortMessage}</span> {t('common.please')}
        </div>
      )}
      <div className="btn-container">
        <div className="btn-group">
          {showDiscountLabel && (
            <div className="discount">{t('layout.pay.save16', { discount })}</div>
          )}
          <button
            className={classNames({
              active: proType === PRODUCT_PERIOD_TYPE.Month,
            })}
            onClick={() => handleProTypeChange(PRODUCT_PERIOD_TYPE.Month)}
          >
            {t('layout.pay.monthly')}
          </button>
          <button
            className={classNames({
              active: proType === PRODUCT_PERIOD_TYPE.Year,
            })}
            onClick={() => handleProTypeChange(PRODUCT_PERIOD_TYPE.Year)}
          >
            {t('layout.pay.annually')}
          </button>
        </div>
      </div>
      <DiscountBanner />
      <div className="product-container">
        <Product
          products={formatProducts}
          priceStatus={priceStatus}
          trialStatus={trialStatus}
          isLoading={isLoading}
          proType={proType}
          onBeforePay={onBeforePay}
          discountInfo={discountInfo}
        />
      </div>
    </div>
  );
};

export default NewPay;
