import { useCallback, useState } from 'react';
import { chatCreateUsingPOST, createNewChat } from '@/services';
import { CreateChatParams, ResponseType, DocEntries } from '@/type';
import { message } from 'antd';

export const useDocChat = () => {
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({});

  const onChatStateCache = useCallback(
    ({
      channelId,
      fileName,
      md5,
      model,
    }: {
      channelId: string;
      fileName: string;
      md5: string;
      model: string;
    }) => {
      localStorage.setItem(
        `oneChatState_${channelId}`,
        JSON.stringify({
          channelId,
          roleEnum: 'SUMMARYPROMPT',
          message: '',
          prompt: '',
          docs: [
            {
              file_name: fileName,
              file_type: 'application/pdf',
              file_page_count: 1,
              md5: md5,
            },
          ],
          channelType: 'ONE_CHAT',
          // searchSwitch: true, // 上传doc进入one chat默认关闭联网开关，等模型效果好转再打开
          defaultOpenGUI: true,
          model,
        }),
      );
      localStorage.setItem('pageMode', 'academicSearch');
    },
    [],
  );

  const isArxivAbsLink = (url?: string) => {
    if (!url) return false;
    const arxivAbsRegex = /^https:\/\/arxiv\.org\/abs\/[0-9]{4}\.[0-9]{4,5}$/;
    return arxivAbsRegex.test(url);
  };

  const createDocChat = async (params: CreateChatParams) => {
    setLoadingStates((prevStates) => ({ ...prevStates, [params.url as string]: true }));
    try {
      let url = params.url;
      if (isArxivAbsLink(url)) {
        url = url?.replace('abs', 'pdf');
      }
      const createRes = await chatCreateUsingPOST<
        ResponseType<{
          md5: string;
          channel_id: string;
          channel_name: string;
          docEntries: DocEntries;
          model: string;
        }>
      >({
        ...params,
        url,
      });

      const channelId = createRes?.data?.channel_id;

      const oneChatParams = {
        channelId: channelId,
        roleEnum: 'SUMMARYPROMPT',
        docs: [
          {
            file_name: createRes.data.channel_name,
            file_type: 'application/pdf',
            file_page_count: 1,
            md5: createRes.data.md5,
          },
        ],
        channelType: 'ONE_CHAT',
        // searchSwitch: true, // 上传doc进入one chat默认关闭联网开关，等模型效果好转再打开
      };

      const oneChatRes = await createNewChat(oneChatParams);
      if (oneChatRes && channelId) {
        // 将自动 send 的信息带到新开的页面
        onChatStateCache({
          channelId,
          fileName: createRes.data.channel_name,
          md5: createRes.data.md5,
          model: createRes.data.model,
        });
        window.open(`/chat/${channelId}`, '_blank');
      }
    } catch (error) {
      message.error('Sorry, this message cannot Read Document.');
    } finally {
      setLoadingStates((prevStates) => ({ ...prevStates, [params.url as string]: false }));
    }
  };

  return {
    createDocChat,
    loadingStates,
    isArxivAbsLink,
  };
};
