import { Node } from 'prosemirror-model';
import { EditorView as PMEditorView, NodeView } from 'prosemirror-view';
import {
  drawSelection,
  EditorView,
  keymap,
  lineNumbers,
  rectangularSelection,
} from '@codemirror/view';
import {
  indentOnInput,
  bracketMatching,
  defaultHighlightStyle,
  syntaxHighlighting,
} from '@codemirror/language';
import { closeBrackets, closeBracketsKeymap } from '@codemirror/autocomplete';
import { defaultKeymap, indentWithTab } from '@codemirror/commands';
import { Compartment, EditorState } from '@codemirror/state';

import {
  backspaceHandler,
  computeChange,
  forwardSelection,
  maybeEscape,
  setMode,
  valueChanged,
} from './utils';
import { CodeBlockSettings } from './types';

export const CodeMirrorNodeView: (
  settings: CodeBlockSettings,
) => (pmNode: Node, view: PMEditorView, getPos: (() => number) | boolean) => NodeView =
  (settings) => (pmNode, view, getPos) => {
    let node = pmNode;
    let updating = false;
    const dom = document.createElement('div');
    dom.className = 'codeblock-root';
    const languageConf = new Compartment();
    const state = EditorState.create({
      extensions: [
        EditorState.readOnly.of(settings.readOnly),
        EditorView.editable.of(!settings.readOnly),
        lineNumbers(),
        bracketMatching(),
        closeBrackets(),
        rectangularSelection(),
        drawSelection({ cursorBlinkRate: 1000 }),
        EditorState.allowMultipleSelections.of(true),
        syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
        languageConf.of([]),
        indentOnInput(),
        keymap.of([
          {
            key: 'ArrowUp',
            run: (cmView) => maybeEscape('line', -1, cmView, view, getPos),
          },
          {
            key: 'ArrowLeft',
            run: (cmView) => maybeEscape('char', -1, cmView, view, getPos),
          },
          {
            key: 'ArrowDown',
            run: (cmView) => maybeEscape('line', 1, cmView, view, getPos),
          },
          {
            key: 'ArrowRight',
            run: (cmView) => maybeEscape('char', 1, cmView, view, getPos),
          },
          {
            key: 'Mod-z',
            run: () => settings.undo?.(view.state, view.dispatch) || true,
            shift: () => settings.redo?.(view.state, view.dispatch) || true,
          },
          {
            key: 'Mod-y',
            run: () => settings.redo?.(view.state, view.dispatch) || true,
          },
          { key: 'Backspace', run: (cmView) => backspaceHandler(view, cmView) },
          {
            key: 'Mod-Backspace',
            run: (cmView) => backspaceHandler(view, cmView),
          },
          {
            key: 'Mod-a',
            run: (cmView) => {
              cmView.dispatch({
                selection: { anchor: 0, head: cmView.state.doc.length },
              });
              cmView.focus();
              return true;
            },
          },
          ...defaultKeymap,
          ...closeBracketsKeymap,
          indentWithTab,
        ]),
      ],
      doc: node.textContent,
    });

    const codeMirrorView = new EditorView({
      state,
      // dispatch: (tr) => {
      //   if (!updating) {
      //     codeMirrorView.update([tr]);
      //     const textUpdate = tr.state.toJSON().doc;
      //     valueChanged(textUpdate, node, getPos, view);
      //     forwardSelection(codeMirrorView, view, getPos);
      //   }
      // },
      dispatchTransactions: (trs) => {
        if (!updating) {
          codeMirrorView.update(trs);
          const textUpdate = trs[trs.length - 1].state.toJSON().doc;
          valueChanged(textUpdate, node, getPos, view);
          forwardSelection(codeMirrorView, view, getPos);
        }
      },
    });
    dom.append(codeMirrorView.dom);

    const selectDeleteCB = settings.createSelect(settings, dom, node, view, getPos);

    setMode(node.attrs.lang, codeMirrorView, settings, languageConf);

    const uniqueId = node.attrs.uniqueId;
    // @ts-expect-error ignore
    view.nodeViews[uniqueId] = { cmView: codeMirrorView };

    // if (
    //   view.state.doc.content.childCount === 1 &&
    //   view.state.doc.content.firstChild?.type.name === CodeBlock.name
    // ) {
    //   setTimeout(() => {
    //     codeMirrorView.focus();
    //   }, 300);
    // }

    return {
      dom,
      selectNode() {
        codeMirrorView.focus();
      },
      stopEvent: (e: Event) => settings.stopEvent(e, node, getPos, view, dom),
      setSelection: (anchor, head) => {
        forwardSelection(codeMirrorView, view, getPos);
        updating = true;
        // const pos = typeof getPos === 'function' ? getPos() : 0;
        codeMirrorView.dispatch({
          selection: { anchor: anchor, head: head },
        });
        updating = false;
      },
      update: (updateNode) => {
        if (updateNode.type.name !== node.type.name) {
          return false;
        }
        if (updateNode.attrs.lang !== node.attrs.lang) {
          setMode(updateNode.attrs.lang, codeMirrorView, settings, languageConf);
        }

        if (updateNode.textContent !== node.textContent) {
          const oldNode = node;
          node = updateNode;
          const change = computeChange(codeMirrorView.state.doc.toString(), node.textContent);
          if (change) {
            updating = true;
            codeMirrorView.dispatch({
              changes: {
                from: change.from,
                to: change.to,
                insert: change.text,
              },
              selection: { anchor: change.from + change.text.length },
            });
            updating = false;
          }
          settings.updateSelect(settings, dom, updateNode, view, getPos, oldNode);
        }

        return true;
      },
      ignoreMutation: () => true,
      destroy: () => {
        selectDeleteCB();
        codeMirrorView.destroy();
      },
    };
  };
