import { getPresignedUsingGET } from '@/services';
import type { ResponseType, PresignedData } from '@/type';
import axios from '@/common/request';
import { calculateHash, splitFile } from '@/common/utils';
import { AxiosProgressEvent } from 'axios';
import { monitorReportError } from '@/common/monitor';

const uploadFile = async <T extends File>(
  file: T,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) => {
  try {
    // 获取上传文件的url地址（S3）
    const presignedPostUrl = await getPresignedPostUrl(file);
    //向上一步获取到的url地址上传文件
    if (presignedPostUrl) {
      const res = await customUpload(presignedPostUrl, file, onUploadProgress);
      return res;
    }
  } catch (error: any) {
    console.log(error);
  }
};

/**
 * 获取上传文件真实接口
 * @param file
 * @returns
 */
const getPresignedPostUrl = async <T extends File>(file: T) => {
  const chunkList = splitFile(file as File);
  const md5 = await calculateHash(chunkList);
  try {
    const res = await getPresignedUsingGET<ResponseType<PresignedData>>({
      md5: md5 as string,
    });
    return res.data;
  } catch (error: any) {
    const errMsg = error?.response?.data?.message || error?.data?.message || error?.message;
    error.message = `getPresignedPostUrl fail: ${errMsg}`;
    monitorReportError(error);
  }
};

/**
 * 上传文件
 * @param presignedPostUrl
 * @param file
 */
const customUpload = async (
  presignedPostUrl: PresignedData,
  file: File,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) => {
  try {
    const uploadS3FormData = new FormData();
    Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
      uploadS3FormData.append(k, v);
    });
    uploadS3FormData.append('file', file);
    await axios.post(presignedPostUrl.url, uploadS3FormData, {
      onUploadProgress,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      noAuthHeader: true,
      noAlertError: true,
    });
    return { ...presignedPostUrl };
  } catch (error: any) {
    error.message = `customUpload failed:${error.message}`;
    monitorReportError(error);
  }
};

export default uploadFile;
