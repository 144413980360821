import { Node } from '@tiptap/pm/model';
import { Editor, NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Popover, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonStore } from '@/store';

import './CalloutView.less';

interface CalloutViewProps {
  editor: Editor;
  node: Node;
  updateAttributes: (attrs: Record<string, string>) => void;
}

export const DEFAULT_EMOJI = '📍';

export const CalloutView = (props: CalloutViewProps) => {
  const { editor, node, updateAttributes } = props;

  const { t } = useTranslation();
  const pageLanguage = useCommonStore((state) => state.pageLanguage);

  const [open, setOpen] = useState(false);
  const [emoji, setEmoji] = useState(node.attrs.emoji || DEFAULT_EMOJI);
  const [mounted, setMounted] = useState(false);

  const handleEmojiSelect = (data: any) => {
    setEmoji(data.native);
    setOpen(false);
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (open) {
      editor.view.dom.blur();
    }
  };

  useEffect(() => {
    if (mounted) {
      setTimeout(() => {
        updateAttributes({ emoji });
      }, 0);
    } else {
      setMounted(true);
    }
  }, [emoji]);

  return (
    <NodeViewWrapper>
      <div className="callout">
        <Popover
          open={open}
          overlayClassName="emoji-popover"
          trigger="click"
          placement={pageLanguage == 'ar' ? 'bottomRight' : 'bottomLeft'}
          overlayInnerStyle={{
            padding: '0px',
          }}
          onOpenChange={handleOpenChange}
          content={<Picker data={data} onEmojiSelect={handleEmojiSelect} />}
          getPopupContainer={() => document.querySelector('.emoji') as HTMLElement}
        >
          <Tooltip
            title={t('components.write.calloutEmoji')}
            overlayInnerStyle={{
              borderRadius: '8px',
              fontSize: '13px',
              lineHeight: '18px',
            }}
          >
            <span className="emoji">{emoji}</span>
          </Tooltip>
        </Popover>
        <NodeViewContent className="content" />
      </div>
    </NodeViewWrapper>
  );
};

export default CalloutView;
