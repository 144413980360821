import { mergeAttributes } from '@tiptap/core';
import TiptapLink from '@tiptap/extension-link';
import { Plugin } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { getLinkTitle } from '@/services';
import { isFullUrl } from '@/common/utils';

export const Link = TiptapLink.extend({
  inclusive: false,

  parseHTML() {
    return [{ tag: 'a[href]:not([data-type="button"]):not([href *= "javascript:" i])' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: 'link',
        target: '_blank',
      }),
      0,
    ];
  },

  addProseMirrorPlugins() {
    const { editor } = this;

    return [
      ...(this.parent?.() || []),
      new Plugin({
        props: {
          handleKeyDown: (_view: EditorView, event: KeyboardEvent) => {
            const { selection } = editor.state;

            if (event.key === 'Escape' && selection.empty !== true) {
              editor.commands.focus(selection.to, { scrollIntoView: false });
            }

            return false;
          },
          handlePaste: (view: EditorView, _e: ClipboardEvent, slice) => {
            const { state } = view;
            const { selection } = state;
            const { from } = selection;

            let textContent = '';
            slice.content.forEach((node) => {
              textContent += node.textContent;
            });
            if (isFullUrl(textContent)) {
              getLinkTitle<{ data: string }>({ url: textContent }).then((resp) => {
                const linkTitle = resp.data;
                if (linkTitle) {
                  editor
                    .chain()
                    .command(({ tr }) => {
                      tr.deleteRange(from, from + textContent.length).replaceWith(
                        from,
                        from,
                        editor.schema.text(linkTitle, [
                          editor.schema.marks.link.create({
                            target: '_blank',
                            href: textContent,
                          }),
                        ]),
                      );
                      return true;
                    })
                    .run();
                }
              });
            }
          },
        },
      }),
    ];
  },

  // addKeyboardShortcuts() {
  //   return {
  //     'Mod-k': () => this.editor.commands.setLink(),
  //   }
  // },
});

export default Link;
