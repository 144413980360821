import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import CustomIcon from '@/components/CustomIcon';
import { useGASendEvent } from '@/hooks';
import FeatureTipPopover from '@/components/FeatureTipPopover';

import type { MenuItemType } from './';

import './index.less';

interface MenuItemProps {
  collapsed: boolean;
  menu: MenuItemType;
  onNavLinkClick?: (key: string | null) => void;
}

const FEATURE_TIP_MENUS = ['writing', 'workspace'];

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { collapsed, menu, onNavLinkClick } = props;
  const { sendEvent } = useGASendEvent();
  const { icon, iconHover, iconActive } = menu;
  const location = useLocation();

  const handleNavLinkClick = (key: string | null) => {
    if (key === 'workspace') {
      sendEvent('enter_workspace', {
        from: 'menu',
      });
    }
    onNavLinkClick?.(key);
  };

  const wrapTipRender: any = (content: React.ReactNode) => {
    if (FEATURE_TIP_MENUS.includes(menu.key)) {
      return (
        <FeatureTipPopover tag={menu.key} placement="right">
          {content}
        </FeatureTipPopover>
      );
    }
    return content;
  };

  const itemContent = wrapTipRender(
    <div
      className={classNames({
        'side-menu-item-container': true,
        'side-menu-item-container-collapsed': collapsed,
      })}
    >
      <div className="menu-icon">
        <CustomIcon className="menu-default-icon" type={icon} />
        <CustomIcon className="menu-active-icon" type={iconActive} />
        <CustomIcon className="menu-hover-icon" type={iconHover} />
      </div>
      {!collapsed && <span className="item-label">{menu.label}</span>}
    </div>,
  );

  return (
    <NavLink
      className={classNames({
        'side-menu-item': true,
        'side-menu-item-expanded': !collapsed,
        'side-menu-item-collapsed': collapsed,
        [`side-menu-item-${menu?.key}`]: !collapsed,
        'item-active': location?.pathname.split('/')[1] === menu?.path?.split('/')[1],
      })}
      to={`${menu.path}${location?.search}`}
      onClick={() => handleNavLinkClick(menu.key)}
    >
      {itemContent}
    </NavLink>
  );
};

export default MenuItem;
