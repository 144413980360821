import React, { useEffect, useMemo, useState } from 'react';
import type { QuoteInfoItem } from '@/type';
import { isEmpty } from 'lodash-es';
import { useChatStore } from '@/store';
import classNames from 'classnames';
import Earth from '@/assets/earth.svg';
import { useTranslation } from 'react-i18next';
import { useDA } from '@/hooks';

import './index.less';
import { getChatType } from '@/common/helpers/chatHelper';

interface QuoteListProps {
  links: QuoteInfoItem[];
  messageId?: string;
}

const QuoteList: React.FC<QuoteListProps> = ({ links, messageId }) => {
  const { sendDAEvent } = useDA();

  const [expanded, setExpanded] = useState(false);
  const [favicons, setFavicons] = useState<string[]>([]);
  const { showOneChatGUI, channel } = useChatStore();

  const { t } = useTranslation();

  const handleExpand = () => {
    sendDAEvent(expanded ? 'OnlineSearchResultCollapse_Click' : 'OnlineSearchResultViewAll_Click', {
      channelid: channel?.channelId,
      chattype: getChatType(channel),
      messageid: messageId,
      more_urls: links.map((item) => item?.url),
    });
    setExpanded(!expanded);
  };

  const displayedLinks = useMemo(() => {
    if (links.length <= 4) {
      return links;
    }

    return expanded ? links : links.slice(0, 3);
  }, [expanded, links]);

  useEffect(() => {
    const faviconUrls: string[] = [];
    for (const link of links) {
      if (link.favicon && !faviconUrls.includes(link.favicon)) {
        faviconUrls.push(link.favicon);
      }
    }

    setFavicons(faviconUrls.slice(0, 7));
  }, [links]);

  const handleClick = (link: any, index: number) => {
    sendDAEvent('OnlineSearchResult_Click', {
      channelid: channel?.channelId,
      chattype: getChatType(channel),
      click_url: link.url,
      messageid: messageId,
      no: index + 1,
    });
    window.open(link.url, '_blank');
  };

  return (
    <section className="quote-container">
      <div className={classNames({ 'quote-list': true, 'quote-list--side': showOneChatGUI })}>
        {displayedLinks.map((link, index) => (
          <div key={index} className="quote-list__card" onClick={() => handleClick(link, index)}>
            <div className="quote-list__card-content">{link.title}</div>
            <div className="quote-list__card-icon">
              {link.favicon ? (
                <img
                  className="quote-list__card-logo"
                  src={link.favicon}
                  onError={(e) => {
                    (e.target as HTMLImageElement).setAttribute('src', Earth);
                  }}
                />
              ) : (
                <i className="iconfont popai-a-searchweb1"></i>
              )}

              <span className="quote-list__card-source">{link?.source}</span>
            </div>
          </div>
        ))}
        {!expanded && links.length > 4 && (
          <div className="quote-list__card" onClick={handleExpand}>
            <div className="quote-list__card-content quote-list__expand-btn">
              {!isEmpty(favicons) &&
                favicons.map((url) => (
                  <img
                    key={url}
                    className="quote-list__card-logo"
                    src={url}
                    onError={(e) => {
                      (e.target as HTMLImageElement).setAttribute('src', Earth);
                    }}
                  />
                ))}
            </div>
            <div className="quote-list__length">
              {t('pages.chat.viewAll')} {links.length}
            </div>
          </div>
        )}
      </div>
      {expanded && (
        <div className="quote-list__close" onClick={handleExpand}>
          <i className="iconfont popai-up"></i>
          <span>{t('pages.chat.collapse')}</span>
        </div>
      )}
    </section>
  );
};

export default QuoteList;
