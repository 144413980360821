import CustomIcon from '@/components/CustomIcon';
import { cancelDiscountGet, detailPreviewGet } from '@/services';
import { CouponDetail } from './interface';
import { useDebounceFn } from 'ahooks';
import { useGASendEvent } from '@/hooks';
import { App, Skeleton, Spin } from 'antd';
import type { ResponseType } from '@/type';
import ToastContent from '@/components/ToastContent';
import { useTranslation } from 'react-i18next';
import './coupon.less';
import { useEffect, useState } from 'react';

interface CouponProps {
  handleNext?: () => void;
  onCancel?: () => void;
  subscriptionId?: string;
  type?: string;
  extraFooterOperateRender?: () => React.ReactNode;
}

const Coupon: React.FC<CouponProps> = (props) => {
  const { t } = useTranslation();
  const { sendEvent } = useGASendEvent();

  const { message } = App.useApp();

  const { subscriptionId = '', extraFooterOperateRender, type } = props;
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [couponDetail, setCouponDetail] = useState<CouponDetail>();

  const intervalMap: Record<string, string> = {
    Month: t('layout.billingManage.mo'),
    Year: t('layout.billingManage.yr'),
  };

  useEffect(() => {
    getCouponDetail();
    !extraFooterOperateRender && sendEvent('cancelcoupon_show');
  }, []);

  const getCouponDetail = async () => {
    setSkeletonLoading(true);
    try {
      const res = await detailPreviewGet<ResponseType<CouponDetail>>({
        fastSpring: type === 'StudentSummer' ? 'FastSpringStudentSummer' : 'FastSpringCancel',
        stripe: type === 'StudentSummer' ? 'StripeStudentSummer' : 'StripeCancel',
      });
      setCouponDetail(res.data);
    } catch (e) {
      console.log(e);
    } finally {
      setSkeletonLoading(false);
    }
  };

  const handleContinue = async () => {
    setLoading(true);
    try {
      const res = await cancelDiscountGet<ResponseType>({ subscriptionId });
      sendEvent('continuewithdiscount_click');
      setLoading(false);
      if (res && res.data) {
        props?.onCancel?.();
        message.open({
          content: (
            <ToastContent
              icon="success"
              content={t('layout.coupon.successTip', {
                discountPercent: couponDetail?.discountPercent,
              })}
            />
          ),
        });
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    props?.handleNext?.();
    sendEvent('cancelsubscribtion_click');
  };

  const { run: handleContinueDebounce } = useDebounceFn(handleContinue, {
    wait: 500,
    leading: true,
    trailing: false,
  });

  return (
    <div className="coupon-wrapper">
      <Skeleton loading={skeletonLoading} active paragraph={{ rows: 13 }}>
        <Spin spinning={loading}>
          <div className="coupon">
            <div className="coupon-content">
              <CustomIcon type="coupon" />
              <div className="coupon-cancel-pause">
                {type ? t('layout.newPayCoupon.title') : t('layout.coupon.title')}
              </div>
              <div className="coupon-discount">
                {t('layout.coupon.off', { discountPercent: couponDetail?.discountPercent })}
              </div>
              <div className="coupon-next-renewal">{t('layout.coupon.target')}</div>
              <div className="coupon-desc">
                {t('layout.coupon.desc', {
                  discountPercent: couponDetail?.discountPercent,
                })}
                <span>
                  {t('layout.coupon.savePrice', {
                    interpolation: { escapeValue: false },
                    symbol: couponDetail?.symbol,
                    discountAmount: couponDetail?.discountAmount?.toFixed(1),
                  })}
                </span>
              </div>
              <div className="coupon-price">
                <div className="coupon-price-original">
                  <div>{t('layout.coupon.originalPrice')}</div>
                  <div>
                    {couponDetail?.symbol}
                    {couponDetail?.amount?.toFixed(1)}/
                    {intervalMap[couponDetail?.subscriptionInterval as string]}
                  </div>
                </div>
                <div className="coupon-price-discount">
                  <div>{t('layout.coupon.nextCharge')}</div>
                  <div>
                    {couponDetail?.symbol}
                    {couponDetail?.nextCharge?.toFixed(1)}/
                    {intervalMap[couponDetail?.subscriptionInterval as string]}
                  </div>
                </div>
              </div>
            </div>
            <div className="coupon-operation">
              {extraFooterOperateRender ? (
                extraFooterOperateRender()
              ) : (
                <>
                  <div className="coupon-continue" onClick={handleContinueDebounce}>
                    {t('layout.coupon.continueWithDiscount')}
                  </div>
                  <div className="coupon-cancel" onClick={handleCancel}>
                    {t('layout.coupon.cancelSubscribtion')}
                  </div>
                </>
              )}
            </div>
          </div>
        </Spin>
      </Skeleton>
    </div>
  );
};

export default Coupon;
