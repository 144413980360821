export enum CodeBlockLanguages {
  javascript = 'javascript',
  html = 'html',
  css = 'css',
  sql = 'sql',
  python = 'python',
  rust = 'rust',
  xml = 'xml',
  json = 'json',
  markdown = 'markdown',
  java = 'java',
  cpp = 'cpp',
  lezer = 'lezer',
  php = 'php',
  wast = 'wast',
}

export enum LegacyLanguages {
  clike = 'clike',
  clojure = 'clojure',
  cmake = 'cmake',
  cobol = 'cobol',
  coffeescript = 'coffeescript',
  crystal = 'crystal',
  d = 'd',
  diff = 'diff',
  dockerfile = 'dockerfile',
  erlang = 'erlang',
  fortran = 'fortran',
  go = 'go',
  groovy = 'groovy',
  haskell = 'haskell',
  http = 'http',
  julia = 'julia',
  livescript = 'livescript',
  lua = 'lua',
  mathematica = 'mathematica',
  nginx = 'nginx',
  pascal = 'pascal',
  perl = 'perl',
  powershell = 'powershell',
  properties = 'properties',
  protobuf = 'protobuf',
  puppet = 'puppet',
  r = 'r',
  ruby = 'ruby',
  sas = 'sas',
  scheme = 'scheme',
  shell = 'shell',
  stylus = 'stylus',
  swift = 'swift',
  tcl = 'tcl',
  toml = 'toml',
  vb = 'vb',
  vbscript = 'vbscript',
  velocity = 'velocity',
  verilog = 'verilog',
  vhdl = 'vhdl',
  yaml = 'yaml',
}
