import { useEffect } from 'react';
import { USERSNAP_PROJECT_API_KEY } from '@/common/config';
import { useUserStore } from '@/store';

type ValueKey = 'assignee' | 'custom' | 'labels' | 'visitor';

interface OpenEvent {
  apiKey: string;
  api: {
    setValue: (key: ValueKey, value: any) => void;
  };
}

const useUsersnap = () => {
  const { userInfo } = useUserStore();

  useEffect(() => {
    if (!window.usersnap) {
      return;
    }

    const handleOpenWidget = (event: OpenEvent) => {
      if (event.apiKey !== USERSNAP_PROJECT_API_KEY) return;
      if (userInfo.email) {
        event.api.setValue('visitor', userInfo.email);
        event.api.setValue('custom', {
          googleEmail: userInfo.email,
        });
      }
    };

    window.usersnap.on('open', handleOpenWidget);

    return () => window.usersnap.off('open', handleOpenWidget);
  }, [window.usersnap, userInfo.email]);

  const openFeedback = () => {
    if (!window.usersnap) {
      return;
    }
    window.usersnap.logEvent('openFeedBack');
    // window.usersnap.show(USERSNAP_PROJECT_API_KEY).then((widgetApi: any) => {
    //   widgetApi.open();
    // });
  };

  return { openFeedback };
};

export default useUsersnap;
