import { create } from 'zustand';
import { isFunction } from 'lodash-es';

import type { AdvanceConfig, MessageItem } from '@/type';
import { MessagePayload } from './chat';
import { WRITE_TEMPLATE_ID } from '@/common/config';

export interface Heading {
  id: string;
  level: number;
  text: string | undefined;
}

export interface AutoAskConfig {
  message?: string;
  writingDocPromptTemplateId?: WRITE_TEMPLATE_ID;
  model?: string;
  searchSwitch?: boolean;
  advanceConfig?: AdvanceConfig;
}

interface WriteState {
  loading: boolean | null;
  setLoading: (payload: boolean | null) => void;
  showAskAI: boolean;
  setShowAskAI: (payload: boolean) => void;
  askAIMessage: MessageItem;
  setAskAIMessage: (payload: MessagePayload) => void;
  answers: MessageItem;
  setAnswers: (payload: MessagePayload) => void;
  docId: string;
  setDocId: (payload: string) => void;
  docTitle: string;
  setDocTitle: (payload: string) => void;
  createdAt: number;
  setCreatedAt: (payload: number) => void;
  chatText: string;
  setChatText: (payload: string) => void;
  docInitContent: string;
  setDocInitContent: (payload: string) => void;
  insertStartPos: number | null;
  setInsertStartPos: (payload: number | null) => void;
  tipsFlag: string;
  setTipsFlag: (payload: string) => void;
  addTipFlag: boolean;
  setAddTipFlag: (payload: boolean) => void;
  headings: Heading[];
  setHeadings: (payload: Heading[]) => void;
  channelId: string;
  setChannelId: (payload: string) => void;
  resetWrite: () => void;
  closeWrite: () => void;
  autoAskSend: boolean;
  setAutoAskSend: (payload: boolean) => void;
  autoAskConfig: AutoAskConfig;
  setAutoAskConfig: (payload: AutoAskConfig) => void;
}

const useWriteStore = create<WriteState>((set) => ({
  loading: null,
  setLoading: (payload: boolean | null) => set(() => ({ loading: payload })),
  showAskAI: false,
  setShowAskAI: (payload: boolean) => set(() => ({ showAskAI: payload })),
  askAIMessage: {},
  setAskAIMessage: (payload: MessagePayload) =>
    set(({ askAIMessage }) => ({
      askAIMessage: isFunction(payload) ? payload(askAIMessage) : payload,
    })),
  answers: {},
  setAnswers: (payload: MessagePayload) =>
    set(({ answers }) => ({
      answers: isFunction(payload) ? payload(answers) : payload,
    })),
  docId: '',
  setDocId: (payload: string) => set(() => ({ docId: payload })),
  docTitle: '',
  setDocTitle: (payload: string) => set(() => ({ docTitle: payload })),
  createdAt: 0,
  setCreatedAt: (payload: number) => set(() => ({ createdAt: payload })),
  chatText: '',
  setChatText: (payload: string) => set(() => ({ chatText: payload })),
  docInitContent: '',
  setDocInitContent: (payload: string) => set(() => ({ docInitContent: payload })),
  insertStartPos: null,
  setInsertStartPos: (payload: number | null) => set(() => ({ insertStartPos: payload })),
  tipsFlag: '',
  setTipsFlag: (payload: string) => set(() => ({ tipsFlag: payload })),
  addTipFlag: false,
  setAddTipFlag: (payload: boolean) => set(() => ({ addTipFlag: payload })),
  headings: [],
  setHeadings: (payload: Heading[]) => set(() => ({ headings: payload })),
  channelId: '',
  setChannelId: (payload: string) => set(() => ({ channelId: payload })),
  resetWrite: () =>
    set(() => {
      return {
        loading: null,
        showAskAI: false,
        askAIMessage: {},
        chatText: '',
        docId: '',
        docTitle: '',
        docInitContent: '',
        headings: [],
        channelId: '',
        autoAskSend: false,
        autoAskConfig: {},
      };
    }),
  closeWrite: () =>
    set(() => {
      return {
        loading: null,
        showAskAI: false,
        askAIMessage: {},
        chatText: '',
        headings: [],
      };
    }),
  autoAskSend: false,
  setAutoAskSend: (payload: boolean) => set(() => ({ autoAskSend: payload })),
  autoAskConfig: {},
  setAutoAskConfig: (payload: AutoAskConfig) => set(() => ({ autoAskConfig: payload })),
}));

export default useWriteStore;
