import { UserInfo } from '@/store';
import BrowserLogger from 'alife-logger';

const { VITE_APP_ALIFE_PID, VITE_APP_VERSION } = import.meta.env;

let __bl: any;

/**
 * 初始化监控
 */
export function initMonitor() {
  __bl = BrowserLogger.singleton({
    pid: VITE_APP_ALIFE_PID,
    release: VITE_APP_VERSION,
    appType: 'web',
    imgUrl: 'https://arms-retcode-sg.aliyuncs.com/r.png?',

    //上报页面静态资源。
    sendResource: true,

    //进行前后端链路追踪
    enableLinkTrace: true,

    //是否允许自动发送性能日志。
    autoSendPerf: true,

    //是否为了便于排查错误而记录报错的用户行为。
    behavior: true,

    //采集首屏FMP（First Meaningful Paint，首次有效渲染）数据
    useFmp: true,

    // 采样率, 对性能日志和成功API日志按照1/sample的比例采样
    // sample: 1,

    //PV日志采样配置，值为1~100的整数。对PV日志按照1/pvSample的比例采样。
    // pvSample: 1,

    //监听页面的hashchange事件并重新上报PV，适用于单页面应用场景。
    enableSPA: false,

    ignore: {
      //只上报站内api的日志 （url以/api/开头）
      ignoreApis: function (url: string) {
        if (
          url?.indexOf('api.01ww.org') > -1 ||
          url?.indexOf('api.popai.pro') > -1 ||
          url?.indexOf('lingyiwanwu.net') > -1
        ) {
          return false;
        }
        return true;
      },

      //忽略ResizeObserver 错误和TouchEvent错误
      ignoreErrors: [
        /ResizeObserver loop completed with undelivered notifications/,
        /ResizeObserver loop limit exceeded/,
        /Can't find variable: TouchEvent/,
        /TouchEvent is not defined/,
        /\[Cloudflare Turnstile\] Error\: 3000/,
      ],

      //忽略站外的资源错误
      ignoreResErrors: [/^(?!.*popai\.pro).+$/, /^(?!.*01ww\.org).+$/],
    },
  });
}

/**
 * 更新监控配置
 * @param userinfo 用户信息
 */
export function updateMonitorConfig({ userinfo }: { userinfo: UserInfo }) {
  __bl?.setConfig({
    uid: userinfo.uid,
  });
}

/**
 * 上报自定义事件
 * @param error 错误信息
 */
export function monitorReportError(
  error: Error,
  pos: { filename?: string; lineno?: number; colno?: number } = {},
) {
  __bl?.error(error, pos);
}

function getPageUrl(url: string) {
  if (url?.indexOf('/chat/') > -1) {
    return '/chat/**';
  } else if (url?.indexOf('/ai-writing/') > -1) {
    return '/ai-writing/**';
  }
  return url;
}

//解决parseHash配置有时会失效的问题
export function setPage(url: string) {
  __bl?.setPage(getPageUrl(url));
}
