import { useMemo } from 'react';
import { Avatar, AvatarProps } from 'antd';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';
import CustomIcon from '@/components/CustomIcon';

import './index.less';

interface UserAvatarProps extends AvatarProps {
  type?: string;
  size?: number;
  bordered?: boolean;
  info?: {
    name?: string;
    style?: React.CSSProperties;
  };
}

const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const {
    type = 'upgradeUser',
    info,
    className = '',
    bordered = false,
    size = 48,
    src,
    ...otherProps
  } = props;
  const isInfo = useMemo(() => info && !isEmpty(info), [info]);

  return (
    <Avatar
      className={classNames({
        'avatar-container': true,
        'avatar-bordered': bordered,
        'no-border': !bordered,
        [className]: !!className,
      })}
      style={isInfo ? info?.style : {}}
      shape="square"
      size={size}
      src={src}
      icon={
        isInfo ? (
          <>{info?.name && <CustomIcon type={info?.name as string} />}</>
        ) : (
          <CustomIcon className="user-icon" type={type} />
        )
      }
      {...otherProps}
    />
  );
};

export default UserAvatar;
