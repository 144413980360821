import { useState } from 'react';
import { Modal, Collapse, Switch } from 'antd';
import { useGASendEvent } from '@/hooks';
import { useTranslation } from 'react-i18next';
import CustomIcon from '@/components/CustomIcon';
import type { CollapseProps } from 'antd';
import './index.less';
import classNames from 'classnames';
interface CookiesSettingModalProps {
  setOpen: (value: boolean) => void;
  onAccept: () => void;
}

const CookiesSettingTitle = () => (
  <div className="cookies-setting-title">
    <CustomIcon type="logoText" />
    <span className="cookies-setting-title-name">PopAi</span>
  </div>
);

const CookiesSettingModal: React.FC<CookiesSettingModalProps> = (props) => {
  const { sendEvent } = useGASendEvent();
  const { t } = useTranslation();
  const { setOpen } = props;

  const [consentPreferences, setConsentPreferences] = useState([true, true, true, true]);

  const handleSwitchChange = (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement>,
    order: number,
  ) => {
    event.stopPropagation();
    setConsentPreferences((prev) => {
      const newData = [...prev];
      newData[order] = checked;
      return newData;
    });
  };

  const handleConfirm = () => {
    sendEvent('cookies_popup_confirmmychoice_click');
    props.onAccept();
  };

  const handleAccept = () => {
    sendEvent('cookies_popup_acceptall_click');
    props.onAccept();
  };

  const genExtra = (order: number) => (
    <Switch
      checked={consentPreferences[order]}
      onChange={(checked, e) => handleSwitchChange(checked, e, order)}
    />
  );

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: `${t('layout.cookies.strictlyNecessaryCookies')}`,
      children: <div>{t('layout.cookies.strictlyNecessaryCookiesDetail')}</div>,
      extra: <span className="collapse-item-extra">{t('layout.cookies.alwaysActive')}</span>,
    },
    {
      key: '2',
      label: `${t('layout.cookies.performanceCookies')}`,
      children: <div>{t('layout.cookies.performanceCookiesDetail')}</div>,
      extra: genExtra(1),
    },
    {
      key: '3',
      label: `${t('layout.cookies.functionalCookies')}`,
      children: <div>{t('layout.cookies.functionalCookiesDetail')}</div>,
      extra: genExtra(2),
    },
    {
      key: '4',
      label: `${t('layout.cookies.shareOfPersonalData')}`,
      children: <div>{t('layout.cookies.shareOfPersonalDataDetail')}</div>,
      extra: genExtra(3),
    },
  ];

  const renderFooter = () => {
    return (
      <div className="cookies-setting-footer">
        <div className="confirm-choices" onClick={handleConfirm}>
          {t('layout.cookies.confirmMyChoices')}
        </div>
        <div className="accept-all" onClick={handleAccept}>
          {t('layout.cookies.acceptAll')}
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={true}
      title={CookiesSettingTitle()}
      className="cookies-setting-modal"
      width={480}
      footer={null}
      maskClosable={false}
      onCancel={() => {
        sendEvent('cookies_popup_close_click');
        setOpen(false);
      }}
    >
      <div className="cookies-setting-content">
        <div className="preference-title">{t('layout.cookies.privacyPreferenceCenter')}</div>
        <div className="detailed-description">
          {t('layout.cookies.privacyPreferenceCenterDetail')}
          <br />
          <a href="https://www.popai.pro/terms/privacy" target="_block">
            {t('layout.cookies.privacyPolicy')}
          </a>
        </div>
        <div className="preference-title">{t('layout.cookies.manageConsentPreferences')}</div>
        <Collapse
          className="cookies-setting-collapse"
          items={items}
          bordered={false}
          expandIcon={({ isActive }) => (
            <CustomIcon
              className={classNames({
                'right-arrow-collapse-icon': true,
                'right-arrow-collapse-icon-rotate': isActive,
              })}
              type="rightArrowCollapse"
            />
          )}
        />
      </div>
      {renderFooter()}
    </Modal>
  );
};

export default CookiesSettingModal;
