import { Space } from 'antd';
import classNames from 'classnames';
import CustomIcon from '@/components/CustomIcon';

import './index.less';

interface ToastContentProps {
  icon?: string;
  content?: React.ReactNode;
  className?: string;
}

const ToastContent: React.FC<ToastContentProps> = (props) => {
  const { icon, content = '', className = '' } = props;

  return (
    <div
      className={classNames({
        'message-toast-wrapper': true,
        [className]: !!className,
      })}
    >
      <Space size={4}>
        {!!icon && <CustomIcon className="message-toast-icon" type={icon} />}
        {!!content && <span>{content}</span>}
      </Space>
    </div>
  );
};

export default ToastContent;
