import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { STORE_KEY } from '@/common/config';

interface CommonState {
  // homeSendControlModalSelect: boolean;
  // setHomeSendControlModalSelect: (payload: boolean) => void;

  // 手动打开首页输入框内的 model select
  homeModelSelectOpen: boolean;
  setHomeModelSelectOpen: (payload: boolean) => void;
}

const useHomeSendControlStore = create<
  CommonState,
  [['zustand/devtools', never], ['zustand/persist', Partial<CommonState>]]
>(
  devtools(
    persist(
      (set) => ({
        // // 首页输入框-是否默认展开过
        // homeSendControlModalSelect: false,
        // setHomeSendControlModalSelect: (payload: boolean) =>
        //   set(() => ({ homeSendControlModalSelect: payload })),
        homeModelSelectOpen: false,
        setHomeModelSelectOpen: (payload: boolean) => set(() => ({ homeModelSelectOpen: payload })),
      }),
      {
        name: STORE_KEY.HomeSendControl,
        storage: createJSONStorage(() => localStorage),
        partialize: ({}: //  homeSendControlModalSelect
        CommonState) => ({
          // homeSendControlModalSelect,
        }),
      },
    ),
  ),
);

export default useHomeSendControlStore;
