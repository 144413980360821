/*
 * @Author: wubo
 * @Date: 2023-07-07 11:07:18
 * @LastEditTime: 2024-01-04 14:26:12
 * @LastEditors: zuoyuxing001
 * @Description:
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDA, useGASendEvent } from '@/hooks';
import './index.less';

interface NotLoginProps {
  openLoginModal?: () => void;
}

const NotLogin: React.FC<NotLoginProps> = (props) => {
  const { openLoginModal } = props;
  const { sendEvent } = useGASendEvent();
  const { t } = useTranslation();
  const { preparePropertyFor } = useDA();

  const handleLogin = () => {
    sendEvent('homepage_login_click');
    preparePropertyFor('RegistLoginModalExposure', 'forward_source', 'sidebar_login_click');
    openLoginModal?.();
  };

  return (
    <div className="notlogin-wrapper">
      <div className="notlogin-title">{t('common.Workspace')}</div>
      <div>
        <button className="login-btn" onClick={handleLogin}>
          {t('common.signUp')} /{t('common.logIn')}
        </button>
      </div>
    </div>
  );
};

export default NotLogin;
