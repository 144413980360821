import { create } from 'zustand';
import { UserPrompt } from '@/type';
import { addPrompt, delPrompt, editPrompt, getPrompts, searchPrompts } from '@/services/userPrompt';

interface UserPromptState {
  /**
   * track first load
   */
  initialized: boolean;
  /**
   * total user prompts count
   */
  total: number;
  /**
   * load result, search won't effect this value
   */
  items: UserPrompt[];
  /**
   * all items
   */
  load: ({ start, limit }: { start: number; limit: number }) => Promise<void>;
  search: ({
    start,
    limit,
    key,
  }: {
    start: number;
    limit: number;
    key: string;
  }) => Promise<UserPrompt[]>;
  add: ({ name, content }: { name: string; content: string }) => Promise<void>;
  delete: (promptId: string) => Promise<void>;
  edit: (promptId: string, { name, content }: { name: string; content: string }) => Promise<void>;
}

const useUserPromptStore = create<UserPromptState>((set, get) => ({
  initialized: false,
  total: 0,
  items: [],
  load: ({ start, limit }) =>
    getPrompts({ start, limit }).then((res) => {
      set(() => ({
        initialized: true,
        total: res.data.totalElements,
        items: res.data.content,
        cache: res.data.content,
      }));
    }),
  search: ({ start, limit, key }) =>
    searchPrompts({ start, limit, key }).then((res) => res.data.content),
  add: ({ name, content }) => {
    // add a fake one
    const fakeId = String(get().items.length);
    set((state) => ({
      total: (state?.total || 0) + 1,
      items: [{ promptId: fakeId, name, content, is_new: true, saved: false }, ...state.items],
    }));
    return addPrompt({ name, content })
      .then((res) => {
        set((state) => {
          // replace the fake one with reponse
          const items = [...state.items];
          const index = items.findIndex((item) => item.promptId === fakeId);
          items.splice(index, 1, res.data);
          return {
            items,
          };
        });
      })
      .catch(() => {
        set((state) => {
          // remove the fake one
          const items = [...state.items];
          const index = items.findIndex((item) => item.promptId === fakeId);
          items.splice(index, 1);
          return {
            items,
          };
        });
      });
  },
  delete: (promptId: string) =>
    delPrompt(promptId).then(() =>
      set((state) => ({
        total: state?.total - 1,
        items: state.items.filter((item) => item.promptId !== promptId),
      })),
    ),
  edit: async (promptId: string, { name, content }: { name: string; content: string }) => {
    const newItems = [...get().items];
    const index = newItems.findIndex((item) => item.promptId === promptId);
    newItems.splice(index, 1, {
      ...newItems[index],
      name: name,
      content: content,
    });
    set({ items: newItems });
    const res = await editPrompt(promptId, { name, content });
    return set((state) => {
      const newItems_1 = [...state.items];
      const index_1 = newItems_1.findIndex((item_1) => item_1.promptId === promptId);
      newItems_1.splice(index_1, 1, {
        ...state.items[index_1],
        name: res.data.name,
        content: res.data.content,
      });
      return {
        items: newItems_1,
      };
    });
  },
}));

export default useUserPromptStore;
