import { useState, useMemo } from 'react';
import classNames from 'classnames';
import CustomIcon from '@/components/CustomIcon';

import './index.less';

interface CheckboxProps {
  checked?: boolean;
  className?: string;
  size?: number
  shape?: 'circle' | 'square';
  onChange?: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { checked, className = '', size = 20, shape = 'circle', onChange } = props;

  const [defaultChecked, setDefaultChecked] = useState(false);

  const value = useMemo(() => {
    if (checked === undefined) {
      return defaultChecked;
    } else {
      return checked;
    }
  }, [checked, defaultChecked]);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setDefaultChecked(checked);
    onChange?.(checked);
  }

  return (
    <label
      className={classNames({
        checkbox: true,
        'checkbox-checked': value,
        [className]: !!className,
      })}
      style={{
        width: size,
        height: size,
        borderRadius: shape === 'circle' ? '50%' : '2px',
      }}
    >
      {value && (
        <CustomIcon className="checked-icon" type="checkbox" />
      )}
      <input type="checkbox" checked={value} onChange={handleChange} />
    </label>
  );
}

export default Checkbox;
