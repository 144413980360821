import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { isNil, omitBy, debounce } from 'lodash-es';
import { getIpAndCountryUsingGET } from '@/services';
import qs from 'qs';
import { useCommonStore, useUserStore, useEventStore } from '@/store';
import { nanoid } from 'nanoid';
import type { ResponseType } from '@/type';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
interface EventTagMap {
  [key: string]: string | number | undefined;
}

interface IpAndCountryProp {
  ip: string;
  country: string;
}

const {
  VITE_APP_GA_ADS_ID_OLD,
  VITE_APP_GA_ADS_ID_NEW,
  // VITE_APP_ANALYSIS_BASE_URL,
  VITE_APP_META_PIXEL_ID1,
  VITE_APP_META_PIXEL_ID2,
  VITE_APP_META_PIXEL_ID3,
  VITE_APP_BING_UET_TAG_ID,
  VITE_APP_TWITTER_PIXEL_ID,
  VITE_APP_TWITTER_PIXEL_ID1,
  VITE_APP_LINKEDIN_ID,
  VITE_APP_NAME,
  VITE_APP_ANALYSIS_ID,
  VITE_APP_AID,
  VITE_APP_PRODUCT_NAME,
  VITE_APP_APP_NAME,
} = import.meta.env;

const OLD_GOOGLE_CONVERSION_TAG: EventTagMap = {
  Sendmessage: 'TXWrCOj_vccYEPbj-v4p',
  Clickgooglelogin: '4M85CKGAvscYEPbj-v4p',
  Goolgelogin: 'xaR4CO3-vccYEPbj-v4p',
};

const NEW_GOOGLE_CONVERSION_TAG: EventTagMap = {
  // add 0802
  Inputmessage: 'rn-nCM-puMwYEKPaz4Qq',
  Clickgooglelogin: 'YMBVCJ-CrMsYEKPaz4Qq',
  Goolgelogin: 'bG-yCJmCrMsYEKPaz4Qq',
  // add 0803
  Clickpay: 'Ie8DCL2puMwYEKPaz4Qq',
  Clicksubscribe: 'mhbBCMCpuMwYEKPaz4Qq',
  Startdocument: 'n93ICMapuMwYEKPaz4Qq',
  Clickprompttemplate: 'cwxhCMmpuMwYEKPaz4Qq',
  Clickshare: 'W2lyCMypuMwYEKPaz4Qq',
  Sendmessage: 'HMLvCJyCrMsYEKPaz4Qq',
  Clicknewchat: '3DpGCLrqqtMYEKPaz4Qq',
  // add 0925
  SubscribeMonthSuccess: 'S-FdCLC9oeUYEKPaz4Qq',
  SubscribeYearSuccess: 'PaQ9CJS_oeUYEKPaz4Qq',
  // add 1129
  Alltriggerpay: 'SgV8CKbB0voYEKPaz4Qq',
  // add 0220
  NewProMonth_pay_success_status: '6_c5CLX7jZQZEKPaz4Qq',
  'NewPro+Month_pay_success_status': '8whiCLj7jZQZEKPaz4Qq',
  NewUnlimitedMonth_pay_success_status: 'bzboCLv7jZQZEKPaz4Qq',
  NewProYear_pay_success_status: 'r3aDCL77jZQZEKPaz4Qq',
  'NewPro+Year_pay_success_status': 'hZjWCO77jZQZEKPaz4Qq',
  NewUnlimitedYear_pay_success_status: 'cgWLCPH7jZQZEKPaz4Qq',
  NewPay_success_status: 'I1F8CPT7jZQZEKPaz4Qq',
  new_login_success: '_HuLCKiR95gZEKPaz4Qq',
  // add 0911
  NewProYear_freetrial_unpaid: 'D1gJCNfy4p4ZEKPaz4Qq',
};

const REDDIT_EVENT_CONVERSION_TAG: EventTagMap = {
  SubscribeMonthSuccess: 'Purchase',
  SubscribeYearSuccess: 'Purchase',
  new_login_success: 'SignUp',
  Clickpay: 'Lead',
};

const META_EVENT = [
  'Clickchat',
  'Clicknewchat',
  'Inputmessage',
  'Newmessage',
  'Sendmessage',
  'Clickshare',
  'Startdocument',
  'Documentselect',
  'Uploadedurl',
  'Alltriggerpay',
  'Clicksubscribe',
  'Triggerlogin',
  'Clickgooglelogin',
  'Goolgelogin',
  'Clickprompttemplate',
  'Sendtemplateprompt',
  'SubscribeMonthSuccess',
  'SubscribeYearSuccess',
];

const BING_EVENT = [
  'Clicknewchat',
  'Sendmessage',
  'Clicksubscribe',
  'SubscribeMonthSuccess',
  'SubscribeYearSuccess',
  'Goolgelogin',
];

const TWITTER_CONVERSION_TAG: EventTagMap = {
  Clicknewchat: 'ogyik',
  Clicksubscribe: 'ogyjs',
  Sendmessage: 'ogyll',
  SubscribeMonthSuccess: 'ogylp',
  SubscribeYearSuccess: 'ogylq',
  Goolgelogin: 'ogylt',
};

const TWITTER_CONVERSION_TAG1: EventTagMap = {
  Clicknewchat: 'oi4q5',
  Clicksubscribe: 'oi4qc',
  Sendmessage: 'oi4q9',
  SubscribeMonthSuccess: 'oi4qe',
  SubscribeYearSuccess: 'oi4qe',
  Selectphoto: 'oi4q7',
  new_login_success: 'okrrz',
};

const LINKEDIN_CONVERSION_TAG: EventTagMap = {
  Clicknewchat: 14583858,
  Sendmessage: 14583866,
  Clicksubscribe: 14583874,
  SubscribeMonthSuccess: 14583882,
  SubscribeYearSuccess: 14583890,
  Goolgelogin: 14583898,
};

// 一期共五个，特别注意进站埋点
const MIQ_CONVERSION_TAG: EventTagMap = {
  homepage_login_click:
    'https://ad.doubleclick.net/ddm/activity/src=14384411;type=stand0;cat=popai00;u1=[page url];u2=[referrer];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?',
  emaillogin_click:
    'https://ad.doubleclick.net/ddm/activity/src=14384411;type=stand0;cat=popai000;u1=[page url];u2=[referrer];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?',
  emaillogin_verifycode_click:
    'https://ad.doubleclick.net/ddm/activity/src=14384411;type=stand0;cat=popai001;u1=[page url];u2=[referrer];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?',
  Clickgooglelogin:
    'https://ad.doubleclick.net/ddm/activity/src=14384411;type=stand0;cat=popai002;u1=[page url];u2=[referrer];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?',
  Clickpay:
    'https://ad.doubleclick.net/ddm/activity/src=14384411;type=stand0;cat=popai003;u1=[page url];u2=[referrer];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?',
  Clicksubscribe:
    'https://ad.doubleclick.net/ddm/activity/src=14384411;type=stand0;cat=popai004;u1=[page url];u2=[referrer];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?',
};

const useGASendEvent = () => {
  const searchParams = qs.parse(location.search.replace('?', ''));
  const utm_source = searchParams?.['utm_source'];
  const utm_medium = searchParams?.['utm_medium'];
  const utm_campaign = searchParams?.['utm_campaign'];
  const utm_content = searchParams?.['utm_content'];
  const utm_term = searchParams?.['utm_term'];
  const utmInfo = { utm_source, utm_medium, utm_campaign, utm_content, utm_term };

  const handleGoogleConversion = (name: string) => {
    if (VITE_APP_GA_ADS_ID_OLD) {
      const oldTag = OLD_GOOGLE_CONVERSION_TAG[name];
      oldTag &&
        ReactGA.gtag('event', 'conversion', { send_to: `${VITE_APP_GA_ADS_ID_OLD}/${oldTag}` });
    }
    if (VITE_APP_GA_ADS_ID_NEW) {
      const newTag = NEW_GOOGLE_CONVERSION_TAG[name];
      newTag &&
        ReactGA.gtag('event', 'conversion', { send_to: `${VITE_APP_GA_ADS_ID_NEW}/${newTag}` });
    }
  };

  const handleGoogleRedditConversion = (name: string) => {
    const eventName = REDDIT_EVENT_CONVERSION_TAG[name];
    if (eventName) {
      window?.rdt('track', eventName);
    }
  };

  const handleMetaConversion = (name: string, eventResult: any) => {
    if (
      VITE_APP_META_PIXEL_ID1 &&
      VITE_APP_META_PIXEL_ID2 &&
      VITE_APP_META_PIXEL_ID3 &&
      META_EVENT.includes(name) &&
      window?.fbq
    ) {
      window.fbq('trackCustom', name, eventResult);
    }
  };

  const handleBingConversion = (name: string) => {
    if (VITE_APP_BING_UET_TAG_ID && BING_EVENT.includes(name)) {
      window.uetq = window.uetq || [];
      // event_action
      window.uetq.push('event', name, {});
    }
  };

  const handleTwitterConversion = (name: string) => {
    if (window?.twq) {
      if (VITE_APP_TWITTER_PIXEL_ID) {
        const conversionId = TWITTER_CONVERSION_TAG[name];
        conversionId && window.twq('event', `tw-${VITE_APP_TWITTER_PIXEL_ID}-${conversionId}`, {});
      }

      if (VITE_APP_TWITTER_PIXEL_ID1) {
        const conversionId = TWITTER_CONVERSION_TAG1[name];
        conversionId && window.twq('event', `tw-${VITE_APP_TWITTER_PIXEL_ID1}-${conversionId}`, {});
      }
    }
  };

  const handleLinkedInConversion = (name: string) => {
    if (VITE_APP_LINKEDIN_ID && window?.lintrk) {
      const conversionId = LINKEDIN_CONVERSION_TAG[name];
      conversionId && window.lintrk('track', { conversion_id: conversionId });
    }
  };

  const handleMIQConversion = (name: string) => {
    const src = MIQ_CONVERSION_TAG[name] as string;
    if (src) {
      let img = document.createElement('img');
      img.src = src;
      img.width = 1;
      img.height = 1;
      img.style.display = 'none';
      document.body.appendChild(img);
    }
  };

  const sendEvent = async (name: string, params?: any) => {
    const { userInfo } = useUserStore.getState();
    const { experiment } = useCommonStore.getState();
    const {
      sessionId,
      lastActivityTime,
      ip,
      country,

      setSessionId,
      setLastActivityTime,
      setIp,
      setCountry,
    } = useEventStore.getState();

    let localSessionId: string = sessionId;
    const currentTime = Date.now();
    setSessionId((prev) => {
      const newSessionId = nanoid();
      if (!prev) {
        localSessionId = newSessionId;
        return newSessionId;
      } else {
        const isExpired = !lastActivityTime || currentTime - lastActivityTime > 30 * 60 * 1000;
        if (isExpired) {
          localSessionId = newSessionId;
          return newSessionId;
        } else {
          localSessionId = prev;
          return prev;
        }
      }
    });
    setLastActivityTime(currentTime);
    const eventParams = Object.assign({}, params, utmInfo, {
      referrer: document.referrer,
      eid: experiment?.result ? experiment?.eid : null,
      uid: userInfo?.uid,
    });
    const eventResult = omitBy(eventParams, isNil);
    ReactGA.event(name, eventResult);

    handleGoogleConversion(name);
    handleGoogleRedditConversion(name);
    handleMetaConversion(name, eventResult);
    handleBingConversion(name);
    handleTwitterConversion(name);
    handleLinkedInConversion(name);
    handleMIQConversion(name);

    const currentUTCTime = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');

    // 接入大数据平台埋点 @晓东
    if (VITE_APP_ANALYSIS_ID) {
      let localIp: string = ip;
      let localCountry: string = country;
      if (!localIp || !localCountry) {
        try {
          const res = await getIpAndCountryUsingGET<ResponseType>();
          const data = res?.data as IpAndCountryProp;
          if (data) {
            setIp((prev) => {
              if (!prev) {
                localIp = data?.ip;
                return data?.ip;
              } else {
                return prev;
              }
            });
            setCountry((prev) => {
              if (!prev) {
                localCountry = data?.country;
                return data?.country;
              } else {
                return prev;
              }
            });
          }
        } catch (error) {
          console.error('error:', error);
        }
      }
      try {
        window.xaf.record({
          aid: VITE_APP_AID,
          recordId: VITE_APP_ANALYSIS_ID,
          recordData: {
            appid: VITE_APP_ANALYSIS_ID,
            utc_timestamp: currentUTCTime,
            product_name: VITE_APP_PRODUCT_NAME,
            app_name: VITE_APP_APP_NAME,
            device_info: {
              web_id: window?.td?.getDistinctId(),
              baidu_id: localStorage.getItem('__bid_n'),
            },
            user_info: {
              uid: userInfo?.uid,
            },
            page_info: {
              url: location.href,
            },
            event_name: name,
            event_params: eventResult,
            ext: {
              country: localCountry,
              ip: localIp,
              session_id: localSessionId,
            },
          },
          timeout: 10000,
          complete: function () {},
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (VITE_APP_NAME !== 'production') {
      console.log('%c 🍫 event', 'color:#7f2b82', name, JSON.stringify(eventResult));
    }
  };

  const sendEventDelay = useCallback(debounce(sendEvent, 300), []);

  return { sendEvent, sendEventDelay };
};

export default useGASendEvent;
