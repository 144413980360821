import { EditorState, Plugin } from '@tiptap/pm/state';
import { Decoration, DecorationSet, EditorView } from '@tiptap/pm/view';
import { mergeAttributes, Node, nodeInputRule } from '@tiptap/core';
import { getPresignedUsingGET } from '@/services';
import { calculateHash, splitFile } from '@/common/utils';
import axios from '@/common/request';

import type { ResponseType, PresignedData } from '@/type';
import { Schema } from '@tiptap/pm/model';

const inputRegex = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;
let imagePreview: string | null = null;

const { VITE_APP_UPLOAD_IMAGE_BUCKET } = import.meta.env;

export const ImageUpload = Node.create({
  name: 'imageUpload',

  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    };
  },

  inline: false,

  group: 'block',

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]:not([src^="data:"])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  // @ts-expect-error ignore
  addCommands() {
    return {
      addImage: () => () => {
        const fileHolder = document.createElement('input');
        fileHolder.setAttribute('type', 'file');
        fileHolder.setAttribute('accept', 'image/png, image/jpeg');
        fileHolder.setAttribute('style', 'visibility:hidden');
        document.body.appendChild(fileHolder);

        const view = this.editor.view;
        const schema = this.editor.schema;

        fileHolder.addEventListener('change', (e) => {
          const target = e.target as HTMLInputElement;
          const files = target.files;

          if (view.state.selection.$from.parent.inlineContent && files && files.length > 0) {
            const fileType = files[0]?.type;
            const isJpgOrPng = fileType === 'image/jpeg' || fileType === 'image/png';
            if (!isJpgOrPng) {
              alert('Unsupported file type. Supports PNG, JPG and JPEG.');
              return;
            }
            startImageUpload(view, files[0], schema);
            view.focus();
          }
        });
        fileHolder.click();
      },
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , alt, src, title] = match;

          return { src, alt, title };
        },
      }),
    ];
  },
  addProseMirrorPlugins() {
    return [placeholderPlugin];
  },
});

//Plugin for placeholder
const placeholderPlugin = new Plugin({
  state: {
    init() {
      return DecorationSet.empty;
    },
    apply(tr, set) {
      // Adjust decoration positions to changes made by the transaction
      set = set.map(tr.mapping, tr.doc);
      // @ts-expect-error ignore
      const action = tr.getMeta(this);
      if (action && action.add) {
        const widget = document.createElement('div');
        const img = document.createElement('img');
        widget.className = 'image-uploading';
        img.src = imagePreview as string;
        widget.appendChild(img);
        const deco = Decoration.widget(action.add.pos, widget, { id: action.add.id });
        set = set.add(tr.doc, [deco]);
      } else if (action && action.remove) {
        set = set.remove(set.find(undefined, undefined, (spec) => spec.id == action.remove.id));
      }
      return set;
    },
  },
  props: {
    decorations(state) {
      return this.getState(state);
    },
    handlePaste(view, event) {
      const items = event.clipboardData?.items;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            if (file) {
              startImageUpload(view, file, view.state.schema);
              return true;
            }
          }
        }
      }
      return false;
    },
  },
});

//Find the placeholder in editor
function findPlaceholder(state: EditorState, id: string | number) {
  const decos: DecorationSet | undefined = placeholderPlugin.getState(state);
  if (!decos) return null;
  const found = decos.find(undefined, undefined, (spec) => spec.id == id);
  return found.length ? found[0].from : null;
}

async function startImageUpload(view: EditorView, file: File, schema: Schema) {
  imagePreview = URL.createObjectURL(file);
  const img = new Image();
  img.src = imagePreview;

  img.onload = async () => {
    const width = img.width;
    const height = img.height;
    const chunkList = splitFile(file);
    const md5 = await calculateHash(chunkList);
    if (md5) {
      let presignedPostUrl: PresignedData;
      const id = Date.now().toString();
      const tr = view.state.tr;
      if (!tr.selection.empty) {
        tr.deleteSelection();
      }
      tr.setMeta(placeholderPlugin, { add: { id, pos: tr.selection.from } });
      view.dispatch(tr);
      view.dom.blur();

      try {
        const res = await getPresignedUsingGET<ResponseType<PresignedData>>({
          md5: md5 as string,
          bucket: VITE_APP_UPLOAD_IMAGE_BUCKET,
        });
        presignedPostUrl = res.data;
        // 上传文件到s3
        if (presignedPostUrl) {
          const uploadS3FormData = new FormData();
          Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
            uploadS3FormData.append(k, v);
          });
          uploadS3FormData.append('file', file);
          await axios.post(presignedPostUrl.url, uploadS3FormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            noAuthHeader: true,
            noAlertError: true,
          });
          const imageUrl = `${presignedPostUrl.url}${presignedPostUrl.fields.key}`;

          const pos = findPlaceholder(view.state, id);
          if (pos == null) {
            return;
          }

          const container = document.querySelector('.write-content');
          const maxWidth = container ? container.clientWidth - 160 : 800;
          const scaleFactor = maxWidth / width;
          const scaledWidth = width * scaleFactor;
          const scaledHeight = height * scaleFactor;

          view.dispatch(
            view.state.tr
              .replaceWith(
                pos - 1,
                pos - 1,
                schema.nodes.imageBlock.create({
                  src: imageUrl,
                  width: Math.round(scaledWidth),
                  height: Math.round(scaledHeight),
                }),
              )
              .setMeta(placeholderPlugin, { remove: { id } }),
          );
        }
      } catch (err: any) {
        view.dispatch(tr.setMeta(placeholderPlugin, { remove: { id } }));
      }
    }
  };
}
