import { useEffect, useState } from 'react';
import CustomIcon from '@/components/CustomIcon';
import CookiesSettingModal from '../CookiesSettingModal';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { useCommonStore } from '@/store';
import { getIpAndCountryUsingGET } from '@/services';
import { useGASendEvent } from '@/hooks';
import { ResponseType } from '@/type';
import './index.less';
interface CookiesSettingBannerProps {}

const CookiesSettingBanner: React.FC<CookiesSettingBannerProps> = () => {
  const { t } = useTranslation();
  const { sendEvent } = useGASendEvent();
  const { fillInCookieSettingBanner, setFillInCookieSettingBanner } = useCommonStore();

  const [cookiesSettingBannerOpen, setCookiesSettingBannerOpen] = useState(false); // 是否展示页面顶部cookies-setting-banner
  const [cookiesSettingModalOpen, setCookiesSettingModalOpen] = useState(false); // cookies-setting modal

  const handleRejectAll = () => {
    sendEvent('cookies_banner_rejectall_click');
    handleCloseBanner();
  };

  const handleAcceptAll = () => {
    sendEvent('cookies_banner_selectall_click');
    handleCloseBanner();
  };

  const handleCloseCookiesSettingBanner = () => {
    sendEvent('cookies_banner_close_click');
    handleCloseBanner();
  };

  const handleCookiesSeetingModalCB = () => {
    setCookiesSettingModalOpen(false);
    handleCloseBanner();
  };

  const handleCloseBanner = () => {
    handleSendGoogleGA();
    setCookiesSettingBannerOpen(false);
    setFillInCookieSettingBanner(true);
  };

  const handleSendGoogleGA = () => {
    ReactGA.gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  };

  const initCookiesSettingBanner = async () => {
    // 如果填过不请求，直接不展示
    if (fillInCookieSettingBanner) return;
    const res = await getIpAndCountryUsingGET<ResponseType<any>>();
    if (res && res?.data) {
      const { isEuMember = false } = res.data;
      if (isEuMember) {
        // 是否是欧盟地区
        setCookiesSettingBannerOpen(true);
      }
    }
  };

  useEffect(() => {
    initCookiesSettingBanner();
  }, []);

  return (
    <>
      {cookiesSettingBannerOpen ? (
        <div className="cookies-setting">
          <div></div>
          <div className="cookies-setting-center">
            <div className="cookies-setting-left">
              <CustomIcon type="ExclamationMark" />
              <span className="cookies-setting-left-tip">
                {t('layout.cookies.bannerText')}{' '}
                <a href="https://www.popai.pro/terms/privacy" target="_block">
                  {t('layout.cookies.cookiePolicy')}
                </a>
              </span>
            </div>
            <div className="cookies-setting-right">
              <div
                className="cookies-setting-right-csbtn"
                onClick={() => {
                  sendEvent('cookies_banner_settings_click');
                  setCookiesSettingModalOpen(true);
                }}
              >
                {t('layout.cookies.cookiesSettings')}
              </div>
              <div className="cookies-setting-right-rjabtn" onClick={handleRejectAll}>
                {t('layout.cookies.rejectAll')}
              </div>
              <div className="cookies-setting-right-accbtn" onClick={handleAcceptAll}>
                {t('layout.cookies.acceptAll')}
              </div>
            </div>
          </div>
          <div className="cookies-setting-right-close">
            <CustomIcon type="close" onClick={handleCloseCookiesSettingBanner} />
          </div>
        </div>
      ) : null}
      {/* cookies setting modal */}
      {cookiesSettingModalOpen && (
        <CookiesSettingModal
          setOpen={setCookiesSettingModalOpen}
          onAccept={handleCookiesSeetingModalCB}
        />
      )}
    </>
  );
};

export default CookiesSettingBanner;
