import { useState, useCallback } from 'react';
import { useCommonStore } from '@/store';
import { getMessageUsage } from '@/services';

import { ResponseType, IUsage } from '@/type';

const useFetchUsagesInfo = () => {
  const { setUsageInfo } = useCommonStore();
  const [error, setError] = useState('');

  const updateUsageInfo = (data?: IUsage) => {
    setUsageInfo({
      gpt3: {
        used: data?.gpt3Usage || 0,
        total: data?.gpt3Quota || 0,
      },
      gpt4: {
        used: data?.gpt4Usage || 0,
        total: data?.gpt4Quota || 0,
      },
      aiCreation: {
        used: data?.aiCreationUsage || 0,
        total: data?.aiCreationQuota || 0,
      },
      docUpload: {
        used: data?.docUploadUsage || 0,
        total: data?.docUploadQuota || 0,
      },
      docUploadLimitHint: data?.docUploadLimitHint || '',
      doc: {
        used: data?.docUploadUsage || 0,
        total: data?.docUploadQuota || 0,
      },
      ai: {
        used: data?.aiCreationUsage || 0,
        total: data?.aiCreationQuota || 0,
      },
      image: {
        used: data?.imageExtractUsage || 0,
        total: data?.imageExtractQuota || 0,
      },
      writeLong: {
        used: data?.writeLongUsage || 0,
        total: data?.writeLongQuota || 0,
      },
      writePaperReport: {
        used: data?.writePaperReportUsage || 0,
        total: data?.writePaperReportQuota || 0,
      },
      writeExtract: {
        used: data?.writeExtractUsage || 0,
        total: data?.writeExtractQuota || 0,
      },
      guestChat: {
        used: data?.chatUsage || 0,
        total: data?.chatQuota || 0,
      },
      guestDoc: {
        used: data?.docUsage || 0,
        total: data?.docQuota || 0,
      },
      guestWrite: {
        used: data?.writeUsage || 0,
        total: data?.writeQuota || 0,
      },
    });
  };
  const fetchUsagesInfo = useCallback(async () => {
    try {
      const res = await getMessageUsage<ResponseType<IUsage>>();
      const data = res?.data;
      updateUsageInfo(data);
    } catch (err: any) {
      setError(err);
    }
  }, []);

  return { fetchUsagesInfo, updateUsageInfo, error };
};

export default useFetchUsagesInfo;
