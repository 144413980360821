import React, { ReactElement, useMemo, useState } from 'react';
import cls from 'classnames';
import dayjs from 'dayjs';
import { useUserStore, useCommonStore, GptUsageItem } from '@/store';
import { Divider } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useDA, useGASendEvent, useSundry } from '@/hooks';
import { AB_EXPERIMENTS, MEMBERSHIP_ID } from '@/common/config';
import UserAvatar from '@/components/UserAvatar';
import UserBadge from '@/layout/BasicLayout/components/UserBadge';
import UpgradeButton from '@/components/UpgradeButton';
import CustomIcon from '@/components/CustomIcon';

import './index.less';

const UserUsage: React.FC<{
  isWebMember: Boolean;
  freeTrial: number;
  onLogin?: () => void;
  onUpgrade: () => void;
}> = (props) => {
  const { isWebMember, freeTrial, onLogin, onUpgrade } = props;
  const { t } = useTranslation();
  const { userInfo } = useUserStore();
  const { usageInfo, billingDetail } = useCommonStore();
  const { sendEvent } = useGASendEvent();
  const { preparePropertyFor } = useDA();
  const { experiment } = useSundry();

  const guestFeature = experiment(AB_EXPERIMENTS.GUEST);
  const removeGPT4Feature = experiment(AB_EXPERIMENTS.REMOVEGPT4);
  const guestUser = guestFeature && !userInfo?.isLogin;
  const isFree = userInfo?.membershipId === MEMBERSHIP_ID.Free;
  const [isFold, setFold] = useState(true);
  const usageList = useMemo(() => {
    const data = guestUser
      ? [
          {
            name: t('layout.usage.GPT3'),
            type: 'gpt3',
          },
          {
            name: removeGPT4Feature ? t('layout.usage.GPT4o') : t('layout.usage.GPT4/4o'),
            type: 'gpt4',
          },
          {
            name: t('layout.sideMenu.sideWriting'),
            type: 'guestWrite',
          },
          {
            name: t('layout.pay.payTitleAiReader'),
            type: 'guestDoc',
          },
        ]
      : [
          {
            name: t('layout.usage.GPT3'),
            type: 'gpt3',
          },
          {
            name: removeGPT4Feature ? t('layout.usage.GPT4o') : t('layout.usage.GPT4/4o'),
            type: 'gpt4',
          },
          {
            name: t('layout.pay.payTitleAiPPT'),
            type: 'aiCreation',
          },
          {
            name: t('layout.pay.payTitleAiReader'),
            type: 'docUpload',
          },
          {
            name: t('layout.sideMenu.sideWriting'),
            type: 'writeExtract',
          },
        ];
    return data
      .map(({ name, type }, i) => {
        if (
          !usageInfo[type] ||
          (isFold && !guestUser && ['writeExtract'].includes(type)) ||
          (guestUser && ('aiCreation' === type || i > 3))
        ) {
          return null;
        }
        let refreshType = userInfo.isPro
          ? 'layout.usage.usagePerMonth'
          : 'layout.usage.usagePerDay';
        const { used, total } = usageInfo[type] as GptUsageItem;
        if (type === 'docUpload') {
          refreshType = 'layout.usage.usageUpload';
        }
        if (type === 'aiCreation') {
          refreshType = 'layout.usage.usageOngoing';
        }
        if (type === 'writeExtract') {
          refreshType = 'layout.usage.usageDownload';
        }
        return {
          name,
          isUnlimited: total >= 9999,
          usage: (
            <Trans
              i18nKey={refreshType}
              components={{ b: <div className="number" /> }}
              values={{ usage: used, count: total }}
            />
          ),
        };
      })
      .filter(Boolean) as {
      name: string;
      usage: ReactElement;
      isUnlimited?: boolean;
    }[];
  }, [guestUser, t, removeGPT4Feature, usageInfo, isFold, userInfo.isPro]);

  const isShowUpgradeBtn = useMemo(() => {
    return userInfo.isLogin && !userInfo?.parsedRoles?.includes('Trail');
  }, [userInfo]);

  const handleLogin = (e: React.MouseEvent<HTMLElement>) => {
    if (userInfo?.isLogin) {
      return;
    }
    e.stopPropagation();
    sendEvent('homepage_login_click');
    preparePropertyFor('RegistLoginModalExposure', 'forward_source', 'sidebar_login_click');
    onLogin?.();
  };

  const toggleFold = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setFold(!isFold);
  };
  return (
    <>
      <div className={cls('user-usage-container', !userInfo.isLogin && 'theme-unlogin')}>
        {userInfo.isLogin && (
          <>
            <div className="user-info">
              <UserAvatar className="user-avatar" size={24} bordered src={userInfo?.picture} />
              <UserBadge renderType="fixedIcon" />
              {billingDetail?.currentPeriodEnd && (
                <p className="user-dueon">
                  {t('layout.usage.dueOnDate')}
                  {dayjs(billingDetail.currentPeriodEnd).format('MM/DD/YY')}
                </p>
              )}
            </div>
            <Divider className="avatar-divider" />
          </>
        )}
        <div className="user-usage-detail" onClick={handleLogin}>
          {usageList.map((item) => (
            <div className="user-usage-item" key={item.name}>
              <p className="user-usage-type">{item.name}</p>
              <div
                className={cls(
                  'user-usage-number',
                  (userInfo.isUnlimited || item?.isUnlimited) && 'unlimited',
                )}
              >
                {userInfo.isUnlimited || item?.isUnlimited ? (
                  <CustomIcon type="popai-a-unlimitedicon" className="user-type-icon" />
                ) : (
                  item.usage
                )}
              </div>
            </div>
          ))}
        </div>
        {!guestUser && (
          <div className="user-usage-fold-btn" onClick={toggleFold}>
            {!isFold && (
              <p className="user-usage-refresh">
                <CustomIcon type="popai-prompt" />
                {t('layout.usage.quotaRefresh')}
              </p>
            )}
            <div className="toggle-btn">
              <p>{isFold ? 'More' : 'Hide'}</p>
              <CustomIcon
                className={cls('arrow-icon', isFold && 'fold')}
                type="arrowBottomSquare"
              />
            </div>
          </div>
        )}
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="usagebtn-con"
        >
          {userInfo.isLogin && (isWebMember || isFree) && isShowUpgradeBtn && (
            <UpgradeButton freeTrial={freeTrial} onUpgrade={onUpgrade} />
          )}
          {!userInfo.isLogin && (
            <div className="login-button" onClick={handleLogin}>
              {t('common.signUp')} /{t('common.logIn')}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserUsage;
