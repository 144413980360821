import './index.less';

interface OlElementProps {
  start?: number;
  children: React.ReactNode;
}

const OlElement: React.FC<OlElementProps> = (props) => {
  const { children, start = 1 } = props;

  return (
    <ol start={start} style={{ counterReset: `li ${start - 1}` }}>
      {children}
    </ol>
  );
};

export default OlElement;
