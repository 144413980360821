import { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { Skeleton, Popover } from 'antd';
import CustomIcon from '@/components/CustomIcon';
import { AB_EXPERIMENTS, MEMBERSHIP_ID } from '@/common/config';

import './PayChart.less';
import { useSundry } from '@/hooks';
const PayChart: React.FC<{
  memberTypes: any[];
  isLoading: boolean;
}> = ({ memberTypes = [], isLoading }) => {
  const { t } = useTranslation();
  const { experiment } = useSundry();
  const removeGPT4Feature = experiment(AB_EXPERIMENTS.REMOVEGPT4);
  const isUnlimitedMember = (memberType: MEMBERSHIP_ID) =>
    [MEMBERSHIP_ID.YearUnlimited, MEMBERSHIP_ID.MonthUnlimited].includes(memberType);

  const getUsageCountStr = (memberType: MEMBERSHIP_ID, count: number) => {
    return (
      <Trans
        i18nKey={
          MEMBERSHIP_ID.Free === memberType ? 'ENUM.payCountPerDay' : 'ENUM.payCountPerMonth'
        }
        values={{
          count,
        }}
        components={{
          b: memberType === 0 ? <span></span> : <strong />,
        }}
      />
    );
  };

  const UnlimitedEl = <strong>Unlimited</strong>;
  const producesChart = useMemo(() => {
    return [
      {
        title: t('layout.pay.payTitleTraffic'),
        iconType: 'popai-chat',
        equities: [
          {
            title: t('layout.usage.GPT3'),
            data: memberTypes.map((m) =>
              isUnlimitedMember(m.memberType) ? (
                <>
                  {UnlimitedEl}
                  <Popover
                    content={t('layout.pay.payUsageLimitTip')}
                    overlayStyle={{ maxWidth: '300px' }}
                  >
                    <CustomIcon type="popai-tixing" />
                  </Popover>
                </>
              ) : (
                getUsageCountStr(m.memberType, m.data?.membership.gpt3Quota)
              ),
            ),
          },
          {
            title: removeGPT4Feature
              ? t('layout.pay.payTitleGPT4o')
              : t('layout.pay.payTitleGPT4/4o'),
            data: memberTypes.map((m) =>
              isUnlimitedMember(m.memberType) ? (
                <>
                  {UnlimitedEl}
                  <Popover
                    content={t('layout.pay.payUsageLimitTip')}
                    overlayStyle={{ maxWidth: '300px' }}
                  >
                    <CustomIcon type="popai-tixing" />
                  </Popover>
                </>
              ) : (
                getUsageCountStr(m.memberType, m.data?.membership.gpt4Quota)
              ),
            ),
          },
        ],
      },
      {
        title: t('layout.pay.payTitleAiReader'),
        iconType: 'popai-wendang-xian',
        equities: [
          {
            title: t('layout.pay.payTitleUpload'),
            data: memberTypes.map((m) =>
              isUnlimitedMember(m.memberType) ||
              [MEMBERSHIP_ID.MonthPro, MEMBERSHIP_ID.YearPro].includes(m.memberType) ? (
                UnlimitedEl
              ) : (
                <Trans
                  i18nKey={'ENUM.payCountPerDay'}
                  values={{
                    count: m.data?.membership.uploadQuota,
                  }}
                  components={{
                    b: m.name === 'FREE' ? <span></span> : <strong />,
                  }}
                />
              ),
            ),
          },
          {
            title: t('layout.pay.payTitlePages'),
            data: memberTypes.map((m) => {
              return (
                <Trans
                  i18nKey={'ENUM.payCountPerPDF'}
                  values={{
                    count: m.data?.membership.pdfPageQuota,
                  }}
                  components={{
                    b: m.name === 'FREE' ? <span></span> : <strong />,
                  }}
                />
              );
            }),
          },
          {
            title: t('layout.pay.payTitleOcr'),
            data: [true, true, true],
          },
        ],
      },
      {
        title: t('layout.pay.payTitleAiPPT'),
        iconType: 'popai-presentation',
        equities: [
          {
            title: t('layout.pay.payTitleFileManage'),
            data: memberTypes.map((m) => {
              if (isUnlimitedMember(m.memberType)) {
                return UnlimitedEl;
              }
              const isFree = m.memberType === MEMBERSHIP_ID.Free;
              return (
                <Trans
                  i18nKey={'ENUM.ongoingProject'}
                  values={{
                    count: m.data?.membership.aiCreationQuota,
                  }}
                  components={{
                    b: isFree ? <span></span> : <strong />,
                  }}
                />
              );
            }),
          },
          {
            title: t('layout.pay.payTitleExportPPT'),
            data: [false, true, true],
          },
          {
            title: t('layout.pay.payTitleWatermark'),
            data: [false, true, true],
          },
          {
            title: t('layout.pay.payTitleAiEdit'),
            data: [false, true, true],
          },
        ],
      },
      {
        title: t('layout.sideMenu.sideWriting'),
        iconType: 'popai-a-AIwriting1',
        equities: [
          {
            title: t('layout.pay.payTitleGeneralWriting'),
            data: [true, true, true],
          },
          {
            title: t('layout.pay.payTitleMediumLong'),
            data: memberTypes.map((m) => {
              if (isUnlimitedMember(m.memberType)) {
                return UnlimitedEl;
              }
              const isFree = m.memberType === MEMBERSHIP_ID.Free;
              return (
                <Trans
                  i18nKey={'ENUM.payCountPerDay'}
                  values={{
                    count: m.data?.membership.writeLongQuota,
                  }}
                  components={{
                    b: isFree ? <span></span> : <strong />,
                  }}
                />
              );
            }),
          },
          {
            title: t('layout.pay.payTitlePaper'),
            data: memberTypes.map((m) => {
              if (isUnlimitedMember(m.memberType)) {
                return UnlimitedEl;
              }
              const isFree = m.memberType === MEMBERSHIP_ID.Free;
              return (
                <Trans
                  i18nKey={'ENUM.payCountPerDay'}
                  values={{
                    count: m.data?.membership.writePaperQuota,
                  }}
                  components={{
                    b: isFree ? <span></span> : <strong />,
                  }}
                />
              );
            }),
          },
          {
            title: t('common.download'),
            data: memberTypes.map((m) => {
              if (isUnlimitedMember(m.memberType)) {
                return UnlimitedEl;
              }
              const isFree = m.memberType === MEMBERSHIP_ID.Free;
              return (
                <Trans
                  i18nKey={'ENUM.payCountPerDay'}
                  values={{
                    count: m.data?.membership.writeExportQuota,
                  }}
                  components={{
                    b: isFree ? <span></span> : <strong />,
                  }}
                />
              );
            }),
          },
        ],
      },
      {
        title: t('layout.pay.payTitleAiImage'),
        iconType: 'popai-a-AIImage',
        equities: [
          {
            title: t('layout.pay.payTitleBeta'),
            data: [true, true, true],
          },
        ],
      },
    ];
  }, [memberTypes]);
  return (
    <div className="product-diff-container">
      {['PRO', 'Unlimited'].map((type) => (
        <div className={classNames('product-item-bg-container', type)} />
      ))}
      <div className="product-diff-header">
        <div className="product-diff-header-item"></div>
        {memberTypes.map((member) => (
          <>
            <div className={classNames('product-diff-header-item', member.name)}>
              <p className="product-diff-header-item-text">{member.name}</p>
            </div>
          </>
        ))}
      </div>
      <Skeleton
        loading={isLoading}
        style={{ position: 'relative', zIndex: 99, padding: 24 }}
        paragraph={{ rows: 10 }}
      >
        {producesChart.map((group) => (
          <>
            <div className="product-group-title">
              <CustomIcon type={group.iconType} />
              <span className="product-group-title-text">{group.title}</span>
            </div>
            {group.equities.map((equity) => (
              <div className="product-group-data-item">
                <div className="product-equity-title">
                  <p>{equity.title}</p>
                </div>
                {equity.data.map((data, i) => (
                  <div className={classNames('product-equity', ['free', 'pro', 'unlimited'][i])}>
                    {typeof data === 'boolean' ? (
                      <CustomIcon type={data ? 'popai-yes' : 'popai-no'} />
                    ) : (
                      data
                    )}
                  </div>
                ))}
              </div>
            ))}
          </>
        ))}
      </Skeleton>
    </div>
  );
};

export default PayChart;
