import { Editor, Extension } from '@tiptap/core';
import { ReactRenderer } from '@tiptap/react';
import Suggestion, { SuggestionProps, SuggestionKeyDownProps } from '@tiptap/suggestion';
import { PluginKey } from '@tiptap/pm/state';
import tippy from 'tippy.js';

import { GROUPS } from './groups';
import { MenuList } from './MenuList';
import { ZERO_WIDTH_CHAR } from '@/common/config';
import { isEmpty } from 'lodash-es';

const extensionName = 'zeroWidthCommand';

let popup: any;

export const ZeroWidthCommand = Extension.create({
  name: extensionName,

  priority: 200,

  onCreate() {
    popup = tippy('body', {
      interactive: true,
      trigger: 'manual',
      placement: 'bottom-start',
      theme: 'slash-command',
      offset: [0, 8],
      popperOptions: {
        strategy: 'fixed',
        modifiers: [
          {
            name: 'flip',
            enabled: false,
          },
        ],
      },
      onShow: () => {
        document.querySelector('.write-view > div')?.classList.add('no-scroll');
      },
      onHide: () => {
        document.querySelector('.write-view > div')?.classList.remove('no-scroll');
        const { view, state } = this.editor;
        const { $from } = view.state.selection;
        const tr = state.tr;
        if ($from.nodeBefore?.text?.endsWith(ZERO_WIDTH_CHAR)) {
          tr.delete($from.pos - 1, $from.pos);
        }
        view.dispatch(tr);
        setTimeout(() => {
          view.focus();
        }, 100);
      },
    });
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        char: ZERO_WIDTH_CHAR,
        allowSpaces: true,
        startOfLine: true,
        pluginKey: new PluginKey(extensionName),
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const isRootDepth = $from.depth === 1;
          const isParagraph = $from.parent.type.name === 'paragraph';
          const isStartOfNode = $from.parent.textContent?.charAt(0) === ZERO_WIDTH_CHAR;
          const isInColumn = this.editor.isActive('column');

          const afterContent = $from.parent.textContent?.substring(
            $from.parent.textContent?.indexOf(ZERO_WIDTH_CHAR),
          );
          const isValidAfterContent = !afterContent?.endsWith('  ');

          return (
            ((isRootDepth && isStartOfNode && isParagraph) ||
              (isInColumn && isParagraph && isStartOfNode)) &&
            isValidAfterContent
          );
        },
        command: ({ editor, props }: { editor: Editor; props: any }) => {
          const { view, state } = editor;
          const { $head, $from } = view.state.selection;

          const end = $from.pos;
          const from = $head?.nodeBefore
            ? end -
              ($head.nodeBefore.text?.substring($head.nodeBefore.text?.indexOf(ZERO_WIDTH_CHAR))
                .length ?? 0)
            : $from.start();

          const tr = state.tr.deleteRange(from, end);
          view.dispatch(tr);

          props.action(editor);
          view.focus();
        },
        items: ({ query }: { query: string }) => {
          const withFilteredCommands = GROUPS().map((group) => ({
            ...group,
            commands: group.commands
              .filter((item) => {
                const labelNormalized = item.label.toLowerCase().trim();
                const queryNormalized = query.toLowerCase().trim();

                if (item.aliases) {
                  const aliases = item.aliases.map((alias) => alias.toLowerCase().trim());

                  return (
                    labelNormalized.includes(queryNormalized) || aliases.includes(queryNormalized)
                  );
                }

                return labelNormalized.includes(queryNormalized);
              })
              .filter((command) =>
                command.shouldBeHidden ? !command.shouldBeHidden(this.editor) : true,
              ),
          }));

          const withoutEmptyGroups = withFilteredCommands.filter((group) => {
            if (group.commands.length > 0) {
              return true;
            }

            return false;
          });

          const withEnabledSettings = withoutEmptyGroups.map((group) => ({
            ...group,
            commands: group.commands.map((command) => ({
              ...command,
              isEnabled: true,
            })),
          }));

          return withEnabledSettings;
        },
        render: () => {
          let component: any;

          const getReferenceClientRect = (props: SuggestionProps) => {
            if (!props.clientRect) {
              return props.editor.storage[extensionName].rect;
            }

            const rect = props.clientRect();

            if (!rect) {
              return props.editor.storage[extensionName].rect;
            }

            let yPos = rect.y;

            if (rect.top + component.element.offsetHeight + 40 > window.innerHeight) {
              const diff = rect.top + component.element.offsetHeight - window.innerHeight + 40;
              yPos = rect.y - diff;
            }

            return new DOMRect(rect.x, yPos, rect.width, rect.height);
          };

          return {
            onStart: (props: SuggestionProps) => {
              component = new ReactRenderer(MenuList, {
                props,
                editor: props.editor,
              });

              popup?.[0].setProps({
                getReferenceClientRect: () => getReferenceClientRect(props),
                appendTo: () => document.body,
                content: component.element,
              });
              props.editor.storage[extensionName].isVisible = true;
              popup?.[0].show();
            },

            onUpdate(props: SuggestionProps) {
              component.updateProps(props);
              if (isEmpty(props?.items) || props?.query?.trim()?.length === 0) {
                popup?.[0].hide();
              }

              // eslint-disable-next-line no-param-reassign
              props.editor.storage[extensionName].rect = props.clientRect
                ? getReferenceClientRect(props)
                : {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                  };
              popup?.[0].setProps({
                getReferenceClientRect: () => getReferenceClientRect(props),
              });
            },

            onKeyDown(props: SuggestionKeyDownProps) {
              if (props.event.key === 'Escape') {
                popup?.[0].hide();
                return true;
              }

              if (!popup?.[0].state.isShown) {
                if (props.event.key === 'Backspace') {
                  popup?.[0].hide();
                  return component.ref?.onKeyDown(props);
                }
                popup?.[0].show();
              }

              return component.ref?.onKeyDown(props);
            },

            onExit(props: SuggestionProps) {
              props.editor.storage[extensionName].isVisible = false;
              popup?.[0].hide();
              component?.destroy();
            },
          };
        },
      }),
    ];
  },

  addStorage() {
    return {
      rect: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      isVisible: false,
    };
  },
});

export default ZeroWidthCommand;
