import { Modal } from 'antd';
import { useDebugStore } from '@/store';
import Switch from '@/components/Switch';

import './index.less';

interface SettingsModalProps {
  open?: boolean;
  onCancel?: () => void;
}

const SettingsModal: React.FC<SettingsModalProps> = (props) => {
  const { open, onCancel } = props;
  const { debug, setDebug } = useDebugStore();

  const handleDebugChange = (debug: boolean) => {
    setDebug(debug);
  };

  return (
    <Modal
      wrapClassName="settings-modal-wrapper"
      open={open}
      centered
      title="Debug Settings"
      footer={null}
      styles={{
        mask: {
          backgroundColor: 'rgba(0, 0, 0, 0.88)',
        },
      }}
      width={360}
      zIndex={1000}
      onCancel={onCancel}
    >
      <div className="settings-container">
        <div className="debug-item">
          <div className="debug-item-label">SendChunkConsole</div>
          <Switch className="debug-switch" checked={debug} onChange={handleDebugChange} />
        </div>
      </div>
    </Modal>
  );
};

export default SettingsModal;
