import { useWriteStore } from '@/store';
import { useEffect } from 'react';
import { WritingSendParams } from '.';

interface Props {
  handleAutoSend: (params: WritingSendParams) => Promise<void>;
}

/** 自动化调用askHook */
export const useAutoHook = (props: Props) => {
  const { handleAutoSend } = props;
  const { autoAskSend, setAutoAskSend, autoAskConfig, setAutoAskConfig } = useWriteStore();

  useEffect(() => {
    if (autoAskSend && autoAskConfig) {
      handleAutoSend({
        message: autoAskConfig.message,
        writingDocPromptTemplateId: autoAskConfig?.writingDocPromptTemplateId,
        model: autoAskConfig.model,
        searchSwitch: autoAskConfig.searchSwitch,
        advanceConfig: autoAskConfig?.advanceConfig,
      });
      setAutoAskSend(false);
      setAutoAskConfig({});
    }
  }, [autoAskSend, autoAskConfig]);
};
