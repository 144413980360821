import {
  PRODUCT_PERIOD_TYPE,
  PRICE_STATUS,
  PRODUCT_TYPE,
  TRIAL_STATUS,
  MEMBERSHIP_ID,
} from '@/common/config';
import { ProductItem } from '@/layout/BasicLayout/components/PayModal';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash-es';
import { Button, Skeleton } from 'antd';
import { useUserStore } from '@/store';
import CustomIcon from '@/components/CustomIcon';
import cls from 'classnames';

import './PayCard.less';
import { useMemo } from 'react';
import { hasCouponActivity } from '@/components/UpgradeButton';

const PayCard: React.FC<{
  product: ProductItem;
  proType: PRODUCT_PERIOD_TYPE;
  priceStatus?: PRICE_STATUS;
  trialStatus: TRIAL_STATUS;
  onPay: () => void;
  isLoading: boolean;
  isGetCoupon: boolean;
  handleCoupon: () => void;
}> = (props) => {
  const {
    product,
    proType,
    priceStatus,
    trialStatus,
    onPay,
    isLoading,
    isGetCoupon,
    handleCoupon,
  } = props;
  const { t } = useTranslation();
  const { discountInfo, userInfo } = useUserStore();

  const cancelTip = useMemo(() => {
    if (product.productType !== 'FREE') {
      if (
        trialStatus === TRIAL_STATUS.Pro &&
        product.productType === PRODUCT_TYPE.Pro &&
        userInfo.membershipId === MEMBERSHIP_ID.Free &&
        product.membershipId === MEMBERSHIP_ID.YearPro
      ) {
        return t('layout.pay.freeTrialCancelTip', {
          price: `${product.productPriceText}${product.productPriceNum}`,
        });
      }

      if (
        trialStatus === TRIAL_STATUS.ProAndUnlimit &&
        [PRODUCT_TYPE.Pro, PRODUCT_TYPE.Unlimited].includes(product.productType as PRODUCT_TYPE) &&
        userInfo.membershipId === MEMBERSHIP_ID.Free &&
        product.membershipId === MEMBERSHIP_ID.YearPro
      ) {
        return t('layout.pay.freeTrialCancelTip', {
          price: `${product.productPriceText}${product.productPriceNum}`,
        });
      }
      if (
        trialStatus === TRIAL_STATUS.YearProAndThreeDay &&
        product.productType === PRODUCT_TYPE.Pro &&
        userInfo.membershipId === MEMBERSHIP_ID.Free &&
        product.membershipId === MEMBERSHIP_ID.YearPro
      ) {
        return t('layout.pay.freeTrial3DayCancelTip', {
          price: `${product.productPriceText}${product.productPriceNum}`,
        });
      }

      if ([PRICE_STATUS.TESTGROUPB].includes(priceStatus as PRICE_STATUS))
        return t('layout.pay.cancelTip');
    }
    return '';
  }, [proType, product, trialStatus, priceStatus, t]);
  const inFreeTrail = useMemo(
    () => userInfo.isPro && userInfo?.parsedRoles?.includes('Trail'),
    [userInfo],
  );
  const isCurrentPlan = useMemo(
    () => userInfo.membershipId === product.membershipId,
    [userInfo, product],
  );
  const disable = isCurrentPlan || inFreeTrail;

  const isShowGetCouponBtn = useMemo(() => {
    return (
      userInfo?.membershipId === MEMBERSHIP_ID.YearUnlimited &&
      isCurrentPlan &&
      hasCouponActivity(discountInfo)
    );
  }, [userInfo, isCurrentPlan]);

  // const isFree = userInfo.membershipId === MEMBERSHIP_ID.Free;
  const disabledProductTypes = [];
  const userMemberId = userInfo?.membershipId as MEMBERSHIP_ID;
  if ([MEMBERSHIP_ID.MonthPro, MEMBERSHIP_ID.MonthProPlus].includes(userMemberId)) {
    disabledProductTypes.push(MEMBERSHIP_ID.MonthPro);
  }
  if (MEMBERSHIP_ID.MonthUnlimited === userMemberId) {
    disabledProductTypes.push(
      ...[MEMBERSHIP_ID.MonthPro, MEMBERSHIP_ID.MonthUnlimited, MEMBERSHIP_ID.YearPro],
    );
  }
  if ([MEMBERSHIP_ID.YearPro, MEMBERSHIP_ID.YearProPlus].includes(userMemberId)) {
    disabledProductTypes.push(...[MEMBERSHIP_ID.MonthPro, MEMBERSHIP_ID.MonthUnlimited]);
  }
  if (MEMBERSHIP_ID.YearUnlimited === userMemberId) {
    disabledProductTypes.push(
      ...[MEMBERSHIP_ID.MonthPro, MEMBERSHIP_ID.MonthUnlimited, MEMBERSHIP_ID.YearPro],
    );
  }
  const subscribeLabel = useMemo(() => {
    if (isCurrentPlan) {
      return t('common.currentPlan');
    }
    if (
      proType === PRODUCT_PERIOD_TYPE.Year &&
      product.productType !== 'FREE' &&
      userInfo.membershipId === MEMBERSHIP_ID.Free
    ) {
      if (trialStatus === TRIAL_STATUS.Pro && product.productType === PRODUCT_TYPE.Pro) {
        return t('common.freeSevenDayTrial');
      }

      if (
        trialStatus === TRIAL_STATUS.ProAndUnlimit &&
        [PRODUCT_TYPE.Pro, PRODUCT_TYPE.Unlimited].includes(product.productType as PRODUCT_TYPE)
      ) {
        return t('common.freeSevenDayTrial');
      }
      if (
        trialStatus === TRIAL_STATUS.YearProAndThreeDay &&
        PRODUCT_TYPE.Pro === product.productType
      ) {
        return t('common.free3DayTrial');
      }
    }
    return t('common.subscribe');
  }, [disable, proType, trialStatus, product, userInfo]);
  const hasPrice =
    isNumber(Number(product?.productPriceNum)) && Number(product?.productPriceNum) > 0;

  const isFreeProduct = product?.productType === PRODUCT_TYPE.Free;
  const isProPruoduct = product?.productType === PRODUCT_TYPE.Pro;
  const isProPlusProduct = product?.productType === PRODUCT_TYPE.ProPlus;
  const isUnlimitedProduct = product?.productType === PRODUCT_TYPE.Unlimited;

  // 月是否显示折扣
  const shouldShowMonthSaveTag =
    (isProPruoduct || isProPlusProduct || isUnlimitedProduct) &&
    proType === PRODUCT_PERIOD_TYPE.Month &&
    discountInfo?.monthlyDiscount &&
    discountInfo?.monthlyDiscount > 0;
  // 年是否显示折扣
  const shouldShowYearSaveTag =
    (isProPruoduct || isProPlusProduct || isUnlimitedProduct) &&
    proType === PRODUCT_PERIOD_TYPE.Year &&
    discountInfo?.yearlyDiscount &&
    discountInfo?.yearlyDiscount > 0;

  // 是否显示独家优惠: 无限产品且（没有月折扣或者没有年折扣）,有折扣显示折扣，没折扣显示这个
  const shouldShowExclusiveOffer =
    isUnlimitedProduct &&
    ((proType === PRODUCT_PERIOD_TYPE.Month && !discountInfo?.monthlyDiscount) ||
      (proType === PRODUCT_PERIOD_TYPE.Year && !discountInfo?.yearlyDiscount));

  if (isFreeProduct) {
    return null;
  }
  return (
    <div className={cls('product-card', product?.productType)}>
      <Skeleton loading={isLoading} active paragraph={{ rows: 3 }}>
        {shouldShowMonthSaveTag && (
          <div className="discount-tip">
            {t('layout.pay.save16', { discount: (discountInfo?.monthlyDiscount || 0) * 100 })}
          </div>
        )}
        {shouldShowYearSaveTag && (
          <div className="discount-tip">
            {t('layout.pay.save16', { discount: (discountInfo?.yearlyDiscount || 0) * 100 })}
          </div>
        )}
        {shouldShowExclusiveOffer && (
          <div className="discount-tip">{t('layout.pay.mostPopular')}</div>
        )}
        {product.productType === PRODUCT_TYPE.Pro && (
          <CustomIcon type="payCardPro" className="pay-card-bg-icon" />
        )}
        {product.productType === PRODUCT_TYPE.Unlimited && (
          <CustomIcon type="payCardUnlimit" className="pay-card-bg-icon" />
        )}
        <p className="produc-type">{product?.productType}</p>
        <div className="product-price">
          <div>
            <span className="price-currency">{product.productPriceText}</span>
            <span className="price-num">{product.productPriceNum}</span>
            <span className="price-period">{t('layout.pay.mo')}</span>
            {/* 年有折扣显示年划线价，月有折扣显示月划线价 */}
            {!!product?.strikePrice &&
              ((discountInfo?.monthlyDiscount && proType === PRODUCT_PERIOD_TYPE.Month) ||
                (discountInfo?.yearlyDiscount && proType === PRODUCT_PERIOD_TYPE.Year)) &&
              [PRICE_STATUS.TESTGROUPA, PRICE_STATUS.TESTGROUPB].includes(
                priceStatus as PRICE_STATUS,
              ) && (
                <span className="strike-price">
                  {product.productPriceText}
                  {product?.strikePrice}
                </span>
              )}
          </div>
          {hasPrice && proType === PRODUCT_PERIOD_TYPE.Year && (
            <div className="billed-tag">{t('layout.pay.billedYearly')}</div>
          )}
        </div>
        {cancelTip && <div className="cancel-tip">{cancelTip}</div>}
        {hasPrice ? (
          isShowGetCouponBtn ? (
            <div className="get-coupon-container" onClick={handleCoupon}>
              <span>{subscribeLabel}</span>
              <div className="get-coupon">
                <span>{isGetCoupon ? t('common.gotCoupon') : t('common.getCoupon')}</span>
                <CustomIcon type="RightArrowOutlinedfont" className="subcribe-icon" />
              </div>
            </div>
          ) : (
            <Button
              disabled={disable || disabledProductTypes.includes(product.membershipId)}
              onClick={onPay}
              className="subscribe-btn"
            >
              <span>{subscribeLabel}</span>
              {!isCurrentPlan && (
                <CustomIcon type="RightArrowOutlinedfont" className="subcribe-icon" />
              )}
            </Button>
          )
        ) : null}
      </Skeleton>
    </div>
  );
};

export default PayCard;
