// From prosemirror guide
import { TextSelection, Selection, EditorState, Transaction, Command } from 'prosemirror-state';
import { EditorView as PMEditorView } from 'prosemirror-view';
import { Node } from 'prosemirror-model';
import { EditorView } from '@codemirror/view';
import { setBlockType } from 'prosemirror-commands';
import { Compartment } from '@codemirror/state';

import { CodeBlockSettings } from './types';

export const CodeBlockNodeName = 'code_block';

export function computeChange(oldVal: string, newVal: string) {
  if (oldVal === newVal) return null;
  let start = 0;
  let oldEnd = oldVal.length;
  let newEnd = newVal.length;
  while (start < oldEnd && oldVal.charCodeAt(start) === newVal.charCodeAt(start)) start += 1;
  while (
    oldEnd > start &&
    newEnd > start &&
    oldVal.charCodeAt(oldEnd - 1) === newVal.charCodeAt(newEnd - 1)
  ) {
    oldEnd -= 1;
    newEnd -= 1;
  }
  return { from: start, to: oldEnd, text: newVal.slice(start, newEnd) };
}

export const asProseMirrorSelection = (
  pmDoc: Node,
  cmView: EditorView,
  getPos: (() => number) | boolean,
) => {
  const offset = (typeof getPos === 'function' ? getPos() || 0 : 0) + 1;
  const anchor = cmView.state.selection.main.from + offset;
  const head = cmView.state.selection.main.to + offset;
  return TextSelection.create(pmDoc, anchor, head);
};

export const forwardSelection = (
  cmView: EditorView,
  pmView: PMEditorView,
  getPos: (() => number) | boolean,
) => {
  if (!cmView.hasFocus) {
    return;
  }
  const selection = asProseMirrorSelection(pmView.state.doc, cmView, getPos);
  if (!selection.eq(pmView.state.selection)) {
    pmView.dispatch(pmView.state.tr.setSelection(selection));
  }
};

export const valueChanged = (
  textUpdate: string,
  node: Node,
  getPos: (() => number) | boolean,
  view: PMEditorView,
) => {
  const change = computeChange(node.textContent, textUpdate);
  if (change && typeof getPos === 'function') {
    const start = getPos() + 1;

    let pmTr = view.state.tr;
    pmTr = pmTr.replaceWith(
      start + change.from,
      start + change.to,
      change.text ? view.state.schema.text(change.text) : [],
    );
    view.dispatch(pmTr);
  }
};

export const maybeEscape = (
  unit: 'char' | 'line',
  dir: -1 | 1,
  cm: EditorView,
  view: PMEditorView,
  getPos: boolean | (() => number),
) => {
  const sel = cm.state.selection.main;
  const line = cm.state.doc.lineAt(sel.from);
  const lastLine = cm.state.doc.lines;
  if (
    sel.to !== sel.from ||
    line.number !== (dir < 0 ? 1 : lastLine) ||
    (unit === 'char' && sel.from !== (dir < 0 ? 0 : line.to)) ||
    typeof getPos !== 'function'
  ) {
    return false;
  }

  view.focus();
  const node = view.state.doc.nodeAt(getPos());
  if (!node) {
    return false;
  }
  const targetPos = getPos() + (dir < 0 ? 0 : node.nodeSize);
  const selection = Selection.near(view.state.doc.resolve(targetPos), dir);
  view.dispatch(view.state.tr.setSelection(selection).scrollIntoView());
  view.focus();
  return true;
};

export const backspaceHandler = (pmView: PMEditorView, view: EditorView) => {
  const { selection } = view.state;
  if (selection.main.empty && selection.main.from === 0) {
    setBlockType(pmView.state.schema.nodes.paragraph)(pmView.state, pmView.dispatch);
    setTimeout(() => pmView.focus(), 20);
    return true;
  }
  return false;
};

export const setMode = async (
  lang: string,
  cmView: EditorView,
  settings: CodeBlockSettings,
  languageConf: Compartment,
) => {
  const support = await settings.languageLoaders?.[lang]?.();
  if (support) {
    cmView.dispatch({
      effects: languageConf.reconfigure(support),
    });
  }
};

export const codeBlockBackspaceHandler = (
  state: EditorState,
  dispatch: ((tr: Transaction) => void) | undefined,
  view: PMEditorView,
) => {
  const { selection } = state;
  const { $from } = selection;

  const parentNode = $from.node($from.depth);

  if (selection.empty && $from.parentOffset === 0) {
    if (parentNode && parentNode.type.name === 'paragraph' && parentNode.textContent === '') {
      const grandParentNode = $from.node($from.depth - 1);
      const prevSiblingIndex = $from.index($from.depth - 1) - 1;
      if (prevSiblingIndex >= 0 && grandParentNode) {
        const prevSiblingNode = grandParentNode.child(prevSiblingIndex);
        if (prevSiblingNode && prevSiblingNode.type.name === CodeBlockNodeName) {
          const pos = $from.pos - 1;
          const resolvedPos = state.doc.resolve(pos);
          const selection = TextSelection.create(state.doc, resolvedPos.end());
          dispatch?.(state.tr.setSelection(selection).scrollIntoView());

          // 获取前一个 code_block 的 cmView
          const uniqueId = prevSiblingNode.attrs.uniqueId;
          // @ts-expect-error ignore
          const cmView = view.nodeViews[uniqueId]?.cmView;
          if (cmView) {
            cmView.focus();
          }
          return true;
        }
      }
    }
  }
  return false;
};

export const codeBlockKeymap = {
  Backspace: codeBlockBackspaceHandler as Command,
};
