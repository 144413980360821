import { Group } from './types';
import i18n from '@/i18n';

export const GROUPS = ():Group[] => [
  {
    name: 'format',
    title: i18n.t('components.write.basicBlocks'),
    commands: [
      {
        name: 'heading1',
        label: i18n.t('components.write.heading1'),
        iconName: 'heading1',
        description: 'High priority section title',
        aliases: ['h1'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 1 }).run();
        },
      },
      {
        name: 'heading2',
        label: i18n.t('components.write.heading2'),
        iconName: 'heading2',
        description: 'Medium priority section title',
        aliases: ['h2'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 2 }).run();
        },
      },
      {
        name: 'heading3',
        label: i18n.t('components.write.heading3'),
        iconName: 'heading3',
        description: 'Low priority section title',
        aliases: ['h3'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 3 }).run();
        },
      },
      {
        name: 'heading4',
        label: i18n.t('components.write.heading4'),
        iconName: 'heading4',
        description: 'Low priority section title',
        aliases: ['h4'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 4 }).run();
        },
      },
      {
        name: 'horizontalRule',
        label: i18n.t('components.write.horizontalRule'),
        iconName: 'divider',
        description: 'Insert a horizontal divider',
        aliases: ['hr'],
        action: (editor) => {
          editor.chain().focus().setHorizontalRule().run();
        },
      },
      {
        name: 'bulletList',
        label: i18n.t('components.write.bulletedList'),
        iconName: 'bulletList',
        description: 'Unordered list of items',
        aliases: ['ul'],
        action: (editor) => {
          editor.chain().focus().toggleBulletList().run();
        },
      },
      {
        name: 'numberedList',
        label: i18n.t('components.write.numberedList'),
        iconName: 'numberedList',
        description: 'Ordered list of items',
        aliases: ['ol'],
        action: (editor) => {
          editor.chain().focus().toggleOrderedList().run();
        },
      },
      {
        name: 'codeBlock',
        label: i18n.t('components.write.codeBlock'),
        iconName: 'codeBlock',
        description: 'Code block with syntax highlighting',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setCodeBlock().run();
          setTimeout(() => {
            // 自动focus到开头，暂时hack
            editor.view.dom.blur();
          }, 100);
        },
      },
      {
        name: 'callout',
        label: i18n.t('components.write.callout'),
        iconName: 'callout',
        description: 'Callout',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setCallout().run();
        },
      },
      {
        name: 'blockquote',
        label: i18n.t('components.write.blockquote'),
        iconName: 'writingQuote',
        description: 'Element for quoting',
        action: (editor) => {
          editor.chain().focus().setBlockquote().run();
        },
      },
    ],
  },
  {
    name: 'insert',
    title: i18n.t('components.write.insert'),
    commands: [
      {
        name: 'todoList',
        label: i18n.t('components.write.todoList'),
        iconName: 'todoList',
        description: 'Task list with todo items',
        aliases: ['todo'],
        action: (editor) => {
          editor.chain().focus().toggleTaskList().run();
        },
      },
      {
        name: 'table',
        label: i18n.t('components.write.table'),
        iconName: 'table',
        description: 'Insert a table',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run();
        },
      },
      // {
      //   name: 'image',
      //   label: 'Image',
      //   iconName: 'imageUpload',
      //   description: 'Insert an image',
      //   aliases: ['img'],
      //   action: (editor) => {
      //     // @ts-expect-error ignore
      //     editor.chain().focus().addImage().run();
      //   },
      // },
      {
        name: 'columns',
        label: i18n.t('components.write.columns'),
        iconName: 'columns',
        description: 'Add two column content',
        aliases: ['cols'],
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor
            .chain()
            .focus()
            .setColumns()
            .focus(editor.state.selection.head - 1)
            .run();
        },
      },
    ],
  },
  // {
  //   name: 'insert',
  //   title: 'Insert',
  //   commands: [
  //     {
  //       name: 'table',
  //       label: 'Table',
  //       iconName: 'Table',
  //       description: 'Insert a table',
  //       shouldBeHidden: (editor) => editor.isActive('columns'),
  //       action: (editor) => {
  //         editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run();
  //       },
  //     },

  //     {
  //       name: 'columns',
  //       label: 'Columns',
  //       iconName: 'Columns',
  //       description: 'Add two column content',
  //       aliases: ['cols'],
  //       shouldBeHidden: (editor) => editor.isActive('columns'),
  //       action: (editor) => {
  //         editor
  //           .chain()
  //           .focus()
  //           .setColumns()
  //           .focus(editor.state.selection.head - 1)
  //           .run();
  //       },
  //     },
  //     },
  //     {
  //       name: 'toc',
  //       label: 'Table of Contents',
  //       iconName: 'Book',
  //       aliases: ['outline'],
  //       description: 'Insert a table of contents',
  //       shouldBeHidden: (editor) => editor.isActive('columns'),
  //       action: (editor) => {
  //         editor.chain().focus().insertTableOfContents().run();
  //       },
  //     },
  //   ],
  // },
] ;

export default GROUPS;
