import axios from '@/common/request';

export async function shareChat<T>(params: {
  channelId?: string;
  messageIdList?: string[];
  watermark?: boolean;
  internal?: number;
  docId?: string;
}) {
  const result = await axios.post<T>('/api/v1/channel/share/create', params);
  return result.data;
}

export async function getShareChatDetail<T>(params: { shareKey: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/channel/share/detail?${paramArr.join('&')}`);
  return result.data;
}

export async function createChannelByShareKey<T>(params: { shareKey: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/share/conversation?${paramArr.join('&')}`);
  return result.data;
}
