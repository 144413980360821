import { useUserStore } from '@/store';
import CustomIcon from '../CustomIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.less';
import { useEffect, useMemo } from 'react';
import { MEMBERSHIP_ID, AB_EXPERIMENTS } from '@/common/config';
import { DiscountCouponInfo } from '@/type';
import { useDA, useGASendEvent, useSundry } from '@/hooks';
import UpgradeButtonOld from './UpgradeButton.old';

interface UpgradeButtonProps {
  freeTrial: number;
  onUpgrade: () => void;
}

// 是否有活动，只要有一个折扣率大于0就有活动
export function hasCouponActivity(discountInfo: DiscountCouponInfo | null) {
  if (!discountInfo) return false;
  return (
    (discountInfo?.monthlyDiscount && discountInfo?.monthlyDiscount > 0) ||
    (discountInfo?.yearlyDiscount && discountInfo?.yearlyDiscount > 0)
  );
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({ freeTrial, onUpgrade }) => {
  const { t } = useTranslation();

  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();
  const { experiment } = useSundry();

  const { userInfo } = useUserStore();
  const memberShipFeature = experiment(AB_EXPERIMENTS.MEMBERSHIP);
  // 是否有活动

  const shouldUpgradeButtonShow = useMemo(() => {
    return userInfo.membershipId !== MEMBERSHIP_ID.YearUnlimited;
  }, [userInfo.membershipId]);
  const isFree = userInfo?.membershipId === MEMBERSHIP_ID.Free;

  useEffect(() => {
    if (shouldUpgradeButtonShow) {
      sendEvent('promotion_show');
    }
  }, [shouldUpgradeButtonShow]);

  if (!memberShipFeature) {
    return <UpgradeButtonOld freeTrial={freeTrial} onUpgrade={onUpgrade} />;
  }

  // 如果是pro用户，就不显示升级按钮
  if (shouldUpgradeButtonShow) {
    return (
      <div
        onClick={() => {
          onUpgrade();
          sendEvent('promotion_click');
          sendDAEvent('SidebarButtonClick', {
            button_name: 'upgrade_to_pro',
            sidebar_status: 'open',
          });
        }}
        className={classNames({
          'upgrade-pro-btn-container': true,
          upgrade: memberShipFeature,
        })}
      >
        {/* {discountInfo?.discount && (
          <div className="discount-tag">New Year {discountInfo?.discount * 100}% off</div>
        )} */}
        {freeTrial && isFree ? (
          <button className="free-trial-btn">
            <CustomIcon type="rocket" className="isRtlIcon_language_ar" />
            {t('layout.sideMenu.freeTrialLabel')}
          </button>
        ) : (
          <button className="upgrade-pro-btn">
            <CustomIcon type="RocketIconColored" className="isRtlIcon_language_ar" />
            {t('layout.sideMenu.upgradeInActivityLabel')}
          </button>
        )}
      </div>
    );
  }

  return null;
};

export default UpgradeButton;
