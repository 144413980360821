import { create } from 'zustand';
import { isFunction } from 'lodash-es';
import { STORE_KEY } from '@/common/config';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

type LastActivityTimePayload = number | ((payload: number) => number);
type SessionIdPayload = string | ((payload: string) => string);
type IpPayload = string | ((payload: string) => string);
type CountryPayload = string | ((payload: string) => string);
interface EventState {
  webId: string;
  setWebId: (payload: string) => void;
  sessionId: string;
  setSessionId: (payload: SessionIdPayload) => void;
  lastActivityTime: number;
  setLastActivityTime: (payload: LastActivityTimePayload) => void;
  ip: string;
  setIp: (payload: IpPayload) => void;
  country: string;
  setCountry: (payload: CountryPayload) => void;
  finishedFirstEnterWebsiteReport: boolean;
  setFinishedFirstEnterWebsiteReport: (payload: boolean) => void;
}

const useEventStore = create<
  EventState,
  [['zustand/devtools', never], ['zustand/persist', Partial<EventState>]]
>(
  devtools(
    persist(
      (set) => ({
        webId: '', // 浏览器指纹
        setWebId: (payload: string) => set(() => ({ webId: payload })),
        sessionId: '',
        // setSessionId: (payload: string) => set(() => ({ sessionId: payload })),
        setSessionId: (payload: SessionIdPayload) =>
          set(({ sessionId }) => ({
            sessionId: isFunction(payload) ? payload(sessionId) : payload,
          })),
        lastActivityTime: 0,
        setLastActivityTime: (payload: LastActivityTimePayload) =>
          set(({ lastActivityTime }) => ({
            lastActivityTime: isFunction(payload) ? payload(lastActivityTime) : payload,
          })),
        ip: '',
        setIp: (payload: IpPayload) =>
          set(({ ip }) => ({
            ip: isFunction(payload) ? payload(ip) : payload,
          })),
        country: '',
        setCountry: (payload: CountryPayload) =>
          set(({ country }) => ({
            country: isFunction(payload) ? payload(country) : payload,
          })),
        finishedFirstEnterWebsiteReport: false, // 是否完成进入网站首次上报enterWebsite事件
        setFinishedFirstEnterWebsiteReport: (payload: boolean) =>
          set(() => ({ finishedFirstEnterWebsiteReport: payload })),
      }),
      {
        name: STORE_KEY.Event,
        storage: createJSONStorage(() => localStorage),
        partialize: ({ webId, sessionId, lastActivityTime }: EventState) => ({
          webId,
          sessionId,
          lastActivityTime,
        }),
      },
    ),
  ),
);

export default useEventStore;
