import React, { useState, useEffect } from 'react';
import { Modal, Spin } from 'antd';
import './index.less';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCommonStore } from '@/store';
import { useGASendEvent } from '@/hooks';

interface IndexProps {
  isShow: boolean;
  setIsShow: (value: boolean) => void;
}
const Index = React.memo<IndexProps>(({ isShow, setIsShow }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setPageLanguage } = useCommonStore();
  const { i18n } = useTranslation();
  const { sendEvent } = useGASendEvent();

  const handleClickOk = () => {
    setIsLoading(true);
    localStorage.setItem('isAutoChangeLanguage', 'true');
    i18n.changeLanguage('ar');
    setPageLanguage('ar');
    window.location.reload();
    sendEvent('arabic_languages_confirm_click');
  };

  const handleClickCancel = () => {
    localStorage.setItem('isAutoChangeLanguage', 'true');
    setIsShow(false);
    sendEvent('arabic_languages_cancel_click');
  };

  useEffect(() => {
    sendEvent('arabic_languages_show');
  }, []);

  return (
    <Modal centered width={440} footer={null} open={isShow} onCancel={handleClickCancel}>
      <p className="settingLanguageSaveTitle">Arabic Version Now Available！</p>
      <p className="settingLanguageSaveContent">
        Switching to the Arabic version, PopAi's interfacewill be displayed in RTL(Right-To-Left)
        mode, andthe interface language will change to Arabic.
      </p>
      <div className="settingLanguageSaveButtonBox">
        <div
          onClick={handleClickCancel}
          className={classNames({
            settingLanguageSaveButton: true,
            settingLanguageSaveButtonCancel: true,
          })}
        >
          Cancel
        </div>
        <div
          onClick={handleClickOk}
          className={classNames({
            settingLanguageSaveButton: true,
            settingLanguageSaveButtonChange: true,
          })}
        >
          {isLoading ? <Spin size="small" /> : <>Switch to Arabic</>}
        </div>
      </div>
    </Modal>
  );
});

export default Index;
