import './index.less';

interface ImgElementProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

const ImgElement: React.FC<ImgElementProps> = (props) => {
  const { src, alt } = props;

  return (
    <span className="img-wrapper">
      <img src={src} alt={alt} />
    </span>
  );
}

export default ImgElement;
