import { useEffect } from 'react';

/**
 *
 * add canonical URL for SEO
 */
const useCanonicalURL = () => {
  useEffect(() => {
    const reg =
      /^(\/(zh|en|ar|es))?\/(chatwithdoc|ai-reader|ppt|ai-presentation|writing|ai-writing|creation|more|workspace|files)/;

    const isCanonicalPath = window.location.pathname === '/' || reg.test(window.location.pathname);

    if (isCanonicalPath) {
      const canonicalEle = document.head.querySelector('link[rel=canonical]');
      const canonicalUrl = window.location.origin + window.location.pathname;

      if (canonicalEle) {
        canonicalEle?.setAttribute('href', canonicalUrl);
      } else {
        let ele = document.createElement('link');
        ele.rel = 'canonical';
        ele.href = canonicalUrl;
        document.head.appendChild(ele);
      }
    } else {
      document.head.querySelectorAll('link[rel=canonical]')?.forEach((ele) => ele?.remove());
    }
  }, [window.location.pathname]);
};

export default useCanonicalURL;
