/*
 * @Author: wubo
 * @Date: 2023-06-20 14:02:09
 * @LastEditTime: 2023-08-02 17:15:16
 * @LastEditors: wubo
 * @Descripttion:
 */
import { Modal, Space } from 'antd';
import CustomIcon from '@/components/CustomIcon';

import './index.less';

interface ConfirmModalProps {
  open: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  title: React.ReactNode;
  desc?: React.ReactNode;
  okText: string;
  cancelText: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const { open, onCancel, onConfirm, title, desc, okText, cancelText } = props;

  return (
    <Modal
      wrapClassName="confirm-modal"
      centered
      width={400}
      open={open}
      footer={null}
      closeIcon={<CustomIcon className="close-icon" type="modalClose" />}
      styles={{
        mask: {
          backgroundColor: 'rgba(0, 0, 0, 0.88)',
        },
      }}
      onCancel={onCancel}
    >
      <h1>{title}</h1>
      {desc && <div className="confirm-tips">{desc}</div>}
      <Space className="buttons" size={16}>
        <button className="cancel-btn" onClick={onCancel}>
          {cancelText}
        </button>
        <button className="confirm-btn" onClick={onConfirm}>
          {okText}
        </button>
      </Space>
    </Modal>
  );
};

export default ConfirmModal;
