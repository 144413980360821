import { Modal } from 'antd';
import './index.less';
import CustomIcon from '@/components/CustomIcon';
import { TRIAL_STATUS } from '@/common/config';
import { useTranslation } from 'react-i18next';

interface FreeTrialModalProps {
  freeTrial?: TRIAL_STATUS;
  open: boolean;
  productPrice: string;
  onClose: () => void;
}
const FreeTrialModal: React.FC<FreeTrialModalProps> = (props) => {
  const { t } = useTranslation();

  const onCancel = () => {
    props.onClose();
  };
  const trialDays = props.freeTrial === TRIAL_STATUS.YearProAndThreeDay ? 3 : 7;
  return (
    <Modal
      wrapClassName="freeTrial-success-modal"
      open={props.open}
      destroyOnClose
      centered
      footer={null}
      width={432}
      maskClosable
      onCancel={onCancel}
    >
      <CustomIcon className="free-trial-success-icon" type="freeTrialSuccess" />
      <div className="free-trial-content">
        <div className="free-trial-content-title">
          {t('layout.freeTrial.successfullyTrial', {
            number: trialDays,
          })}
        </div>
        <div className="free-trial-content-tip">
          <CustomIcon type="yesSideBar" />
          {t('layout.freeTrial.cancelTip', {
            number: trialDays,
          })}
        </div>
        <div className="free-trial-content-tip">
          <CustomIcon type="yesSideBar" />
          {t('layout.freeTrial.remindEmail')}
        </div>
        <div className="free-trial-content-tip">
          <CustomIcon type="yesSideBar" />
          {t('layout.freeTrial.afterPriceTip', {
            productPrice: props.productPrice,
            number: trialDays,
          })}
        </div>
      </div>
      <div className="free-trial-success-btn" onClick={onCancel}>
        {t('layout.freeTrial.enjoyBenefit')}
      </div>
    </Modal>
  );
};

export default FreeTrialModal;
