import { useUserStore } from '@/store';
import { Modal } from 'antd';
import './index.less';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGASendEvent } from '@/hooks';
import { useCommonStore } from '@/store';
import UserBadge from '@/layout/BasicLayout/components/UserBadge';

const Account: React.FC = () => {
  const { userInfo } = useUserStore();
  const pageLanguage = useCommonStore((state) => state.pageLanguage);
  const [permanentlyDeleteModalOpen, setPermanentlyDeleteModalOpen] = useState(false);
  const { t } = useTranslation();
  const { sendEvent } = useGASendEvent();

  const handleOK = () => {
    sendEvent('deleteaccount_gotit_click');
    setPermanentlyDeleteModalOpen(false);
  };

  const handleClose = () => {
    sendEvent('deleteaccount_close_click');
    setPermanentlyDeleteModalOpen(false);
  };

  const handleDeleteAccount = () => {
    sendEvent('settings_deleteaccount_click');
    setPermanentlyDeleteModalOpen(true);
  };

  return (
    <div className="account-information">
      <div className="account-information-header">
        <div className="account-information-header-title">
          {t('layout.settingModal.accountInformation')}
        </div>
        <div className="account-information-header-operation" onClick={handleDeleteAccount}>
          {t('layout.settingModal.deleteAccount')}
        </div>
      </div>
      <div className="account-information-content">
        <div className="account-information-content-item">
          <div className="account-information-content-item-label">
            {t('layout.settingModal.email')}
          </div>
          <div className="account-information-content-item-value">{userInfo?.email || '-'}</div>
        </div>
        <div className="account-information-content-item">
          <div className="account-information-content-item-label">
            {t('layout.settingModal.subscriptionPlan')}
          </div>
          <div className="account-information-content-item-value">
            <UserBadge renderType="fixedIcon" />
          </div>
        </div>
      </div>
      <Modal
        width={480}
        open={permanentlyDeleteModalOpen}
        title={t('layout.settingModal.permanentlyDeleteYourAccount')}
        okText={t('layout.settingModal.deleteAccountBtn')}
        onOk={handleOK}
        onCancel={handleClose}
        footer={(_, { OkBtn }) => <OkBtn />}
        centered={true}
        wrapClassName="permanently-delete"
      >
        <div className="permanently-delete-content">
          <p>{t('layout.settingModal.deleteTip1')}</p>
          <p>
            {t('layout.settingModal.deleteTip2')} <br />
            <span>{pageLanguage === 'ar' ? 'ب' : ''}customerservice@popai.pro</span>.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Account;
