import { useUserStore } from '@/store';
import { useCallback, useEffect } from 'react';
import thinkingdata from 'thinkingdata-browser';
// import useGASendEvent from './useGASendEvent';

const { VITE_APP_NAME, VITE_TD_APP_AID, VITE_APP_APP_NAME } = import.meta.env;

interface DAConfig {
  /**开启thing data埋点 */
  td?: boolean;
  /**开启GA埋点 */
  // ga?: boolean;
}

const globalScope = window;

const TD_CONFIG = {
  appId: VITE_TD_APP_AID,
  // serverUrl: 'https://eagle-sg.lingyiwanwu.net',
  serverUrl: 'https://eagle-sg.uploaded.vip',
  send_method: 'ajax',
  zoneOffset: 0,
  showLog: VITE_APP_NAME !== 'production',
};

const G_CACHED_PROPERTIES: Record<
  string,
  Record<string, { value: unknown; autoClear: boolean }>
> = {};

export function preparePropertyFor(
  eventName: string,
  key: string,
  value: unknown,
  autoClear = true,
  overwrite = true,
) {
  G_CACHED_PROPERTIES[eventName] ??= {};
  if (overwrite === false && key in G_CACHED_PROPERTIES[eventName]) {
    // ignore
  } else {
    G_CACHED_PROPERTIES[eventName][key] = { value, autoClear };
  }
}

/**
 * 获取为eventName准备的参数
 * @param {boolean} autoClear 如果只是检查参数，设置autoClear=false, 以避免自动删除
 */
export function getPreparedPropertiesOf(eventName: string, autoClear = true) {
  const res: Record<string, unknown> = {};
  if (eventName in G_CACHED_PROPERTIES) {
    const prepared = G_CACHED_PROPERTIES[eventName];
    Object.keys(prepared).forEach((key) => {
      res[key] = prepared[key].value;
      if (autoClear && prepared[key].autoClear) {
        delete prepared[key];
      }
    });

    if (autoClear && Object.keys(prepared).length === 0) {
      delete G_CACHED_PROPERTIES[eventName];
    }
  }
  return res;
}
/**
 * 初始化thinking data埋点配置
 */
const initTDAnalytics = ((window) => {
  let g_TD_initialized = false;
  return () => {
    if (g_TD_initialized) {
      return;
    }
    window.td = thinkingdata;
    window.td.init(TD_CONFIG);
    g_TD_initialized = true;
  };
})(globalScope);

const useDA = (
  config = {
    td: true,
    // ga: false,
  } as DAConfig,
) => {
  const {
    td = true,
    //  ga = false
  } = config;

  const { userInfo, userProperties } = useUserStore.getState() ?? {};
  // const { sendEvent } = useGASendEvent();

  /**
   * 发送thinking data统计事件
   * @param eventName
   * @param eventParams
   */
  const sendTDEvent = useCallback((eventName: string, eventParams?: Record<string, any>) => {
    globalScope.td?.track(eventName, eventParams);
  }, []);

  /**
   * 发送埋点
   * @param eventName
   * @param eventParams
   */
  const sendDAEvent = useCallback(
    (eventName: string, eventParams?: Record<string, unknown>) => {
      const prepared = getPreparedPropertiesOf(eventName);
      // 发送thingking data 事件
      td && sendTDEvent(eventName, Object.assign(prepared, eventParams));
      // 发送GA埋点
      // ga && sendEvent(eventName, eventParams);
    },
    [sendTDEvent, td],
  );

  /**
   * 设置页面级别公共属性
   * @param params
   */
  const setPageProperty = useCallback((pageProperty: Record<string, any>) => {
    const currentProperty = globalScope.td.getSuperProperties();
    globalScope.td.setSuperProperties({
      ...currentProperty,
      ...pageProperty,
    });
  }, []);

  /**
   * 清空页面级别公共属性
   */
  const clearPageProperty = useCallback(() => {
    const currentProperty = globalScope.td.getSuperProperties();
    Object.keys(currentProperty).forEach((key) => {
      globalScope.td.unsetSuperProperty(key);
    });
  }, []);

  useEffect(() => {
    initTDAnalytics();
  }, []);

  useEffect(() => {
    const { isLogin } = userInfo;
    if (isLogin) {
      globalScope.td.login(userInfo.uid);
    } else {
      globalScope.td.logout();
    }
  }, [userInfo]);

  /** 动态设置thinking data全局埋点参数 */
  useEffect(
    function setupProperties() {
      const {
        is_login,
        account_name,
        interval,
        product_type,
        subscribe_expire_time,
        subscribe_start_time,
        user_session_source,
        visitorId,
      } = userProperties;
      globalScope.td.setDynamicSuperProperties(function () {
        return {
          is_login,
          account_name,
          interval,
          product_type,
          subscribe_expire_time,
          subscribe_start_time,
          user_session_source,
          visitor_id: visitorId || '',
          app_name: VITE_APP_APP_NAME,
        };
      });
    },
    [userProperties],
  );

  return {
    /**
     * 发送埋点事件
     */
    sendDAEvent,
    /**
     * 设置页面参数
     */
    setPageProperty,
    /**
     * 清除页面参数
     */
    clearPageProperty,
    /**
     * 为某个event准备property, 适用于跨组件或者页面获取参数
     */
    preparePropertyFor,
    /**
     * 获取为eventName准备的参数
     * @param {boolean} autoClear 如果只是检查参数，设置autoClear=false, 以避免自动删除
     */
    getPreparedPropertiesOf,
  };
};

export default useDA;
