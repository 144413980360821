import { useCallback, useEffect, useRef } from 'react';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { isEmpty } from 'lodash-es';
import { STORE_KEY } from '@/common/config';

import useUserStore from '@/store/user';
import type { ResponseType, FeatureInfo, UserFeatureTipsResult } from '@/type';
import { getFeatureInfo, updateFeatureTips } from '@/services/featureTips';
import { AB_EXPERIMENTS, FEATURE_TIPS_INFO, USER_FEATURE_TIPS } from '@/common/config';

interface SundryState {
  abResList: FeatureInfo['abResList'];
  setAbResList: (payload: FeatureInfo['abResList']) => void;
  userTips: FeatureInfo['userTips'];
  setUserTips: (payload: FeatureInfo['userTips']) => void;
  //当前应该展示的tip，按group分组，每组只展示一个
  currentTipGroup: UserFeatureTipsResult[];
  setCurrentTipGroup: (payload: UserFeatureTipsResult[]) => void;
}

//私有store，只为useSundry提供服务
const useSundryStore = create<SundryState, [['zustand/persist', Partial<SundryState>]]>(
  persist(
    (set) => ({
      abResList: {},
      setAbResList: (payload: FeatureInfo['abResList']) => set(() => ({ abResList: payload })),
      userTips: [],
      setUserTips: (payload: FeatureInfo['userTips']) => set(() => ({ userTips: payload })),
      currentTipGroup: [] as UserFeatureTipsResult[],
      setCurrentTipGroup: (payload: UserFeatureTipsResult[]) =>
        set(() => ({ currentTipGroup: payload })),
    }),
    {
      name: STORE_KEY.Sundry,
      storage: createJSONStorage(() => localStorage),
      partialize: ({ abResList }: SundryState) => ({
        //abResList存储在localStorage中，为了让onechat的输入框第一时间出现，否则只能在拿到popai-onechat的实验结果后才出现
        abResList,
      }),
    },
  ),
);

//是否在登录前获取过数据,登录状态改变时获取1次
let fetchedBeforeLogin = false;

//是否在登录后获取过数据,登录状态改变时获取1次
let fetchedAfterLogin = false;

const useSundry = () => {
  const { userInfo } = useUserStore();
  const { abResList, setAbResList, userTips, setUserTips, currentTipGroup, setCurrentTipGroup } =
    useSundryStore();

  const userTipsRef = useRef(userTips);
  userTipsRef.current = userTips;

  const findCurrentTip = (
    _abResList: FeatureInfo['abResList'],
    _userTips: FeatureInfo['userTips'],
  ): UserFeatureTipsResult | undefined => {
    if (isEmpty(_abResList) || isEmpty(_userTips)) {
      return;
    }

    const topTip = _userTips?.find((item) => {
      const flagKey = `popai-${item?.tag}`;
      //如果这个feature在灰度中且没命中灰度，就不显示
      if (flagKey in (_abResList ?? {}) && _abResList?.[flagKey] !== 'A') {
        return false;
      }
      return String(item?.value) === String(item?.defaultValue);
    });
    return topTip ?? { tag: '', value: 0, defaultValue: 1 };
  };

  const fetchFeatureInfo = useCallback(async (options?: { withTips?: boolean }) => {
    const { withTips } = options || {};

    const res = await getFeatureInfo<ResponseType<FeatureInfo>>({
      abKeyList: Object.values(AB_EXPERIMENTS),
      ...(withTips ? { userTips: USER_FEATURE_TIPS } : {}),
    });

    if (res?.data) {
      const { abResList: _abResList, userTips: _userTips } = res.data;
      let abResListWidthMissed: FeatureInfo['abResList'] = _abResList;

      if (_abResList) {
        /**
         * 从AB_EXPERIMENTS中找到_abResList没有的(可能后端没返回)，赋值给missedKeys数组，
         * 确保AB_EXPERIMENTS查询的实验都有结果，没有结果的话前端补上OFF的值，否则会影响tip展示的逻辑
         */
        const missedKeys =
          Object.values(AB_EXPERIMENTS).filter((key) => !(key in _abResList)) ?? [];

        const missedList: { [key: string]: 'A' | 'OFF' } = Object.fromEntries(
          missedKeys.map((key) => [key, 'OFF']),
        );

        abResListWidthMissed = {
          ..._abResList,
          ...missedList,
        };
        setAbResList(abResListWidthMissed);
      }

      //未登录状态下，不查询userTips
      if ((_userTips?.length ?? 0) > 0) {
        setUserTips(_userTips);

        //获取所有tip的value
        const userTipsGroup = FEATURE_TIPS_INFO.filter((group) => group?.length > 0).map((group) =>
          group.map((tip) => _userTips?.find((userTip) => userTip?.tag === tip?.tag)),
        );

        //将每组tips中优先级最高的tip推入currentTipGroup数组，命中实验对照组的排除
        const _curentTipGroup = userTipsGroup.map((group) =>
          findCurrentTip(abResListWidthMissed, (group ?? []) as UserFeatureTipsResult[]),
        );

        setCurrentTipGroup(_curentTipGroup as UserFeatureTipsResult[]);
      }
    }
  }, []);

  /**
   * 根据tag更新userTips中对应数组项的value
   * 点击tip的关闭按钮时，更新数据库里这个tip的状态。产品需求是用户不主动关闭就一直显示
   */
  const updateTip = useCallback(
    (tag: string, value?: 0 | 1) => {
      //如果tip没在config里注册过，就不更新
      const tipIsRegisted = !!USER_FEATURE_TIPS.find((item) => item?.tag === tag);
      if (!tipIsRegisted) {
        return;
      }

      //如果tip的值和valeu相同，就不更新
      const needNotChange = userTipsRef.current?.find((item) => item?.tag === tag)?.value === value;

      if (needNotChange) {
        return;
      }

      const newTips = userTipsRef.current?.map((item) => {
        if (item?.tag === tag) {
          return { ...item, value: value || 0 } as UserFeatureTipsResult;
        }
        return item;
      });

      // 更新store里的userTips
      setUserTips(newTips);

      //更新currentTipGroup
      const targetTip = currentTipGroup?.find((item) => item?.tag === tag);
      if (targetTip) {
        const newCurrentTip = { ...targetTip, value: value || 0 } as UserFeatureTipsResult;
        const _curentTipGroup = currentTipGroup.map((tip) =>
          tip?.tag === tag ? newCurrentTip : tip,
        );
        setCurrentTipGroup(_curentTipGroup);
      }

      //更新数据库里的userTips
      updateFeatureTips(tag, value);
    },
    [userTipsRef.current, setUserTips, updateFeatureTips],
  );

  const experiment = useCallback(
    (key: AB_EXPERIMENTS): boolean | undefined => {
      if (!abResList?.[key]) {
        return undefined;
      }
      return abResList?.[key] === 'A';
    },
    [abResList],
  );

  /**
   * 只在登录前和登录后各请求一次，因为AB测试与登录状态有关
   * 对于只在登录后做的AB实验（用uid分组），登录前和登录后都会请求一次，登录前永远返回OFF
   */
  useEffect(() => {
    if (typeof userInfo.isLogin === 'boolean') {
      if (userInfo.isLogin && !fetchedAfterLogin) {
        fetchedAfterLogin = true;
        fetchFeatureInfo({ withTips: true });
      } else if (!userInfo.isLogin && !fetchedBeforeLogin) {
        fetchedBeforeLogin = true;
        fetchFeatureInfo();
      }
    }
  }, [userInfo.isLogin, fetchFeatureInfo, fetchedAfterLogin, fetchedBeforeLogin]);

  return {
    //当前应展示的tip们
    currentTipGroup,
    //按实验名称查询AB实验结果
    experiment,
    //按tag更新tip的value
    updateTip,
  };
};

export default useSundry;
