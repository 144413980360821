import { Modal, Flex } from 'antd';
import CustomIcon from '@/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import './index.less';

interface DownAppModalProps {
  open?: boolean;
  onCancel?: () => void;
}

const DownAppModal: React.FC<DownAppModalProps> = (props) => {
  const { open = false, onCancel } = props;
  const { t } = useTranslation();
  const handleCancel = () => {
    onCancel?.();
  };

  const handleOpenChromeWebstore = () => {
    window.open('https://chromewebstore.google.com/detail/popai/jaaajddkaoldkiajncgakhmogoboijoo');
  };

  // const handleDownloadPlugin = () => {
  //   window.open('https://popaife.s3-accelerate.amazonaws.com/other/popai.crx.zip');
  // };

  return (
    <Modal
      wrapClassName="download-app-modal-wrapper"
      width={1078}
      open={open}
      centered
      maskClosable={true}
      onCancel={handleCancel}
      closeIcon={null}
      footer={null}
      styles={{
        mask: {
          backgroundColor: 'rgba(0, 0, 0, 0.88)',
        },
      }}
    >
      <div className="download-app-modal-header">
        {t('layout.downloadPlugin.header')}
        <div className="download-app-modal-close-icon" onClick={handleCancel}>
          <CustomIcon type="close" />
        </div>
      </div>
      <div className="download-app-modal-description">{t('layout.downloadPlugin.description')}</div>
      <div className="download-app-modal-content">
        <Flex gap={16}>
          <div className="download-app-modal-content-right">
            <div className="download-app-modal-content-right-title">
              {t('layout.downloadPlugin.chromeExtension')}
            </div>
            <Flex className="download-app-modal-content-right-desc">
              <CustomIcon className="pdf-summary-icon" type="pdfSummary" />
              <div className="download-app-modal-content-right-desc-text">
                {t('layout.downloadPlugin.PDFSummary')}
              </div>
            </Flex>
            <Flex className="download-app-modal-content-right-desc">
              <CustomIcon className="screenshot-icon" type="screenshot" />
              <div className="download-app-modal-content-right-desc-text">
                {t('layout.downloadPlugin.captureCharts')}
              </div>
            </Flex>
            <Flex className="download-app-modal-content-right-desc">
              <CustomIcon className="homework-icon" type="homework" />
              <div className="download-app-modal-content-right-desc-text">
                {t('layout.downloadPlugin.extractChart')}
              </div>
            </Flex>
            <div className="download-app-modal-content-right-pic"></div>
            <div className="download-app-modal-content-right-operate">
              <Flex
                align="center"
                justify="center"
                flex={1}
                className="download-app-modal-content-right-operate-store"
                onClick={handleOpenChromeWebstore}
              >
                <CustomIcon className="homework-icon" type="chromeStore" />
                <div className="text">{t('layout.downloadPlugin.chromeStore')}</div>
              </Flex>
              {/* <Flex
                align="center"
                className="download-app-modal-content-right-operate-install"
                onClick={handleDownloadPlugin}
              >
                <CustomIcon className="homework-icon" type="chromePluginFileDown" />
                <div className="text">File installation</div>
              </Flex> */}
            </div>
          </div>
          <div className="download-app-modal-content-center">
            <div className="download-app-modal-content-center-title">
              <CustomIcon type="ios" />
              {t('layout.downloadPlugin.iosApp')}
            </div>
            <Flex className="download-app-modal-content-center-desc">
              <CustomIcon className="check-mark-icon" type="checkMark" />
              <div className="download-app-modal-content-center-desc-text">
                {t('layout.downloadPlugin.continueChat')}
              </div>
            </Flex>
            <Flex className="download-app-modal-content-center-desc">
              <CustomIcon className="check-mark-icon" type="checkMark" />
              <div className="download-app-modal-content-center-desc-text">
                {t('layout.downloadPlugin.snapPhotoChat')}
              </div>
            </Flex>
            <div className="download-app-modal-content-center-link">
              <div className="download-app-modal-content-center-link-left"></div>
              <div className="download-app-modal-content-center-link-right">
                <div className="download-app-modal-content-center-link-right-qr-code" />
                <div className="download-app-modal-content-center-link-right-content">
                  <a href="http://itunes.apple.com/app/id6462700934" target="_blank">
                    <CustomIcon className="apple-icon" type="apple" />
                    <div className="download-app-modal-content-center-link-right-content-desc">
                      <span>{t('layout.downloadPlugin.link')}</span>
                      <span>{t('layout.downloadPlugin.appStore')}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="download-app-modal-content-left">
            <div className="download-app-modal-content-left-title">
              <CustomIcon type="android" />
              {t('layout.downloadPlugin.androidApp')}
            </div>
            <Flex className="download-app-modal-content-left-desc">
              <CustomIcon className="check-mark-icon" type="checkMark" />
              <div className="download-app-modal-content-left-desc-text">
                {t('layout.downloadPlugin.accessAI')}
              </div>
            </Flex>
            <Flex className="download-app-modal-content-left-desc">
              <CustomIcon className="check-mark-icon" type="checkMark" />
              <div className="download-app-modal-content-left-desc-text">
                {t('layout.downloadPlugin.professional')}
              </div>
            </Flex>
            <div className="download-app-modal-content-left-link">
              <div className="download-app-modal-content-left-link-left"></div>
              <div className="download-app-modal-content-left-link-right">
                <div className="download-app-modal-content-left-link-right-qr-code" />
                <div className="download-app-modal-content-left-link-right-content">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.popai.chat"
                    target="_blank"
                  >
                    <CustomIcon className="apple-icon" type="googlePlay" />
                    <div className="download-app-modal-content-left-link-right-content-desc">
                      <span>{t('layout.downloadPlugin.link')}</span>
                      <span>{t('layout.downloadPlugin.googlePlay')}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Flex>
      </div>
    </Modal>
  );
};

export default DownAppModal;
