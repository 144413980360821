import { PromptTemplate } from '@/type';
import { create } from 'zustand';

interface SendState {
  /**输入框输入的内容 */
  value: string;
  setValue: (payload: string) => void;
  /**输入框内部模版 */
  innerTpl: PromptTemplate;
  setInnerTpl: (payload: PromptTemplate) => void;
}

const useSendStore = create<SendState>((set) => ({
  value: '',
  setValue: (payload: string) => set({ value: payload }),
  innerTpl: {},
  setInnerTpl: (payload: PromptTemplate) => set({ innerTpl: payload }),
}));

export default useSendStore;
