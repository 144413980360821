/*
 * @Author: zuoyuxing001
 * @Date: 2024-01-02 15:35:58
 * @LastEditors: zuoyuxing001
 * @LastEditTime: 2024-01-05 14:47:34
 * @FilePath: /popai/src/i18n/index.ts
 * @Description:
 */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en/index.ts';

i18next
  ?.use(LanguageDetector)
  ?.use(initReactI18next)
  ?.init({
    // lng: window.navigator.language,
    // lng: 'en',
    debug: false,
    fallbackLng: 'en',
    resources: {
      en,
    },
    // saveMissing: true,
    // missingKeyHandler: (...args) => {
    //   console.log('missingKey', args)
    // }
    interpolation: { escapeValue: false },
  });

export default i18next;
