import { useUserStore } from '@/store';
import CustomIcon from '../CustomIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.less';
import { useEffect, useMemo } from 'react';
import { MEMBERSHIP_ID } from '@/common/config';
import { DiscountCouponInfo } from '@/type';
import { useDA, useGASendEvent } from '@/hooks';

interface UpgradeButtonProps {
  freeTrial: number;
  onUpgrade: () => void;
}

// 是否有活动，只要有一个折扣率大于0就有活动
export function hasCouponActivity(discountInfo: DiscountCouponInfo | null) {
  if (!discountInfo) return false;
  return (
    (discountInfo?.monthlyDiscount && discountInfo?.monthlyDiscount > 0) ||
    (discountInfo?.yearlyDiscount && discountInfo?.yearlyDiscount > 0)
  );
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({ freeTrial, onUpgrade }) => {
  const { t } = useTranslation();

  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();

  const { userInfo, discountInfo } = useUserStore();
  // 是否有活动
  const hasActivity = hasCouponActivity(discountInfo);

  // 如果有活动，年unlimited和free trial期间用户，不显示
  // 如果没活动，非pro用户，显示
  const shouldUpgradeButtonShow = useMemo(() => {
    // 是否是年unlimited用户
    const isYearUnlimited =
      userInfo.isUnlimited && userInfo.membershipId === MEMBERSHIP_ID.YearUnlimited;
    // 是否是free trial用户，todo：用parsedRoles判断还是用membershipId判断
    const isFreeTrial = userInfo?.parsedRoles?.includes('Trail');
    // 是否是pro用户
    const isPro = userInfo.isPro;

    return (!hasActivity && !isPro) || (hasActivity && !isYearUnlimited && !isFreeTrial);
  }, [
    hasActivity,
    userInfo.isPro,
    userInfo.isUnlimited,
    userInfo.membershipId,
    userInfo?.parsedRoles,
  ]);

  useEffect(() => {
    if (shouldUpgradeButtonShow) {
      sendEvent('promotion_show');
    }
  }, [shouldUpgradeButtonShow]);

  // 如果是pro用户，就不显示升级按钮
  if (shouldUpgradeButtonShow) {
    // 显示年折扣率和月折扣率中的最大值
    const discount =
      Math.max(discountInfo?.monthlyDiscount || 0, discountInfo?.yearlyDiscount || 0) * 100;

    return (
      <div
        onClick={() => {
          onUpgrade();
          sendEvent('promotion_click');
          sendDAEvent('SidebarButtonClick', {
            button_name: 'upgrade_to_pro',
            sidebar_status: 'open',
          });
        }}
        className={classNames({
          'upgrade-pro-btn-container': true,
          'discount-active': hasActivity,
        })}
        style={{
          // 如果有discount，就显示discount的背景图
          backgroundImage: hasActivity ? `url(${discountInfo?.url})` : 'none',
        }}
      >
        {hasActivity && (
          <div className="discount-info">
            <div className="icon">🎁</div>
            <div>
              <div className="discount-title">
                {`${discountInfo?.desc} `}
                <span>{discount}% OFF !</span>
              </div>
              <div className="discount-sub">{discountInfo?.period}</div>
            </div>
          </div>
        )}
        {/* {discountInfo?.discount && (
          <div className="discount-tag">New Year {discountInfo?.discount * 100}% off</div>
        )} */}
        {freeTrial ? (
          <button className="free-trial-btn">
            <CustomIcon type="rocket" className="isRtlIcon_language_ar" />
            {t('layout.sideMenu.freeTrialLabel')}
          </button>
        ) : (
          <button className="upgrade-pro-btn">
            {hasActivity ? (
              <div className="discount-button">
                <CustomIcon type="rocket" className="isRtlIcon_language_ar" />
                {userInfo.isPro
                  ? t('layout.sideMenu.upgradeInActivityLabel')
                  : t('layout.sideMenu.upgradeToProLabel')}
              </div>
            ) : (
              t('layout.sideMenu.upgradeToProLabel')
            )}
          </button>
        )}
      </div>
    );
  }

  return null;
};

export default UpgradeButton;
