import uploadFile from '@/common/uploadFile';
import { chatCreateUsingPOST } from '@/services';
import type { ResponseType } from '@/type';
import { UploadFile } from 'antd/lib/upload/interface';
import { create } from 'zustand';
import { UPLOAD_STATUS } from '@/store/sendUpload';
import { getFileType } from '@/common/helpers/fileHelper';

/**上传文件后解析出的文件主题topic，文件总页数，文件语种 */
interface FileExtraInfo {
  /**推断文件的topic */
  topic: string;
  /**文件总页数 */
  page_count: number;
  /**文件语种 */
  language: string; // 枚举？
}

interface EnhanceUploadState {
  /**上传状态 */
  uploadStatus: UPLOAD_STATUS;
  /**设置上传状态 */
  setUploadStatus: (payload: UPLOAD_STATUS) => void;
  /**
   * 上传进度
   */
  uploadProgress: number;
  /**
   * 设置上传进度
   */
  setUploadProgress: (payload: number) => void;
  /**
   * 上传错误
   */
  uploadError: string;
  /**
   * 设置上传错误
   */
  setUploadError: (payload: string) => void;
  /**
   * 取消上传
   */
  cancelUpload: () => void;

  /**
   * 上传的文件，用于重新上传
   */
  file: UploadFile | null;
  /**
   * 设置上传的文件
   */
  setFile: (payload: UploadFile | null) => void;
  /**
   * 重置各种状态
   */
  reset: () => void;

  // 写入某些参数
  setFileParams: (key: string, type?: string) => void;

  /**
   * 假进度条的定时器
   */
  increaseTimer: number | null;
  /**
   * 通过url上传时，定时增加进度条（等接口响应）
   */
  increaseProgressTo99: () => void;
  uploadPrepare: (file: UploadFile) => void;
  upload: (file: UploadFile) => Promise<string | undefined>;
  /**重新上传 */
  // redo: () => void;
  /**解析文件 */
  parsingFile: (key: string) => Promise<void>;
  /**
   * 文件额外信息
   */
  fileExtraInfo: FileExtraInfo | null;
  setFileExtraInfo: (payload: FileExtraInfo) => void;
  md5: string;
  /**文件类型，ppt或者pdf */
  fileType: string | undefined;
}

let uploadCurl: AbortController | null = null;
// 文档解析使用的模型
const PARSE_FILE_MODEL = 'Standard';

const useEnhanceUploadStore = create<EnhanceUploadState>((set, get) => ({
  uploadStatus: UPLOAD_STATUS.BeforeUpload,
  fileExtraInfo: null,
  setFileExtraInfo: (payload: FileExtraInfo) => set({ fileExtraInfo: payload }),
  setUploadStatus: (payload: UPLOAD_STATUS) => set({ uploadStatus: payload }),
  uploadProgress: 0,
  // 上传文件时，先做个假进度条，真实进度超过假进度时，停止假进度
  setUploadProgress: (payload: number) =>
    set((state) => {
      // pm不要100%
      payload = payload === 100 ? 99 : payload;
      // payload是真进度，state.uploadProgress可能是真或假
      if (payload > state.uploadProgress) {
        // 如果timer存在，说明是假进度，清除timer
        if (state.increaseTimer) {
          clearInterval(state.increaseTimer);
          return { uploadProgress: payload, increaseTimer: null };
        }
        return { uploadProgress: payload };
      }
      return state;
    }),
  uploadError: '',
  md5: '',
  fileType: undefined,
  setUploadError: (payload: string) =>
    set({ uploadError: payload, uploadStatus: UPLOAD_STATUS.Failed, uploadProgress: 0 }),
  cancelUpload: () =>
    set({ uploadStatus: UPLOAD_STATUS.BeforeUpload, uploadProgress: 0, uploadError: '' }),
  file: null,
  setFile: (payload: UploadFile | null) => set({ file: payload }),
  reset: () =>
    set((state) => {
      // 重置时清除定时器
      if (state.increaseTimer) {
        clearInterval(state.increaseTimer);
      }
      uploadCurl?.abort();
      uploadCurl = null;
      return {
        uploadStatus: UPLOAD_STATUS.BeforeUpload,
        uploadProgress: 0,
        uploadError: '',
        file: null,
        increaseTimer: null,
        fileExtraInfo: null,
      };
    }),
  increaseTimer: null,
  increaseProgressTo99: () => {
    const timer = window.setInterval(() => {
      set((state) => {
        if (state.uploadProgress < 99) {
          return { uploadProgress: state.uploadProgress + 1 };
        } else {
          clearInterval(timer);
          return { uploadProgress: 99 };
        }
      });
    }, 100);
    set({ increaseTimer: timer });
  },
  uploadPrepare: (file: UploadFile) => {
    // 开始前先清空之前的状态
    get().reset();
    get().setUploadStatus(UPLOAD_STATUS.Uploading);
    // 假进度条，避免卡在0%
    get().increaseProgressTo99();
    // 保留文件，用于上传失败时重新上传
    if (file) {
      get().setFile(file);
    }
  },
  upload: async (file: UploadFile) => {
    const { uploadPrepare, setUploadStatus, setUploadError } = get();
    try {
      if (file) {
        const file_type = getFileType(file.type);
        set({
          fileType: file_type,
        });
        uploadCurl = new AbortController();
        uploadPrepare(file);
        const res = await uploadFile(file as unknown as File);
        if (res?.fields.key) {
          set({ md5: res?.fields.key });
        }
        return res?.fields?.key ?? '';
      }
    } catch (error: any) {
      const errMsg = error?.response?.data?.message || error?.data?.message || error?.message;
      setUploadStatus(UPLOAD_STATUS.Failed);
      setUploadError(errMsg);
    }
  },

  // 写入md5，配合别的文件一起使用
  setFileParams: (key: string, fileType?: string) => {
    const args: any = {
      md5: key,
    };
    if (fileType) {
      args.fileType = fileType;
    }
    set({
      ...args,
    });
  },
  /**
   * 解析文档topic
   */
  parsingFile: async (key: string) => {
    const { setUploadStatus, file, setUploadError } = get();
    try {
      setUploadStatus(UPLOAD_STATUS.Parsing);
      const params = {
        model: PARSE_FILE_MODEL,
        md5: key,
        fileName: file?.name,
        fileContentType: file?.type,
        fromPpt: true,
      };
      const createRes = await chatCreateUsingPOST<ResponseType<FileExtraInfo>>(params);
      const {
        data: { topic, language, page_count },
      } = createRes;
      set({
        fileExtraInfo: {
          topic,
          language,
          page_count,
        },
      });
      setUploadStatus(UPLOAD_STATUS.Success);
    } catch (error: any) {
      const errMsg = error?.response?.data?.message || error?.data?.message || error?.message;
      setUploadStatus(UPLOAD_STATUS.Failed);
      setUploadError(errMsg);
      throw new Error('parse failed');
    }
  },
  // redo: async () => {
  //   const { file, upload, parsingFile } = get();
  //   if (file) {
  //     const md5 = await upload(file);
  //     if (md5) {
  //       await parsingFile(md5);
  //     }
  //   }
  // }
}));

export default useEnhanceUploadStore;
