import { Node } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { Select, message } from 'antd';
import { createRoot } from 'react-dom/client';
import { CodeBlockSettings } from './types';
import CustomIcon from '@/components/CustomIcon';
import i18n from '@/i18n';

const { Option } = Select;

export const defaultCreateSelect = (
  settings: CodeBlockSettings,
  dom: HTMLElement,
  node: Node,
  view: EditorView,
  getPos: (() => number) | boolean,
) => {
  const { languageLoaders } = settings;

  const header = document.createElement('div');
  header.className = 'code-header';

  const handleLanguageChange = async (value: string) => {
    if (typeof getPos === 'function') {
      view.dispatch(
        view.state.tr.setNodeMarkup(getPos(), undefined, {
          ...node.attrs,
          lang: value,
        }),
      );
    }
  };

  const handleCopy = () => {
    if (typeof getPos === 'function') {
      const currentNode = view.state.doc.nodeAt(getPos());
      if (currentNode) {
        navigator.clipboard.writeText(currentNode.textContent).then(() => {
          message.success(i18n.t('common.copySuccess'));
        });
      }
    }
  };

  const selectComponent = (
    <Select
      className="language-select"
      popupClassName="language-select-option"
      defaultValue={node.attrs.lang}
      suffixIcon={
        location.pathname.includes('share.html') ? null : (
          <CustomIcon className="ant-select-suffix" type="popai-down" />
        )
      }
      getPopupContainer={() => document.querySelector('.write-content') as HTMLElement}
      onChange={handleLanguageChange}
    >
      {Object.keys(languageLoaders ?? {})
        .sort()
        .filter((lang) => !settings.languageWhitelist || settings.languageWhitelist.includes(lang))
        .map((lang) => (
          <Option key={lang} value={lang}>
            {settings.languageNameMap?.[lang] || lang}
          </Option>
        ))}
    </Select>
  );

  const copyButton = (
    <span className="copy-btn" onClick={handleCopy}>
      <CustomIcon className="copy-icon" type="copy" />
      <span>{i18n.t('components.markdown.copyCode')}</span>
    </span>
  );

  const root = createRoot(header);
  root.render(
    <>
      {selectComponent}
      {copyButton}
    </>,
  );
  dom.prepend(header);

  return () => {
    root.unmount();
  };
};

const defaultUpdateSelect = (
  _settings: CodeBlockSettings,
  dom: HTMLElement,
  node: Node,
  _view: EditorView,
  _getPos: (() => number) | boolean,
  oldNode: Node,
) => {
  if (oldNode.attrs.lang !== node.attrs.lang) {
    const select = dom.querySelector('.codeblock-select');
    if (!(select instanceof HTMLSelectElement)) return;
    select.value = node.attrs.lang || 'javascript';
  }
};

const defaultStopEvent = () => true;

export const defaultSettings: CodeBlockSettings = {
  createSelect: defaultCreateSelect,
  updateSelect: defaultUpdateSelect,
  stopEvent: defaultStopEvent,
  readOnly: location.pathname.includes('share.html'),
};
