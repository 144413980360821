import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { STORE_KEY } from '@/common/config';

interface DebugState {
  debug: boolean;
  setDebug: (payload: boolean) => void;
}

const useDebugStore = create<
  DebugState,
  [['zustand/devtools', never], ['zustand/persist', Partial<DebugState>]]
>(
  devtools(
    persist(
      (set) => ({
        debug: false,
        setDebug: (payload: boolean) => set(() => ({ debug: payload })),
      }),
      {
        name: STORE_KEY.Debug,
        storage: createJSONStorage(() => localStorage),
        partialize: ({
          debug,
        }: DebugState) => ({
          debug,
        }),
      },
    ),
  ),
);

export default useDebugStore;
