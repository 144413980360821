import { Plugin, PluginKey } from 'prosemirror-state';

import { CodeBlockSettings, LanguageLoaders } from './types';
import { CodeMirrorNodeView } from './CodeMirrorNodeView';
import { CodeBlockNodeName, codeBlockKeymap } from './utils';
import { CodeBlockLanguages, LegacyLanguages } from './languages';
import { defaultSettings } from './defaults';
import languageLoaders, { legacyLanguageLoaders } from './languageLoaders';

export const codeMirrorBlockKey = new PluginKey('codemirror-block');

const codeMirrorBlockPlugin = (settings: CodeBlockSettings) =>
  new Plugin({
    key: codeMirrorBlockKey,
    props: {
      nodeViews: {
        [settings.codeBlockName || 'code_block']: (pmNode, view, getPos) => {
          const resolvedGetPos = getPos as () => number;
          return CodeMirrorNodeView(settings)(pmNode, view, resolvedGetPos);
        },
      },
    },
  });

export default codeMirrorBlockPlugin;

export {
  CodeBlockNodeName,
  CodeMirrorNodeView,
  // codeBlockArrowHandlers,
  codeMirrorBlockPlugin,
  type CodeBlockSettings,
  type LanguageLoaders,
  CodeBlockLanguages,
  LegacyLanguages,
  defaultSettings,
  languageLoaders,
  legacyLanguageLoaders,
  codeBlockKeymap,
};
