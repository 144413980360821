/*
 * @Author: zuoyuxing001
 * @Date: 2023-12-18 19:32:34
 * @LastEditors: zuoyuxing001
 * @LastEditTime: 2024-01-02 22:22:55
 * @FilePath: /popai/src/components/Markdown/PreElement.tsx
 * @Description:
 */
import { useState, useRef, useLayoutEffect, lazy, Suspense } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';
import CustomIcon from '@/components/CustomIcon';
import { useTranslation } from 'react-i18next';
// import withSuspense from '@/hoc/LazySuspense';

const Mermaid = lazy(() => import('./Mermaid'));

// import Mermaid from './Mermaid';

import './index.less';

interface PreElementProps {
  processing?: boolean;
  children: React.ReactNode;
  onCopied?: (copy: string) => void;
}

const PreElement: React.FC<PreElementProps> = (props) => {
  const { processing, children, onCopied } = props;

  const [language, setLanguage] = useState('');
  const [copyText, setCopyText] = useState('');
  const { t } = useTranslation();
  const codeRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (codeRef?.current) {
      const codeClassName = codeRef?.current?.querySelectorAll('code')?.[0]?.className;
      const languageValue = codeClassName?.match(/language-(\w+)/)?.[1] || '';
      const copyTextValue = codeRef?.current?.innerText;

      if (language !== languageValue) {
        setLanguage(languageValue);
      }

      if (copyText !== copyTextValue) {
        setCopyText(copyTextValue);
      }
    }
  }, [codeRef?.current]);

  return (
    <>
      {language === 'mermaid' && (
        <Suspense>
          <Mermaid processing={processing} text={copyText} />
        </Suspense>
      )}
      <pre
        className={classNames({
          'code-wrapper': true,
          'code-wrapper-show': language !== 'mermaid',
        })}
      >
        <div className="code-header">
          <span>{language}</span>
          <CopyToClipboard text={copyText} onCopy={onCopied}>
            <span className="copy-btn">
              <CustomIcon className="copy-icon" type="copy" />
              <span>{t('components.markdown.copyCode')}</span>
            </span>
          </CopyToClipboard>
        </div>
        <div ref={codeRef}>{children}</div>
      </pre>
    </>
  );
};

export default PreElement;
