import { schema as BasicSchema } from 'prosemirror-schema-basic';
import { Schema } from 'prosemirror-model';
import {
  codeMirrorBlockPlugin,
  defaultSettings,
  languageLoaders,
  // codeBlockKeymap,
  legacyLanguageLoaders,
  CodeBlockNodeName,
} from '.';
import { undo, redo } from 'prosemirror-history';

const codeBlockSpec = BasicSchema.spec.nodes.get(CodeBlockNodeName);

export const schema = new Schema({
  nodes: BasicSchema.spec.nodes.update(CodeBlockNodeName, {
    ...(codeBlockSpec || {}),
    attrs: { ...codeBlockSpec?.attrs, lang: { default: null } },
  }),
  marks: BasicSchema.spec.marks,
});

export const codemirrorPlugin = codeMirrorBlockPlugin({
  ...defaultSettings,
  languageLoaders: { ...languageLoaders, ...legacyLanguageLoaders },
  undo,
  redo,
});
