import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { STORE_KEY } from '@/common/config';

interface CreationState {
  previewKey: string;
  setPreviewKey: (payload: string) => void;
}

const useCreationStore = create<
  CreationState,
  [['zustand/devtools', never], ['zustand/persist', Partial<CreationState>]]
>(
  devtools(
    persist(
      (set) => ({
        previewKey: '',
        setPreviewKey: (payload: string) => set(() => ({ previewKey: payload })),
      }),
      {
        name: STORE_KEY.Creation,
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);

export default useCreationStore;
