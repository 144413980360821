import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { isEmpty } from 'lodash-es';
import { STORE_KEY } from '@/common/config';
import { langConf, getLangList } from '@/services/common';
import { replaceENUMInterpolation } from '@/i18n/en';

import type { LangType, LangConf, KvObj } from '@/hooks/useI18n';
import type { kvMap } from '@/type';

interface LanguageStates {
  langCached: boolean;
  langTypeList: LangType[];
  langResource: { [key in LangType]: KvObj };
}

interface FlatLangMap {
  [key: string]: string;
}

const parseServerLang = (lang: { [key: string]: LangConf[] }) => {
  const flatLangMap: FlatLangMap = Object.keys(lang).reduce((acc, k1: string) => {
    const parts: FlatLangMap = lang[k1].reduce((partsAcc, cur: LangConf) => {
      const { key, value, calibration } = cur;
      return { ...partsAcc, [key]: calibration || value }; // 人工修改值优先
    }, {});
    return { ...acc, ...parts };
  }, {});

  const result: KvObj = {};
  Object.keys(flatLangMap).map((key) => {
    const keys = key.split('.');
    const value = flatLangMap[key];
    let obj = result;
    keys.map((k, i) => {
      const isLast = i === keys.length - 1;
      if (!obj[k]) {
        obj[k] = {};
      }
      if (typeof obj[k] === 'string') {
        return
      }
      if (isLast) {
        obj[k] = value;
      } else {
        obj = obj[k] as KvObj;
      }
    });
  });
  // parse ENUM, see: src/i18n/en/index.ts#L59
  if (result.ENUM) {
    return Object.assign(result, {
      ENUM: replaceENUMInterpolation(result.ENUM as kvMap<string>),
    });
  }
  return result;
};

interface LanguageState extends LanguageStates {
  setLangCached: () => void;
  setLangTypeList: () => Promise<LangType[] | null>;
  setLangResource: (langType: LangType) => Promise<KvObj | null>;
}

const initStates: LanguageStates = {
  langCached: false,
  langTypeList: [],
  langResource: {} as LanguageStates['langResource'],
};
const useLanguageStore = create<
  LanguageState,
  [['zustand/devtools', never], ['zustand/persist', Partial<LanguageState>]]
>(
  devtools(
    persist(
      (set) => ({
        ...initStates,
        setLangCached: () => {
          set(() => ({
            langCached: true,
          }));
        },
        setLangTypeList: async () => {
          try {
            const res = await getLangList<{ data: LangType[] }>();
            if (res.data) {
              set(() => ({
                langTypeList: res.data,
              }));
              return res.data;
            }
          } catch {
            console.log('load langs conf failed');
          }
          return null;
        },
        setLangResource: async (langType: LangType) => {
          try {
            const res = await langConf<{
              data: {
                [key: string]: LangConf[];
              };
            }>({ locale: langType });
            const langs = parseServerLang(res.data);
            if (langs && !isEmpty(langs)) {
              set((state) => ({
                langResource: {
                  ...state.langResource,
                  [langType]: langs,
                },
              }));
              return langs;
            }
          } catch (e) {
            console.log('fetch lang error', e);
          }
          return null;
        },
      }),
      {
        name: STORE_KEY.Language,
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);

export default useLanguageStore;
