import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tiptap/react';
import { App, Input, Popover, Skeleton, Space } from 'antd';
import CustomIcon from '@/components/CustomIcon';
import { useChatStore, useUserStore, useWriteStore } from '@/store';
import { EventStreamContentType, fetchEventSource } from '@fortaine/fetch-event-source';
import { getBaseUrl, getRiskControlToken, replaceEmptyMarkdownCells } from '@/common/utils';
import {
  CUSTOM_RESPONSE_HEADER,
  RESPONSE_DATA_CODE,
  STORE_KEY,
  WRITE_TEMPLATE_ID,
} from '@/common/config';
import i18next from 'i18next';
import { getGrecaptchaToken } from '@/common/grecaptcha';
import { AxiosRequestHeaders } from 'axios';
import { cloneDeep, isEmpty } from 'lodash-es';
import { useSize, useToggle } from 'ahooks';
import LanguageSelect from '@/components/LanguageSelect';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { useDA, useGASendEvent, useFetchUsagesInfo } from '@/hooks';
import { useGlobalModal } from '@/layout/BasicLayout';
import Markdown from '@/components/Markdown';
import { requestTurnstileToken } from '@/common/turnstile';
import ModelSelect from '@/components/ModelSelect';
import ToastContent from '@/components/ToastContent';
import { SpaceCommand } from '../../extensions/Command/SpaceCommand';
import { AdvanceConfig, Language, MessageItem } from '@/type';
import { useAutoHook } from './useAutoHook';
import SwitchRag from '@/components/SwitchRag';
import { useSelectModel } from '@/components/SendControllerView/hooks';
import { gpt4oName, proChatModelName } from '@/common/model';

import './index.less';

interface AskAIMenuProps {
  editor: Editor | null;
  command?: () => void;
  popup?: any;
}

export interface WritingSendParams {
  writingDocId?: string;
  writingDocPromptTemplateId?: WRITE_TEMPLATE_ID;
  message?: string;
  writingSelectionText?: string;
  translateLanguage?: string | null;
  'Cf-Captcha-Token'?: string;
  model?: string;
  searchSwitch?: boolean;
  advanceConfig?: AdvanceConfig;
}

interface SettingItem {
  key: string;
  label: string | React.ReactElement;
  icon?: React.ReactElement;
  onClick?: () => void;
  type?: string;
}

const TextArea = Input.TextArea;
const { VITE_APP_APP_NAME } = import.meta.env;

const AskAIMenu: React.FC<AskAIMenuProps> = (props) => {
  const { editor, command, popup } = props;

  const { t } = useTranslation();
  const { sendDAEvent } = useDA();
  const { sendEvent } = useGASendEvent();
  const { checkPayStatus } = useGlobalModal();
  const { message } = App.useApp();

  const size = useSize(document.querySelector('.write-container .chat-input'));

  const { userInfo } = useUserStore();
  const { fetchUsagesInfo } = useFetchUsagesInfo();
  const {
    showAskAI,
    setShowAskAI,
    askAIMessage,
    setAskAIMessage,
    docId,
    docTitle,
    channelId,
    createdAt,
  } = useWriteStore();
  const { ocrLanguage } = useChatStore();

  const [div, setDiv] = useState<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [translateLanguage, setTranslateLanguage] = useState<string | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<WRITE_TEMPLATE_ID>(
    WRITE_TEMPLATE_ID.WritingAskSelection,
  );
  const [isComposition, setIsComposition] = useState(false);
  const [searchSwitch, { toggle: toggleSwitchRag }] = useToggle(true);

  const { currentModel, modelOptions, handleOutputModelSelect } = useSelectModel({
    switchChecked: searchSwitch,
  });

  const askAIFininsh = !showLoading && askAIMessage?.content;
  const showLoadingOrHasContent = showLoading || askAIMessage?.content;

  const ctrlRef = useRef<AbortController | null>(null);
  const textAreaRef = useRef<TextAreaRef>(null);
  const languageWrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const isTextSelected = editor?.state?.selection?.from !== editor?.state?.selection?.to;

  const scrollBottom = () => {
    document.querySelector('.ProseMirror')?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  const handleTextReplace = () => {
    command?.();
    if (editor) {
      editor
        .chain()
        .focus()
        .deleteSelection()
        .insertContent(askAIMessage.content as string)
        .run();
    }
    handleDiscard();
  };

  const handleTextInsert = () => {
    command?.();
    let isAtEndOfDoc = false;
    if (editor && askAIMessage?.content) {
      const endOfSelection = editor.state.selection.to;
      const currentBlockEnd = editor.state.doc.resolve(endOfSelection).end();
      isAtEndOfDoc = currentBlockEnd === editor.state.doc.content.size;

      const insertPosition =
        command || editor?.isEmpty || isAtEndOfDoc ? endOfSelection : currentBlockEnd + 1;

      editor
        .chain()
        .focus()
        .insertContentAt(insertPosition, replaceEmptyMarkdownCells(askAIMessage.content))
        .run();
    }
    handleDiscard();
    if (isAtEndOfDoc) {
      scrollBottom();
    }
  };

  const handleOpenChange = (open: boolean) => {
    if (showLoading || askAIMessage?.content) {
      setShowAskAI(true);
    } else {
      if (!open) {
        handleDiscard();
      }
      setShowAskAI(open);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event?.target?.value;
    setInputValue(value);
  };

  const handlekeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event?.code === 'Backspace' && (event?.target as HTMLTextAreaElement)?.value === '') {
      handleDiscard();
    }
  };

  const handleStreamMsg = ({ message }: { message: MessageItem }) => {
    setAskAIMessage((prev: MessageItem) => {
      const prevClone = cloneDeep(prev);
      const chunkId = message.chunkId;
      const tail = isEmpty(prevClone)
        ? {
            chunkId: '',
            content: '',
          }
        : prevClone;
      if (tail?.chunkId !== chunkId) {
        tail.content += message.content ?? '';
      }
      tail.chunkId = chunkId;
      return tail;
    });
  };

  const handleRegenerate = async () => {
    const message = inputValue;
    setAskAIMessage({});
    setShowLoading(true);

    await eventSourceFunc({
      message,
      writingSelectionText: getSelectedText(),
      writingDocPromptTemplateId: selectedTemplate,
      translateLanguage: translateLanguage || null,
    });
  };

  /** 自动化调用该流程 */
  const handleAutoSend = async (params: WritingSendParams) => {
    setInputValue(params?.message as string);
    setAskAIMessage({});
    setShowLoading(true);
    setSelectedTemplate(params?.writingDocPromptTemplateId as number);
    await eventSourceFunc({
      ...params,
      writingSelectionText: getSelectedText(),
    });
  };

  const handleDiscard = () => {
    ctrlRef?.current?.abort();
    setShowAskAI(false);
    setAskAIMessage({});
    setInputValue('');
    setTranslateLanguage(null);
    if (command) {
      popup?.[0].hide();
    }
    editor?.chain()?.focus()?.run();
    setShowLoading(false);
  };

  const eventSourceFunc = (params: WritingSendParams) => {
    return new Promise(async (resolve, reject) => {
      const ctrl = new AbortController();
      ctrlRef.current = ctrl;

      const jt = await getRiskControlToken();

      const baseUrl = getBaseUrl();
      const allParams = {
        channelId,
        model: currentModel,
        searchSwitch,
        ...params,
      };
      const chatPayload = {
        method: 'POST',
        body: JSON.stringify({
          isGetJson: true,
          version: '1.3.6',
          language: navigator.language,
          ...allParams,
        }),
        signal: ctrl.signal,
        headers: {
          Authorization: `${localStorage.getItem(STORE_KEY.Authorization)}`,
          'Content-Type': 'application/json',
          'x-popai-riskControlToken': jt as string,
          language: i18next.language,
          'App-Name': VITE_APP_APP_NAME,
          'Device-Info': JSON.stringify({
            web_id: window?.td?.getDistinctId(),
            baidu_id: localStorage.getItem('__bid_n'),
          }),
        } as Partial<AxiosRequestHeaders>,
        openWhenHidden: true,
      };
      // 免费、无限制用户需要获取grecaptcha token
      if (userInfo.isUnlimited || !userInfo.isPro) {
        const token = await getGrecaptchaToken();
        chatPayload.headers['gtoken'] = token;
        // 有Cf-Captcha-Token的情况下，塞到请求头中
        if (params['Cf-Captcha-Token']) {
          chatPayload.headers['Cf-Captcha-Token'] = params['Cf-Captcha-Token'];
        }
      }

      sendDAEvent('Chat_SendMessage', {
        createdAt: createdAt,
        model: currentModel,
        browserlanguage: navigator.language,
        chattype: 'Writing',
        content: params?.message,
        message_type: 'user_prompt',
        online_search_switch: searchSwitch,
      });

      // 发送前手动stop
      // if (!showLoading) {
      //   return;
      // }

      fetchEventSource(`${baseUrl}/api/v1/chat/send`, {
        ...chatPayload,
        async onopen(response) {
          if (
            !response.ok ||
            !response.headers.get('content-type')?.startsWith(EventStreamContentType) ||
            response?.status !== 200
          ) {
            throw response;
          }
        },
        onmessage(msg) {
          const text = msg.data;
          try {
            const lastChunk = JSON.parse(msg.data) as MessageItem[];
            if (lastChunk?.[0]?.error) {
              throw lastChunk?.[0]?.error;
            } else {
              if (lastChunk?.length === 1) {
                const chunks = lastChunk?.[0];
                handleStreamMsg({ message: chunks });
              }
            }
          } catch (err) {
            console.error('[Request] parse error', text, msg);
          }
        },
        onclose() {
          fetchUsagesInfo();
          setShowLoading(false);
          resolve(null);
        },
        onerror(err) {
          const headerData = err?.headers?.get(CUSTOM_RESPONSE_HEADER) || null;
          const data = JSON.parse(headerData);
          // 如果需要人机验证
          if (data?.code === RESPONSE_DATA_CODE.Turnstile) {
            // 请求人机验证token之后再次请求
            requestTurnstileToken().then((token) => {
              eventSourceFunc({
                ...params,
                'Cf-Captcha-Token': token as string,
              });
            });
            reject(err);
            throw err;
          }

          if (data?.code === RESPONSE_DATA_CODE.UsageExceedsLimit) {
            checkPayStatus?.({
              errorMsg: data.message,
              source: data?.source,
              shortMessage: data?.shortMessage,
            });
            setShowAskAI(false);
          }

          setShowLoading(false);
          reject(err);
          throw err;
        },
      });
    });
  };

  const getSelectedText = () => {
    if (!isTextSelected) {
      return '';
    }

    let selectedText = '';

    if (editor?.state?.selection) {
      const { from, to } = editor.state.selection;
      selectedText = editor.state.doc.textBetween(from, to);
    }
    return selectedText;
  };

  const handleSend = async () => {
    const message = inputValue;
    setAskAIMessage({});
    setShowLoading(true);
    forceUpdatePop();

    await eventSourceFunc({
      message,
      writingSelectionText: getSelectedText(),
      writingDocPromptTemplateId: WRITE_TEMPLATE_ID.WritingAskSelection,
    });

    sendDAEvent('writing_AskAiSendMessage', {
      model: currentModel,
      message_type: 'customize',
      outputlang: translateLanguage || ocrLanguage.languageEnglish,
      doc_id: docId,
      filename: docTitle,
    });
    sendEvent('WriteSelectAndAsk_UserInput', {
      docId,
    });
  };

  const handleSendByTemplate = async ({
    writingDocId,
    writingDocPromptTemplateId,
    translateLanguage,
  }: {
    writingDocId?: string;
    writingDocPromptTemplateId: number;
    translateLanguage?: string | null;
  }) => {
    setAskAIMessage({});
    setShowLoading(true);
    setInputValue('');
    setSelectedTemplate(writingDocPromptTemplateId);

    await eventSourceFunc({
      message: inputValue || '',
      writingDocId,
      writingDocPromptTemplateId,
      writingSelectionText: getSelectedText(),
      translateLanguage: translateLanguage || null,
    });
  };

  const handleOperation = (templateId: number, eventName: string, messageType: string) => {
    handleSendByTemplate({
      writingDocPromptTemplateId: templateId,
      writingDocId: isTextSelected ? undefined : docId,
    });
    sendEvent(eventName, {
      docId,
    });
    sendDAEvent('writing_AskAiSendMessage', {
      model: currentModel,
      message_type: messageType,
      outputlang: translateLanguage || ocrLanguage.languageEnglish,
      doc_id: docId,
      filename: docTitle,
    });
  };

  const handleStop = () => {
    ctrlRef?.current?.abort();
    setShowLoading(false);
    setInputValue('');
  };

  const handleModelSelect = (model: string) => {
    sendDAEvent('DocModelSwitchButtonClick', {
      model: model,
      from: currentModel,
    });
    handleOutputModelSelect(model);
  };

  const onPressEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    if (event.key === 'Enter' && event.shiftKey) {
      const textarea = event?.currentTarget;
      const selectionStart = textarea?.selectionStart;
      const selectionEnd = textarea?.selectionEnd;
      const newValue = `${textarea?.value?.slice(0, selectionStart)}${'\n'}${textarea?.value?.slice(
        selectionEnd,
      )}`;
      const newPos = selectionStart + 1;

      setInputValue(newValue);

      Promise.resolve().then(() => {
        textarea.setSelectionRange(newPos, newPos);

        if (newPos === newValue?.length) {
          textarea.scrollTo({
            top: textarea?.scrollHeight,
          });
        }
      });
    } else if (event.key === 'Enter' && !isComposition) {
      handleSend();
    }
  };

  const handleCopied = useCallback((copy: string) => {
    if (copy.length > 0) {
      message.open({
        content: <ToastContent icon="success" content={t('common.copySuccess')} />,
      });
    }
  }, []);

  const forceUpdatePop = () => {
    SpaceCommand?.storage?.popup?.[0]?.popperInstance?.update();
  };

  useEffect(() => {
    if (showAskAI || command) {
      setTimeout(() => {
        textAreaRef?.current?.focus({
          cursor: 'end',
        });
      }, 300);
    }
  }, [showAskAI, command]);

  useEffect(() => {
    if (showAskAI && editor) {
      const { selection } = editor.state;

      if (!selection.empty) {
        const end = selection.to;
        const { node } = editor.view.domAtPos(end);
        const nodeRect = (node as HTMLElement).getBoundingClientRect();

        const writeContent = document.querySelector('.write-content') as HTMLElement;
        const writeContentRect = writeContent.getBoundingClientRect();

        const relativeY = nodeRect.top - writeContentRect.top;

        const newDiv = document.createElement('div');
        newDiv.style.position = 'absolute';
        newDiv.style.top = `${relativeY + nodeRect.height}px`;

        document.body.appendChild(newDiv);

        setDiv(newDiv);

        return () => {
          document.body.removeChild(newDiv);
        };
      }
    }
  }, [showAskAI, editor]);

  useEffect(() => {
    if (askAIFininsh) {
      forceUpdatePop();
    }
  }, [askAIFininsh]);

  useEffect(() => {
    if (searchSwitch && currentModel === proChatModelName) {
      toggleSwitchRag();
    }
  }, [searchSwitch, currentModel]);

  useEffect(() => {
    return () => {
      handleDiscard();
    };
  }, []);

  const languageBlockRender = (title: string, languages: Language[]) => {
    return (
      <div className="language-group">
        <div className="language-group-title">{title}</div>
        {languages?.map((language) => (
          <div
            className="language-item"
            key={language?.languageEnglish}
            onClick={() => {
              setTranslateLanguage(language?.languageEnglish || null);
              handleSendByTemplate({
                writingDocPromptTemplateId: isTextSelected
                  ? WRITE_TEMPLATE_ID.WritingTranslateSelection
                  : WRITE_TEMPLATE_ID.WritingTranslateDoc,
                translateLanguage: language?.languageEnglish as string,
                writingDocId: isTextSelected ? undefined : docId,
              });

              sendDAEvent('writing_AskAiSendMessage', {
                model: currentModel,
                message_type: 'translate',
                outputlang: language?.languageEnglish || ocrLanguage.languageEnglish,
                doc_id: docId,
                filename: docTitle,
              });
              sendEvent('WriteSelectAndAsk_Translate', {
                docId,
              });
            }}
          >
            {language?.languageLabel}
          </div>
        ))}
      </div>
    );
  };

  const aiOperateItems: (SettingItem | boolean)[] = [
    {
      key: 'Generate form selection',
      label: isTextSelected
        ? t('components.write.generateFromSelection')
        : t('components.write.generateFromPage'),
      type: 'group',
    },
    {
      key: 'Summarize',
      icon: <CustomIcon type="askAISummary" />,
      label: t('components.write.summarize'),
      onClick: () =>
        handleOperation(
          isTextSelected
            ? WRITE_TEMPLATE_ID.WritingSummarizeSelection
            : WRITE_TEMPLATE_ID.WritingSummarizeDoc,
          'WriteSelectAndAsk_Summarize',
          'summarize',
        ),
    },
    {
      key: 'Translate',
      icon: <CustomIcon type="translate" />,
      label: (
        <div
          ref={languageWrapperRef}
          onClick={(event) => event?.stopPropagation()}
          className="select-translate-language"
        >
          <LanguageSelect
            overlayClassName="translate-popover"
            placement="right"
            arrow={false}
            autoAdjustOverflow={false}
            languageBlockRender={languageBlockRender}
            popupContainer={() => languageWrapperRef?.current as HTMLDivElement}
          >
            <div className="enrich-item">
              {t('pages.chat.translate')}
              <CustomIcon
                className={classNames({
                  'dropdown-icon': true,
                  isRtlIcon_language_ar: true,
                })}
                type="arrowRightSquare"
              />
            </div>
          </LanguageSelect>
        </div>
      ),
      onClick: handleSend,
    },
    isTextSelected && {
      key: 'Edit or review selection',
      label: t('components.write.editOrReviewSelection'),
      type: 'group',
    },
    isTextSelected && {
      key: 'Fix spelling & grammar',
      icon: <CustomIcon type="askAIFixSpell" />,
      label: t('components.write.fixSpell'),
      onClick: () =>
        handleOperation(
          WRITE_TEMPLATE_ID.WritingFixSpellingAndGrammar,
          'WriteSelectAndAsk_FixSpellingAndGrammar',
          'fix_spelling_and_grammr',
        ),
    },
    isTextSelected && {
      key: 'Make shorter',
      icon: <CustomIcon type="askAIShorter" />,
      label: t('components.write.shorter'),
      onClick: () =>
        handleOperation(
          WRITE_TEMPLATE_ID.WritingMakeShorter,
          'WriteSelectAndAsk_MakeShorter',
          'shorter',
        ),
    },
    isTextSelected && {
      key: 'Make longer',
      icon: <CustomIcon type="askAILonger" />,
      label: t('components.write.longer'),
      onClick: () =>
        handleOperation(
          WRITE_TEMPLATE_ID.WritingMakeLonger,
          'WriteSelectAndAsk_MakeLonger',
          'longer',
        ),
    },
  ].filter(Boolean);

  const labelRender = (item: SettingItem) => {
    const { key, icon, label, onClick, type } = item;
    if (type === 'group') {
      return (
        <div className="group-title" key={key}>
          {label}
        </div>
      );
    }
    return (
      <div className="label-container" key={key} onClick={onClick}>
        <span className="label-icon">{icon ? icon : null}</span>
        <span className="label-title">{label}</span>
      </div>
    );
  };

  const handleSwitchRag = useCallback(
    (checked: boolean) => {
      sendDAEvent('OnlineSearch_Switch', {
        channelid: channelId,
        is_on: !searchSwitch,
        chattype: 'Writing',
      });
      if (currentModel === proChatModelName && checked) {
        handleModelSelect(gpt4oName);
      }
      toggleSwitchRag();
    },
    [sendDAEvent, channelId, searchSwitch, currentModel, toggleSwitchRag, handleModelSelect],
  );

  const askAIRender = () => {
    return (
      <div
        className="websearch-container"
        ref={containerRef}
        style={{ width: size?.width ? size?.width - 120 : 400 }}
      >
        <div className="websearch-wrapper">
          <div className="websearch-content-wrapper">
            {!askAIFininsh && (
              <>
                <div className="websearch-input">
                  <div className="websearch-input-prefix">
                    <CustomIcon type="askAI" className="websearch-input-prefix-icon" />
                  </div>
                  {showLoading ? (
                    <div className="websearch-processing-placeholder">
                      {t('components.write.writingPlaceholder')}
                    </div>
                  ) : (
                    <TextArea
                      ref={textAreaRef}
                      placeholder={t('components.write.inputPlaceholder')}
                      variant="borderless"
                      autoSize={{ maxRows: 10 }}
                      value={inputValue}
                      onChange={handleInputChange}
                      onPressEnter={onPressEnter}
                      onKeyDown={handlekeyDown}
                      onCompositionStart={() => setIsComposition(true)}
                      onCompositionEnd={() => setIsComposition(false)}
                    />
                  )}
                </div>
                {showLoading && !askAIMessage?.content && (
                  <div className="websearch-processing">
                    <Skeleton
                      className="websearch-skeleton"
                      title={false}
                      paragraph={{ rows: 4 }}
                      loading
                      active
                    />
                  </div>
                )}
              </>
            )}
            {askAIMessage?.content && (
              <div className="websearch-result">
                <Markdown content={askAIMessage?.content} onCopied={handleCopied} />
              </div>
            )}
          </div>
          <div className="websearch-content-bottom">
            <div className="websearch-content-bottom-left">
              {!showLoadingOrHasContent && (
                <SwitchRag value={searchSwitch} onChange={handleSwitchRag} />
              )}
              {showLoading && (
                <div onClick={handleStop} className="stop-btn">
                  <CustomIcon type="popai-a-tingzhishengchan1" />
                  <span>{t('common.stopGenerating')}</span>
                </div>
              )}
              {askAIFininsh && (
                <Space size={12}>
                  {isTextSelected && (
                    <CustomIcon
                      tooltip={t('components.write.replaceSelection')}
                      type="askAIReplace"
                      onClick={handleTextReplace}
                    />
                  )}
                  <CustomIcon
                    tooltip={t('components.write.discard')}
                    type="popai-deleta"
                    onClick={handleDiscard}
                  />
                  <CustomIcon
                    tooltip={t('components.write.tryAgain')}
                    type="popai-zhongxinshengcheng"
                    onClick={handleRegenerate}
                  />
                </Space>
              )}
            </div>
            <div className="websearch-content-bottom-right">
              {!showLoadingOrHasContent && (
                <>
                  <ModelSelect
                    size="small"
                    value={currentModel}
                    selectStyle={{
                      minWidth: '92px',
                      height: '28px',
                      backgroundColor: '#fff',
                      borderRadius: 6,
                    }}
                    options={modelOptions}
                    onChange={handleModelSelect}
                    getPopupContainer={() => containerRef.current as HTMLDivElement}
                  />
                  <button
                    onClick={handleSend}
                    className={classNames({
                      'websearch-send-btn': true,
                      'websearch-send-disabled': !inputValue,
                    })}
                  >
                    <CustomIcon type="askAISend" />
                  </button>
                </>
              )}
              {showLoadingOrHasContent && (
                <button
                  onClick={handleTextInsert}
                  className={classNames({
                    'websearch-send-btn': true,
                    'websearch-send-disabled': showLoading,
                  })}
                >
                  <span className="btn-text">{t('components.write.insert')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
        {isTextSelected && !showLoadingOrHasContent && !inputValue && (
          <div className="ask-operate-container ">
            {aiOperateItems.map((item) => labelRender(item as SettingItem))}
          </div>
        )}
      </div>
    );
  };

  /** 自动化调起流程 */
  useAutoHook({
    handleAutoSend,
  });

  if (command) {
    return askAIRender();
  }

  return (
    <Popover
      destroyTooltipOnHide
      autoAdjustOverflow
      open={showAskAI}
      overlayStyle={{
        top: div?.style.top,
        left: '60px',
        bottom: 'unset',
        right: 'unset',
      }}
      overlayInnerStyle={{
        padding: '0px',
        boxShadow: 'none',
        background: 'transparent',
      }}
      arrow={false}
      content={askAIRender()}
      onOpenChange={handleOpenChange}
      trigger={['click']}
      getPopupContainer={() =>
        document.querySelector('.write-container .write-content') as HTMLElement
      }
    />
  );
};

export default AskAIMenu;
