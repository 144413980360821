import { useState } from 'react';
import { Form, Radio, Input, Button, Space } from 'antd';
import CustomIcon from '@/components/CustomIcon';
import { useGASendEvent } from '@/hooks';
import { useUserStore } from '@/store';
import type { ResponseType } from '@/type';
import { cancelSubscriptionPOST } from '@/services';
import { BillingDetail } from './interface';
import { useTranslation } from 'react-i18next';
import './index.less';

const { TextArea } = Input;

interface CancelQuestionaireProps {
  billingDetail: BillingDetail;
  handlePre: () => void;
  handleNext: () => void;
}

const QuestionaireId = 'billingCancelQuestionaire';
const FreetrialCancelQuestionaireId = 'freetrialCancelQuestionaire';

const CancelQuestionaire: React.FC<CancelQuestionaireProps> = (props) => {
  const { userInfo } = useUserStore();
  const { sendEvent } = useGASendEvent();
  const { t } = useTranslation();
  const [submitloading, setsubmitloading] = useState(false);
  const [form] = Form.useForm();
  const rules = [{ required: true, message: t('layout.billingManage.verticalRules') }];
  const isFreeTrial = userInfo?.parsedRoles?.includes('Trail');
  const onSubmit = async (values: Record<string, string>) => {
    sendEvent('Submitquestionnaire');
    if (isFreeTrial) {
      sendEvent('Submitfree-trialquestion');
    }
    try {
      setsubmitloading(true);

      await cancelSubscriptionPOST<ResponseType<null>>({
        feedback: JSON.stringify({
          id: isFreeTrial ? FreetrialCancelQuestionaireId : QuestionaireId,
          ...values,
        }),
        subscriptionId: props.billingDetail.subscriptionId,
      });
      setsubmitloading(false);
      props.handleNext();
    } catch (e) {
      console.error(e);
    }
  };

  const onBack = () => {
    sendEvent('Canclequestionnaire');
    if (isFreeTrial) {
      sendEvent('Canclefree-trialquestion');
    }
    props.handlePre();
  };

  return (
    <div className="billingCancelQuestionaire">
      <button onClick={onBack} className="billingCancelQuestionaire-leftArrow">
        <CustomIcon type="leftArrow" />
      </button>
      <div className="billingCancelQuestionaire-title">
        <span>
          {isFreeTrial
            ? t('layout.billingManage.freeTrailCancelQuestionaireTitle')
            : t('layout.billingManage.cancelQuestionaireTitle')}
        </span>
      </div>
      <div className="billingCancelQuestionaire-content">
        <div className="billingCancelQuestionaire-content-des">
          {t('layout.billingManage.cancelQuestionaireContent')}
        </div>
        <Form
          name={QuestionaireId}
          form={form}
          layout="vertical"
          className="billingCancelQuestionaire-content-form"
          requiredMark={false}
          scrollToFirstError
          onFinish={onSubmit}
        >
          <Form.Item label={t('layout.billingManage.Q1verticalLabel')} name="q1" rules={rules}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="a"> a.{t('layout.billingManage.Q1verticalA')}</Radio>
                <Radio value="b"> b.{t('layout.billingManage.Q1verticalB')}</Radio>
                <Radio value="c"> c.{t('layout.billingManage.Q1verticalC')}</Radio>
                <Radio value="d"> d.{t('layout.billingManage.Q1verticalD')}</Radio>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.q1 !== currentValues.q1}
                >
                  {({ getFieldValue }) => {
                    return getFieldValue('q1') === 'd' ? (
                      <Form.Item
                        name="q1-d"
                        className="billingCancelQuestionaire-content-form-innerItem"
                        rules={rules}
                      >
                        <TextArea placeholder={t('layout.billingManage.verticalPlaceholder')} />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Radio value="e"> e.{t('layout.billingManage.Q1verticalE')}</Radio>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.q1 !== currentValues.q1}
                >
                  {({ getFieldValue }) => {
                    return getFieldValue('q1') === 'e' ? (
                      <Form.Item
                        name="q1-e"
                        className="billingCancelQuestionaire-content-form-innerItem"
                        rules={rules}
                      >
                        <TextArea placeholder={t('layout.billingManage.verticalPlaceholder')} />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Radio value="f"> f.{t('layout.billingManage.Q1verticalF')}</Radio>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.q1 !== currentValues.q1}
                >
                  {({ getFieldValue }) => {
                    return getFieldValue('q1') === 'f' ? (
                      <Form.Item
                        name="q1-f"
                        className="billingCancelQuestionaire-content-form-innerItem"
                        rules={rules}
                      >
                        <TextArea placeholder={t('layout.billingManage.verticalPlaceholder')} />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Radio value="g"> g.{t('layout.billingManage.Q1verticalG')}</Radio>
                <Radio value="h"> h.{t('layout.billingManage.Q1verticalH')}</Radio>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.q1 !== currentValues.q1}
                >
                  {({ getFieldValue }) => {
                    return getFieldValue('q1') === 'h' ? (
                      <Form.Item
                        name="q1-h"
                        className="billingCancelQuestionaire-content-form-innerItem"
                        rules={rules}
                      >
                        <TextArea placeholder={t('layout.billingManage.verticalPlaceholder')} />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={t('layout.billingManage.Q2verticalLabel')} name="q2" rules={rules}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="a"> a.{t('layout.billingManage.Q2verticalA')}</Radio>
                <Radio value="b"> b.{t('layout.billingManage.Q2verticalB')}</Radio>
                <Radio value="c"> c.{t('layout.billingManage.Q2verticalC')}</Radio>
                <Radio value="d"> d.{t('layout.billingManage.Q2verticalD')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={t('layout.billingManage.Q3verticalLabel')} name="q3" rules={rules}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="a"> a.{t('layout.billingManage.Q3verticalA')}</Radio>
                <Radio value="b"> b.{t('layout.billingManage.Q3verticalB')}</Radio>
                <Radio value="c"> c.{t('layout.billingManage.Q3verticalC')}</Radio>
                <Radio value="d"> d.{t('layout.billingManage.Q3verticalD')}</Radio>
                <Radio value="e"> e.{t('layout.billingManage.Q3verticalE')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={t('layout.billingManage.Q4verticalLabel')} name="q4" rules={rules}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="a"> a.{t('layout.billingManage.Q4verticalA')}</Radio>
                <Radio value="b"> b.{t('layout.billingManage.Q4verticalB')}</Radio>
                <Radio value="c"> c.{t('layout.billingManage.Q4verticalC')}</Radio>
                <Radio value="d"> d.{t('layout.billingManage.Q4verticalD')}</Radio>
                <Radio value="e"> e.{t('layout.billingManage.Q4verticalE')}</Radio>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.q4 !== currentValues.q4}
                >
                  {({ getFieldValue }) => {
                    return getFieldValue('q4') === 'e' ? (
                      <Form.Item
                        name="q4-e"
                        className="billingCancelQuestionaire-content-form-innerItem"
                        rules={rules}
                      >
                        <TextArea placeholder={t('layout.billingManage.verticalPlaceholder')} />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={t('layout.billingManage.Q5verticalLabel')} name="q5" rules={rules}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="a"> a.{t('layout.billingManage.Q5verticalA')}</Radio>
                <Radio value="b"> b.{t('layout.billingManage.Q5verticalB')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={t('layout.billingManage.Q6verticalLabel')} name="q6" rules={rules}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="a"> a.{t('layout.billingManage.Q6verticalA')}</Radio>
                <Radio value="b"> b.{t('layout.billingManage.Q6verticalB')}</Radio>
                <Radio value="c"> c.{t('layout.billingManage.Q6verticalC')}</Radio>
                <Radio value="d"> d.{t('layout.billingManage.Q6verticalD')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={t('layout.billingManage.Q7verticalLabel')} name="q7" rules={rules}>
            <TextArea placeholder={t('layout.billingManage.verticalPlaceholder')} />
          </Form.Item>

          <Form.Item>
            <Button
              className="questionaire-btn"
              loading={submitloading}
              type="primary"
              htmlType="submit"
            >
              {t('common.submit')}
              <CustomIcon type="rightArrowPlus" />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CancelQuestionaire;
