import React, { useState } from 'react';
import { Modal } from 'antd';
import './index.less';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CustomIcon from '@/components/CustomIcon';
// import { useGASendEvent } from '@/hooks';
import ToggleLanauge from './ToggleLanguages';
import Account from './Account';
import ConnectorLink from './ConnectorLink';

interface SettingsMenuProps {
  open: boolean;
  onClose: (value: boolean) => void;
}

const listCheck = [
  {
    title: 'account',
    key: 'account',
    icon: 'account',
  },
  {
    title: 'languages',
    key: 'languages',
    icon: 'translateAr',
  },
  {
    title: 'connector',
    key: 'connector',
    icon: 'connector',
  },
];

const modalStyles = {
  header: {
    padding: '12px 24px 4px 24px',
  },
  content: {
    boxShadow: '0 0 30px #999',
    padding: 0,
  },
};

const SettingsMenu = React.memo<SettingsMenuProps>(({ open, onClose }) => {
  const [isCheck, setIsCheck] = useState('account'); // 左侧是否选中

  // const { sendEvent } = useGASendEvent();

  const { t } = useTranslation();

  const handleClick = (key: string) => {
    setIsCheck(key);
  };

  return (
    <>
      <Modal
        open={open}
        styles={modalStyles}
        centered
        title={t('layout.usersnap.setting')}
        footer={null}
        width={800}
        zIndex={2000}
        onCancel={() => {
          onClose(false);
        }}
        wrapClassName="setting-menu"
      >
        <div className="settingMenuBox">
          <div className="settingMenuLeftBox">
            {listCheck?.map((item) => (
              <div
                onClick={() => handleClick(item.key)}
                key={item.key}
                className={classNames({
                  settingItems: true,
                  settingCheckItems: isCheck === item.key,
                })}
              >
                <CustomIcon type={item.icon} />
                <span className="settingMenuLeftBoxTranslateIcon">
                  {t(`layout.settingModal.${item.title}`)}
                </span>
              </div>
            ))}
          </div>
          <div className="settingMenuRightBox">
            {isCheck === 'languages' && <ToggleLanauge />}
            {isCheck === 'account' && <Account />}
            {isCheck === 'connector' && <ConnectorLink />}
          </div>
        </div>
      </Modal>
    </>
  );
});

export default SettingsMenu;
