import type { QuoteInfoItem } from '@/type';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useChatStore, useCommonStore } from '@/store';
import { useDocChat } from './useDocQuote';
import { useDA } from '@/hooks';
import { isEmpty } from 'lodash-es';
import { getChatType } from '@/common/helpers/chatHelper';
import { useState } from 'react';
import { isUrlEndWithPdf } from '@/common/utils';
import './index.less';

interface Props {
  links: QuoteInfoItem[];
  messageId?: string;
}

const DocQuoteList = (props: Props) => {
  const { links, messageId } = props;

  const { t } = useTranslation();
  const { sendDAEvent } = useDA();
  const { showOneChatGUI, channel } = useChatStore();
  const { createDocChat, loadingStates, isArxivAbsLink } = useDocChat();
  const { model } = useCommonStore();
  const [currentLoading, setCurrentLoading] = useState('');

  const isPdfLink = (url: string) => {
    return isUrlEndWithPdf(url) || isArxivAbsLink(url);
  };

  const createChatHandler = async (url: string, index: number) => {
    setCurrentLoading(url);
    await createDocChat({
      model,
      url,
      extract_img_5k: true,
      // searchSwitch: true, // 上传doc进入chat默认关闭联网开关，等模型效果好转再打开
      channelType: 'ONE_CHAT',
    });
    setCurrentLoading('');
    sendDAEvent('AcademicSearchResultReadDocument_Click', {
      channelid: channel?.channelId,
      chattype: getChatType(channel),
      click_url: url,
      messageid: messageId,
      no: index + 1,
    });
  };

  const isExist = (data: any) => {
    return !isEmpty(data);
  };

  const handleClick = (link: any, index: number) => {
    sendDAEvent('AcademicSearchResult_Click', {
      channelid: channel?.channelId,
      chattype: getChatType(channel),
      click_url: link.url,
      messageid: messageId,
      no: index + 1,
    });
  };

  return (
    <div className="doc-quote-list">
      {links.map((link, index) => (
        <div key={index} className="doc-quote-list__item">
          <div className="doc-quote-list__content">
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleClick(link, index)}
            >
              {index + 1}, {link.title}
            </a>
            <div className="doc-quote-list__time">
              <span>{link.publishDate}</span>
            </div>
            {isExist(link.snippet) && (
              <div className="doc-quote-list__summary">
                <span>{t('pages.chat.summary')}：</span>
                <span>{link.snippet}</span>
              </div>
            )}
          </div>
          <div className="doc-quote-list__btn_container">
            {(link?.isPdf || isPdfLink(link.url)) && !location.pathname.includes('share') && (
              <Button
                loading={loadingStates[link.url]}
                className="doc-quote-list__btn"
                onClick={() => createChatHandler(link.url, index)}
                disabled={!!currentLoading && currentLoading !== link.url}
              >
                {!loadingStates[link.url] && (
                  <>
                    <i className="iconfont popai-wendang-xian"></i>
                    {isEmpty(showOneChatGUI) && (
                      <span className="doc-quote-list__btn-text">
                        {t('pages.chat.readDocument')}
                      </span>
                    )}
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DocQuoteList;
