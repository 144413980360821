/*
 * @Author: wubo
 * @Date: 2023-09-07 12:42:39
 * @Description:
 * @LastEditTime: 2024-01-02 21:10:58
 * @LastEditors: zuoyuxing001
 */
import { CSSProperties, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useDA, useGASendEvent, useSundry } from '@/hooks';
import {
  AB_EXPERIMENTS,
  TPL_TYPE,
  WORKSPACE_FILE_CATEGORY,
  WORKSPACE_ROUTE_TYPE,
} from '@/common/config';
import CustomIcon from '@/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import MenuItem from './MenuItem';
import { UserInfo, useWriteStore } from '@/store';
import CommandK from '@/assets/newHome/chat/commandK.svg';
import './index.less';
import { setTipsExistFlag } from '@/services';
import { Space } from 'antd';

interface SideMenuProps {
  collapsed?: boolean;
  userInfo?: UserInfo;
  checkLoginStatus?: (params: { type?: string }) => boolean;
  isFooter?: boolean; // 是否在分割线下方
  style?: CSSProperties; // 自定义样式
  className?: string;
}

export interface MenuItemType {
  key: string;
  path: string | null;
  label: string;
  icon: string;
  iconHover: string;
  iconActive: string;
}

const eventMap: { [key: string]: string } = {
  'ai-reader': 'ChatwithDoc',
  creation: 'CreateWithAi',
  image: 'ClickPanelAIImage',
  resentation: 'ClickPanelAIPresentation',
  'ai-writing': 'Sidebar_AIWriting',
};

const eventButtonNameMap: { [key: string]: string } = {
  'ai-reader': 'chat_with_document',
  resentation: 'ai_presentation',
  'ai-writing': 'ai_writing',
  workspace: 'workspace',
  more: 'more',
};

const eventDAMap: { [key: string]: { name: string; data: { [key: string]: unknown } } } = {
  'ai-writing': {
    name: 'EnterAiWritingMainPage',
    data: {
      forward_source: 'sidebar_ai_writing',
    },
  },
};

const SideMenu: React.FC<SideMenuProps> = (props) => {
  const { collapsed = false, userInfo, isFooter = false, className = '', style } = props;
  const { experiment } = useSundry();
  const memberShipFeature = experiment(AB_EXPERIMENTS.MEMBERSHIP);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { sendEvent } = useGASendEvent();
  const { sendDAEvent, preparePropertyFor } = useDA();
  // const pageLanguage = useCommonStore((state) => state.pageLanguage);
  const { tipsFlag, setTipsFlag } = useWriteStore();

  const createBtnLabel = useMemo(() => {
    return t('layout.sideMenu.createBtnLabel');
  }, [t]);

  const defaultMenu = useMemo(() => {
    return [
      {
        key: 'ai-reader',
        path: '/ai-reader',
        label: <Space>{t('layout.sideMenu.sideDocumentAB')}</Space>,
        icon: 'popai-wendang-xian',
        iconHover: 'popai-wendang-xian',
        iconActive: 'popai-wendang-xian',
      },
      {
        key: 'resentation',
        path: `/ai-presentation`,
        label: <Space>{t('layout.sideMenu.presentation')}</Space>,
        icon: 'popai-presentation',
        iconHover: 'popai-presentation',
        iconActive: 'popai-presentation',
      },
      {
        key: 'ai-writing',
        path: `/ai-writing`,
        label: t('components.homeCard.writingTitle'),
        icon: 'popai-a-AIwriting',
        iconHover: 'popai-a-AIwriting',
        iconActive: 'popai-a-AIwriting',
      },
      {
        key: 'more',
        path: `/more/${TPL_TYPE.All}/Flowchart`,
        label: t('common.more'),
        icon: 'popai-grid',
        iconHover: 'popai-grid',
        iconActive: 'popai-grid',
      },
    ];
  }, [memberShipFeature, t]);

  const footerMenu = useMemo(() => {
    return [
      {
        key: 'files',
        path: `/files/${WORKSPACE_ROUTE_TYPE.Uploaded}/${WORKSPACE_FILE_CATEGORY.All}`,
        label: t('layout.sideMenu.files'),
        icon: 'popai-a-workspace1',
        iconHover: 'popai-a-workspace1',
        iconActive: 'popai-a-workspace1',
      },
    ];
  }, [t]);

  const menus = useMemo(() => {
    return isFooter ? footerMenu : defaultMenu;
  }, [isFooter, footerMenu, defaultMenu]);

  const handleNewChat = () => {
    preparePropertyFor('RegistLoginModalExposure', 'forward_source', 'sidebar_new_chat_click');
    navigate(`/${location.search}`);
    sendEvent('Clicknewchat');
    sendDAEvent('SidebarButtonClick', {
      button_name: 'new_chat',
      sidebar_status: collapsed ? 'close' : 'open',
    });
  };

  const handleNavLinkClick = (key: string | null) => {
    if (tipsFlag?.[2] === '1') {
      const flag = tipsFlag.replace(tipsFlag[2], '0');
      setTipsFlag(flag);
      setTipsExistFlag({
        flag,
      });
    }
    if (key && eventMap[key]) {
      sendEvent(eventMap[key], {
        isLogin: !!userInfo?.isLogin,
      });
    }

    if (key && eventButtonNameMap[key]) {
      sendDAEvent('SidebarButtonClick', {
        button_name: eventButtonNameMap[key],
        sidebar_status: collapsed ? 'close' : 'open',
      });
    }

    if (key && eventDAMap[key]) {
      sendDAEvent(eventDAMap[key].name, eventDAMap[key].data);
    }
  };

  // 渲染commandK
  const renderCommandK = () => {
    const cmdOrCtrl = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0 ? 'metaKey' : 'ctrlKey';
    // mac电脑
    if (cmdOrCtrl === 'metaKey') {
      return (
        <div className="commandK">
          <img src={CommandK} alt="" />
        </div>
      );
    }
    // windows电脑
    if (cmdOrCtrl === 'ctrlKey') {
      return <div className="commandK">Ctrl+I</div>;
    }
  };

  const menuExpandedRender = () => (
    <>
      {!isFooter && (
        <>
          <button className="new-chat-button new-chat-button-expanded" onClick={handleNewChat}>
            <CustomIcon className="plus-icon" type={'popai-add'} />
            <span className="plus-text">{createBtnLabel}</span>
            {renderCommandK()}
          </button>
        </>
      )}

      <div className="tools">
        {t(isFooter ? 'layout.sideMenu.assets' : 'layout.sideMenu.tools')}
      </div>
      {menus.map((menu) => (
        <MenuItem
          key={menu.key}
          collapsed={false}
          menu={menu as MenuItemType}
          onNavLinkClick={handleNavLinkClick}
        />
      ))}
    </>
  );

  const menuCollapsedRender = () => (
    <>
      {!isFooter && (
        <button className="new-chat-button new-chat-button-collapsed" onClick={handleNewChat}>
          <CustomIcon className="plus-icon" type="plus" />
        </button>
      )}
      <div className="tools"></div>
      {menus.map((menu) => (
        <MenuItem
          key={menu.key}
          collapsed
          menu={menu as MenuItemType}
          onNavLinkClick={handleNavLinkClick}
        />
      ))}
    </>
  );

  return (
    <div
      className={classNames({
        'side-menu': true,
        'side-menu-expanded': !collapsed,
        'side-menu-collapsed': collapsed,
        [className]: !!className,
      })}
      style={style}
    >
      {collapsed ? menuCollapsedRender() : menuExpandedRender()}
    </div>
  );
};

export default SideMenu;
