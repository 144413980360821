import { create } from 'zustand';

import type { UploadFile } from 'antd/es/upload/interface';
import { isFunction } from 'lodash-es';

/**文件上传状态的枚举 */
export enum UPLOAD_STATUS {
  /**文件上传前 */
  BeforeUpload = 0,
  /**文件上传中 */
  Uploading,
  /**文件解析中 */
  Parsing,
  /**文件上传失败 */
  Failed,
  /**文件上传成功 */
  Success,
}

export interface InputUploadFile extends UploadFile {
  uploadTime?: number;
  md5?: string;
  uploading?: boolean;
  isUrlFile?: boolean;
  pageCount?: string;
  mimeType?: string;
  upload?: () => void;
}

interface SendUploadState {
  uploadMethod?: 'url' | 'googleDrive' | 'files' | 'computer';
  setuploadMethod: (payload?: 'url' | 'googleDrive' | 'files' | 'computer') => void;
  /**文件超限弹窗是否展示 */
  exceedConfirmOpen: boolean;
  setExceedConfirmOpen: (payload: boolean) => void;
  /**是否全部上传成功 */
  isAllUploaded?: boolean;
  /**是否能上传 */
  disable: boolean;
  /**设置是否能上传 */
  setDisable: (payload: boolean) => void;
  /**是否正在上传 */
  uploading: boolean;
  /** 设置是否正在上传*/
  setUploading: (payload: boolean) => void;
  /**当前全部上传 */
  allUploadFiles: InputUploadFile[];
  setAllUploadFiles: (
    payload: InputUploadFile[] | ((payload: InputUploadFile[]) => InputUploadFile[]),
  ) => void;
  /**当前正在上传的文件 */
  currentUploadFiles: UploadFile[];
  setCurrentUploadFiles: (payload: UploadFile[]) => void;
  /**文件上传状态map */
  fileStatusMap?: Record<
    string,
    {
      status: UPLOAD_STATUS;
      progress: number;
    }
  >;
  setFileStatusMap: (fileUid: string, value?: { status: UPLOAD_STATUS; progress: number }) => void;
  onRemoveFile: (fileUid: string) => void;
  /**
   * 重置各种状态
   */
  onReset: () => void;
  /**是否在上传图片 */
  isUploadImage: boolean;
  setIsUploadImage: (payload: boolean) => void;
  /**上传图片url数组 */
  imageUrls: string[];
  setImageUrls: (payload: string[]) => void;
  /**上传图片的进度 */
  imgProgress: number;
  setImgProgress: (payload: number) => void;
  /* 上传图片文件名 */
  imageFileNames: string[];
  setImageFileNames: (payload: string[]) => void;
}

const useSendUploadStore = create<SendUploadState>((set, get) => ({
  setuploadMethod: (payload?: 'url' | 'googleDrive' | 'files' | 'computer') =>
    set({ uploadMethod: payload }),
  exceedConfirmOpen: false,
  setExceedConfirmOpen: (payload: boolean) => set({ exceedConfirmOpen: payload }),
  disable: false,
  setDisable: (payload: boolean) => set({ disable: payload }),
  uploading: false,
  setUploading: (payload: boolean) => set({ uploading: payload }),
  allUploadFiles: [],
  setAllUploadFiles: (
    payload: InputUploadFile[] | ((payload: InputUploadFile[]) => InputUploadFile[]),
  ) =>
    set(({ allUploadFiles }) => ({
      allUploadFiles: isFunction(payload) ? payload(allUploadFiles) : payload,
    })),
  currentUploadFiles: [],
  setCurrentUploadFiles: (payload: UploadFile[]) => set({ currentUploadFiles: payload }),
  setFileStatusMap: (fileUid: string, value?: { status: UPLOAD_STATUS; progress: number }) => {
    set((state) => {
      const fileStatusMap = { ...state.fileStatusMap }; // 获取最新的 fileStatusMap
      if (value) {
        fileStatusMap[fileUid] = value;
      } else {
        delete fileStatusMap[fileUid];
      }
      const isAllUploaded = Object.values(fileStatusMap).every(
        (file) => file.status === UPLOAD_STATUS.Success,
      );
      return { fileStatusMap, isAllUploaded };
    });
  },
  onRemoveFile: (fileUid: string) => {
    const { setAllUploadFiles, setFileStatusMap } = get();
    setAllUploadFiles((prevFiles) => {
      const fileIndex = prevFiles.findIndex((i) => i.uid == fileUid);
      if (fileIndex > -1) {
        setFileStatusMap(fileUid);
        const res = prevFiles.filter((i) => i.uid !== fileUid);
        return res;
      }
      return prevFiles;
    });
  },
  onReset: () =>
    set({
      disable: false,
      uploading: false,
      allUploadFiles: [],
      currentUploadFiles: [],
      fileStatusMap: {},
      isAllUploaded: undefined,
      exceedConfirmOpen: false,
      uploadMethod: undefined,
    }),
  imgProgress: 0,
  setImgProgress: (payload: number) => set({ imgProgress: payload }),
  imageUrls: [],
  isUploadImage: false,
  imageFileNames: [],
  setImageUrls: (payload: string[]) => set({ imageUrls: payload }),
  setIsUploadImage: (payload: boolean) => set({ isUploadImage: payload }),
  setImageFileNames: (payload: string[]) => set({ imageFileNames: payload }),
}));

export default useSendUploadStore;
