import axios from '@/common/request';
import { UserPrompt } from '@/type';
export interface UserPromptResponse {
  data: {
    id: string;
    content: string;
    name: string;
    createdAt: number;
    deleted: false;
    promptId: string;
    uid: string;
    updatedAt: number;
  };
}

export interface UserPromptListResponse {
  data: {
    totalElements: number;
    content: UserPrompt[];
  };
}

export async function getPrompts<T extends UserPromptListResponse>(params: {
  start: number;
  limit: number;
}) {
  const result = await axios.get<T>('/api/v1/prompt/list', { params });
  return result.data;
}

export async function searchPrompts<T extends UserPromptListResponse>(params: {
  start: number;
  limit: number;
  key: string;
}) {
  const result = await axios.post<T>('/api/v1/prompt/instance', params);
  return result.data;
}

export async function addPrompt<T extends UserPromptResponse>(params: {
  name: string;
  content: string;
}) {
  const add = () => axios.post<T>('/api/v1/prompt/add', params);
  // simple retry
  const res = await add().catch(add);
  return res.data;
}

export async function delPrompt(promptId: string) {
  const res = await axios.delete(`/api/v1/prompt/${promptId}`);
  return res.data;
}

export async function editPrompt(
  promptId: string,
  { name, content }: { name: string; content: string },
) {
  const res = await axios.put(`/api/v1/prompt/${promptId}`, {
    name,
    content,
  });
  return res.data;
}
