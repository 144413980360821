export enum FileExt {
  'pdf' = 'pdf',
  'doc' = 'doc',
  'docx' = 'docx',
  'ppt' = 'ppt',
  'pptx' = 'pptx',
  'png' = 'png',
  'jpeg' = 'jpeg',
  'jpg' = 'jpg',
}

export enum FileType {
  pdf = 'pdf',
  ppt = 'ppt',
  doc = 'doc',
  image = 'image',
  /**
   * PopAI独有的类型
   */
  writing = 'writing',
  /**
   * PopAI独有的文件类型
   */
  presentation = 'presentation',
  /**
   * 未知的类型
   */
  unknown = '',
}

const ExtMappingType: Record<FileExt, FileType> = {
  pdf: FileType.pdf,
  ppt: FileType.ppt,
  pptx: FileType.ppt,
  doc: FileType.doc,
  docx: FileType.doc,
  png: FileType.image,
  jpeg: FileType.image,
  jpg: FileType.image,
};

const ExtMappingMineType: Record<FileExt, string> = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  pdf: 'application/pdf',
  png: 'image/png',
  jpg: 'image/jpg	',
  jpeg: 'image/jpeg	',
};

/**
 * 通过mineType来获取文件类型
 *
 * 此方法严格匹配，未命中将返回`FileType.unknown`
 */
export const getFileTypeByMineType = (mineType: string): FileType => {
  let fileExt: FileExt | null = null;
  let fileType: FileType = FileType.unknown;

  for (const [ext, mine] of Object.entries(ExtMappingMineType)) {
    if (mine === mineType) fileExt = ext as FileExt;
  }

  if (fileExt) {
    fileType = getFileTypeByExt(fileExt);
  }

  return fileType;
};

/**
 * 通过文件扩展名(不包含点)来获取文件类型
 *
 * 此方法严格匹配，未命中将返回`FileType.unknown`
 */
export const getFileTypeByExt = (ext: FileExt): FileType => {
  if (ext in ExtMappingType) {
    return ExtMappingType[ext];
  }
  return FileType.unknown;
};

/**
 * 获取文件类型
 * 会尽可能的尝试多种方法来获取
 *
 * @param type 文件的mineType, 或者实际的fileType, 或者扩展名
 * @param defaultType 匹配失败时返回的默认类型，默认值是FileType.doc
 */
export const getFileType = (type: string | undefined, defaultType = FileType.doc): FileType => {
  if (!type) return defaultType;

  if (type.startsWith('.')) type = type.substring(1);

  if (type in FileType) {
    return type as FileType;
  }

  let filetype = getFileTypeByExt(type as FileExt);

  if (filetype === FileType.unknown) {
    filetype = getFileTypeByMineType(type);
  }

  if (filetype === FileType.unknown) {
    filetype = defaultType;
  }

  return filetype;
};

export function getFileMineType(ext: FileExt) {
  return ExtMappingMineType[ext];
}
