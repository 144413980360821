import { useState, useEffect, useCallback } from 'react';
import { Modal, Skeleton, Button, message } from 'antd';
import dayjs from 'dayjs';
import { useGASendEvent, useFetchUserInfo, useFetchUsagesInfo, useDA } from '@/hooks';
import type { ResponseType } from '@/type';
import { useUserStore, useCommonStore } from '@/store';
import { MEMBERSHIP_ID, PAY3DS_STATUS, RESPONSE_DATA_CODE } from '@/common/config';
import BillingFreeUrl from '@/assets/billing-free.png';
import BillingProUrl from '@/assets/billing-pro.png';
import BillingProPLusUrl from '@/assets/billing-proplus.png';
import BillingUnlimitedUrl from '@/assets/billing-unlimited.png';
import ToastContent from '@/components/ToastContent';
import BillingCancelConfirmationUrl from '@/assets/billing-cancel-confirmation.png';
import BillingCancelSuccessUrl from '@/assets/billing-cancel-success.png';
import CustomIcon from '@/components/CustomIcon';
import CancelQuestionaire from './CancelQuestionaire';
import classNames from 'classnames';
import CheckBox from '@/components/CheckBox';
import {
  getStripePortalUsingGET,
  getFastspringPortalUsingGET,
  billingDetailGET,
  upGradeProducts,
  upgradeV2,
  allowCancelDiscountGet,
  allowStudentDiscountGET,
} from '@/services';
import { BillingDetail, UpGradeProductsListData, UpgradeProducts } from './interface';
import { useTranslation } from 'react-i18next';

import './index.less';
import Coupon from './coupon';
import StudentDiscount from './studentDiscount';
import PauseUpgrade from './pauseUpgrade';
import { getExtraHeaders } from '@/common/utils';
import { requestTurnstileToken } from '@/common/turnstile';

const { VITE_APP_STRIPE_PUBLISH_ABLE_KEY } = import.meta.env;

const smallModalWidth = 380;
const largeModalWidth = 532;
const STUDENTSUMMER = 'StudentSummer';
// const MEMBERSHIP_ID_MAP_TEXT: { [key: number]: string[] } = {
//   [MEMBERSHIP_ID.MonthPro]: ['Pro', 'Monthly', 'mo', ''],
//   [MEMBERSHIP_ID.YearPro]: ['Pro', 'Annually', 'yr', 'Proannually'],
//   [MEMBERSHIP_ID.MonthProPlus]: ['Pro+', 'Monthly', 'mo', 'pro+monthly'],
//   [MEMBERSHIP_ID.YearProPlus]: ['Pro+', 'Annually', 'yr', 'pro+annually'],
//   [MEMBERSHIP_ID.MonthUnlimited]: ['Unlimited', 'Monthly', 'mo', 'unlimitedmonthly'],
//   [MEMBERSHIP_ID.YearUnlimited]: ['Unlimited', 'Annually', 'yr', 'unlimitedannually'],
// };

function formatPrice(price?: number) {
  return price !== undefined ? (price / 100).toFixed(1) : '';
}

function formatProvider(provider?: string) {
  if (provider) {
    return provider[0] + provider.slice(1).toLocaleLowerCase();
  }
  return '';
}

interface BillingManageModalProps {
  open: boolean;
  showUpgrade?: boolean;
  onUpgrade: () => void;
  onClose: () => void;
}

const BillingManageModal: React.FC<BillingManageModalProps> = (props) => {
  const { sendDAEvent } = useDA();
  const { userInfo, discountInfo } = useUserStore();
  const { billingDetail, setBillingDetail } = useCommonStore();
  const { sendEvent } = useGASendEvent();
  const { fetchUserInfo } = useFetchUserInfo();
  const { fetchUsagesInfo } = useFetchUsagesInfo();
  const [loading, setLoading] = useState(true);
  const [showClose, setShowClose] = useState<undefined | null>();
  const [width, setWidth] = useState(smallModalWidth);
  const [cancelStep, setCancelStep] = useState(1);
  const [showUpgradePlan, setShowUpgradePlan] = useState<boolean>(false);
  const [pauseUpgrade, setPauseUpgrade] = useState<boolean>(false);
  const [showCoupon, setShowCoupon] = useState<boolean>(false); // 是否展示coupon弹窗
  const [showStudentDiscount, setShowStudentDiscount] = useState<boolean>(false); // 是否展示假期学生优惠弹窗
  const [upGradeProductList, setUpGradeProductList] = useState<UpGradeProductsListData[]>([]);
  const [selectProductId, setSelectProductId] = useState('');
  const { t } = useTranslation();
  const isFreeTrial = userInfo?.parsedRoles?.includes('Trail');
  const MEMBERSHIP_ID_MAP_TEXT: { [key: number]: string[] } = {
    [MEMBERSHIP_ID.MonthPro]: [
      'Pro',
      t('layout.billingManage.Monthly'),
      t('layout.billingManage.mo'),
      '',
    ],
    [MEMBERSHIP_ID.YearPro]: [
      'Pro',
      t('layout.billingManage.Annually'),
      t('layout.billingManage.yr'),
      t('layout.billingManage.Proannually'),
    ],
    [MEMBERSHIP_ID.MonthProPlus]: [
      'Pro+',
      t('layout.billingManage.Monthly'),
      t('layout.billingManage.mo'),
      t('layout.billingManage.pro_add_monthly'),
    ],
    [MEMBERSHIP_ID.YearProPlus]: [
      'Pro+',
      t('layout.billingManage.Annually'),
      t('layout.billingManage.yr'),
      t('layout.billingManage.pro_add_annually'),
    ],
    [MEMBERSHIP_ID.MonthUnlimited]: [
      t('layout.billingManage.Unlimited'),
      t('layout.billingManage.Monthly'),
      t('layout.billingManage.mo'),
      'unlimitedmonthly',
    ],
    [MEMBERSHIP_ID.YearUnlimited]: [
      t('layout.billingManage.Unlimited'),
      t('layout.billingManage.Annually'),
      t('layout.billingManage.yr'),
      'unlimitedannually',
    ],
  };

  useEffect(() => {
    if (props.open) {
      setShowUpgradePlan(false);
      setPauseUpgrade(false);
      getBillingDetail(!props.showUpgrade);
      if (props.showUpgrade) {
        getUpGradeProducts();
      } else {
        getAllowCancelDiscount();
        getAllowStudentDiscount();
      }
    } else {
      setLoading(true);
      setWidth(smallModalWidth);
      setShowClose(undefined);
      setCancelStep(1);
      setShowCoupon(false);
      setShowStudentDiscount(false);
      setPauseUpgrade(false);
      setShowUpgradePlan(false);
    }
  }, [props.open]);

  useEffect(() => {
    if (cancelStep === 5 || cancelStep === 6) {
      setWidth(largeModalWidth);
      setShowClose(null);
    } else {
      setWidth(smallModalWidth);
      setShowClose(undefined);
    }
  }, [cancelStep]);

  const getAllowCancelDiscount = async () => {
    try {
      const res = await allowCancelDiscountGet<ResponseType<boolean>>();
      if (res && res.code === 200) {
        setShowCoupon(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAllowStudentDiscount = async () => {
    try {
      const res = await allowStudentDiscountGET<ResponseType<boolean>>();
      if (res && res.code === 200) {
        setShowStudentDiscount(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * handleNext、handlePre前提必知：
   * 1、coupon弹窗，每个人都展示，但使用后 三个月内不重复展示，三个月后再进行展示，具体由接口 allowCancelDiscountGet 返回是否展示
   * 2、studentDiscount弹窗，仅在学生假期展示，大概是每年5月底到8月底，具体由接口 allowStudentDiscountGET 返回是否展示
   * 3、其余弹窗（第一步弹窗，第二步弹窗，问卷弹窗）固定展示
   */

  /**
   * handleNext方法跳转逻辑，
   * 默认跳转一步，即 step = 1
   * 再逐步判断后续弹窗是否展示
   * 若!showCoupon, step+1，若!showStudentDiscount step+1
   */
  const handleNext = () => {
    let step = 1;

    if (cancelStep === 2 && !showCoupon) {
      step += 1;
      if (!showStudentDiscount) step += 1;
    }

    if (cancelStep === 3 && !showStudentDiscount) {
      step += 1;
    }
    setCancelStep(cancelStep + step);
  };

  /**
   * handlePre跳转逻辑为 逆handleNext即可
   */
  const handlePre = () => {
    let step = 1;
    if (cancelStep === 5 && !showStudentDiscount) {
      step += 1;
      if (!showCoupon) step += 1;
    }
    if (cancelStep === 4 && !showCoupon) {
      step += 1;
    }
    setCancelStep(cancelStep - step);
  };

  const handleCancelSubscription = () => {
    if (billingDetail?.canceled) return;
    sendEvent('Cancelsubscription');
    if (isFreeTrial) {
      sendEvent('Cancelfree-trial');
    }
    handleNext();
  };

  const handleConfirmationBack = () => {
    sendEvent('Notcancelsubscription');
    if (isFreeTrial) {
      sendEvent('Notcancelfree-trial');
    }
    handlePre();
  };

  const handleConfirmationCancel = () => {
    sendEvent('Gocancelsubscription');
    if (isFreeTrial) {
      sendEvent('Gocancelfree-trial');
    }
    handleNext();
  };

  const onCancel = () => {
    if (cancelStep === 2) {
      sendEvent('Closecancelsubscription');
      if (isFreeTrial) {
        sendEvent('Closecancelfree-trial');
      }
    }
    if (cancelStep === 4) {
      sendEvent('students_close');
    }
    sendEvent('CloseUpgradeversion');
    props.onClose();
  };

  const getBillingDetail = async (stopLoading = true) => {
    try {
      setLoading(true);
      const resData = await billingDetailGET<ResponseType<BillingDetail>>();
      setBillingDetail(resData.data);
      stopLoading && setLoading(false);
      if (resData.data.membershipId === MEMBERSHIP_ID.Free) {
        sendEvent('Freemanagerbilling');
      } else {
        sendEvent('Promanagerbilling');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const toBillingDetail = async () => {
    let res: ResponseType<string> | undefined;
    try {
      if (userInfo.stripeCustomer) {
        res = await getStripePortalUsingGET<ResponseType<string>>({
          customer: userInfo.stripeCustomer as string,
          callbackUrl: window.location.href,
        });
      } else if (userInfo.fastspringCustomer) {
        res = await getFastspringPortalUsingGET<ResponseType<string>>({
          accountId: userInfo.fastspringCustomer as string,
        });
      }
      res?.data && window.open(res.data);
    } catch (e) {
      console.error(e);
    }
    if (userInfo.isPro) {
      sendEvent('ViewOrderHistory', { pro: true });
    } else {
      sendEvent('ViewOrderHistory', { free: true });
    }
  };

  const getUpGradeProducts = async (params?: { 'Cf-Captcha-Token'?: string }) => {
    try {
      setLoading(true);
      const extraHeaders = await getExtraHeaders();
      const res = await upGradeProducts<ResponseType<UpgradeProducts>>({
        ...extraHeaders,
        ...params,
      });
      setPauseUpgrade(res?.data?.studentDiscount);
      const upGradeProductList = res?.data?.upgradeList || res?.data;
      setUpGradeProductList(upGradeProductList);
      setShowUpgradePlan(true);
      upGradeProductList?.length && setSelectProductId(upGradeProductList[0].productIds);
      setLoading(false);
    } catch (err: any) {
      if (err?.response?.data?.code === RESPONSE_DATA_CODE.Turnstile) {
        requestTurnstileToken().then((token) => {
          getUpGradeProducts({
            ...params,
            'Cf-Captcha-Token': token as string,
          });
        });
      } else {
        message.open({
          content: <ToastContent icon="error" content={t('common.networkError')} />,
        });
      }
    }
  };

  const handleUpgradeConfirm = useCallback(async () => {
    if (!selectProductId) return;
    const item = upGradeProductList.find(
      (item) => item.productIds === selectProductId,
    ) as UpGradeProductsListData;
    sendEvent('Upgradeversion', {
      upgradeType: MEMBERSHIP_ID_MAP_TEXT[item.membershipId][3],
    });
    const params = {
      productId: selectProductId as string,
      subscription: item?.subscription as string,
    };
    try {
      const res = await upgradeV2<ResponseType<any>>(params);
      if (res) {
        if (res?.data.action === 1) {
          location.href = res?.data.confirmUrl;
          sendDAEvent('hit3ds', { action: res?.data.action });
          return;
        } else if (res?.data?.action === 2) {
          sendDAEvent('hit3ds', { action: res?.data.action });
          const stripe = window.Stripe(VITE_APP_STRIPE_PUBLISH_ABLE_KEY);
          const result = await stripe.handleNextAction({
            clientSecret: res?.data.confirmUrl,
          });
          sendDAEvent('hit3ds_result', {
            action: res?.data.action,
            result: result?.paymentIntent?.status || result?.error,
          });
          if (result?.paymentIntent?.status === PAY3DS_STATUS.SUCCESSSED) {
            message.open({
              content: <ToastContent icon="success" content={t('common.upgradeSuccessful')} />,
            });
          } else if (result?.paymentIntent?.status === PAY3DS_STATUS.PROCESSING) {
            message.open({
              content: <ToastContent icon="error" content={t('common.process')} />,
            });
          } else {
            message.open({
              content: <ToastContent icon="error" content={t('common.payment')} />,
            });
          }
          setShowUpgradePlan(false);
          getBillingDetail();
          fetchUsagesInfo();
          setTimeout(async () => {
            await fetchUserInfo();
          }, 5000);
          return;
        }
        setShowUpgradePlan(false);
        fetchUsagesInfo();
        getBillingDetail();
        message.open({
          content: <ToastContent icon="success" content={t('common.upgradeSuccessful')} />,
        });
        await fetchUserInfo();
        // 临时方案，为了解决升级成功后，用户角色更新有延时问题
        setTimeout(async () => {
          await fetchUserInfo();
        }, 5000);
      }
    } catch (e) {
      setShowUpgradePlan(false);
      getBillingDetail();
      message.open({
        content: <ToastContent icon="error" content={t('common.payment')} />,
      });
      await fetchUserInfo();
      // 临时方案，为了解决升级成功后，用户角色更新有延时问题
      setTimeout(async () => {
        await fetchUserInfo();
      }, 5000);
    }
  }, [selectProductId]);

  const handleUpgradeConfirmThrottle = useCallback(() => {
    Modal.confirm({
      closable: true,
      className: 'confirm-pay-modal',
      width: smallModalWidth,
      icon: null,
      centered: true,
      title: t('layout.billingManage.ConfirmPayTitle'),
      cancelText: t('common.cancel'),
      okText: t('common.confirmAndPay'),
      onOk: () => {
        return handleUpgradeConfirm();
      },
    });
  }, [handleUpgradeConfirm]);

  const onUpgrade = () => {
    sendEvent('UpgradeManagerbilling');
    props.onUpgrade();
  };

  const intervalMap: Record<string, string> = {
    // Month: 'mo',
    // Year: 'yr',
    Month: t('layout.billingManage.mo'),
    Year: t('layout.billingManage.yr'),
  };

  const upgradeRender = () => {
    return billingDetail ? (
      <div className="billingManage-modal-content">
        <div>
          <img className="billingManage-modal-content-img" width="238" src={BillingFreeUrl} />
          <div className="billingManage-modal-content-planName">
            <span>${formatPrice(billingDetail?.price)}</span>
            <span>/{intervalMap[billingDetail!.subscriptionInterval]}</span>
          </div>
        </div>
        <div>
          <div
            className="billingManage-modal-content-btn billingManage-modal-content-upgrade-btn"
            onClick={onUpgrade}
          >
            {t('layout.billingManage.upgradeToPro')}
          </div>
          <div className="billingManage-modal-content-toDetail billingManage-modal-content-toDetail-mb">
            <span onClick={toBillingDetail}>{t('layout.billingManage.viewOrderDetails')}</span>
          </div>
        </div>
      </div>
    ) : null;
  };

  const handleUpgradePlanVersion = () => {
    sendEvent('Upgradeplanversion');
    getUpGradeProducts();
  };

  const cancelRender = () => {
    if (!billingDetail) {
      return null;
    }

    const cancelInfo = {
      end: t('layout.billingManage.Next_renewal_date'),
      endIcon: 'nextRenewal',
      btnText: t('layout.billingManage.cancelSubscription'),
      btndisabled: false,
    };
    const cancelledInfo = {
      end: t('layout.billingManage.Plan_Ends_On'),
      endIcon: 'lockTime',
      btnText: t('layout.billingManage.Subscription_cancelled'),
      btndisabled: true,
    };
    const info = billingDetail?.canceled ? cancelledInfo : cancelInfo;
    const isUnlimited = [MEMBERSHIP_ID.MonthUnlimited, MEMBERSHIP_ID.YearUnlimited].includes(
      billingDetail?.membershipId as number,
    );
    const isProPlus = [MEMBERSHIP_ID.MonthProPlus, MEMBERSHIP_ID.YearProPlus].includes(
      billingDetail?.membershipId as number,
    );
    return (
      <div className="billingManage-modal-content">
        <div>
          <img
            width="238"
            className="billingManage-modal-content-img"
            src={isUnlimited ? BillingUnlimitedUrl : isProPlus ? BillingProPLusUrl : BillingProUrl}
          />
          {isFreeTrial ? (
            <div
              className={classNames({
                'billingManage-modal-content-planName': true,
              })}
            >
              <span>{t('layout.billingManage.freeTrial')}</span>
            </div>
          ) : billingDetail?.discountAmount ? (
            <div
              className={classNames({
                'billingManage-modal-content-planName': true,
                'billingManage-modal-content-planName-narrow': !!billingDetail?.discountAmount,
              })}
            >
              <span>
                {billingDetail?.symbol}
                {billingDetail?.nextCharge?.toFixed(1)}
              </span>
              <span>/{intervalMap[billingDetail!.subscriptionInterval]}</span>
              <span>
                {billingDetail?.symbol}
                {billingDetail?.amount?.toFixed(1)}
              </span>
            </div>
          ) : (
            <div
              className={classNames({
                'billingManage-modal-content-planName': true,
              })}
            >
              <span>
                {billingDetail?.symbol}
                {billingDetail?.amount?.toFixed(1)}
              </span>
              <span>/{intervalMap[billingDetail!.subscriptionInterval]}</span>
            </div>
          )}

          {billingDetail?.discountAmount ? (
            billingDetail?.activity?.endsWith(STUDENTSUMMER) ? (
              <div className="billingManage-modal-content-couponTip">
                <div className="billingManage-modal-content-couponTip-left"></div>
                <div>
                  {t('layout.studentDiscount.tip', {
                    discountDuration: billingDetail?.discountDuration,
                  })}
                </div>
                <div className="billingManage-modal-content-couponTip-right"></div>
              </div>
            ) : (
              <div className="billingManage-modal-content-couponTip">
                <div className="billingManage-modal-content-couponTip-left"></div>
                <div>
                  {t('layout.coupon.tip', {
                    discountPercent: billingDetail?.discountPercent,
                  })}
                </div>
                <div className="billingManage-modal-content-couponTip-right"></div>
              </div>
            )
          ) : null}

          <div className="billingManage-modal-content-cancel-detail">
            <div className="billingManage-modal-content-cancel-detail-item">
              <CustomIcon type="bankCard" />
              <span>{t('layout.billingManage.paymentMethod')}</span>
              <span>{formatProvider(billingDetail?.provider)}</span>
            </div>
            <div className="billingManage-modal-content-cancel-detail-item">
              <CustomIcon type="orderTime" />
              <span>{t('layout.billingManage.orderTime')} </span>
              <span>{dayjs(billingDetail?.currentPeriodStart)?.format('YYYY-MM-DD')}</span>
            </div>
            <div className="billingManage-modal-content-cancel-detail-item">
              <CustomIcon type={info.endIcon} />
              <span>{info.end}: </span>
              <span>{dayjs(billingDetail?.currentPeriodEnd)?.format('YYYY-MM-DD')}</span>
            </div>
          </div>
        </div>

        <div>
          {billingDetail?.membershipId === MEMBERSHIP_ID.YearUnlimited || isFreeTrial ? (
            <div className="billingManage-modal-content-upgrade-placeholder"></div>
          ) : (
            <div className="billingManage-modal-content-upgrade" onClick={handleUpgradePlanVersion}>
              {t('layout.billingManage.UpgradeVersion')}
            </div>
          )}

          <div className="billingManage-modal-content-toDetail">
            <span onClick={toBillingDetail}>{t('layout.billingManage.viewOrderDetails')}</span>
          </div>
          <div className="billingManage-modal-content-btn">
            <span
              className={classNames({
                'cancel-subscription-btn': true,
                'cancel-subscription-btn-disabled': info.btndisabled,
              })}
              onClick={handleCancelSubscription}
            >
              {info.btnText}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const cancelConfirmationRender = () => {
    return (
      <div className="billingManage-modal-content">
        <div>
          <div className="billingManage-modal-content-img">
            <img width="292" src={BillingCancelConfirmationUrl} />
          </div>
          <div className="billingManage-modal-content-cancelConfirmation-title">
            {t('layout.billingManage.Leaving_soon')}
          </div>
          {isFreeTrial && (
            <div className="billingManage-modal-content-cancelConfirmation-des">
              {t('layout.billingManage.freeTrialCancelConfirmation_des')}
            </div>
          )}
          {!isFreeTrial && (
            <div className="billingManage-modal-content-cancelConfirmation-desc">
              <div className="billingManage-modal-content-cancelConfirmation-desc-wra">
                <div className="billingManage-modal-content-cancelConfirmation-desc-wra-doc">·</div>
                <div>
                  {t('layout.billingManage.leavingSoSoonOneFirst')}{' '}
                  <a href="mailto:customerservice@popai.pro">
                    {t('layout.billingManage.leavingSoSoonOneSecond')}
                  </a>{' '}
                  {t('layout.billingManage.leavingSoSoonThird')}
                </div>
              </div>
              <div className="billingManage-modal-content-cancelConfirmation-desc-wra">
                <div className="billingManage-modal-content-cancelConfirmation-desc-wra-doc">·</div>
                <div>{t('layout.billingManage.leavingSoSoonTwo')}</div>
              </div>
            </div>
          )}
        </div>
        <div className="billingManage-modal-content-btn billingManage-modal-content-cancelConfirmation-btn">
          <Button className="chat-btn" onClick={handleConfirmationBack}>
            {t('common.back')}
          </Button>
          <Button className="chat-btn" onClick={handleConfirmationCancel}>
            {t('common.proceedToCancel')}
          </Button>
        </div>
      </div>
    );
  };

  const cancelSuccessRender = () => {
    return (
      <div className="billingManage-modal-content-cancelSuccess">
        <div className="billingManage-modal-content-cancelSuccess-img">
          <img width="116" src={BillingCancelSuccessUrl} />
        </div>
        <div className="billingManage-modal-content-cancelSuccess-title">
          {userInfo?.parsedRoles?.includes('Trail')
            ? t('layout.billingManage.freeTrialSubscription')
            : t('layout.billingManage.yourSubscription')}
        </div>
        <div className="billingManage-modal-content-cancelSuccess-des">
          {userInfo?.parsedRoles?.includes('Trail')
            ? t('layout.billingManage.freeTrialThank_you') +
              ` ${dayjs(billingDetail?.currentPeriodEnd)?.format('MM/DD/YYYY')}.`
            : t('layout.billingManage.Thank_you')}
        </div>
        <div className="billingManage-modal-content-cancelSuccess-btn">
          <Button onClick={props.onClose} className="chat-btn">
            {t('layout.billingManage.Got_It')}
          </Button>
        </div>
      </div>
    );
  };

  const upgradePlanRender = () => {
    // 当前选中的产品
    const selectedProduct = upGradeProductList.find((item) => item.productIds === selectProductId);
    // 当前选中产品是否有折扣
    const showDiscount = selectedProduct?.discount && selectedProduct?.discount > 0;

    return (
      <div className="upgrade-plan">
        <div className="upgrade-plan-title">{t('layout.billingManage.Select_upgrade_plan')}</div>
        {showDiscount ? (
          <div className="upgrade-plan-desc">
            🎉{' '}
            {`${discountInfo?.desc} ! ${t('common.discountBannerLabel', {
              discount: selectedProduct?.discount,
            })}`}
          </div>
        ) : (
          <div className="upgrade-plan-desc" style={{ padding: 0 }}></div>
        )}
        <div className="upgrade-plan-content">
          {!!upGradeProductList.length &&
            upGradeProductList.map((item: UpGradeProductsListData, index: number) => (
              <div
                className={classNames({
                  'upgrade-plan-content-item': true,
                  'upgrade-plan-content-item-checked': selectProductId === item.productIds,
                })}
                key={index}
                onClick={() => setSelectProductId(item.productIds)}
              >
                <div className="upgrade-plan-content-item-header">
                  <div className="upgrade-plan-content-item-header-left">
                    <div className="upgrade-plan-content-item-header-left-membership">
                      {MEMBERSHIP_ID_MAP_TEXT[item.membershipId][0]}
                    </div>
                    <div
                      className={classNames({
                        'upgrade-plan-content-item-header-left-time': true,
                        'upgrade-plan-content-item-header-left-rel': !item?.discount,
                      })}
                    >
                      <span>{MEMBERSHIP_ID_MAP_TEXT[item.membershipId][1]}</span>
                      {!!item?.discount && (
                        <div className="discount">
                          <div className="discount-text">-{item?.discount}%</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="upgrade-plan-content-item-header-right">
                    <span className="upgrade-plan-content-item-header-right-price">
                      <span className="upgrade-plan-content-item-header-right-price-symbol">
                        {item.symbol}
                      </span>
                      {item.discountAmount.toFixed(item.decimalPlaces > 0 ? 1 : 0)}
                    </span>
                    <span className="upgrade-plan-content-item-header-right-unit">
                      /{MEMBERSHIP_ID_MAP_TEXT[item.membershipId][2]}
                    </span>
                    {selectProductId === item.productIds ? (
                      <CheckBox
                        className="upgrade-plan-content-item-header-right-checked"
                        checked
                      />
                    ) : (
                      <div className="upgrade-plan-content-item-header-right-uncheck"></div>
                    )}
                  </div>
                </div>
                <div className="upgrade-plan-content-item-main">
                  <div className="upgrade-plan-content-item-main-desc">{item.greyDetails}</div>
                  {selectProductId === item.productIds &&
                    item?.colorDetails?.length > 0 &&
                    item.colorDetails.map((son, idx) => (
                      <div className="upgrade-plan-content-item-main-permission" key={idx}>
                        · <span>{son}</span>
                      </div>
                    ))}
                  {selectProductId === item.productIds && (
                    <div className="upgrade-plan-content-item-main-tip">
                      <CustomIcon className="upgrade-plan-content-item-main-tip-icon" type="tip" />
                      <div className="upgrade-plan-content-item-main-tip-text">
                        {t('layout.billingManage.Select_upgrade_plan_time_for')}{' '}
                        <span>
                          {item.symbol}
                          {item?.remainAmount}.
                        </span>{' '}
                        {t('layout.billingManage.Select_upgrade_next')}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="upgrade-plan-btn" onClick={handleUpgradeConfirmThrottle}>
          {t('layout.billingManage.Select_upgrade_now')}
        </div>
      </div>
    );
  };
  const renderContent = () => {
    const isFree = billingDetail?.membershipId === MEMBERSHIP_ID.Free;
    if (isFree) {
      return upgradeRender();
    }
    if (pauseUpgrade) {
      return <PauseUpgrade />;
    }
    if (showUpgradePlan) {
      return upgradePlanRender();
    }
    if (cancelStep === 1) {
      return cancelRender();
    }
    if (cancelStep === 2) {
      return cancelConfirmationRender();
    }
    if (cancelStep === 3) {
      return (
        <Coupon
          handleNext={handleNext}
          onCancel={onCancel}
          subscriptionId={billingDetail?.subscriptionId as string}
        />
      );
    }
    if (cancelStep === 4) {
      return (
        <StudentDiscount
          handleNext={handleNext}
          onCancel={onCancel}
          subscriptionId={billingDetail?.subscriptionId as string}
        />
      );
    }
    if (cancelStep === 5) {
      return (
        <CancelQuestionaire
          handlePre={handlePre}
          handleNext={handleNext}
          billingDetail={billingDetail!}
        />
      );
    }
    return cancelSuccessRender();
  };

  return (
    <Modal
      wrapClassName="billingManage-modal"
      open={props.open}
      destroyOnClose
      centered
      footer={null}
      width={width}
      maskClosable={false}
      onCancel={onCancel}
      closeIcon={showClose}
      zIndex={1030}
    >
      <div className="billingManage-modal-wrapper">
        {loading ? (
          <div style={{ padding: 20, height: '525px' }}>
            <Skeleton active style={{ marginTop: 30 }} paragraph={{ rows: 13 }} />
          </div>
        ) : (
          renderContent()
        )}
      </div>
    </Modal>
  );
};

export default BillingManageModal;
