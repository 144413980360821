import { useState, useEffect, useMemo } from 'react';
import SaveLangeuage from '../saveLanguage';
import { Select } from 'antd';
import { useCommonStore, useLanguageStore } from '@/store';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useGASendEvent } from '@/hooks';
import { LangType } from '@/hooks/useI18n';
import { toggleLngList } from '@/i18n/config';

interface ToggleLanaugeProps {}

const ToggleLanauge: React.FC<ToggleLanaugeProps> = () => {
  const { pageLanguage, setPageLanguage } = useCommonStore();
  const { langResource, setLangResource } = useLanguageStore();
  const [saveLanguageIsShow, setSaveLanguageIsShow] = useState<boolean>(false); // 保存语言弹窗
  const [lng, setLng] = useState<string>('en'); // 保存语言弹窗
  const { sendEvent } = useGASendEvent();

  const optionValue = useMemo(() => {
    const memoLang = toggleLngList.find((lang) => lang.key === pageLanguage);
    return memoLang?.key || toggleLngList[0].key;
  }, [pageLanguage]);

  const { i18n, t } = useTranslation();

  const handleToggleLanguage = (lng: LangType) => {
    setLng(lng);
    if (lng !== i18n.resolvedLanguage) {
      setSaveLanguageIsShow(true);
      sendEvent('languages_change_status', { lng });
    }
  };

  useEffect(() => {
    setLng(i18next.language);
    setPageLanguage(i18next.language);

    sendEvent('settings_languages_show');
  }, []);

  return (
    <>
      <div className="settingRightContent">
        <div className="settingRightContentLeft">
          <div className="settingRightContentLeftTitlle">
            {t('layout.settingModal.Interface_Language')}
          </div>
          <div className="settingRightContentLeftContent">{t('layout.settingModal.content')}</div>
        </div>
        <div className="settingRightContentRight">
          <div>
            <p className="settingRightContentRightTitle">
              {t('layout.settingModal.currentLanguage')}
            </p>
            <Select
              className="settingRightContentRightSelect"
              value={optionValue}
              defaultValue={optionValue}
              options={toggleLngList.map((lang) => ({
                original: lang,
                label: lang.showText,
                value: lang.key,
                title: lang.showText,
              }))}
              onChange={(value) => {
                handleToggleLanguage(value as LangType);
              }}
            />
          </div>
        </div>
      </div>
      <SaveLangeuage
        isShow={saveLanguageIsShow}
        onCancel={setSaveLanguageIsShow}
        onOk={async () => {
          if (!lng) {
            return;
          }
          if (lng !== 'en' && !langResource[lng as LangType]) {
            await setLangResource(lng as LangType);
          }
          setPageLanguage(lng);
          window.location.replace(`/${lng}`);
        }}
      />
    </>
  );
};

export default ToggleLanauge;
