import { WRITE_TEMPLATE_ID } from '@/common/config';
import axios from '@/common/request';
import { AdvanceConfig } from '@/type';
import { RawAxiosRequestHeaders, GenericAbortSignal, AxiosRequestConfig } from 'axios';

export async function extractQuestionPOST<T>(
  params: {
    writingDocPromptTemplateId: WRITE_TEMPLATE_ID;
    channelId: string; // 聊天对话框 id
    isGetJson: boolean;
    message: string; // 发送的问题
    version: string;
    model: string; // 使用的模型
    language?: string | null;
  },
  extraHeaders?: RawAxiosRequestHeaders,
  signal?: GenericAbortSignal,
) {
  const result = await axios.post<T>('/api/v1/chat/extractQuestion', params, {
    signal,
    useRiskControl: true,
    ...(extraHeaders ? { headers: extraHeaders } : {}),
  });
  return result.data;
}

export async function getWritingDoc<T>(params: { docId: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/writing/getDoc?${paramArr.join('&')}`);
  return result.data;
}

export async function upsertWritingDoc<T>(
  params: {
    docId?: string;
    name?: string;
    content?: string;
    generalChatMessageIds?: string[];
    advanceConfig?: AdvanceConfig;
    sourceEnum?: string;
  },
  options?: AxiosRequestConfig,
) {
  const result = await axios.post<T>(`/api/v1/writing/upsertDoc`, params, options);
  return result.data;
}

export async function getWritingDocList<T>() {
  const result = await axios.get<T>(`/api/v1/writing/myDocList`);
  return result.data;
}

export async function updateWritingDoc<T>(params: { docId?: string; status?: number }) {
  const result = await axios.post<T>(`/api/v1/writing/updateDoc`, params);
  return result.data;
}

export async function setTipsExistFlag<T>(params: { flag: string }) {
  const result = await axios.post<T>(`/api/v1/writing/setTipsExistFlag`, params);
  return result.data;
}

export async function getTipsExistFlag<T>() {
  const result = await axios.get<T>(`/api/v1/writing/getTipsExistFlag`);
  return result.data;
}

export async function getLinkTitle<T>(params: { url: string }) {
  const result = await axios.get<T>(`/api/v1/writing/extractTitle`, {
    params,
  });
  return result.data;
}
