import { basicChatModelName, gpt4oName, proChatModelName } from '@/common/model';
import { useDA, useSundry } from '@/hooks';
import { useGlobalModal } from '@/layout/BasicLayout';
import { useChatStore, useCommonStore, useSendUploadStore, useUserStore } from '@/store';
import { useCallback, useEffect, useMemo } from 'react';
import { UPDATE_OUTPUT_MODEL } from '../constants';
import { EChatType, SendControllerViewProps } from '../types';
import { AB_EXPERIMENTS } from '@/common/config';

const useSelectModel = (
  props: Pick<SendControllerViewProps, 'channelId' | 'chatType'> & {
    switchChecked?: boolean;
  },
) => {
  const { allUploadFiles } = useSendUploadStore((state) => ({
    allUploadFiles: state.allUploadFiles,
  }));

  const { userInfo, guestToken } = useUserStore((state) => ({
    userInfo: state.userInfo,
    guestToken: state.guestToken,
  }));
  const { setChatModel, chatModel } = useCommonStore((state) => ({
    chatModel: state.chatModel,
    setChatModel: state.setChatModel,
  }));
  const { setOutputModel, channelModel } = useChatStore((state) => ({
    setOutputModel: state.setOutputModel,
    channelModel: state.channel.model,
  }));

  const { sendDAEvent } = useDA();
  const { experiment } = useSundry();
  const removeGPT4Feature = experiment(AB_EXPERIMENTS.REMOVEGPT4);

  // layout
  const { checkLoginStatus } = useGlobalModal();

  /**
   * 若用户上次选择模型为非4、4o、standard，则自动绑定到standard
   * 若用户命中remove gpt4灰度，且若用户上次选择模型为4，则自动绑定到standard
   */
  const updateCurrentModel = useMemo(() => {
    if (!userInfo.isLogin && !guestToken) return basicChatModelName;
    if (UPDATE_OUTPUT_MODEL.map((i) => i.name).includes(chatModel))
      // 若用户命中remove gpt4灰度，且若用户上次选择模型为4，则自动绑定到GPT4-o
      return removeGPT4Feature && chatModel === proChatModelName ? gpt4oName : chatModel;
    return basicChatModelName;
  }, [userInfo.isLogin, guestToken, chatModel, removeGPT4Feature]);

  // 当前输入框的模型
  const currentModel = useMemo(() => {
    // 如果在对话内，从channel数据上取model
    if (props?.channelId) return channelModel || basicChatModelName;
    return updateCurrentModel;
  }, [props?.channelId, channelModel, updateCurrentModel]);

  const showModelSelect = useMemo(() => {
    return !props?.channelId && currentModel && props?.chatType !== EChatType.chatWithTPL;
  }, [props?.channelId, currentModel, props?.chatType]);

  /**
   * model选项
   * 如果打开联网开关则选项中GPT-4需要disable
   */
  const modelOptions = useMemo(() => {
    if (props?.switchChecked) {
      if (removeGPT4Feature) {
        return UPDATE_OUTPUT_MODEL.filter((i) => i.name !== proChatModelName);
      }
      return UPDATE_OUTPUT_MODEL.map((i) => ({
        ...i,
        disabled: i.name === proChatModelName,
      }));
    }
    if (removeGPT4Feature) {
      return UPDATE_OUTPUT_MODEL.filter((i) => i.name !== proChatModelName);
    }
    return UPDATE_OUTPUT_MODEL;
  }, [removeGPT4Feature, props?.switchChecked]);

  const handleOutputModelSelect = useCallback(
    (model: string) => {
      sendDAEvent('DocModelSwitchButtonClick', {
        model: model,
        from: currentModel,
      });
      if (!checkLoginStatus?.({ type: 'input-model', model })) {
        setChatModel(model);
        return;
      }
      if (allUploadFiles.length > 0) {
        sendDAEvent('DocModelSwitchResult', {
          model: model,
        });
      }
      setChatModel(model);
      setOutputModel(model);
    },
    [
      allUploadFiles.length,
      checkLoginStatus,
      currentModel,
      sendDAEvent,
      setChatModel,
      setOutputModel,
    ],
  );

  useEffect(() => {
    setChatModel(currentModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModel]);

  return {
    currentModel,
    showModelSelect,
    modelOptions,
    handleOutputModelSelect,
  };
};

export default useSelectModel;
