import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import CalloutView, { DEFAULT_EMOJI } from './CalloutView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    callout: {
      setCallout: () => ReturnType;
      deleteTextInCallout: () => ReturnType;
    };
  }
}

export const Callout = Node.create({
  name: 'callout',

  group: 'block',

  content: 'block+',

  isolating: true,

  addAttributes() {
    return {
      emoji: {
        default: DEFAULT_EMOJI,
        parseHTML: (element) => element.getAttribute('data-emoji'),
        renderHTML: (attributes) => {
          return { 'data-emoji': attributes.emoji };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="callout"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'callout', 'data-type': 'callout' }),
      0,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CalloutView);
  },

  addCommands() {
    return {
      setCallout:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: { emoji: DEFAULT_EMOJI },
            content: [
              {
                type: 'paragraph',
              },
            ],
          });
        },
      deleteTextInCallout:
        () =>
        ({ state, dispatch }) => {
          const { selection, tr } = state;
          const { from } = selection;
          let $pos = state.doc.resolve(from);
          let node = $pos.node($pos.depth);

          while (node && node.type.name !== 'callout' && $pos.depth > 0) {
            $pos = state.doc.resolve($pos.before($pos.depth));
            node = $pos.node($pos.depth);
          }

          if (node && node.type.name === 'callout' && node.childCount === 1) {
            const isEmpty = node?.firstChild?.content?.size === 0;
            if (isEmpty) {
              // delete callout
              const start = $pos.before($pos.depth);
              const end = $pos.after($pos.depth);
              dispatch?.(tr.deleteRange(start, end));
              return true;
            }
          }

          return false;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () => this.editor.commands.deleteTextInCallout(),
    };
  },
});
