import './index.less';

const TableElement: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <div style={{ overflowX: 'auto' }}>
      <table>{children}</table>
    </div>
  );
};

export default TableElement;
