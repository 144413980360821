import { create } from 'zustand';

interface ClientMessage {
  /**
   * message被创建的时间戳
   */
  id: number;
  value: string;
  /**
   * 粘贴过
   */
  pasted: boolean;
  /**
   * 是否已经发送
   */
  sent: boolean;
  /**
   * 获取用户从输入第一次输入，到发送的时间差(ms), lastMessage才具有此值
   */
  duration: number;
  /**
   * 用户从输入第一次输入input value的时间,包括手动输入或者其他控件输入
   */
  firstTypeTime: number | null;
}

interface InputMessageState {
  lastMessage: null | ClientMessage;
  currentMessage: ClientMessage;
  markSent: () => void;
  setPasted: (pasted: boolean) => void;
  setValue: (value: string) => void;
  clearLast: () => void;
  /**
   * 获取用户从输入第一次输入，到现在的时间差(ms),
   *
   * 优先尝试从currentMessage中取,
   * 如果currentMessage没有输入过，说明消息刚被发送，则从lastMessage中取。
   */
  getDuration: () => number;
}

/**
 * 记录用户输入过程中的动作
 */
const useInputMessageStore = create<InputMessageState>((set, get) => ({
  lastMessage: null,
  currentMessage: {
    id: Date.now(),
    value: '',
    pasted: false,
    sent: false,
    firstTypeTime: null,
    duration: NaN,
  },
  markSent: () =>
    set((state) => ({
      lastMessage: {
        ...(state.currentMessage || {}),
        sent: true,
        duration: state.currentMessage.firstTypeTime
          ? Date.now() - state.currentMessage.firstTypeTime
          : NaN,
      },
      currentMessage: {
        id: Date.now(),
        value: '',
        pasted: false,
        sent: false,
        firstTypeTime: null,
        duration: NaN,
      },
    })),
  setPasted: (pasted: boolean) =>
    set((state) => ({
      currentMessage: {
        ...state.currentMessage,
        pasted,
        firstTypeTime: state.currentMessage?.firstTypeTime || Date.now(),
      },
    })),
  setValue: (value: string) =>
    set((state) => ({
      currentMessage: {
        ...state.currentMessage,
        value,
        firstTypeTime: state.currentMessage?.firstTypeTime || Date.now(),
      },
    })),
  clearLast: () =>
    set({
      lastMessage: null,
    }),
  getDuration: () => {
    const { currentMessage, lastMessage } = get();
    if (currentMessage.firstTypeTime) {
      return Date.now() - currentMessage.firstTypeTime;
    } else {
      return lastMessage?.duration || NaN;
    }
  },
}));

export default useInputMessageStore;
