/*
 * @Author: wubo
 * @Date: 2023-08-29 21:04:57
 * @Description:
 * @LastEditTime: 2024-01-07 10:36:19
 * @LastEditors: zuoyuxing001
 */
import axios from '@/common/request';
import { ConnectorType, ConnectorStatus } from '@/store/connector';
import { RawAxiosRequestHeaders } from 'axios';

export async function currentUserUsingGET<T>() {
  const result = await axios.get<T>('/api/v1/user/currentUser');
  return result.data;
}

export async function userPropertiesGET<T>() {
  const result = await axios.get<T>('/api/v1/user/userProperties', {
    noAlertError: true,
  });
  return result.data;
}

export async function currentUserWithClientId<T>(params: { clientId: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/user/currentUserWithClientId?${paramArr.join('&')}`);
  return result.data;
}

export async function bloomTokenUsingGET<T>() {
  const result = await axios.get<T>('/oauth2/bloom/token', { noAlertError: true });
  return result.data;
}

export async function oneTapLoginTokenUsingGET<T>(params: { idTokenString: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/bloom/oauth2/google/onetap?${paramArr.join('&')}`, {
    useRiskControl: true,
  });
  return result.data;
}

export async function googleCodeLoginTokenUsingGET<T>(
  params: { code: string; uri: string },
  extraHeaders?: RawAxiosRequestHeaders,
) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/bloom/oauth2/google/code?${paramArr.join('&')}`, {
    useRiskControl: true,
    ...(extraHeaders ? { headers: extraHeaders } : {}),
  });
  return result.data;
}

export async function appleCodeLoginTokenUsingPOST<T>(
  params: { code: string },
  extraHeaders?: RawAxiosRequestHeaders,
) {
  const result = await axios.post<T>(
    `/api/v1/auth/appleAuthCode?code=${params.code}`,
    {},
    {
      useRiskControl: true,
      ...(extraHeaders ? { headers: extraHeaders } : {}),
    },
  );
  return result.data;
}

export async function stripeCheckUsingGET<T>(
  params: {
    successUrl: string;
    cancelUrl: string;
    productId: string;
  },
  extraHeaders?: RawAxiosRequestHeaders,
) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/stripe/check?${paramArr.join('&')}`, {
    useRiskControl: true,
    ...(extraHeaders ? { headers: extraHeaders } : {}),
  });
  return result.data;
}
export async function stripeCheckWithTrailAndCoupon<T>(
  params: {
    successUrl: string;
    cancelUrl: string;
    productId: string;
    days?: number;
  },
  extraHeaders?: RawAxiosRequestHeaders,
) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/stripe/checkWithTrailCoupon?${paramArr.join('&')}`, {
    useRiskControl: true,
    ...(extraHeaders ? { headers: extraHeaders } : {}),
  });
  return result.data;
}

export async function stripeCheckWithTrailUsingGET<T>(
  params: {
    successUrl: string;
    cancelUrl: string;
    productId: string;
    days?: number;
  },
  extraHeaders?: RawAxiosRequestHeaders,
) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/stripe/checkWithTrail?${paramArr.join('&')}`, {
    useRiskControl: true,
    ...(extraHeaders ? { headers: extraHeaders } : {}),
  });
  return result.data;
}

export async function stripeCheckWithCouponUsingGET<T>(
  params: {
    successUrl: string;
    cancelUrl: string;
    productId: string;
  },
  extraHeaders?: RawAxiosRequestHeaders,
) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/stripe/checkWithCoupon?${paramArr.join('&')}`, {
    useRiskControl: true,
    ...(extraHeaders ? { headers: extraHeaders } : {}),
  });
  return result.data;
}

// export async function stripeEmbedCheckoutUsingGET<T>(params: { productId: string }) {
//   const paramArr = [];
//   for (const key in params) {
//     paramArr.push(
//       `${key}=${
//         typeof (params as any)[key] === 'string'
//           ? (params as any)[key]
//           : JSON.stringify((params as any)[key])
//       }`,
//     );
//   }
//   const result = await axios.get<T>(`/api/v1/stripe/checkout?${paramArr.join('&')}`);
//   return result.data;
// }

export async function getStripePortalUsingGET<T>(params: {
  callbackUrl: string;
  customer: string;
}) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/stripe/portal?${paramArr.join('&')}`);
  return result.data;
}

export async function getFastspringPortalUsingGET<T>(params: { accountId: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/fastspring/portal?${paramArr.join('&')}`);
  return result.data;
}

export async function getMessageUsage<T>() {
  const result = await axios.get<T>(`/api/v1/user/usage/message`);
  return result.data;
}

export async function checkClientIDExist<T>(params: { clientId: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/user/clientId?${paramArr.join('&')}`, {
    noAlertError: true,
  });
  return result.data;
}

export async function upGradeProducts<T>(extraHeaders?: RawAxiosRequestHeaders) {
  const result = await axios.get<T>(`/api/v1/upgrade/upGradeProducts`, {
    useRiskControl: true,
    ...(extraHeaders ? { headers: extraHeaders } : {}),
  });
  return result.data;
}

export async function upgrade<T>(params: { subscription: string; productId: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/upgrade/upgrade?${paramArr.join('&')}`);
  return result.data;
}

export async function upgradeV2<T>(params: { subscription: string; productId: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/upgrade/upgradeV2?${paramArr.join('&')}`);
  return result.data;
}

export async function checkConfirm<T>(params: { confirmId: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/upgrade/checkConfirm?${paramArr.join('&')}`);
  return result.data;
}

// k:百分比，n:倒数第几位uid
export async function checkUserTest<T>(params: { k: number; n: number }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/api/v1/user/isTest?${paramArr.join('&')}`, {
    noAlertError: true,
  });
  return result.data;
}

// 发送验证码code的邮件
export async function toLoginByEmailValidationCodeUsingPOST<T>(
  params: { email: string; token?: string },
  extraHeaders?: RawAxiosRequestHeaders,
) {
  const result = await axios.post<T>(`/api/v1/auth/toLoginByEmailValidationCode`, params, {
    useRiskControl: true,
    ...(extraHeaders ? { headers: extraHeaders } : {}),
  });
  return result.data;
}

// 邮件、验证码登录
export async function loginByEmailValidationCodeUsingPOST<T>(params: {
  email: string;
  code: string;
}) {
  const result = await axios.post<T>(`/api/v1/auth/loginByEmailValidationCode`, params, {
    useRiskControl: true,
  });
  return result.data;
}

export async function getGoogleDriveToken<T>(
  params: { code: string; uri: string },
  extraHeaders?: RawAxiosRequestHeaders,
) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(`/bloom/oauth2/google/gdrivecallback?${paramArr.join('&')}`, {
    useRiskControl: true,
    ...(extraHeaders ? { headers: extraHeaders } : {}),
  });
  return result.data;
}

export async function checkConnector() {
  const result = await axios.get('/api/v1/user/checkConnector', { noAlertError: true });
  return result.data;
}

export async function updateConnector(params: { type: ConnectorType; status: ConnectorStatus }) {
  const result = await axios.post('/api/v1/user/updateConnector', params);
  return result.data;
}

export async function visitGET<T>(params: { sign: string }) {
  const result = await axios.post<T>('/api/v1/auth/visit', params, { noAlertError: true });
  return result.data;
}
