import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Menu } from 'antd';
import CustomIcon from '@/components/CustomIcon';

import { MenuListProps } from './types';

import './menu.less';
import { useDA } from '@/hooks';

export const MenuList = React.forwardRef((props: MenuListProps, ref) => {
  const { items, query } = props;
  const { sendDAEvent } = useDA();

  const scrollContainer = useRef<HTMLDivElement>(null);
  const activeItem = useRef<HTMLButtonElement>(null);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [selectedCommandIndex, setSelectedCommandIndex] = useState(-1);

  useEffect(() => {
    setSelectedGroupIndex(0);
    setSelectedCommandIndex(query ? 0 : -1);
  }, [items]);

  const selectItem = useCallback(
    (groupIndex: number, commandIndex: number) => {
      const command = items[groupIndex].commands[commandIndex];
      props.command(command);
      sendDAEvent('writing_AddBelowFunction');
    },
    [props],
  );

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      if (event.key === 'ArrowDown') {
        if (!items.length) {
          return false;
        }

        const commands = items[selectedGroupIndex].commands;

        let newCommandIndex = selectedCommandIndex + 1;
        let newGroupIndex = selectedGroupIndex;

        if (commands.length - 1 < newCommandIndex) {
          newCommandIndex = 0;
          newGroupIndex = selectedGroupIndex + 1;
        }

        if (items.length - 1 < newGroupIndex) {
          newGroupIndex = 0;
        }

        setSelectedCommandIndex(newCommandIndex);
        setSelectedGroupIndex(newGroupIndex);

        return true;
      }

      if (event.key === 'ArrowUp') {
        if (!items.length) {
          return false;
        }

        let newCommandIndex = selectedCommandIndex - 1;
        let newGroupIndex = selectedGroupIndex;

        if (newCommandIndex < 0) {
          newGroupIndex = selectedGroupIndex - 1;
          newCommandIndex = items[newGroupIndex]?.commands.length - 1 || 0;
        }

        if (newGroupIndex < 0) {
          newGroupIndex = items.length - 1;
          newCommandIndex = items[newGroupIndex].commands.length - 1;
        }

        setSelectedCommandIndex(newCommandIndex);
        setSelectedGroupIndex(newGroupIndex);

        return true;
      }

      if (event.key === 'Enter') {
        if (!items.length || selectedGroupIndex === -1 || selectedCommandIndex === -1) {
          return false;
        }

        selectItem(selectedGroupIndex, selectedCommandIndex);

        return true;
      }

      return false;
    },
  }));

  useEffect(() => {
    if (activeItem.current && scrollContainer.current) {
      const offsetTop = activeItem.current.offsetTop;
      const offsetHeight = activeItem.current.offsetHeight;

      scrollContainer.current.scrollTop = offsetTop - offsetHeight;
    }
  }, [selectedCommandIndex, selectedGroupIndex]);

  // const createCommandClickHandler = useCallback(
  //   (groupIndex: number, commandIndex: number) => {
  //     return () => {
  //       selectItem(groupIndex, commandIndex);
  //     };
  //   },
  //   [selectItem],
  // );

  if (!items.length) {
    return null;
  }

  return (
    <div className="slash-conatiner" ref={scrollContainer}>
      <Menu
        selectedKeys={[`${selectedGroupIndex}-${selectedCommandIndex}`]}
        items={items.map((group, groupIndex: number) => ({
          key: `${group.title}-wrapper`,
          label: group.title,
          type: 'group',
          children: group.commands.map((command, commandIndex) => ({
            key: `${groupIndex}-${commandIndex}`,
            label: (
              <>
                <CustomIcon type={command.iconName} className="menu-icon" />
                {groupIndex === items?.length - 1 && command.label}
              </>
            ),
            onClick: () => selectItem(groupIndex, commandIndex),
          })),
        }))}
      />
    </div>
  );
});

MenuList.displayName = 'MenuList';

export default MenuList;
