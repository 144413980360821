import { FEATURE_TIPS_INFO } from '@/common/config';
import CustomIcon from '@/components/CustomIcon';
import { Popover } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSundry } from '@/hooks';

import type { TooltipPlacement } from 'antd/es/tooltip';

import './index.less';

interface FeatureTipPopoverProps {
  tag: string;
  children?: React.ReactNode;
  canOpen?: boolean;
  placement?: TooltipPlacement;
  autoAdjustOverflow?: boolean;
  closeable?: boolean;
  onOpenChange?: (open: boolean) => void;
  onCloseTips?: (e?: React.MouseEvent) => void;
  /**
   * 自定义内容区域，不使用配置中的title和desc
   */
  content?: JSX.Element;
}

/**
 *
 *
 * 显示逻辑优先级：onechat > writing > workspace
 * 1.用户访问时按照优先级显示tips
 * 2.显示过的不再显示
 * 3.再次进入官网显示下一顺位的tips
 *
 * onechat以下几种情况会消失
 * 1.点击了➕就消失；
 * 2.切换到首页以外的页面会消失（包含点击sidebar或进入一个新/历史chat等）
 * 3.点击❎消失
 *
 */
const FeatureTipPopover: React.FC<FeatureTipPopoverProps> = (props) => {
  const {
    tag,
    children,
    placement = 'top',
    autoAdjustOverflow = true,
    closeable = true,
    onOpenChange,
    onCloseTips,
    content: contentProp,
  } = props;
  const { t } = useTranslation();

  const { currentTipGroup, updateTip } = useSundry();

  const [open, setOpen] = useState(false);

  const close = useCallback(() => {
    if (open) {
      setOpen(false);
      updateTip(tag, 0);
    }
  }, [tag, updateTip, open]);

  const handleCloseTips = useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation();
      close();
    },
    [close],
  );

  useEffect(() => {
    const _currentTip = currentTipGroup?.find((item) => item?.tag === tag);
    if (!_currentTip?.tag) {
      return;
    }
    setOpen(Number(_currentTip?.value) === 1);
  }, [tag, currentTipGroup]);

  const generateTipsContent = useCallback(
    (options: { title?: string; desc?: string; icon?: string }) => {
      const { title, desc, icon } = options;

      return (
        <div className="feature-tips-container">
          {contentProp ? (
            contentProp
          ) : (
            <>
              {icon && <CustomIcon type={icon} className="tips-icon" />}
              <div className="feature-tips-content">
                {title && <div className="feature-tips-content-title">{t(title)}</div>}
                {desc && <div className="feature-tips-content-subtitle">{t(desc)}</div>}
              </div>
            </>
          )}
          {closeable && (
            <div className="close-tips" onClick={onCloseTips || handleCloseTips}>
              <CustomIcon type="modalClose" className="close-icon" />
            </div>
          )}
        </div>
      );
    },
    [currentTipGroup, tag, t, handleCloseTips, onCloseTips, contentProp],
  );

  const content = useMemo(() => {
    if (!tag) return null;
    const tip = FEATURE_TIPS_INFO.flat().find((item) => item?.tag === tag);

    if (tip) {
      return generateTipsContent(tip);
    }
    return null;
  }, [tag, generateTipsContent, currentTipGroup]);

  if (!content) return <>{children}</>;

  return (
    <Popover
      open={open}
      overlayClassName="feature-tips-popover"
      placement={placement}
      content={content}
      trigger={['click']}
      autoAdjustOverflow={autoAdjustOverflow}
      onOpenChange={onOpenChange}
    >
      {children}
    </Popover>
  );
};

export default FeatureTipPopover;
