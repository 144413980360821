/*
 * @Author: wubo
 * @Date: 2023-07-03 20:01:11
 * @LastEditTime: 2023-12-18 16:05:42
 * @LastEditors: zuoyuxing001
 * @Description:
 */
import { useState, useEffect, useMemo, useRef } from 'react';
import { App, Modal } from 'antd';
import classNames from 'classnames';
import NewPay from './newPay';
import NewPayOld from './newPay.old';
import CustomIcon from '@/components/CustomIcon';
import {
  marketingActivePOST,
  fastSpringOrderUsingPOST,
  stripeCheckUsingGET,
  stripeCheckWithTrailUsingGET,
  stripeCheckWithTrailAndCoupon,
  getPayment,
  getAllProductsUsingGET,
  fastSpringPriceUsingGET,
  stripeCheckWithCouponUsingGET,
  fastSpringOrderCheck,
} from '@/services';
import { useDA, useFetchUsagesInfo, useFetchUserInfo, useGASendEvent, useSundry } from '@/hooks';
import { useUserStore } from '@/store';
import { isEmpty } from 'lodash-es';
import {
  MEMBERSHIP_ID,
  MEMBERSHIP_ID_MAP,
  MEMBERSHIP_ID_SENDEVENT,
  PAY_PROVIDER,
  PAY_PROVIDER_TEXT,
  CUSTOMER_JUMP_OPTIONS,
  PRICE_STATUS,
  TRIAL_STATUS,
  PRODUCT_TYPE,
  AB_EXPERIMENTS,
  RESPONSE_DATA_CODE,
} from '@/common/config';
import ChoosePayModal from '../ChoosePayModal';
import ToastContent from '@/components/ToastContent';
import type { ResponseType, IProduct, DiscountInfo, ProductInfoItem } from '@/type';
import { useTranslation } from 'react-i18next';
import { getExtraHeaders } from '@/common/utils';
import { requestTurnstileToken } from '@/common/turnstile';

interface PayModalProps {
  open: boolean;
  freeTrial?: number;
  shortMessage: string;
  triggerPaySource: string;
  onClose?: (isRefresh?: boolean) => void;
  paySuccess: (productYearPrice: string) => void;
  handleUpgradeMultiply: (showUpgrade?: boolean) => void;
}

interface AllProductsProps {
  productVOS: IProduct[];
  status: CUSTOMER_JUMP_OPTIONS;
  priceStatus: PRICE_STATUS;
  trialStatus: TRIAL_STATUS;
}
export interface ProductItem {
  productType: string;
  productIds: {
    [key: string]: string;
  };
  productPriceNum: string | number;
  productPriceText: string;
  productInfoSummary: string;
  productInfoItems?: ProductInfoItem[];
  productInfoItemsDetail?: ProductInfoItem[];
  membershipId: MEMBERSHIP_ID;
  strikePrice?: string | number;
  price?: string;
}

interface Params {
  successUrl: string;
  cancelUrl: string;
  productId: string;
  days?: number;
  coupon?: string;
}

const { VITE_APP_NAME, VITE_APP_FASTSPRING_FRONT_URL } = import.meta.env;
const EVENT_SUBSCRIBE_YEAR_SUCCESS = 'SubscribeYearSuccess';
const EVENT_SUBSCRIBE_MONTH_SUCCESS = 'SubscribeMonthSuccess';
const EVENT_SUBSCRIBE_YEAR_FAIL = 'SubscribeYearFail';
const EVENT_SUBSCRIBE_MONTH_FAIL = 'SubscribeMonthFail';

const PayModal: React.FC<PayModalProps> = (props) => {
  const {
    freeTrial,
    open,
    shortMessage,
    triggerPaySource,
    onClose,
    paySuccess,
    handleUpgradeMultiply,
  } = props;

  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();
  const { userInfo, setDiscountInfo, discountInfo } = useUserStore();
  const { fetchUsagesInfo } = useFetchUsagesInfo();
  const { fetchUserInfo } = useFetchUserInfo();
  const { experiment } = useSundry();
  const teamFeature = experiment(AB_EXPERIMENTS.TEAMS);
  const memberShipFeature = experiment(AB_EXPERIMENTS.MEMBERSHIP);

  const { message } = App.useApp();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [payData, setPayData] = useState<IProduct[]>([]);
  const [priceStatus, setPriceStatus] = useState<PRICE_STATUS | undefined>();
  const [customerHistoryStatus, setCustomerHistoryStatus] = useState<CUSTOMER_JUMP_OPTIONS>(
    CUSTOMER_JUMP_OPTIONS.Default,
  );
  const [provider, setProvider] = useState<number>(PAY_PROVIDER.Stripe);
  const [payProduct, setPayProduct] = useState<{ product?: ProductItem; subType?: string }>({});
  const [choosePayOpen, setChoosePayOpen] = useState(false);
  const [providers, setProviders] = useState<number[]>([]);
  const productYearPrice = useRef('');
  const isFirstLoadRef = useRef(true);
  const trialStatus = useRef(0);

  const fetchDiscountInfo = async () => {
    try {
      const res = await marketingActivePOST<ResponseType<DiscountInfo>>();
      setDiscountInfo(res.data?.discountCoupon);
    } catch (e) {
      setDiscountInfo(null);
      console.error(e);
    }
  };

  const sendSubscriptionEvent = (success: boolean, productId: string, isAnnual: boolean) => {
    const event = success
      ? isAnnual
        ? EVENT_SUBSCRIBE_YEAR_SUCCESS
        : EVENT_SUBSCRIBE_MONTH_SUCCESS
      : isAnnual
      ? EVENT_SUBSCRIBE_YEAR_FAIL
      : EVENT_SUBSCRIBE_MONTH_FAIL;
    let productTypeKey;
    if (isAnnual) {
      if (productId.includes('unlimited')) {
        productTypeKey = MEMBERSHIP_ID.YearUnlimited;
      } else if (productId.includes('proplus')) {
        productTypeKey = MEMBERSHIP_ID.YearProPlus;
      } else {
        productTypeKey = MEMBERSHIP_ID.YearPro;
      }
    } else {
      if (productId.includes('unlimited')) {
        productTypeKey = MEMBERSHIP_ID.MonthUnlimited;
      } else if (productId.includes('proplus')) {
        productTypeKey = MEMBERSHIP_ID.MonthProPlus;
      } else {
        productTypeKey = MEMBERSHIP_ID.MonthPro;
      }
    }
    const isFreeTrialProduct =
      (trialStatus?.current == TRIAL_STATUS.Pro && productTypeKey == MEMBERSHIP_ID.YearPro) ||
      (trialStatus?.current == TRIAL_STATUS.ProAndUnlimit &&
        [MEMBERSHIP_ID.YearPro, MEMBERSHIP_ID.YearUnlimited].includes(productTypeKey));

    if (!isFreeTrialProduct) {
      sendEvent(event, {
        productId,
        provider: PAY_PROVIDER_TEXT[PAY_PROVIDER.Fastspring],
        productType: MEMBERSHIP_ID_MAP[productTypeKey],
      });
      if (success) {
        sendEvent(MEMBERSHIP_ID_SENDEVENT[productTypeKey]);
        sendEvent('NewPay_success_status');
      }
    }
    if (trialStatus?.current == TRIAL_STATUS.Pro && productTypeKey == MEMBERSHIP_ID.YearPro) {
      if (success) {
        sendEvent('Freetrialsuccess', { type: 'Pro' });
      } else {
        sendEvent('Freetrialcancle', { type: 'Pro' });
      }
    }
    if (
      trialStatus?.current == TRIAL_STATUS.ProAndUnlimit &&
      [MEMBERSHIP_ID.YearPro, MEMBERSHIP_ID.YearUnlimited].includes(productTypeKey)
    ) {
      if (success) {
        sendEvent('Freetrialsuccess', {
          type: productTypeKey == MEMBERSHIP_ID.YearPro ? 'Pro' : 'Unlimite',
        });
      } else {
        sendEvent('Freetrialcancle', {
          type: productTypeKey == MEMBERSHIP_ID.YearPro ? 'Pro' : 'Unlimite',
        });
      }
    }
  };

  // fastspring付款成功回调,https://fastspringexamples.com/callback/data-popup-webhook-received/
  const fastSpringCallBack = async (data: any) => {
    const productId = data?.items?.[0]?.product;
    const isAnnual = productId?.includes('annually');
    try {
      await fastSpringOrderUsingPOST(data);
      // 通过subscriptionID 获取产品价格，用于展示
      let productTypeKey = 0;
      if (isAnnual) {
        if (productId.includes('unlimited')) {
          productTypeKey = MEMBERSHIP_ID.YearUnlimited;
        } else if (productId.includes('proplus')) {
          productTypeKey = MEMBERSHIP_ID.YearProPlus;
        } else {
          productTypeKey = MEMBERSHIP_ID.YearPro;
        }
      }
      // 判断是否是free-trial产品, 是的话 才进行价格查询（用于free-trial弹窗展示）
      const ifFreeTrialProduct =
        (trialStatus?.current == TRIAL_STATUS.Pro && productTypeKey == MEMBERSHIP_ID.YearPro) ||
        (trialStatus?.current == TRIAL_STATUS.ProAndUnlimit &&
          [MEMBERSHIP_ID.YearPro, MEMBERSHIP_ID.YearUnlimited].includes(productTypeKey));
      if (ifFreeTrialProduct) {
        try {
          const res = await fastSpringPriceUsingGET<ResponseType<string>>({
            subscriptionId: data?.items?.[0].subscription,
          });
          if (res.data) {
            productYearPrice.current = res.data;
          }
        } catch (e) {
          console.log(e);
        }
      }
      sendSubscriptionEvent(true, productId, isAnnual);
      handlePayClose(true);
      setChoosePayOpen(false);
      setPayProduct({});

      import('@/common/animations').then(async (animationsModule) => {
        const animationJson = await import('@/assets/animations/pay-success.json');

        animationJson?.default &&
          animationsModule?.showLottieAnimation?.({
            animationData: animationJson?.default,
          });
      });
    } catch (err) {
      console.error(err);
      sendSubscriptionEvent(false, productId, isAnnual);
    }
  };

  // fastspring付款窗口关闭回调,https://fastspringexamples.com/callback/data-popup-closed/
  const fastSpringClosedCallBack = async (data: any) => {
    sendEvent('Fastspring_closed', {
      callbackData: data,
    });
    await fetchUserInfo();
    const { userInfo } = useUserStore.getState();
    if (userInfo?.parsedRoles?.includes('Trail')) {
      paySuccess(productYearPrice.current);
    }
  };

  // fastspring错误回调,https://fastspringexamples.com/callback/data-error-callback/
  const fastSpringErrorCallBack = async (code: number, reason: string) => {
    sendEvent('Fastspring_error', {
      errorCode: code,
      errorReason: reason,
    });
  };

  const addFastSpringSBL = () => {
    const scriptId = 'fsc-api';
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      const storeFrontToUse = VITE_APP_FASTSPRING_FRONT_URL;
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.id = scriptId;
      script.src = 'https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js';
      script.dataset.storefront = storeFrontToUse;
      window.fastSpringCallBack = fastSpringCallBack;
      window.fastSpringClosedCallBack = fastSpringClosedCallBack;
      window.fastSpringErrorCallBack = fastSpringErrorCallBack;

      script.setAttribute('data-popup-webhook-received', 'fastSpringCallBack');
      script.setAttribute('data-popup-closed', 'fastSpringClosedCallBack');
      script.setAttribute('data-error-callback', 'fastSpringErrorCallBack');
      ['development', 'boe'].includes(VITE_APP_NAME) && script.setAttribute('data-debug', 'true');
      document.body.appendChild(script);
    }
  };

  const handlePayClose = async (isRefresh?: boolean) => {
    if (isRefresh) {
      await Promise.all([fetchUserInfo(), fetchUsagesInfo()]);
    }
    onClose?.();
  };

  const checkFastSpringPay = async (isPaypal = false) => {
    await fetchUserInfo();
    const userData = useUserStore.getState().userInfo;
    if (userData.isPro) {
      message.open({
        content: <ToastContent icon="error" content={t('common.aMember')} />,
      });
    } else {
      handleFastSpringPay(isPaypal);
    }
  };

  const fetchPayInfo = async (params?: { 'Cf-Captcha-Token'?: string }) => {
    setLoading(true);
    try {
      const extraHeaders = await getExtraHeaders();
      const res = await getAllProductsUsingGET<ResponseType<AllProductsProps>>({
        ...extraHeaders,
        ...params,
      });
      if (isFirstLoadRef?.current) {
        isFirstLoadRef.current = false;
      }
      setPayData(res?.data?.productVOS);
      setPriceStatus(res?.data?.priceStatus);
      trialStatus.current = res.data.trialStatus;
      setCustomerHistoryStatus(res?.data?.status);
      setLoading(false);
      sendEvent('Alltriggerpay');
    } catch (err: any) {
      if (err?.response?.data?.code === RESPONSE_DATA_CODE.Turnstile) {
        requestTurnstileToken().then((token) => {
          fetchPayInfo({
            ...params,
            'Cf-Captcha-Token': token as string,
          });
        });
      } else {
        handlePayClose();
        setLoading(false);
        sendEvent('Alltriggerpay');
      }
    }
  };

  // const isHaveProPlus = useMemo(() => {
  //   return payData.some((item) => {
  //     return [MEMBERSHIP_ID.YearProPlus, MEMBERSHIP_ID.MonthProPlus].includes(
  //       item.membership.membershipId,
  //     );
  //   });
  // }, [payData]);

  const productsLoading = useMemo(() => {
    return isFirstLoadRef?.current && loading;
  }, [loading]);

  const handleCancel = () => {
    sendDAEvent('ProductPurchaseModalButtonClick', { button_name: 'close' });
    sendEvent('Closesubscribe');
    if (trialStatus?.current) {
      sendEvent('Closefreetrial');
    }
    handlePayClose();
  };

  const getProdcutType = (product: ProductItem) => {
    return MEMBERSHIP_ID_MAP[product.membershipId];
  };

  const getRealProductId = (product: ProductItem) => {
    if (customerHistoryStatus === CUSTOMER_JUMP_OPTIONS.Stripe) {
      return product?.productIds[PAY_PROVIDER.Stripe];
    }
    if (customerHistoryStatus === CUSTOMER_JUMP_OPTIONS.Fastspring) {
      return product?.productIds[PAY_PROVIDER.Fastspring];
    }
    if (provider === PAY_PROVIDER.Paypal) {
      return product?.productIds[PAY_PROVIDER.Fastspring];
    }
    return product?.productIds[provider];
  };

  const handleStripePay = async () => {
    try {
      const { product, subType } = payProduct;
      const { price, productPriceText } = product as ProductItem;
      const productType = getProdcutType(product as ProductItem);
      const productId = getRealProductId(product as ProductItem);

      const shouldUseCoupon =
        (discountInfo?.monthlyDiscount &&
          discountInfo?.monthlyDiscount > 0 &&
          subType === 'month') ||
        (discountInfo?.yearlyDiscount && discountInfo?.yearlyDiscount > 0 && subType === 'year');

      // isFreeTrialProduct 标识是否是ft产品
      const isYearProTrial3Day =
        trialStatus?.current === TRIAL_STATUS.YearProAndThreeDay &&
        product?.membershipId === MEMBERSHIP_ID.YearPro;
      const isFreeTrialProduct =
        (subType === 'year' &&
          ((trialStatus?.current === TRIAL_STATUS.Pro &&
            [MEMBERSHIP_ID.YearPro].includes(product?.membershipId as number)) ||
            (trialStatus?.current == TRIAL_STATUS.ProAndUnlimit &&
              [MEMBERSHIP_ID.YearPro, MEMBERSHIP_ID.YearUnlimited].includes(
                product?.membershipId as number,
              )))) ||
        isYearProTrial3Day;
      const initUrlPrefix = `${location.origin}${location.pathname}?stripeSubType=${subType}&productType=${productType}&trialStatus=${freeTrial}`;
      const urlPrefix = isFreeTrialProduct
        ? `${initUrlPrefix}&productYearPrice=${encodeURIComponent('' + productPriceText + price)}`
        : initUrlPrefix;
      let servers = stripeCheckUsingGET;
      if (isFreeTrialProduct) {
        servers = shouldUseCoupon ? stripeCheckWithTrailAndCoupon : stripeCheckWithTrailUsingGET;
      } else {
        servers = shouldUseCoupon ? stripeCheckWithCouponUsingGET : stripeCheckUsingGET;
      }
      const params: Params = {
        successUrl: encodeURIComponent(`${urlPrefix}&stripeStatus=success`),
        cancelUrl: encodeURIComponent(`${urlPrefix}&stripeStatus=cancel`),
        productId: productId as string,
      };
      if (isFreeTrialProduct) {
        params.days = isYearProTrial3Day ? 3 : 7;
      }
      if (shouldUseCoupon) {
        params.coupon = discountInfo?.discountCouponCode;
      }
      const res = await servers<ResponseType<string>>(params);
      sendDAEvent('PurchaseUrlTriggeredSuccessfully', {
        forward_source: triggerPaySource,
        button_name: `${subType === 'year' ? 'subscribe_annually_' : 'subscribe_monthly'}_${
          product?.productType === PRODUCT_TYPE.Unlimited ? 'unlimited' : 'pro'
        }`,
        product_name: `${
          subType === 'year' ? 'annually' : 'monthly'
        }_${product?.productType.toLocaleLowerCase()}`,
        product_price: Number(product?.price),
        currency_type: product?.productPriceText,
        discount_rate:
          (subType === 'year' ? discountInfo?.yearlyDiscount : discountInfo?.monthlyDiscount) || 0,
        payment_channel: 'stripe',
      });
      location.href = res.data;
    } catch (e) {
      console.error(e);
    }
  };

  const handleFastSpringPay = async (isPaypal = false) => {
    const { product, subType } = payProduct;
    const productId = getRealProductId(product as ProductItem);
    const extraHeaders = await getExtraHeaders();
    const res = await fastSpringOrderCheck<ResponseType<string>>({ productId }, extraHeaders);
    window.fastspring.builder.tag({
      uid: userInfo?.uid,
      orderId: res.data,
    });
    if (isPaypal) {
      window.fastspring.builder.add(productId);
      window.fastspring.builder.payment('paypal');
    } else {
      const productSession = {
        checkout: true,
        products: [
          {
            path: productId,
            quantity: 1,
          },
        ],

        paymentContact: {
          email: userInfo.email,
        },
        language: navigator.language,
      };
      // 如果有优惠券，增加coupon字段
      const shouldUseCoupon =
        (payProduct.subType === 'month' && discountInfo?.monthlyDiscount) ||
        (payProduct.subType === 'year' && discountInfo?.yearlyDiscount);
      if (shouldUseCoupon) {
        (productSession as any).coupon = discountInfo?.discountCouponCode;
      }
      window.fastspring.builder.push(productSession);
      sendDAEvent('PurchaseUrlTriggeredSuccessfully', {
        forward_source: triggerPaySource,
        button_name: `${subType === 'year' ? 'subscribe_annually_' : 'subscribe_monthly'}_${
          product?.productType === PRODUCT_TYPE.Unlimited ? 'unlimited' : 'pro'
        }`,
        product_name: `${
          subType === 'year' ? 'annually' : 'monthly'
        }_${product?.productType.toLocaleLowerCase()}`,
        product_price: Number(product?.price),
        currency_type: product?.productPriceText,
        discount_rate:
          (subType === 'year' ? discountInfo?.yearlyDiscount : discountInfo?.monthlyDiscount) || 0,
        payment_channel: 'fastspring',
      });
    }
  };

  const handleChoosePayClose = () => {
    setChoosePayOpen(false);
    setProvider(PAY_PROVIDER.Stripe);
    sendEvent('ClosePaymethod');
  };

  const handleBeforePay = (params: { product: ProductItem; subType: string }) => {
    setPayProduct(params);
    sendEvent('Clicksubscribe', {
      productType: getProdcutType(params.product),
    });
    if (trialStatus?.current && params.subType === 'year') {
      if (
        trialStatus?.current === TRIAL_STATUS.Pro &&
        params.product.productType === PRODUCT_TYPE.Pro
      ) {
        sendEvent('Subscribefreetrial', { type: params.product.productType });
      }
      if (
        trialStatus?.current === TRIAL_STATUS.ProAndUnlimit &&
        [PRODUCT_TYPE.Pro, PRODUCT_TYPE.Unlimited].includes(
          params.product.productType as PRODUCT_TYPE,
        )
      ) {
        sendEvent('Subscribefreetrial', {
          type: params.product.productType,
        });
      }
    }
  };

  const handleConfirmPay = async () => {
    if (provider === PAY_PROVIDER.Paypal) {
      await checkFastSpringPay(true);
    }
    if (provider === PAY_PROVIDER.Fastspring) {
      await checkFastSpringPay();
    }
    if (provider === PAY_PROVIDER.Stripe) {
      await handleStripePay();
    }
    const productId = getRealProductId(payProduct?.product as ProductItem);
    sendEvent('Choosepaymethod', {
      paymethod: PAY_PROVIDER_TEXT[provider as PAY_PROVIDER],
      productId,
    });
  };

  // const handleStripeEmbedPayCancel = () => {
  //   setEmbedStripeOpen(false);
  //   setClientSecret('');
  // };

  useEffect(() => {
    if (choosePayOpen) {
      sendEvent('Paymethodpop-up');
    }
  }, [choosePayOpen]);

  useEffect(() => {
    const fetchPay = async () => {
      fetchDiscountInfo();
      await fetchPayInfo();
    };
    const fetchPayment = async () => {
      try {
        const res = await getPayment<ResponseType<number[]>>();
        setProviders(res.data);
      } catch (e) {
        console.error(e);
      }
    };

    if (open) {
      addFastSpringSBL();
      fetchPay();
      fetchPayment();
    }
  }, [open]);

  useEffect(() => {
    const handleAllPay = async () => {
      if (customerHistoryStatus === CUSTOMER_JUMP_OPTIONS.Stripe) {
        handleStripePay();
      } else if (customerHistoryStatus === CUSTOMER_JUMP_OPTIONS.Fastspring) {
        await checkFastSpringPay();
      } else {
        setChoosePayOpen(true);
      }
    };
    if (!isEmpty(payProduct)) {
      handleAllPay();
    }
  }, [payProduct]);

  // const stripeCheckoutProps = {
  //   clientSecret,
  //   onCancel: handleStripeEmbedPayCancel,
  //   onClose: handlePayClose,
  //   open: embedStripeOpen,
  // };

  return (
    <Modal
      wrapClassName={classNames('new-pay-modal', memberShipFeature && 'upgrade')}
      centered
      width={memberShipFeature ? 1023 : 1360}
      open={open}
      footer={null}
      styles={{
        mask: {
          backgroundColor: 'rgba(0, 0, 0, 0.88)',
        },
      }}
      onCancel={handleCancel}
      closeIcon={<CustomIcon className="close-icon" type="payClose" />}
      maskClosable={false}
      zIndex={1031}
      keyboard={false}
      destroyOnClose
    >
      {memberShipFeature ? (
        <NewPay
          freeTrial={freeTrial}
          products={payData}
          priceStatus={priceStatus}
          trialStatus={trialStatus?.current}
          isLoading={productsLoading}
          shortMessage={shortMessage}
          onBeforePay={handleBeforePay}
          handleUpgradeMultiply={(showUpgrade?: boolean) => {
            handleUpgradeMultiply?.(showUpgrade);
            handlePayClose();
          }}
        />
      ) : (
        <>
          {!teamFeature && (
            <div className="team-plan">
              <span>{t('layout.pay.teamsPlan')}</span>
              <br />
              <a
                href="mailto:customerservice@popai.pro"
                onClick={() =>
                  sendDAEvent('ProductPurchaseModalButtonClick', {
                    button_name: 'contact_us',
                  })
                }
              >
                {t('layout.pay.contactUs')}
              </a>
            </div>
          )}
          <NewPayOld
            products={payData}
            priceStatus={priceStatus}
            trialStatus={trialStatus?.current}
            isLoading={productsLoading}
            shortMessage={shortMessage}
            onBeforePay={handleBeforePay}
          />
        </>
      )}
      <ChoosePayModal
        open={choosePayOpen}
        provider={provider}
        providers={providers}
        onPayProviderChange={setProvider}
        onConfirmPay={handleConfirmPay}
        onCancel={handleChoosePayClose}
      />
    </Modal>
  );
};

export default PayModal;
