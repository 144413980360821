import { useState, useEffect, useRef } from 'react';
import { Input, Space, Popover } from 'antd';
import classNames from 'classnames';
import CustomIcon from '@/components/CustomIcon';
import { useDA, useGASendEvent } from '@/hooks';
import type { InputRef } from 'antd';
import { Channel, ChannelTypeEnum } from '@/type';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';

import './index.less';
import { getChatType } from '@/common/helpers/chatHelper';

interface ItemProps {
  record: Channel;
  selected?: boolean;
  collapsed?: boolean;
  onSelect?: (channel: Channel) => void;
  onDelete?: (value: string) => void;
  onEdit?: (value: string, name: string) => Promise<void>;
}

const Item: React.FC<ItemProps> = (props) => {
  const { record, selected = false, collapsed = false, onSelect, onDelete, onEdit } = props;

  const { t } = useTranslation();
  const { sendEvent } = useGASendEvent();
  const { sendDAEvent, preparePropertyFor } = useDA();

  const [edit, setEdit] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [value, setValue] = useState('');

  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (confirmOpen) {
      sendEvent('Deletecon');
    }
  }, [confirmOpen]);

  const handleSelect = () => {
    onSelect?.(record);

    sendDAEvent('SelectHistoryChat', {
      channelid: record.channelId,
      chattype: getChatType(record),
    });
    preparePropertyFor('Chat_EnterChat', 'forward_source', 'sidebar_history_enter');
  };

  const handleEdit = async (event: React.MouseEvent | React.KeyboardEvent | React.FocusEvent) => {
    event?.stopPropagation();

    const name = inputRef?.current?.input?.value?.trim() || '';

    if (name?.length > 0) {
      setValue(name);
      await onEdit?.(record?.channelId as string, name);
      setEdit(false);
    }
  };

  const handleDelete = (event: React.MouseEvent | undefined) => {
    event?.stopPropagation();
    onDelete?.(record?.channelId as string);
    handleOpenChange(false);
  };

  const handleEditStatusChange = (event: React.MouseEvent, edit: boolean) => {
    event?.stopPropagation();
    if (!edit) {
      sendEvent('Changename');
    }
    setEdit(edit);
  };

  const handleCancel = (event: React.KeyboardEvent) => {
    event?.stopPropagation();
    if (event?.key === 'Escape') {
      setEdit(false);
    }
  };

  const handleOpenChange = (open: boolean) => {
    setConfirmOpen(open);
  };

  useEffect(() => {
    if (!selected) {
      setEdit(false);
    }
  }, [selected]);

  useEffect(() => {
    setValue(record?.channelName as string);
  }, [record?.channelName]);

  const channelIconAndNameRender = () => {
    // 如果是one chat, 显示普通chat的图标
    if (record?.ext?.channelTypeEnum === ChannelTypeEnum.ONE_CHAT) {
      return <div className="item-icon" />;
    }

    return (
      <div
        className={classNames({
          'item-icon': true,
          [`item-icon-${record?.fileTypeEnum}`]: record?.fileTypeEnum,
          'item-multifile-icon': record?.docEntries?.multiDoc,
        })}
      />
    );
  };

  const expandedItemRender = () => (
    <li
      className={classNames({
        'item-expanded': true,
        'item-expanded-active': selected,
      })}
      onClick={handleSelect}
    >
      {channelIconAndNameRender()}
      <div className="item-title item-title-expanded">
        {edit ? (
          <Input
            className="item-input"
            size="small"
            defaultValue={value}
            ref={inputRef}
            autoFocus
            onPressEnter={handleEdit}
            onKeyUp={handleCancel}
            onBlur={handleEdit}
          />
        ) : (
          <h1 className="ellipsis" title={record?.channelName}>
            {value}
          </h1>
        )}
        {selected && (
          <Space size={12}>
            {edit ? (
              <>
                <CustomIcon
                  className="operate-icon"
                  type="check"
                  onMouseDown={(event) => event?.preventDefault()}
                  onClick={handleEdit}
                />
                {/* <CustomIcon
                  className={classNames({
                    "operate-icon": true,
                    "close_icon_menu_color":true
                  })}
                  type="close"
                  onMouseDown={(event) => event?.preventDefault()}
                  onClick={(event) => handleEditStatusChange(event, false)}
                /> */}
                <div
                  className={classNames({
                    'operate-icon': true,
                    close_icon_menu_color: true,
                  })}
                  onMouseDown={(event) => event?.preventDefault()}
                  onClick={(event) => handleEditStatusChange(event, false)}
                >
                  <CloseOutlined />
                </div>
              </>
            ) : (
              <>
                <CustomIcon
                  className="operate-icon"
                  type="edit"
                  onClick={(event) => handleEditStatusChange(event, true)}
                />
                <Popover
                  overlayClassName={'delete-confirm upgrade-delete-confirm'}
                  trigger="click"
                  placement="bottom"
                  color={'#FFF'}
                  content={
                    <>
                      <div className="delete-confirm-title">
                        {t('layout.history.deleteConversation')}
                      </div>
                      <Space className="buttons" size={8}>
                        <button onClick={() => handleOpenChange(false)}>
                          {t('common.cancel')}
                        </button>
                        <button onClick={handleDelete}>{t('common.delete')}</button>
                      </Space>
                    </>
                  }
                  open={confirmOpen}
                  onOpenChange={handleOpenChange}
                >
                  <CustomIcon className="operate-icon" type="delete" />
                </Popover>
              </>
            )}
          </Space>
        )}
      </div>
    </li>
  );

  const collapsedItemRender = () => (
    <li
      className={classNames({
        'item-collapsed': true,
        'item-collapsed-active': selected,
      })}
      onClick={handleSelect}
    >
      {channelIconAndNameRender()}
      <div className="item-title item-title-collapsed">
        <h1 className="ellipsis" title={record?.channelName}>
          {value}
        </h1>
      </div>
    </li>
  );

  return collapsed ? collapsedItemRender() : expandedItemRender();
};

export default Item;
