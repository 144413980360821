import { useState, useMemo } from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import './index.less';

interface SwitchProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

const Switch: React.FC<SwitchProps> = (props) => {
  const { checked, className = '', disabled = false, onChange } = props;
  const [defaultStatus, setDefaultStatus] = useState(true);

  const status = useMemo(() => {
    if (checked === undefined) {
      return defaultStatus;
    } else {
      return checked;
    }
  }, [checked, defaultStatus]);

  const switchId = useMemo(() => {
    return nanoid();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setDefaultStatus(checked);
    onChange?.(checked);
  }

  return (
    <label
      className={classNames({
        switch: true,
        'switch-disabled': disabled,
        'switch-checked': status,
        [className]: !!className,
      })}
      htmlFor={`switch-${switchId}`}
      onClick={(event) => event.stopPropagation()}
    >
      <input
        type="checkbox"
        name={`switch-${switchId}`}
        id={`switch-${switchId}`}
        checked={status}
        onChange={handleChange}
      />
      <span className="track">
        <span className="thumb" />
      </span>
    </label>
  );
}

export default Switch;
