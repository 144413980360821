import { useCallback } from 'react';
import { useUserStore } from '@/store';
import { currentUserUsingGET, userPropertiesGET, visitGET } from '@/services';
import { urlToBase64 } from '@/common/utils';
import { ResponseType } from '@/type';
import type { UserInfo, UserProperties, GuestInfo } from '@/store';
import { MEMBERSHIP_ID, NOT_NEED_LOGIN_PUBLICKEY, STORE_KEY } from '@/common/config';
import dayjs from 'dayjs';
import { updateMonitorConfig } from '@/common/monitor';
import JSEncrypt from 'jsencrypt';

const useFetchUserInfo = () => {
  const { setUserInfo, setUserProperties, setGuestToken } = useUserStore();

  const fetchUserInfo = useCallback(async () => {
    try {
      const res = await currentUserUsingGET<ResponseType<UserInfo>>();
      const userInfoData = res.data;
      const avatar = await urlToBase64(userInfoData?.picture as string);
      const timeRemaining = userInfoData.membershipExpDate
        ? Math.ceil(dayjs(userInfoData.membershipExpDate).diff(dayjs(), 'day', true))
        : null;

      const mergeUserinfo = {
        partnerStatus: '',
        partnerUid: '',
        refCode: '',
        ...userInfoData,
        isPro: [
          MEMBERSHIP_ID.MonthPro,
          MEMBERSHIP_ID.YearPro,
          MEMBERSHIP_ID.MonthProPlus,
          MEMBERSHIP_ID.YearProPlus,
          MEMBERSHIP_ID.MonthUnlimited,
          MEMBERSHIP_ID.YearUnlimited,
        ].includes(userInfoData.membershipId as number),
        isTrial: userInfoData.membershipId === MEMBERSHIP_ID.Trail,
        isProPlus: [
          MEMBERSHIP_ID.MonthProPlus,
          MEMBERSHIP_ID.YearProPlus,
          MEMBERSHIP_ID.MonthUnlimited,
          MEMBERSHIP_ID.YearUnlimited,
        ].includes(userInfoData.membershipId as number),
        isUnlimited: [MEMBERSHIP_ID.MonthUnlimited, MEMBERSHIP_ID.YearUnlimited].includes(
          userInfoData.membershipId as number,
        ),
        avatar: avatar as string,
        isLogin: true,
        timeRemaining,
      };

      setUserInfo(mergeUserinfo);
      updateMonitorConfig({ userinfo: mergeUserinfo });
      await fetchUserProperties();
    } catch (err: any) {}
  }, []);

  const fetchUserProperties = useCallback(async () => {
    try {
      const res = await userPropertiesGET<ResponseType<UserProperties>>();
      const userPropertiesData = {
        ...res.data,
        is_login: res.data._login,
      };

      setUserProperties(userPropertiesData);
    } catch (err: any) {}
  }, []);

  // 获取访客token以及visitorId
  const fetchGuestInfo = useCallback(async () => {
    try {
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(NOT_NEED_LOGIN_PUBLICKEY);
      const timestamp = new Date().toISOString();
      const encrypted = encrypt.encrypt(timestamp);
      const res = await visitGET<ResponseType<GuestInfo>>({ sign: encrypted as string });
      const { token } = res.data;
      localStorage.setItem(STORE_KEY.GuestAuthorization, token);
      setGuestToken(token);
    } catch (e) {}
  }, []);

  return { fetchUserInfo, fetchGuestInfo, fetchUserProperties };
};

export default useFetchUserInfo;
