/*
 * @Author: wubo
 * @Date: 2023-07-07 11:07:18
 * @LastEditTime: 2024-01-04 17:37:23
 * @LastEditors: zuoyuxing001
 * @Description:
 */
import { useMemo } from 'react';
import { Progress } from 'antd';
import { GptUsageItem, useCommonStore, useUserStore } from '@/store';
import { getBasicModelName } from '@/common/config';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { shouldUserUseNewModelName } from '@/common/gray';
import UserAvatar from '@/components/UserAvatar';
import './index.less';

interface IUsageProps {
  onSubscription?: () => void;
  children?: React.ReactNode;
}

const Usage: React.FC<IUsageProps> = (props) => {
  const { onSubscription, children } = props;
  const { usageInfo } = useCommonStore();
  const { userInfo } = useUserStore();

  const usageType = {
    gpt3: getBasicModelName(shouldUserUseNewModelName(userInfo)),
    gpt4: 'GPT-4',
  };

  const { t } = useTranslation();

  const isPro = useMemo(() => userInfo?.isPro, [userInfo]);

  const returnString = (value: string) => {
    if (value.includes('or')) {
      value = value.replace('or', t('layout.usage.or'));
    }
    return value;
  };

  return (
    <div
      className={classNames({
        'usage-detail': true,
        'usage-detail-unlimited': userInfo?.isUnlimited,
      })}
    >
      <div className="user-info-content">
        <div className="user-info">
          <UserAvatar
            className={classNames({
              'user-avatar-login': userInfo.isLogin,
            })}
            size={32}
            bordered={!userInfo?.picture}
            src={userInfo?.picture}
          />
        </div>
        <div className="user-name">
          <div className="user-name-text ellipsis">{userInfo?.username}</div>
          {children}
        </div>
      </div>
      <h1>{userInfo?.isUnlimited ? t('layout.usage.unlimitedPlan') : t('layout.usage.Usage')}</h1>
      {userInfo?.isUnlimited ? (
        <div className="unlimited-desc">{t('layout.usage.enjoyInfinite')}</div>
      ) : (
        Object.keys(usageInfo).map((key) => {
          if (!['gpt3', 'gpt4'].includes(key)) {
            return;
          }
          const { used, total } = usageInfo[key] as GptUsageItem;
          const percent = total ? (used / total) * 100 : 0;

          return (
            <div className="usage-item" key={key}>
              <div className="usage-info">
                <div className="usage-label">{returnString(usageType[key as 'gpt3' | 'gpt4'])}</div>
                <div className="usage-value">
                  {used}/{total} {t('layout.usage.per')}{' '}
                  {isPro ? t('layout.usage.month') : t('layout.usage.day')}
                </div>
              </div>
              <div className="usage-progress">
                <Progress
                  percent={percent}
                  showInfo={false}
                  strokeColor="#3C38FF"
                  trailColor={'#F5F7FA'}
                  strokeLinecap="butt"
                  size="small"
                />
              </div>
            </div>
          );
        })
      )}
      {userInfo.stripeCustomer || userInfo.fastspringCustomer ? (
        <button className="billing-btn" onClick={() => onSubscription?.()}>
          {t('layout.usage.manageBilling')}
        </button>
      ) : null}
    </div>
  );
};

export default Usage;
