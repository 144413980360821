import { memo, useMemo } from 'react';
import { isArabic } from '@/common/utils';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import RemarkGfm from 'remark-gfm';
import RemarkBreaks from 'remark-breaks';
import RemarkEmoji from 'remark-emoji';
import RemarkImages from 'remark-images';
import RemarkMath from 'remark-math';
import RemarkDirective from 'remark-directive';
import RemarkDirectiveRehype from 'remark-directive-rehype';
import RehypeKatex from 'rehype-katex';
import RehypeHighlight from 'rehype-highlight';
import RehypeRaw from 'rehype-raw';
import RehypeSanitize, { defaultSchema } from 'rehype-sanitize';

import PreElement from './PreElement';
import ImgElement from './ImgElement';
import OlElement from './OlElement';
import LiElement from './LiElement';
import SectionElement from './SectionElement';
import TableElement from './TableElement';
import LinkElement from './LinkElement';
import { QuoteInfoItem } from '@/type';
import QuoteList from '@/components/QuoteList';
import DocQuoteList from '@/components/DocQuoteList';

import './index.less';
import SupElement from './SupElement';

interface MarkdownProps {
  content?: string;
  className?: string;
  processing?: boolean;
  onCopied?: (copy: string) => void;
  messageId?: string;
  quoteInfoList?: QuoteInfoItem[];
  cursor?: boolean;
  intentName?: string;
}

const Markdown: React.FC<MarkdownProps> = memo((props) => {
  const {
    content = '',
    className = '',
    processing,
    onCopied,
    messageId,
    cursor,
    quoteInfoList = [],
    intentName,
  } = props;
  const isArabicContent = isArabic(content);

  const transformContent = (content: string) => {
    const pattern = /(\n|^)([-*]|[\d]\.)[^\n]*\n/g;
    const replacement = `$1$&`;
    return content.replace(pattern, replacement);
  };

  //以$开头，非英文字母结尾,中间全是数字的字符串，识别为美元符号
  const escapeDollarNumber = (text: string) => {
    return text?.replace(/(\$\d+[^a-zA-Z])/g, '\\$1');
  };

  const escapeBrackets = (text: string) => {
    const pattern = /(```[\s\S]*?```|`.*?`)|\\\[([\s\S]*?[^\\])\\\]|\\\((.*?)\\\)/g;
    return text.replace(pattern, (match, codeBlock, squareBracket, roundBracket) => {
      if (codeBlock) {
        return codeBlock;
      } else if (squareBracket) {
        return `$$${squareBracket}$$`;
      } else if (roundBracket) {
        return `$${roundBracket}$`;
      }
      return match;
    });
  };

  const escapedContent = useMemo(() => {
    return transformContent(escapeBrackets(escapeDollarNumber(content)));
  }, [content]);

  const showNormalQuote = useMemo(() => {
    return quoteInfoList?.length > 0 && intentName === 'general_search';
  }, [intentName, quoteInfoList, cursor]);
  // 如果是在分享页面，则直接展示文件搜索列表
  const showDocQuote = useMemo(() => {
    return quoteInfoList?.length > 0 && intentName === 'academic_search';
  }, [intentName, quoteInfoList, cursor]);

  return (
    <div
      className={classNames({
        'message-content-text': true,
        [className]: !!className,
      })}
      data-isarabic={isArabicContent}
    >
      <ReactMarkdown
        linkTarget="_blank"
        remarkPlugins={[
          [RemarkGfm, { singleTilde: false }],
          RemarkBreaks,
          RemarkEmoji,
          RemarkImages,
          RemarkMath,
          RemarkDirective,
          RemarkDirectiveRehype,
        ]}
        rehypePlugins={[
          RehypeRaw as any,
          //RehypeSanitize：防止脚本注入，默认使用github.com工作方式。参考：https://github.com/rehypejs/rehype-sanitize
          [
            RehypeSanitize,
            {
              ...defaultSchema,
              attributes: {
                ...defaultSchema.attributes,
                '*': [['className', /^language-./, 'math-inline', 'math-display', 'katex']],
              },
            },
          ],
          RehypeKatex,
          [
            RehypeHighlight,
            {
              ignoreMissing: true,
            },
          ],
        ]}
        remarkRehypeOptions={{
          footnoteLabel: 'Sources',
          footnoteLabelTagName: 'div',
          footnoteLabelProperties: {
            className: 'footnote-label',
          },
        }}
        components={{
          pre: (code) => <PreElement {...code} processing={processing} onCopied={onCopied} />,
          img: (code) => <ImgElement {...code} />,
          ol: (code) => <OlElement {...code} />,
          li: (code) => <LiElement {...code} />,
          section: (code) => <SectionElement {...code} />,
          table: (code) => <TableElement {...code} />,
          a: (code) => <LinkElement messageId={messageId} {...code} />,
          sup: (code) => (
            <SupElement {...code} quoteInfoList={quoteInfoList} messageId={messageId} />
          ),
        }}
      >
        {/* {transformContent(content as string)} */}
        {escapedContent}
      </ReactMarkdown>
      {showNormalQuote && <QuoteList links={quoteInfoList} messageId={messageId} />}
      {showDocQuote && <DocQuoteList links={quoteInfoList} messageId={messageId} />}
    </div>
  );
});

export default Markdown;
