import './index.less';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import useConnectorStore, { ConnectorType, ConnectorStatus, Connector } from '@/store/connector';
import { Button, App } from 'antd';
import CustomIcon from '@/components/CustomIcon';
import { updateConnector as updateConnectorReq } from '@/services/user';
import { useGoogleLogin } from '@react-oauth/google';
import { getGoogleDriveToken } from '@/services';
import { clone, throttle } from 'lodash-es';
import { EventConnectFrom, useGdriveEvent } from '@/hooks/useGdriveUpload';
import useGASendEvent from '@/hooks/useGASendEvent';
import type { ResponseType } from '@/type';

const ConnectorLink = () => {
  const { message } = App.useApp();
  const { t } = useTranslation();
  const { sendEvent } = useGASendEvent();
  const sendGdriveEvent = useGdriveEvent({ sendEvent });

  const { connectorList, updateConnector, resetConnector, updateGdrive } = useConnectorStore();
  const _connectorList = [
    {
      type: ConnectorType.Gdrive,
      icon: 'Gdrive',
      title: t('layout.settingModal.connectorGdrive'),
      explain: t('layout.settingModal.connectorGdriveExplain'),
    },
    {
      type: ConnectorType.Onenote,
      icon: 'Onenote',
      title: t('layout.settingModal.connectorOnenote'),
    },
    {
      type: ConnectorType.Onedrive,
      icon: 'Onedrive',
      title: t('layout.settingModal.connectorOnedrive'),
    },
    {
      type: ConnectorType.Evernote,
      icon: 'Evernote',
      title: t('layout.settingModal.connectorEvernote'),
    },
    {
      type: ConnectorType.Notion,
      icon: 'Notion',
      title: t('layout.settingModal.connectorNotion'),
    },
    {
      type: ConnectorType.Obsidian,
      icon: 'Obsidian',
      title: t('layout.settingModal.connectorObsidian'),
    },
    {
      type: ConnectorType.Zotero,
      icon: 'Zotero',
      title: t('layout.settingModal.connectorZotero'),
    },
  ];
  const gdrive = connectorList.find((c) => c.type === ConnectorType.Gdrive);
  const gdriveConnected = gdrive?.status === ConnectorStatus.enable;
  const [waitingList, setWaiting] = useState(Array(_connectorList.length).fill(false));
  const waitingListRef = useRef(waitingList);
  useEffect(() => {
    updateConnector();
  }, []);
  useEffect(() => {
    const waitingListData = clone(waitingList);
    _connectorList.map((localConnector, i) => {
      const serverConnector = connectorList.find((c) => c.type === localConnector.type);
      if (serverConnector) {
        waitingListData[i] = serverConnector.status === ConnectorStatus.waiting;
      }
    });
    waitingListRef.current = waitingListData;
    setWaiting(waitingListData);
  }, [connectorList]);

  const handleWaiting = async (connectorType: ConnectorType, i: number) => {
    const connector = connectorList.find((c) => c.type === connectorType);
    if (connector?.status === ConnectorStatus.waiting || waitingListRef.current[i]) {
      return;
    }

    try {
      await updateConnectorReq({
        type: connectorType,
        status: ConnectorStatus.waiting,
      });
      waitingListRef.current[i] = true;
      setWaiting(clone(waitingListRef.current));
    } catch {
      console.log('update connector failed.');
    }
  };
  const renderStatus = (connectorType: ConnectorType, i: number) => {
    const isGdrive = connectorType === ConnectorType.Gdrive;
    if (!isGdrive) {
      const inWaiting = waitingList[i];
      return (
        <Button
          className={`${inWaiting ? 'in' : 'not'} waiting-list`}
          onClick={throttle(() => {
            handleWaiting(connectorType, i);
          }, 1000)}
        >
          {inWaiting && <CustomIcon type="SuccessIcon" />}
          {inWaiting
            ? t('layout.settingModal.connectorinWaiting')
            : t('layout.settingModal.connectorWaiting')}
        </Button>
      );
    } else {
      if (gdriveConnected) {
        return (
          <Button danger onClick={throttle(handleClickGdrive, 1000)}>
            {t('layout.settingModal.disconnect')}
          </Button>
        );
      }
      return (
        <Button onClick={throttle(handleClickGdrive, 1000)}>
          {t('layout.settingModal.connect')}
        </Button>
      );
    }
  };
  const handleConnectGdrive = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/drive.file',
    overrideScope: true, // 默认权限包含 email 等，是不需要的 https://github.com/MomenSherif/react-oauth/issues/124
    onSuccess: async (codeResponse) => {
      if (codeResponse?.code) {
        let res = await getGoogleDriveToken<ResponseType<Connector>>({
          code: codeResponse?.code as string,
          uri: location.origin,
        });
        if (res.data) {
          sendGdriveEvent.connectFinish();
          message.success(t('components.GdriveUpload.connectSuccess'));
          updateGdrive(res.data);
        }
      }
    },
  });
  const handleClickGdrive = async () => {
    if (gdriveConnected) {
      sendGdriveEvent.disconnect();
      await updateConnectorReq({
        type: ConnectorType.Gdrive,
        status: ConnectorStatus.disable,
      });
      resetConnector();
      message.info(t('components.GdriveUpload.disconnectSuccess'));
    } else {
      sendGdriveEvent.connect(EventConnectFrom.setting);
      handleConnectGdrive();
    }
  };
  return (
    <div>
      <h2 className="connector-header">{t('layout.settingModal.connectorTitle')}</h2>
      {_connectorList.map((connetor, i) => (
        <div key={connetor.type} className="connector-item">
          <div className="connector-contianer">
            <div className="connector-info">
              <div className="connector-title">
                <CustomIcon type={connetor.icon} className="connector-icon" />
                <p>{connetor.title}</p>
              </div>
              {connetor.explain ? <p className="connector-explain">{connetor.explain}</p> : null}
            </div>
            <div className="connector-status">{renderStatus(connetor.type, i)}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConnectorLink;
