import { useMemo } from 'react';
import { Select, SelectProps, Space } from 'antd';
import UserAvatar from '@/components/UserAvatar';
import CustomIcon from '@/components/CustomIcon';
import {
  basicChatModelName,
  basicChatModelNameOld,
  basicDocModelName,
  basicDocModelNameOld,
  dallModelName,
  proChatModelName,
  proDocModelName,
  WebBrowsingName,
  ImageGenerationName,
  gpt4oName,
} from '@/common/model';
import { useGASendEvent, useSundry } from '@/hooks';
import { isModelPro, isModelFree, AB_EXPERIMENTS } from '@/common/config';
import { getBasicModelConfig, getProModelConfig, getWebBrowsingConfig } from '@/common/modelUtils';
import proBadge from '@/assets/pro-badge.png';
import unlimitedBadge from '@/assets/unlimited-badge.png';
import { useHomeSendControlStore } from '@/store';
import UserBadge from '@/layout/BasicLayout/components/UserBadge';
import type { LanguageModel, AiModelMap } from '@/type';

import './index.less';
import classNames from 'classnames';

interface ModelSelectProps {
  size?: 'small' | 'middle';
  value?: string;
  options?: LanguageModel[];
  disabled?: boolean;
  style?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
  selectClassname?: string;
  defaultOpen?: boolean;
  usedIn?: string;
  onChange?: (model: string) => void;
  onDropdownVisibleChange?: (open: boolean) => void;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
}

const aiModelMap: AiModelMap = {
  [basicChatModelNameOld]: getProModelConfig({ fontSize: '12px', background: '#19C37D' }),
  [basicDocModelNameOld]: getProModelConfig({ fontSize: '12px', background: '#19C37D' }),
  [basicChatModelName]: getBasicModelConfig({ fontSize: '16px' }),
  [basicDocModelName]: getBasicModelConfig({ fontSize: '16px' }),
  [proChatModelName]: getProModelConfig({ fontSize: '12px' }),
  [proDocModelName]: getProModelConfig({ fontSize: '12px' }),
  [gpt4oName]: getProModelConfig({ fontSize: '12px' }),
  [WebBrowsingName]: getWebBrowsingConfig({ fontSize: '16px' }),
  [ImageGenerationName]: getProModelConfig({ fontSize: '12px', background: '#2e2e2e' }),

  [dallModelName]: getProModelConfig({
    background: '#2e2e2e',
    fontSize: '12px',
  }),
  Basic: {
    name: 'logoText',
    style: {
      fontSize: '13px',
    },
  },
};

const ModelSelect: React.FC<ModelSelectProps> = (props) => {
  const {
    value,
    options = [],
    disabled = false,
    size = 'middle',
    style,
    selectStyle,
    // defaultOpen = false,
    usedIn = '',
    onChange,
    onDropdownVisibleChange,
    getPopupContainer,
  } = props;
  const { experiment } = useSundry();
  const memberShipFeature = experiment(AB_EXPERIMENTS.MEMBERSHIP);

  const { sendEvent } = useGASendEvent();
  const { homeModelSelectOpen, setHomeModelSelectOpen } = useHomeSendControlStore();

  const modelSelectorItemRender = (option: any) => (
    <Space className="model-selector-item" size={4}>
      <UserAvatar size={16} info={aiModelMap?.[option?.name]} />
      <span
        className={classNames({
          'model-selector-item-title': usedIn && !option?.desc,
        })}
      >
        {option?.name}
      </span>
    </Space>
  );

  const modelOptions = useMemo(() => {
    const formattedOptions = options?.map((option) => ({
      label: modelSelectorItemRender(option),
      value: option?.name,
      desc: option?.desc,
      disabled: option?.disabled,
      className: classNames({
        'modal-free-flash': homeModelSelectOpen && option.name === basicChatModelName,
      }),
    }));

    if (formattedOptions?.length > 0) {
      return formattedOptions;
    } else {
      //未获取到options数据之前，通过value找到要渲染的icon
      return [{ value, label: modelSelectorItemRender({ name: value }) }];
    }
  }, [options, homeModelSelectOpen]);

  const modelOptionRender: SelectProps['optionRender'] = (option) => {
    if (usedIn) {
      return (
        <div
          className={classNames({
            'model-selector-item-wrapper': true,
          })}
        >
          <div className="model-selector-item-head">
            {option.label}
            {isModelPro(option.value as string) &&
              (memberShipFeature ? (
                <UserBadge renderType="featureHint" />
              ) : (
                <img src={proBadge} width={27} height={12} />
              ))}
            {isModelFree(option.value as string) && homeModelSelectOpen && (
              <img src={unlimitedBadge} width={54} height={12} />
            )}
          </div>
          {option?.data?.desc && (
            <div className="model-selector-item-desc">{option?.data?.desc}</div>
          )}
        </div>
      );
    }

    if (isModelPro(option.value as string)) {
      return (
        <div className="model-selector-item-wrapper">
          {option.label}
          {/* pro模型显示pro图标 */}
          {memberShipFeature ? (
            <UserBadge renderType="featureHint" />
          ) : (
            <img src={proBadge} width={27} height={12} />
          )}
        </div>
      );
    }
    return option.label;
  };

  const handleChange = (model: string) => {
    sendEvent('Click_Model', { Model_Name: model });
    onChange?.(model);
  };

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    onDropdownVisibleChange?.(open);
    !open && setHomeModelSelectOpen(false);
  };

  /**
   *  （homeModelSelectOpen && usedIn） 需要主动弹起select选择框
   *   open为受控属性，如果直接修改，需要处理的情况太多，所以单拎出来一个
   *   其余属性相同
   */
  const openProps = homeModelSelectOpen && usedIn ? { open: true } : {};

  return !disabled ? (
    <Select
      size={size}
      className="model-selector"
      popupClassName={classNames({
        'model-selector-dropdown': true,
        'home-send-control-model-selector-dropdown': !!usedIn,
        hide:
          // defaultOpen &&
          modelOptions.length < 2, //只有一条option时不显示，主要是在新用户进入时默认展开，options真实数据从后端加载前不显示展开项
      })}
      style={selectStyle}
      value={value}
      variant="borderless"
      disabled={disabled}
      options={modelOptions}
      optionRender={modelOptionRender}
      suffixIcon={<CustomIcon className="chat-select-suffix arrow-icon" type="arrowBottomSquare" />}
      onChange={handleChange}
      popupMatchSelectWidth={false}
      // defaultOpen={defaultOpen}
      notFoundContent={null}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      getPopupContainer={getPopupContainer}
      onClick={(e) => handleClick(e)}
      {...openProps}
    />
  ) : (
    <div className="model-tag" style={style}>
      {modelSelectorItemRender({ name: value })}
    </div>
  );
};

export default ModelSelect;
