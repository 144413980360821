import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { helmetConfig } from '@/common/config';
import { MetaTag } from '@/type';

const TKD = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const helmetData = helmetConfig[currentPath];

  return (
    helmetData && (
      <Helmet>
        {/* <title>{helmetData.title}</title> */}
        {helmetData.meta.map((metaTag: MetaTag, index: number) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}
        {helmetData.alternate && (
          <link
            rel="alternate"
            media="only screen and (max-width: 640px)"
            href={helmetData.alternate}
          ></link>
        )}
      </Helmet>
    )
  );
};

export default TKD;
