/**
 * 联网开关
 */
import { ConfigProvider, Switch } from 'antd/lib';
import { Tooltip, type GetProps } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './index.less';

type SwitchType = GetProps<typeof Switch>;

const SWITCH_TOKEN = {
  trackHeight: 16,
  trackHeightSM: 9,
  trackMinWidth: 24,
  trackMinWidthSM: 13,
  handleSize: 12,
  handleSizeSM: 6.75,
  innerMaxMargin: 0,
  innerMinMargin: 0,
  innerMaxMarginSM: 0,
  innerMinMarginSM: 0,
};

const SwitchRag: React.FC<SwitchType> = (props) => {
  const { size = 'default' } = props;
  const { t } = useTranslation();
  return (
    <Tooltip title={!props?.disabled ? '' : t('components.send.ragDisabled')}>
      <div className={classNames(['switch-rag', `${size}`, props?.disabled && 'disabled'])}>
        {size === 'small' && <span className="iconfont popai-a-searchweb1" />}
        <div className="switch-container">
          <ConfigProvider
            theme={{
              components: {
                Switch: {
                  ...SWITCH_TOKEN,
                  trackPadding: size === 'small' ? 1 : 2,
                },
              },
            }}
          >
            <Switch size={size} {...props} />
          </ConfigProvider>
        </div>
        {size === 'default' && <span>{t('components.send.search')}</span>}
      </div>
    </Tooltip>
  );
};

export default SwitchRag;
