import { Node, mergeAttributes } from '@tiptap/core';
import { codemirrorPlugin } from './CodeMirror/CodeMirrorPlugin';
import { keymap } from 'prosemirror-keymap';
import { codeBlockKeymap } from './CodeMirror';
import { nanoid } from 'nanoid';

import './codeblock.less';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    code_block: {
      setCodeBlock: (attributes?: { lang: string }) => ReturnType;
    };
  }
}

export const CodeBlock = Node.create({
  name: 'code_block',

  content: 'text*',

  marks: '',

  group: 'block',

  code: true,

  defining: true,

  addAttributes() {
    return {
      lang: {
        default: 'javascript',
      },
      uniqueId: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'pre',
        preserveWhitespace: 'full',
        getAttrs: (node) => {
          const codeElement = node.querySelector('code');
          const classAttr = codeElement ? codeElement.getAttribute('class') : '';
          const language = classAttr ? classAttr.replace('language-', '') : 'javascript';
          return { lang: language };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['pre', mergeAttributes(HTMLAttributes, { 'data-language': HTMLAttributes.language })];
  },

  addProseMirrorPlugins() {
    return [codemirrorPlugin, keymap(codeBlockKeymap)];
  },

  addCommands() {
    return {
      setCodeBlock:
        (attributes) =>
        ({ commands, state, tr }) => {
          const { $from } = state.selection;
          const lineContent = $from.parent.textContent;

          if (lineContent.trim().length > 0) {
            tr.split($from.pos);
          }

          const uniqueId = `${this.name}-${nanoid()}`;
          const newAttributes = { ...attributes, uniqueId };

          return commands.setNode(this.name, newAttributes);
        },
    };
  },
});
