import { Modal, Radio } from 'antd';
import CustomIcon from '@/components/CustomIcon';
import { PAY_PROVIDER, PAY_PROVIDER_TEXT } from '@/common/config';

import type { RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.less';

interface ChoosePayModalProps {
  open: boolean;
  provider: number | null;
  providers: number[];
  onCancel?: () => void;
  onPayProviderChange?: (value: number) => void;
  onConfirmPay?: () => void;
}

const ChoosePayModal: React.FC<ChoosePayModalProps> = (props) => {
  const { open, provider, providers, onCancel, onPayProviderChange, onConfirmPay } = props;
  const { t } = useTranslation();

  const handleChange = (e: RadioChangeEvent) => {
    onPayProviderChange?.(e.target.value);
  };

  return (
    <Modal
      wrapClassName="choose-pay-modal"
      centered
      width={400}
      open={open}
      footer={null}
      closeIcon={<CustomIcon className="close-icon" type="modalClose" />}
      styles={{
        mask: {
          backgroundColor: 'rgba(0, 0, 0, 0.88)',
        },
      }}
      onCancel={onCancel}
    >
      <div className="choose-header">
        <h1 className="choose-header-title">{t('common.subscribeToPro')}</h1>
        <span className="choose-header-sub-title">{t('common.paymentMethod')}</span>
      </div>
      <div className="choose-content">
        <Radio.Group onChange={handleChange} value={provider}>
          {providers.map((item) => {
            return (
              <Radio value={item} key={item}>
                <div className="option-item">
                  <span>{PAY_PROVIDER_TEXT[item as PAY_PROVIDER]}</span>
                  <CustomIcon type={PAY_PROVIDER_TEXT[item as PAY_PROVIDER]} />
                </div>
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
      <div className="choose-bottom">
        <button onClick={onConfirmPay}>{t('common.confirmAndPay')}</button>
      </div>
    </Modal>
  );
};

export default ChoosePayModal;
