import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Skeleton, App } from 'antd';
import classNames from 'classnames';
import { omit } from 'lodash-es';
import { useChatStore } from '@/store';
import { useGASendEvent, useFetchUsagesInfo } from '@/hooks';
import { deleteChat, editChat } from '@/services';
import emptyLightSvg from '@/assets/empty-light.png';
import ToastContent from '@/components/ToastContent';
import { useTranslation } from 'react-i18next';

import Item from './Item';

import type { ResponseType, Channel } from '@/type';

import './index.less';

interface HistoryProps {
  collapsed?: boolean;
  dataSource: Channel[];
  loading?: boolean;
  onSelected?: () => void;
  onOperationComplete?: () => void;
}

const History: React.FC<HistoryProps> = (props) => {
  const { collapsed = false, dataSource, loading = false, onSelected, onOperationComplete } = props;

  const { t } = useTranslation();
  const { message } = App.useApp();

  const { sendEvent } = useGASendEvent();
  const { setChannel, setMessages, setFileAnchor, setActiveImageId, setMessagesReady } =
    useChatStore();
  const { fetchUsagesInfo } = useFetchUsagesInfo();

  const { conversation } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const handleDelete = async (value: string) => {
    try {
      await deleteChat<ResponseType<any>>({
        channelId: value,
      });

      onOperationComplete?.();

      message.open({
        content: <ToastContent icon="success" content={t('common.delete')} />,
      });

      fetchUsagesInfo();
      navigate(`/${location?.search}`);
      sendEvent('Condeleted');
    } catch (err: any) {
      message.open({
        content: <ToastContent icon="error" content={err?.data?.message} />,
      });
    }
  };

  const handleEdit = async (value: string, name: string) => {
    try {
      await editChat<ResponseType<any>>({
        channelId: value,
        channelName: name,
      });

      onOperationComplete?.();

      message.open({
        content: <ToastContent icon="success" content={t('common.Saved')} />,
      });

      sendEvent('Namechanged');
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelect = (channel: Channel) => {
    if (conversation === channel?.channelId) return;

    setChannel(
      omit(channel, ['themeName', 'slides', 'outline', 'sources', 'language', 'pdfConvertStatus']),
    );
    setMessages([]);
    setMessagesReady(false);
    setFileAnchor({});
    navigate(`/chat/${channel?.channelId}${location?.search}`);

    sendEvent('Clickchat');
    onSelected?.();
    setActiveImageId(undefined);
  };

  if (loading) {
    return (
      <div className="chat-list-container">
        <Skeleton active title={false} paragraph={{ rows: 4 }} />
      </div>
    );
  }

  return (
    <div
      className={classNames({
        'chat-list-container': true,
        'chat-list-container-expanded': !collapsed,
        'chat-list-container-collapsed': collapsed,
        'chat-list-empty': dataSource?.length === 0,
      })}
    >
      {dataSource?.length > 0 ? (
        <ul>
          {dataSource.map((item) => (
            <Item
              key={item?.channelId}
              record={item}
              selected={conversation === item?.channelId}
              collapsed={collapsed}
              onSelect={handleSelect}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          ))}
        </ul>
      ) : (
        <div className="chat-no-data">
          <img src={emptyLightSvg} alt="no data" />
          <div
            className={classNames({
              'empty-text': true,
              'empty-text-expanded': !collapsed,
              'empty-text-collapsed': collapsed,
            })}
          >
            {t('layout.history.startAI')}
          </div>
        </div>
      )}
    </div>
  );
};

export default History;
