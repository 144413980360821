import { UserInfo, EInputVersionStrategyValue, EInputModelStrategyValue, EEnhanceUploadStrategyValue } from '@/store';

/**
 * 是否使用新的输入框
 * 1. grayStrategy === 1
 * 2. grayStrategy === 2
 * 3. internal === 1
 * 4. 未登录用户
 */
export function shouldUserUseNewInput(user: UserInfo) {
  return !user.isLogin 
        || user.grayStrategyNew?.inputVersion === EInputVersionStrategyValue.NEW
        || user.internal === 1;
}

/**
 * 是否使用新的模型名称（GPT3.5 -> Standard）
 * 1. grayStrategy === 2
 * 2. internal === 1
 */
export function shouldUserUseNewModelName(user: UserInfo) {
  return user.grayStrategyNew?.inputModel === EInputModelStrategyValue.UPPER_MODEL || user.internal === 1;
}

/**
 * 是否展示enhance ppt
 * 未登录 或 命中灰度
 * @param user 
 * @returns 
 */
export function shouldUserUseEnhance(user: UserInfo) {
  return !user.isLogin || user.grayStrategyNew?.enhanceUpload === EEnhanceUploadStrategyValue.DISPLAY
}

/**
 * 是否使用新的首页
 */
export function shouldUserUseNewHome(user: UserInfo) {
  return user.internal === 1;
}
