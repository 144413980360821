import { Result } from 'antd';
import { Component, ErrorInfo, ReactNode } from 'react';
import { monitorReportError } from '@/common/monitor';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    //对应arms日志中错误的"类型"字段
    error.name = `Error-Boundary-Name:${error.name}`;
    //对应arms日志中错误的"名称"字段
    error.message = `Error-Boundary-Message:${error.message}`;
    monitorReportError(error);
    console.log('%c Line:30 🥓 info', 'color:#f5ce50', info.componentStack);

    if (
      error?.message?.includes('Failed to fetch dynamically imported module') ||
      error?.message?.includes('Unable to preload CSS for') ||
      error?.message?.includes('error loading dynamically imported module')
    ) {
      location.reload();
    }
    throw error;
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <Result status="info" title="error" subTitle="Sorry, something went wrong." />;
    }

    return this.props.children;
  }
}
