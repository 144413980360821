import classNames from 'classnames';
import { useDebounceFn } from 'ahooks';
import { useGASendEvent } from '@/hooks';
import type { ResponseType } from '@/type';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomIcon from '@/components/CustomIcon';
import { Button, Input, Skeleton, App } from 'antd';
import { StudentDiscountDetail } from './interface';
import { RESPONSE_DATA_CODE } from '@/common/config';
import ToastContent from '@/components/ToastContent';
import BillingCancelSuccessUrl from '@/assets/billing-cancel-success.png';

import {
  detailPreviewGet,
  sendStudentEmailValidationCode,
  toStudentEmailValidationCode,
} from '@/services';
import './studentDiscount.less';
interface StudentDiscountProps {
  subscriptionId: string;
  handleNext: () => void;
  onCancel: () => void;
}

const StudentDiscount: React.FC<StudentDiscountProps> = (props) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { sendEvent } = useGASendEvent();

  const { subscriptionId, handleNext, onCancel } = props;

  const [email, setEmail] = useState('');
  const [emailFormat, setEmailFormat] = useState(false); // 邮箱格式校验

  const [code, setCode] = useState('');
  const [codeFormat, setCodeFormat] = useState(false); // 验证码错误

  const [sentCode, setSentCode] = useState(false); // 是否发送过验证码
  const [isCounting, setIsCounting] = useState(false);
  const [countdown, setCountdown] = useState(0); // 倒计时

  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [studentDiscountSuccess, setStudentDiscountSuccess] = useState(false);

  const [studentDiscountDetail, setStudentDiscountDetail] = useState<StudentDiscountDetail>();

  const getStudentDiscountDetail = async () => {
    setSkeletonLoading(true);
    try {
      const res = await detailPreviewGet<ResponseType<StudentDiscountDetail>>({
        fastSpring: 'FastSpringStudentSummer',
        stripe: 'StripeStudentSummer',
      });
      setStudentDiscountDetail(res?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setSkeletonLoading(false);
    }
  };

  useEffect(() => {
    getStudentDiscountDetail();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (type === 'email') {
      setEmail(e.target.value);
      setEmailFormat(false);
    } else {
      setCode(e.target.value);
      setCodeFormat(false);
    }
  };

  const handleSendCode = () => {
    const regexPattern = /[A-Za-z0-9.\-+_]+@[a-z0-9.\-+_]+\.[a-z]+/;
    if (!regexPattern.test(email)) {
      setEmailFormat(true);
      return;
    }

    if (countdown) return;
    if (sentCode) {
      // 重新发送验证码
      sendEvent('students_resend');
      sendVerificationCode();
    } else {
      // 第一次发送验证码
      sendEvent('students_getcode');
      sendVerificationCode();
      setSentCode(true);
    }
  };

  const { run: handleSendCodeDebounce } = useDebounceFn(handleSendCode, {
    wait: 2000,
    leading: true,
    trailing: false,
  });

  // 发送验证码
  const sendVerificationCode = async () => {
    setIsCounting(true);
    setCountdown(59);
    try {
      await toStudentEmailValidationCode<ResponseType>({ email });
    } catch (e: any) {
      e?.data?.code === RESPONSE_DATA_CODE.NotStudentEmail && setEmailFormat(true);
      if (e?.data.code === 500) {
        const errMsg = e?.data?.message;
        message.open({
          content: <ToastContent icon="error" content={errMsg} />,
        });
      }
    }
  };

  useEffect(() => {
    let timer: any;

    if (isCounting && countdown > 0) {
      // 启动倒计时
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsCounting(false);
    }

    // 清理倒计时定时器
    return () => {
      clearInterval(timer);
    };
  }, [isCounting, countdown]);

  const handleVerify = async () => {
    if (!email || !code) return;
    setVerifyLoading(true);
    sendEvent('students_verify');
    try {
      const res = await sendStudentEmailValidationCode<ResponseType>({
        email,
        code: code?.trim(),
        subscriptionId,
      });
      if (res) {
        setStudentDiscountSuccess(true);
      }
    } catch (e: any) {
      e?.data?.code === RESPONSE_DATA_CODE.NotStudentEmail && setEmailFormat(true);
      e?.data?.code === RESPONSE_DATA_CODE.VerificationCodeNotMatch && setCodeFormat(true);
      if (e?.data.code === 500) {
        const errMsg = e?.data?.message;
        message.open({
          content: <ToastContent icon="error" content={errMsg} />,
        });
      }
    } finally {
      setVerifyLoading(false);
    }
  };

  const { run: handleVerifyDebounce } = useDebounceFn(handleVerify, {
    wait: 2000,
    leading: true,
    trailing: false,
  });

  const handleCancel = () => {
    sendEvent('students_cancelsubscription');
    handleNext();
  };

  const { run: handleCancelDebounce } = useDebounceFn(handleCancel, {
    wait: 2000,
    leading: true,
    trailing: false,
  });

  return (
    <div
      className={classNames({
        'student-discount': true,
        'student-discount-wra': studentDiscountSuccess,
      })}
    >
      {!studentDiscountSuccess && (
        <Skeleton loading={skeletonLoading} active paragraph={{ rows: 13 }}>
          <div className="student-discount-contentwra">
            <div className="student-discount-content">
              <CustomIcon type="payActivity" />
              <div className="student-discount-content-title">
                {t('layout.studentDiscount.title', {
                  discountDuration: studentDiscountDetail?.discountDuration,
                })}
                <br />
                <span>{t('layout.studentDiscount.subTitle')}</span>
              </div>
              <div
                className="student-discount-content-desc"
                dangerouslySetInnerHTML={{
                  __html: t('layout.studentDiscount.desc', {
                    discountDuration: studentDiscountDetail?.discountDuration,
                  }),
                }}
              ></div>
              <Input
                className={classNames({
                  'student-discount-content-email': true,
                  'student-discount-content-verification-error': emailFormat,
                })}
                placeholder={t('layout.studentDiscount.emailPlaceholder')}
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'email')}
              />
              {emailFormat && (
                <div className="student-discount-content-errortip">
                  {t('layout.studentDiscount.emailFormatMessage')}
                </div>
              )}
              <div
                className={classNames({
                  'student-discount-content-verification': true,
                  'student-discount-content-verification-error': codeFormat,
                })}
              >
                <Input
                  className="student-discount-content-verification-code"
                  placeholder={t('layout.studentDiscount.codePlaceholder')}
                  value={code}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'code')}
                />
                <div
                  className={classNames({
                    'student-discount-content-verification-base': true,
                    'student-discount-content-verification-getcode': !countdown,
                    'student-discount-content-verification-getcode-disabled': !countdown && !email,
                    'student-discount-content-verification-countdown': countdown,
                  })}
                  onClick={handleSendCodeDebounce}
                >
                  {countdown
                    ? `${countdown}s`
                    : sentCode
                    ? t('layout.studentDiscount.resend')
                    : t('layout.studentDiscount.getCode')}
                </div>
              </div>
              {codeFormat && (
                <div className="student-discount-content-errortip">
                  {t('layout.studentDiscount.codeFormatMessage')}
                </div>
              )}
            </div>
            <div className="student-discount-operation">
              <Button
                loading={verifyLoading}
                className={classNames({
                  'student-discount-operation-verify': true,
                  'student-discount-operation-verify-disabled': !email || !code,
                })}
                onClick={handleVerifyDebounce}
              >
                {t('layout.studentDiscount.verify')}
              </Button>
              <div className="student-discount-operation-cancel" onClick={handleCancelDebounce}>
                {t('layout.coupon.cancelSubscribtion')}
              </div>
            </div>
          </div>
        </Skeleton>
      )}

      {studentDiscountSuccess && (
        <div className="student-discount-success">
          <img
            className="student-discount-success-icon"
            width="116"
            src={BillingCancelSuccessUrl}
          />
          <div className="student-discount-success-congratulations">
            {t('layout.studentDiscount.congratulations')}
            <br />
            {t('layout.studentDiscount.enjoyFree', {
              discountDuration: studentDiscountDetail?.discountDuration,
            })}
          </div>
          <div className="student-discount-success-incredible">
            {t('layout.studentDiscount.readyExprience')}
          </div>
          <div className="student-discount-success-started" onClick={onCancel}>
            {t('layout.studentDiscount.getStarted')}
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentDiscount;
