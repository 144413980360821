import CustomIcon from '@/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import './SaveTag.less';
import classNames from 'classnames';

interface SaveTagProps {
  save: number;
  isUnlimited: boolean;
}

const SaveTag: React.FC<SaveTagProps> = ({ save, isUnlimited }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('product-save-tag', {
        'product-save-tag-unlimited': isUnlimited,
      })}
    >
      <CustomIcon className="triangle-icon" type="triangle" />
      <div className="product-save-tag-text">
        {t('layout.pay.save16', { discount: save * 100 })}
      </div>
    </div>
  );
};

export default SaveTag;
