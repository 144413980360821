import axios from '@/common/request';
import { UserFeatureTips } from '@/type';
import { USER_FEATURE_TIPS } from '@/common/config';

export async function getFeatureTips<T>(params: Omit<UserFeatureTips, 'value'>[]) {
  const result = await axios.post<T>(`/api/v1/user/tips`, { userTips: params });
  return result.data;
}

export async function updateFeatureTips<T>(tag: string, value?: 0 | 1) {
  const tipInfo = USER_FEATURE_TIPS.find((item) => item?.tag === tag);
  if (!tipInfo) {
    return;
  }
  const result = await axios.post<T>(`/api/v1/user/tips`, {
    userTips: [{ ...tipInfo, value: value || 0 } as UserFeatureTips],
  });
  return result.data;
}

export async function getFeatureInfo<T>(params: {
  abKeyList?: string[];
  userTips?: Omit<UserFeatureTips, 'value'>[];
}) {
  const result = await axios.post<T>(`/api/v1/user/tips`, params);
  return result.data;
}
