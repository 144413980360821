import { Language } from '@/type';
import { Popover } from 'antd';
import { useCommonStore } from '@/store';
import { useTranslation } from 'react-i18next';

import type { TooltipPlacement } from 'antd/es/tooltip';

interface LanguageSelectProps {
  open?: boolean;
  overlayClassName?: string;
  placement?: TooltipPlacement;
  arrow?: boolean | { pointAtCenter: boolean };
  autoAdjustOverflow?: boolean;
  trigger?: string | string[];
  languageBlockRender: (title: string, languages: Language[]) => React.ReactNode;
  popupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
}

const LanguageSelect: React.FC<LanguageSelectProps> = (props) => {
  const {
    open,
    overlayClassName,
    placement = 'top',
    arrow = false,
    autoAdjustOverflow = true,
    trigger = 'hover',
    languageBlockRender,
    popupContainer = () => document.body,
    onOpenChange,
    children,
  } = props;

  const { languageInfo } = useCommonStore();
  const { t } = useTranslation();

  const popoverProps = {
    open,
    overlayClassName,
    placement,
    arrow,
    autoAdjustOverflow,
    trigger,
    content: (
      <div className="language-container">
        {languageBlockRender(t('common.Recommend'), languageInfo?.recommend as Language[])}
        {languageBlockRender('All', languageInfo?.all as Language[])}
      </div>
    ),
    getPopupContainer: popupContainer,
    onOpenChange,
  };

  if (open === undefined) {
    delete popoverProps.open;
  }

  // @ts-expect-error ignore
  return <Popover {...popoverProps}>{children}</Popover>;
};

export default LanguageSelect;
