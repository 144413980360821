/*
 * @Author: wubo
 * @Date: 2023-06-20 11:29:15
 * @LastEditTime: 2023-07-27 15:45:40
 * @LastEditors: wubo
 * @Descripttion:
 */
import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { STORE_KEY, defaultUserInfo, defaultUserProperties } from '@/common/config';
import { DiscountCouponInfo } from '@/type';

/**
 * 灰度策略名称
 * @see https://01ai.feishu.cn/docx/SIbEdUPGSoWLvaxp5rEcHIahnsd
 */
type GrayStrategyKey = 'inputModel' | 'enhanceUpload' | 'inputVersion';

/**
 * 新输入框使用模型灰度策略
 */
export enum EInputModelStrategyValue {
  /**gpt 3.5 */
  LOWER_MODEL = 1,
  /**gpt 4 */
  UPPER_MODEL = 2,
}

export enum EEnhanceUploadStrategyValue {
  /**不展示upload to enhande按钮 */
  HIDDEN = 0,
  /**展示upload to enhande按钮 */
  DISPLAY = 1,
}

export enum EInputVersionStrategyValue {
  /**input渲染SendController2 */
  OLD = 1,
  /**input渲染SendController */
  NEW = 2,
}

/**
 * 灰度策略
 */
type GrayStrategyItem = {
  [K in GrayStrategyKey]: K extends 'inputModel'
    ? EInputModelStrategyValue
    : K extends 'enhanceUpload'
    ? EEnhanceUploadStrategyValue
    : K extends 'inputVersion'
    ? EInputVersionStrategyValue
    : never;
};

export interface UserInfo {
  uid: string | null;
  email: string | null;
  username: string | null;
  picture: string | null;
  avatar: string; // base64转换后的头像
  isLogin: boolean | null;
  isPro: boolean;
  isProPlus: boolean;
  isUnlimited: boolean;
  isTrial: boolean;
  isNew: number | null;
  timeRemaining: number | null;
  membershipId: number | null;
  membershipExpDate: string | null; // 订阅过期时间
  stripeCustomer: string | null; // 是否stripe付费过
  fastspringCustomer: string | null; // 是否fastspring付费过
  createdAt: string | null;
  internal: number; // 1 内部员工
  grayStrategyNew?: GrayStrategyItem;
  partnerStatus?: string; //  是否是推广者  0 active、 1 pending 、2 blocked
  partnerUid?: string; // 推广者uid
  refCode?: string; // 推广码
  isDebugUser?: boolean;
  parsedRoles: string[];
}

export interface UserProperties {
  _login?: boolean;
  is_login: boolean;
  user_session_source: string;
  account_name: string;
  product_type: string;
  interval: string;
  subscribe_start_time: string | null;
  subscribe_expire_time: string | null;
  membership_id: number;
  visitorId?: string;
}

export interface GuestInfo {
  token: string;
  visitorId: string;
}

export interface StagedFeature {
  id?: number;
  featureId?: number;
  featureName?: string;
  rule?: string;
  ruleParams?: string;
  createdAt?: Date;
  updatedAt?: Date;
  status?: number;
}

interface AuthStatus {
  showLogin: boolean;
  loginOpened: boolean;
}

interface UserState {
  userInfo: UserInfo;
  setUserInfo: (payload: Partial<UserInfo>) => void;
  userProperties: UserProperties;
  setUserProperties: (payload: Partial<UserProperties>) => void;
  stagedFeatures: StagedFeature[] | null;
  setStagedFeatures: (payload: StagedFeature[]) => void;
  authStatus: AuthStatus;
  setAuthStatus: (payload: Partial<AuthStatus>) => void;
  shareKey: string;
  setShareKey: (payload: string) => void;
  discountInfo: DiscountCouponInfo | null;
  setDiscountInfo: (payload: DiscountCouponInfo | null) => void;
  guestToken: string;
  setGuestToken: (payload: string) => void;
}

const useUserStore = create<
  UserState,
  [['zustand/devtools', never], ['zustand/persist', Partial<UserState>]]
>(
  devtools(
    persist(
      (set) => ({
        userInfo: defaultUserInfo,
        userProperties: defaultUserProperties,
        setUserProperties: (payload: Partial<UserProperties>) =>
          set(({ userProperties }) => {
            return { userProperties: { ...userProperties, ...payload } };
          }),
        setUserInfo: (payload: Partial<UserInfo>) =>
          set(({ userInfo }) => {
            return { userInfo: { ...userInfo, ...payload } };
          }),
        stagedFeatures: null,
        setStagedFeatures: (payload: StagedFeature[]) => set({ stagedFeatures: payload }),
        authStatus: {
          showLogin: false,
          loginOpened: false,
        },
        setAuthStatus: (payload: Partial<AuthStatus>) =>
          set(({ authStatus }) => {
            return { authStatus: { ...authStatus, ...payload } };
          }),
        shareKey: '',
        setShareKey: (payload: string) => set({ shareKey: payload }),
        discountInfo: null,
        setDiscountInfo: (payload: DiscountCouponInfo | null) => set({ discountInfo: payload }),
        guestToken: '',
        setGuestToken: (payload: string) => set({ guestToken: payload }),
      }),
      {
        name: STORE_KEY.Userinfo,
        storage: createJSONStorage(() => localStorage),
        partialize: ({
          userInfo,
          userProperties,
          authStatus,
          shareKey,
          discountInfo,
        }: // guestToken,
        UserState) => ({
          userInfo,
          userProperties,
          authStatus,
          shareKey,
          discountInfo,
          // guestToken,
        }),
      },
    ),
  ),
);

export default useUserStore;
